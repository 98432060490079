import { Directive, ElementRef } from '@angular/core';

import { VirtualScrollService } from '@shared/providers/virtual-scroll.service';

@Directive({
  selector: '[appVirtualScroll]'
})
export class VirtualScrollDirective {
  constructor(private elementRef: ElementRef, private virtualScroll: VirtualScrollService) {
    this.virtualScroll.setScrollElement(this.elementRef.nativeElement);
  }
}

import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromVenue from './venue.reducer';
import * as fromComponent from './component.reducer';
import * as fromCategory from './category.reducer';
import * as fromPlaceholder from './placeholder.reducer';
import * as fromTranslation from './translation.reducer';
import * as fromModal from './modal.reducer';
import * as fromIcon from './icon.reducer';

import { VenueCategoryModel } from '@components/venue/models/venue.model';

export const featureKey = 'venueComponent';

export interface State {
  venues: fromVenue.State;
  components: fromComponent.State;
  categories: fromCategory.State;
  placeholders: fromPlaceholder.State;
  translations: fromTranslation.State;
  modal: fromModal.State;
  icons: fromIcon.State;
}

export const combinedReducers = combineReducers({
  venues: fromVenue.reducer,
  components: fromComponent.reducer,
  categories: fromCategory.reducer,
  placeholders: fromPlaceholder.reducer,
  translations: fromTranslation.reducer,
  modal: fromModal.reducer,
  icons: fromIcon.reducer,
});

// Wrap combined reducers by function for AOT build compatibility
export function reducer(state: State, action: Action): State {
  return combinedReducers(state, action);
}

export const selectVenueFeature = createFeatureSelector<State>(featureKey);

export const selectVenueState = createSelector(
  selectVenueFeature,
  state => state.venues
);

export const selectVenues = createSelector(
  selectVenueState,
  fromVenue.selectVenues
);

export const selectVenueComponentState = createSelector(
  selectVenueFeature,
  state => state.components
);

export const selectVenueComponentLoading = createSelector(
  selectVenueComponentState,
  fromComponent.selectComponentLoading
);

export const selectVenueCategoryState = createSelector(
  selectVenueFeature,
  state => state.categories
);

export const selectVenueCategories = createSelector(
  selectVenueCategoryState,
  fromCategory.selectCategories
);

export const selectActiveVenueCategory = createSelector(
  selectVenueCategoryState,
  fromCategory.selectActiveCategory
);

export const selectVenuePlaceholderState = createSelector(
  selectVenueFeature,
  state => state.placeholders
);

export const selectVenueIconState = createSelector(
  selectVenueFeature,
  state => state.icons
);

export const selectVenuePlaceholderUuid = createSelector(
  selectVenuePlaceholderState,
  fromPlaceholder.selectPlaceholderUuid
);

export const selectVenuePlaceholderShown = createSelector(
  selectVenuePlaceholderState,
  fromPlaceholder.selectPlaceholderShown
);

export const selectVenuePlaceholderFocused = createSelector(
  selectVenuePlaceholderState,
  fromPlaceholder.selectPlaceholderFocused
);

// Placeholder is a "faked" category entity
export const selectVenueCategoriesWithPlaceholder = createSelector(
  selectVenueCategories,
  selectVenuePlaceholderUuid,
  selectVenuePlaceholderShown,
  (categories, uuid, shown) => {
    const placeholder: VenueCategoryModel = {
      id: 0, componentId: 0, name: '', order: categories.length, uuid,
    };

    return [...categories].concat(shown ? placeholder : []);
  }
);

export const selectVenueTranslationState = createSelector(
  selectVenueFeature,
  state => state.translations
);

export const selectVenueTranslation = createSelector(
  selectVenueTranslationState,
  fromTranslation.selectVenueTranslation
);

export const selectVenueTranslationLoading = createSelector(
  selectVenueTranslationState,
  fromTranslation.selectVenueTranslationLoading
);

export const selectVenueCategoryTranslationState = createSelector(
  selectVenueFeature,
  state => state.translations
);

export const selectVenueCategoryTranslation = () => createSelector(
  selectVenueCategoryTranslationState,
  fromTranslation.selectCategoryTranslation()
);

export const selectVenueCategoryTranslationLoading = createSelector(
  selectVenueCategoryTranslationState,
  fromTranslation.selectVenueTranslationLoading
);

export const selectVenueModalState = createSelector(
  selectVenueFeature,
  state => state.modal
);

export const selectVenueModalSaving = createSelector(
  selectVenueModalState,
  fromModal.selectSaving
);

export const selectVenueModalSaved = createSelector(
  selectVenueModalState,
  fromModal.selectSaved
);

export const selectIconsLoading = createSelector(
  selectVenueIconState,
  fromIcon.selectLoading
);

export const selectIcons = createSelector(
  selectVenueIconState,
  fromIcon.selectIcons
);

export const selectIconsError = createSelector(
  selectVenueIconState,
  fromIcon.selectError
);
import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import * as fromComponent from './reducers';
import { Observable } from "rxjs";
import { ComponentUtilsPatchType, ComponentUtilsType } from "./models/component-utils.model";
import { LanguageModel } from "../language/models/language.model";
import { ComponentModel } from "@shared/models/component.model";
import { clearComponentUtilsState, loadComponentUtils, patchComponentUtils } from "./actions/component-utils.actions";
import { EventLanguageModel } from "../event/models/event-language.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ComponentUtilsFacadeService {
  constructor(private store$: Store<any>) {}

  load(component: ComponentModel, lang: EventLanguageModel) {
    this.store$.dispatch(loadComponentUtils({ component, lang }));
  }

  patch(component: ComponentModel, lang: EventLanguageModel, utils: ComponentUtilsPatchType) {
    this.store$.dispatch(patchComponentUtils({ component, lang, utils }));
  }

  clearState(): void {
    this.store$.dispatch(clearComponentUtilsState());
  }

  getLoading(): Observable<boolean> {
    return this.store$.pipe(select(fromComponent.selectComponentUtilsLoading));
  }

  getUpdating(): Observable<boolean> {
    return this.store$.pipe(select(fromComponent.selectComponentUtilsUpdating));
  }

  getProcessingUpdateRequestsAmount(): Observable<number> {
    return this.store$.pipe(select(fromComponent.selectProcessingUpdateRequestsAmount));
  }

  getError(): Observable<Error> {
    return this.store$.pipe(select(fromComponent.selectComponentUtilsError));
  }

  getUtils(): Observable<ComponentUtilsType> {
    return this.store$.pipe(select(fromComponent.selectComponentUtils));
  }

  getUtilsTranslations(): Observable<{ [key: string]: ComponentUtilsType }> {
    return this.store$.pipe(select(fromComponent.selectComponentUtilsTranslations));
  }

  getUtilsTranslation(key: string): Observable<ComponentUtilsType> {
    return this.getUtilsTranslations()
      .pipe(
        map(object => object[key] || null)
      );
  }
  
  getLoadedComponentId(): Observable<number> {
    return this.store$.pipe(select(fromComponent.selectComponentUtilsLoadedComponentId));
  }
}
<div class="paginator">
    <ul class="pagination" [class.paginator-mobile]="mobile">
        <li class="page-item prev-btn" [class.disabled]="page == 1">
            <a class="page-link" href="javascript:void(0)" (click)="previous()">{{ 'paginator.previous' | translate }}</a>
        </li>
        
        <!-- mobile first page button -->
        <li class="page-item first-btn" [class.active]="page === 1" *ngIf="mobile">
            <a class="page-link" href="javascript:void(0)" (click)="onPageSelect(1)">First</a>
        </li>
        <div class="pages" *ngIf="!mobile">

            <li class="pages-item" [class.active]="page === 1" (click)="onPageSelect(1)" *ngIf="!mobile">
                <a class="pages-link" href="javascript:void(0)">
                    <span>1</span>
                </a>
            </li>

            <li class="pages-item" *ngFor="let pageNr of getPagesToDisplay()" [class.active]="pageNr === page" (click)="onPageSelect(pageNr)">
                <a class="pages-link" href="javascript:void(0)">
                    <span>{{ pageNr }}</span>
                </a>
            </li>

            <span class="px-2" *ngIf="totalPages > 1">...</span>

            <li class="pages-item" [class.active]="page === totalPages" *ngIf="totalPages > 1" (click)="onPageSelect(totalPages)">
                <a class="pages-link" href="javascript:void(0)">
                    <span>{{ totalPages }}</span>
                </a>
            </li>

        </div>
        <li>
          <select class="form-control arrow-input" (change)="option($event.target.value)">
            <option *ngFor="let option of options">{{ showOption(option) }}</option>
          </select>
        </li>
        <li class="page-item next-btn" [class.disabled]="page == totalPages">
            <a class="page-link" href="javascript:void(0)" (click)="next()">{{ 'paginator.next' | translate }}</a>
        </li>
    </ul>
</div>
import { createAction, props } from '@ngrx/store';
import { EventUserModel } from '@shared/models/event-user.model';

import { EventModel } from '@store/features/event/models/event.model';

const loadEventUsers = createAction(
  '[User Select/Click] Load Event Users',
  props<{ event: EventModel }>()
);

const searchEventUsers = createAction(
  '[User Select/Search Input] Search Event Users',
  props<{ event: EventModel, searchTerm: string }>()
);

const loadEventUsersSuccess = createAction(
  '[Event/API] Load Event Users Success',
  props<{ users: EventUserModel[], limit: number, hasMore: boolean }>()
);

const loadEventUsersFailure = createAction(
  '[Event/API] Load Event Users Failure',
  props<{ error: Error }>()
);

const loadNextEventUsers = createAction(
  '[User Select/Scroll] Load Next Event Users',
);

const loadNextEventUsersSuccess = createAction(
  '[Event/API] Load Next Event Users Success',
  props<{ users: EventUserModel[], limit: number, hasMore: boolean }>()
);

const loadNextEventUsersFailure = createAction(
  '[Event/API] Load Next Event Users Success',
  props<{ error: Error }>()
);

export const UserPickerAction = {
  loadEventUsers,
  searchEventUsers,
  loadEventUsersSuccess,
  loadEventUsersFailure,
  loadNextEventUsers,
  loadNextEventUsersSuccess,
  loadNextEventUsersFailure,
}
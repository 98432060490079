import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, Update, createEntityAdapter } from '@ngrx/entity';

import { AgendaRatingQuestionModel } from '@components/agenda/models/agenda-rating-question.model';

import * as RatingQuestionActions from '../actions/rating-question.actions';
import { selectEvent } from '@store/features/event/actions';

export const stateKey = 'ratingQuestions';

export interface State extends EntityState<AgendaRatingQuestionModel> {
  loading: boolean;
  loaded: boolean;
  creating: boolean;
  createdId: number;
  updating: boolean;
  updatedId: number;
  error: Error;
}

const adapter = createEntityAdapter<AgendaRatingQuestionModel>({
  sortComparer: (a, b) => a.order - b.order,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  creating: false,
  createdId: null,
  updating: false,
  updatedId: null,
  error: null,
});

export const ratingQuestionReducer = createReducer(
  initialState,
  on(RatingQuestionActions.loadRatingQuestions, state => ({
    ...initialState,
    loading: true,
  })),
  on(RatingQuestionActions.loadRatingQuestionsSuccess, (state, { questions }) =>
    adapter.setAll(questions, { ...state, loading: false, loaded: true })
  ),
  on(RatingQuestionActions.loadRatingQuestionsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(RatingQuestionActions.createRatingQuestion, state => ({
    ...state,
    creating: true,
  })),
  on(RatingQuestionActions.createRatingQuestionSuccess, (state, { question }) =>
    adapter.addOne(question, {
      ...state,
      creating: false,
      createdId: question.id,
    })
  ),
  on(RatingQuestionActions.createRatingQuestionFailure, (state, { error }) => ({
    ...state,
    creating: false,
    error,
  })),
  on(RatingQuestionActions.updateRatingQuestion, state => ({
    ...state,
    updating: true,
  })),
  on(RatingQuestionActions.updateRatingQuestionSuccess, (state, { question }) =>
    adapter.upsertOne(question, {
      ...state,
      updating: false,
      updatedId: question.id,
    })
  ),
  on(RatingQuestionActions.updateRatingQuestionFailure, (state, { error }) => ({
    ...state,
    updating: false,
    error,
  })),
  on(RatingQuestionActions.reorderRatingQuestions, (state, { questions }) => {
    const updates: Update<AgendaRatingQuestionModel>[] = questions.map(
      (question, order) => ({ id: question.id, changes: { order } })
    );

    return adapter.updateMany(updates, state);
  }),
  on(
    RatingQuestionActions.reorderRatingQuestionSuccess,
    (state, { questions }) => adapter.upsertMany(questions, state)
  ),
  on(RatingQuestionActions.deleteRatingQuestionSuccess, (state, { question }) =>
    adapter.removeOne(question.id, state)
  ),
  on(
    RatingQuestionActions.reorderRatingQuestionFailure,
    RatingQuestionActions.deleteRatingQuestionFailure,
    (state, { error }) => ({ ...state, error })
  ),
  
  on(
    selectEvent,
    () => ({ ...initialState }),
  )
);

export function reducer(state: State, action: Action): State {
  return ratingQuestionReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectRatingQuestionIds = selectIds;

export const selectRatingQuestionEntities = selectEntities;

export const selectAllRatingQuestions = selectAll;

export const selectRatingQuestionLoading = (state: State) => state.loading;

export const selectRatingQuestionLoaded = (state: State) => state.loaded;

export const selectRatingQuestionError = (state: State) => state.error;

export const selectRatingQuestionCreating = (state: State) => state.creating;

export const selectRatingQuestionCreatedId = (state: State) => state.createdId;

export const selectCreatedRatingQuestion = createSelector(
  selectRatingQuestionEntities,
  selectRatingQuestionCreatedId,
  (questions, createdId) => questions[createdId]
);

export const selectRatingQuestionUpdating = (state: State) => state.updating;

export const selectRatingQuestionUpdatedId = (state: State) => state.updatedId;

export const selectUpdatedRatingQuestion = createSelector(
  selectRatingQuestionEntities,
  selectRatingQuestionUpdatedId,
  (questions, updatedId) => questions[updatedId]
);

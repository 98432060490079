import { Directive, HostListener } from '@angular/core';

import { FormContainerService } from '@shared/providers/form-container.service';

@Directive({
  selector: '[appFormSubmit]'
})
export class FormSubmitDirective {
  constructor(private formContainer: FormContainerService) {}

  @HostListener('click', ['$event'])
  onHostClick(ev: Event) {
    this.formContainer.submit();
    this.formContainer.scrollIntoError();
  }
}

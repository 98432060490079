import { Action, createReducer, on, createSelector } from '@ngrx/store';
import { EntityState, createEntityAdapter, Update } from '@ngrx/entity';

import { AttachmentModel } from '@shared/models/attachment.model';
import { InteractiveMapLocationModel } from '@components/interactive-map/models/interactive-map-location.model';
import { InteractiveMapShapeModel } from '@components/interactive-map/models/interactive-map-shape.model';

import * as EditorActions from '../actions/editor.actions';

export const stateKey = 'editor';

export enum EditorTool {
  Pan = 'pan',
  Rectangle = 'rectangle',
  Ellipse = 'ellipse',
  Polygon = 'polygon',
}

export interface State extends EntityState<InteractiveMapLocationModel> {
  image: AttachmentModel;
  activeTool: EditorTool;
  activeLocationUuid: string;
  updatedLocation: InteractiveMapLocationModel;
}

export const adapter = createEntityAdapter<InteractiveMapLocationModel>({
  selectId: location => location.uuid,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  image: null,
  activeTool: EditorTool.Pan,
  activeLocationUuid: null,
  updatedLocation: null
});

export const editorReducer = createReducer(
  initialState,
  on(EditorActions.cleanseEditor, state => ({ ...initialState })),
  on(EditorActions.prepareEditorSuccess, (state, { image, locations }) =>
    adapter.setAll(locations, { ...initialState, image })
  ),
  on(EditorActions.changeTool, (state, { tool }) => ({
    ...state,
    activeTool: tool,
  })),
  on(EditorActions.changeImage, (state, { image }) => ({ ...state, image })),
  on(EditorActions.createLocationSuccess, (state, { location }) =>
    adapter.addOne(location, state)
  ),
  on(EditorActions.updateLocationShape, (state, { uuid, shape }) => {
    const updated = getLocationShapeUpdate(uuid, shape);
    return adapter.updateOne(updated, state);
  }),
  on(EditorActions.updateLocation, (state, { uuid, update }) => {
    const location = state.entities[uuid];
    const updated = getLocationUpdate(location, update);

    return { ...adapter.updateOne(updated, state), updatedLocation: { ...update, uuid } };
  }),
  on(EditorActions.deleteLocation, (state, { uuid }) =>
    adapter.removeOne(uuid, state)
  ),
  on(EditorActions.selectLocation, (state, { uuid }) => ({
    ...state,
    activeLocationUuid: uuid,
  }))
);

export function reducer(state: State, action: Action): State {
  return editorReducer(state, action);
}

function getLocationUpdate(
  location: InteractiveMapLocationModel,
  update: any
): Update<InteractiveMapLocationModel> {
  const shapeUpdate: InteractiveMapShapeModel = {
    ...location.shape,
    color: update.color,
    thickness: update.thickness,
  };

  return {
    id: location.uuid,
    changes: {
      name: update.name,
      exhibitors: update.exhibitors,
      transparent: update.transparent,
      shape: shapeUpdate,
    },
  };
}

function getLocationShapeUpdate(
  uuid: string,
  shape: InteractiveMapShapeModel
): Update<InteractiveMapLocationModel> {
  return {
    id: uuid,
    changes: { shape },
  };
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectLocationIds = selectIds;

export const selectLocationEntities = selectEntities;

export const selectAllLocations = selectAll;

export const selectUpdatedLocation = (state: State) => state.updatedLocation;

export const selectImage = (state: State) => state.image;

export const selectActiveTool = (state: State) => state.activeTool;

export const selectActiveLocationUuid = (state: State) =>
  state.activeLocationUuid;

export const selectActiveLocation = createSelector(
  selectLocationEntities,
  selectActiveLocationUuid,
  (locations, uuid) => locations[uuid]
);

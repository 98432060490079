<div mat-dialog-title>
  <h2 class="d-inline-block">
    {{ 'onboarding_dialog.title' | translate }}
  </h2>

  <button mat-button mat-dialog-close class="close" type="button">
    <img src="/assets/img/icons/delete-cross-black.svg" alt="Close dialog">
  </button>
</div>

<mat-dialog-content appFormContainer class="mat-typography">

  <app-language-tab-list class="mb-4">
    <app-language-tab *ngFor="let language of languages$ | async" [class.active]="isLanguageActive(language)" (click)="onLanguageChange($event, language)">
      <app-language-flag [language]="language.code"></app-language-flag>
    </app-language-tab>
  </app-language-tab-list>


  <ng-container appFormContainer>
    <form class="position-relative mb-3" [formGroup]="onBoardingForm">
      <div class="form-group mb-4">
        <input formControlName="onboardingShow" id="show-onboarding-switch" class="switch-checkbox" type="checkbox">
        <label [class.readonly]="readonly$ | async" for="show-onboarding-switch">{{ 'onboarding_dialog.show_onboarding' | translate }}</label>
      </div>

      <div class="form-group mb-4">
        <label>{{ 'onboarding_dialog.image_for_onboarding' | translate }}</label>
        <app-file-uploader [class.readonly]="(readonly$ | async) || isOnboardingHidden" formControlName="onboardingImage"></app-file-uploader>
      </div>

      <ng-container [formGroup]="activeTranslation$ | async">
        <div class="form-group mb-4">
          <label>{{ 'onboarding_dialog.name' | translate }}</label>
          <input [class.readonly]="isOnboardingHidden" formControlName="onboardingName" type="text" class="form-control" placeholder="Toyota">
        </div>

        <div class="form-group mb-0">
          <label>{{ 'onboarding_dialog.short_description' | translate }}</label>
          <textarea [class.readonly]="isOnboardingHidden" formControlName="onboardingDescription" class="form-control" rows="3" cols="5"
            placeholder="{{ 'onboarding_dialog.short_description_ph' | translate }}"></textarea>
        </div>
      </ng-container>
    </form>
  </ng-container>

  <app-spinner-overlay *ngIf="loading$ | async"></app-spinner-overlay>
</mat-dialog-content>

<mat-dialog-actions align="start">
  <button mat-button mat-dialog-close type="button" class="btn btn-light pl-4 pr-4 mr-4">{{ 'buttons.cancel' | translate }}</button>

  <button mat-button type="button" class="btn btn-primary pl-4 pr-4 mr-4" (click)="save()" appFormSubmit>
    <ng-container *ngIf="isSaving; else noSaving">
      <app-spinner-progress></app-spinner-progress>
    </ng-container>

    <ng-template #noSaving>
      {{ 'buttons.save_changes' | translate }}
    </ng-template>
  </button>
</mat-dialog-actions>
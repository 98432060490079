import { Directive, OnInit, OnDestroy, Optional, ElementRef, Output, EventEmitter, NgZone } from '@angular/core';
import { NgControl } from '@angular/forms';

import { GoogleApiService } from '@shared/providers/google-api.service';

export interface GoogleMapAutocompleteResult {
  name: string;
  formattedAddress: string;
  latitude: number;
  longitude: number;
}

@Directive({
  selector: '[appGoogleMapAutocomplete]'
})
export class GoogleMapAutocompleteDirective implements OnInit, OnDestroy {
  @Output() appPlaceChanged: EventEmitter<GoogleMapAutocompleteResult>;

  private autocompleteInstance: any;
  private disposeInstance: () => void;

  constructor(
    private ngZone: NgZone,
    private elementRef: ElementRef,
    private googleApiService: GoogleApiService,
    @Optional() private ngControl: NgControl,
  ) {
    this.appPlaceChanged = new EventEmitter<GoogleMapAutocompleteResult>();
  }

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      this.googleApiService.getMapsApi().subscribe(
        mapsApi => this.createAutocomplete(mapsApi.places, mapsApi.event)
      );
    });
  }

  ngOnDestroy() {
    if (this.autocompleteInstance) {
      this.disposeInstance();
      this.autocompleteInstance = null;
    }
  }

  private createAutocomplete({ Autocomplete }, event: any) {
    const hostElement: HTMLElement = this.elementRef.nativeElement;

    this.autocompleteInstance = new Autocomplete(hostElement, {
      types: ['geocode']
    });

    this.autocompleteInstance.addListener('place_changed', () => {
      this.handlePlaceResult(this.autocompleteInstance.getPlace());
    });

    this.disposeInstance = () => {
      event.clearInstanceListeners(this.autocompleteInstance);
    };
  }

  private handlePlaceResult(placeResult: any) {
    if (!placeResult || !placeResult.geometry) {
      return;
    }

    this.ngZone.run(() => {
      this.emitPlaceChange(placeResult);
    });
  }

  private emitPlaceChange(placeResult: any) {
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.setValue(placeResult.formatted_address);
    }

    this.appPlaceChanged.emit({
      name: placeResult.name,
      formattedAddress: placeResult.formatted_address,
      latitude: placeResult.geometry.location.lat(),
      longitude: placeResult.geometry.location.lng()
    });
  }
}

import { Component } from '@angular/core';
import { LangService, UserLang } from '@shared/providers/lang.service';
import { Intercom } from '@utils/intercom.util';

@Component({
  selector: 'app-check-event-button',
  templateUrl: './check-event-button.component.html',
  styleUrls: ['./check-event-button.component.scss']
})
export class CheckEventButtonComponent {

  constructor(private langService: LangService) { }

  navigate(_: Event): void {
    const lang = this.langService.getCurrentUserLang();
    switch (lang) {
      case UserLang.EN:
        window.open(Intercom.check_event.en, '_blank');
      case UserLang.PL:
        window.open(Intercom.check_event.pl, '_blank');
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { takeWhile, scan } from 'rxjs/operators';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements OnInit {

  @Input() title: String;
  @Input() desc: String;
  @Input() countdown = true; // enabled (true / false)

  countdown$ = new Observable<number>();

  constructor() { }

  ngOnInit(): void {
    if(this.countdown) {
      this.countdown$ = timer(0, 1000).pipe(
        scan(ticks => --ticks, 5),
        takeWhile(v => v >= 0)
      );
    } else {
      this.countdown$ = timer(0, 1000).pipe(
        scan(ticks => --ticks, 1),
        takeWhile(v => v >= 0)
      );
    }    
  }

}

import { EventModel } from '@store/features/event/models/event.model';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { ExhibitorProviderService } from '@components/exhibitor/providers/exhibitor-provider.service';
import { DropdownOption, DropdownWithSearchComponent } from '../dropdown-with-search/dropdown-with-search.component';
import { ExhibitorApiModel } from '@store/features/exhibitor/models/exhibitor.api-model';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ExhibitorsSelectDropdownService } from './exhibitors-select-dropdown.service';

@Component({
  selector: 'app-exhibitors-select-dropdown',
  templateUrl: './exhibitors-select-dropdown.component.html',
  styleUrls: ['./exhibitors-select-dropdown.component.scss']
})
export class ExhibitorsSelectDropdownComponent implements OnInit, OnDestroy {

  @ViewChild(DropdownWithSearchComponent) dropdown: DropdownWithSearchComponent;

  @Input() event: EventModel;
  @Input() label: string = null;
  @Input() specifiedExhibitors: ExhibitorApiModel[] | null;
  @Input() nullable = false;
  @Input() static = false;
  @Input() loadOnScroll = true;
  @Output() onSelect: EventEmitter<ExhibitorApiModel>;

  searchVal = '';
  loading = true;
  meta: { is_more: boolean, all_items: number };
  exhibitors: ExhibitorApiModel[];

  private subs = new Subscription();

  constructor(
    private exhibitorProvider: ExhibitorProviderService,
    private exhibitorsSelectDropdownService: ExhibitorsSelectDropdownService
  ) {
    this.onSelect = new EventEmitter<ExhibitorApiModel>();
  }

  ngOnInit(): void {

    // local memory injection
    if (this.loadOnScroll === false) {
      this.subs.add(this.exhibitorsSelectDropdownService.getLoadedExhibitors()
        .subscribe(exhibitors => this.exhibitors = exhibitors));
    }

    if(this.specifiedExhibitors) {
      this.exhibitors = this.specifiedExhibitors;
    } else {
      this.initExhibitors();
    }
  }

  ngOnDestroy(): void {
    this.searchVal = '';
    this.loading = true;
    this.meta = null;
    this.exhibitors = [];
    this.subs.unsubscribe();
  }

  public reset(): void {
    this.dropdown.reset();
  }

  get exhibitorsDropdownOptions(): DropdownOption[] {
    return this.exhibitors?.map(e => ({ name: e.name, id: e.id, value: e.id }));
  }

  get maxExhibitorId(): number {
    return this.exhibitors[this.exhibitors.length -1].id;
  }

  initExhibitors(maxId: number = null): void {
    if(this.loadOnScroll === false) return;
    this.loading = true;
    this.subs.add(this.exhibitorProvider.loadAll(this.event, maxId, 10, null)
      .pipe(first(res => !!res))
      .subscribe({
        next: res => {
          this.exhibitors = res.exhibitors;
          this.meta = res.meta;
          this.loading = false;
          this.exhibitorsSelectDropdownService.setLoadedExhibitors(res.exhibitors);
        },
        error: (err) => {
          this.loading = false;
        }
      }));
  }

  load(lm = false): void {
    if (this.loadOnScroll === false) return;
    if(!this.meta.is_more && !lm) {
      return;
    }

    this.loading = true;

    this.subs.add(this.exhibitorProvider.loadAll(
      this.event,
      lm ? null : this.maxExhibitorId,
      10,
      this.getSearchValue()
    ).pipe(first(res => !!res))
      .subscribe({
        next: res => {
          this.modifyExhibitorsArray(res.exhibitors);
          this.meta = res.meta;
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
        }
      }));
  }

  onSearch(val: string): void {
    if(val === this.searchVal) return;
    if(val.length >= 2) {
      this.searchVal = val;
      this.load(true); // true - skip max_id
    } else {
      if(val.length === 0 && this.searchVal.length !== 0) {
        this.searchVal = '';
        this.load(true);
      }
    }
  }

  select(selected: DropdownOption): void {
    if(!selected) {
      this.onSelect.emit(null);
    } else {
      const exhibitor = this.exhibitors.find(e => e.id === selected.value);
      this.onSelect.emit(exhibitor);
    }
  }

  private modifyExhibitorsArray(responseExhibitors: ExhibitorApiModel[]) {
    if(this.getSearchValue()) {
      this.exhibitors = [...responseExhibitors];
    } else {
      const exhibitorsWithoutDuplicates = [...this.exhibitors, ...responseExhibitors]
        .filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
      this.exhibitors = exhibitorsWithoutDuplicates;
    }
  }

  private getSearchValue(): string {
    return this.searchVal.length >= 2 ? this.searchVal : null;
  }

}

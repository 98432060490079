import { Injectable } from '@angular/core';

import { Observable, ReplaySubject, fromEvent } from 'rxjs';
import { map, switchMap, mapTo } from 'rxjs/operators';

@Injectable()
export class VirtualScrollService {
  private scrollElement$ = new ReplaySubject<HTMLElement>(1);
  private scrollObserver$: Observable<number>;

  constructor() {
    this.scrollObserver$ = this.scrollElement$.pipe(
      switchMap(element =>
        fromEvent(element, 'scroll').pipe(mapTo(element))
      ),
      map(element => calcScrollOffset(element))
    );
  }

  setScrollElement(element: HTMLElement) {
    this.scrollElement$.next(element);
  }

  getOffset(): Observable<number> {
    return this.scrollObserver$;
  }
}

function calcScrollOffset(el: HTMLElement) {
  const scrollBottom = el.scrollTop + el.offsetHeight;
  const scrollHeight = el.scrollHeight;

  return scrollHeight - scrollBottom;
}

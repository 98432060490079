const LC_KEY_BASE = 'app_error';

export function setLocalStorageError(item: ErrorLocalStorage): void {
  localStorage.setItem(`${LC_KEY_BASE}_${item}`, '1');
}

export function deleteLocalStorageError(item: ErrorLocalStorage): void {
  localStorage.removeItem(`${LC_KEY_BASE}_${item}`);
}

export function hasLocalStorageError(item: ErrorLocalStorage): boolean {
  const error = localStorage.getItem(`${LC_KEY_BASE}_${item}`);
  return !!error;
}

export function deleteAllLocalStorageErorrs(): void {
  Object.values(ErrorLocalStorage).forEach(item => {
    localStorage.removeItem(`${LC_KEY_BASE}_${item}`);
  });
}

export enum ErrorLocalStorage {
  PROFILE_ERROR = 'profile',
}
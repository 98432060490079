import { createAction, props } from '@ngrx/store';
import { ProfileModel } from '@app/backend/models/profile.model';
import { ChangePasswordModel } from '@app/account/models/change-password.model';

export const loadProfile = createAction(
  '[Backend/Profile] Load Profile' //
);

export const loadProfileSuccess = createAction(
  '[Backend/Profile] Load Profile Success',
  props<{ profile: ProfileModel }>()
);

export const loadProfileFailure = createAction(
  '[Backend/Profile] Load Profile Failure', //
  props<{ error: Error }>()
);

export const changeProfilePicture = createAction(
  '[Backend/Profile] Change Profile Picture',
  props<{ picture: File }>()
);

export const changeProfilePictureSuccess = createAction(
  '[Backend/Profile] Change Profile Picture Success',
  props<{ profile: ProfileModel }>()
);

export const changeProfilePictureFailure = createAction(
  '[Backend/Profile] Change Profile Picture Failure',
  props<{ error: Error }>()
);

export const changePassword = createAction('[Account/Auth] Change Password', props<{ payload: ChangePasswordModel }>());
export const changePasswordSuccess = createAction('[Account/Auth] Change Password Succeed');
export const changePasswordFailure = createAction('[Account/Auth] Change Password Failure', props<{ error: Error }>());

export const signOut = createAction('[Backend/Profile] Sign Out');
export const signOutSuccess = createAction('[Backend/Profile] Sign Out Success');
export const signOutFailed = createAction('[Backend/Profile] Sign Out Failed', props<{ error: Error }>());

<ng-container *ngIf="context$ | async as context">
  <div class="mobile-banner">
    <div class="banner-image" *ngIf="context.banner" [style.backgroundImage]="'url(' + (getBannerUrl() | async) + ')'"></div>
  </div>

  <div class="mobile-header">
    <img *ngIf="context.logo" [src]="getLogoUrl() | async" alt="Company logo" />
    <img *ngIf="!context.logo" src="/assets/img/preview/logo-placeholder.jpg" alt="Company logo" />
    <p>{{ 'mobile_preview.contact_with_organizer' | translate }}</p>
  </div>

  <div class="mobile-title">
    <h1 *ngIf="context.title">{{ context.title }}</h1>
    <h1 *ngIf="!context.title">{{ 'mobile_preview.title' | translate }}</h1>
    <h2 *ngIf="context.startDate && context.endDate">{{ context.startDate | dateRange:context.endDate }}</h2>
    <h2 *ngIf="!context.startDate || !context.endDate">{{ 'mobile_preview.event_date' | translate }}</h2>
  </div>

  <div class="mobile-description">
    <div class="mobile-description-overflow">
      <p *ngIf="context.description">{{ context.description }}</p>
      <p *ngIf="!context.description">{{ 'mobile_preview.desc' | translate }}</p>
    </div>
  </div>

  <div class="mobile-location">
    <img src="/assets/img/preview/location.png" alt="Location marker" />
    <p *ngIf="context.placeAddress">{{ context.placeAddress }}</p>
    <p *ngIf="!context.placeAddress">{{ 'mobile_preview.event_address' | translate }}</p>
  </div>

  <div class="mobile-map">
    <app-mobile-map *ngIf="context.latitude && context.longitude" [latitude]="context.latitude" [longitude]="context.longitude" [width]="343*1.5" [height]="140*1.5"></app-mobile-map>
  </div>
</ng-container>

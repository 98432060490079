import { Action, createFeatureSelector, createSelector, combineReducers } from '@ngrx/store';

import * as fromExhibitor from './exhibitor.reducer';
import * as fromExhibitorTranslation from './exhibitor-translation.reducer';
import * as fromExhibitorContactPerson from './exhibitor-contact-person.reducer';
import * as fromExhibitorContactPersonTranslation from './exhibitor-contact-person-translation.reducer';
import * as fromExhibitorModal from './exhibitor-modal.reducer';
import * as fromExhibitorImport from './exhibitor-import.reducer';
import * as fromExhibitorUpdates from './exhibitor-updates.reducer';

export const featureKey = 'exhibitorFeature';

export interface State {
  [fromExhibitor.stateKey]: fromExhibitor.State;
  [fromExhibitorTranslation.stateKey]: fromExhibitorTranslation.State;
  [fromExhibitorContactPerson.stateKey]: fromExhibitorContactPerson.State;
  [fromExhibitorContactPersonTranslation.stateKey]: fromExhibitorContactPersonTranslation.State;
  [fromExhibitorModal.stateKey]: fromExhibitorModal.State;
  [fromExhibitorImport.stateKey]: fromExhibitorImport.State;
  [fromExhibitorUpdates.stateKey]: fromExhibitorUpdates.State;
}

export const combinedReducers = combineReducers({
  [fromExhibitor.stateKey]: fromExhibitor.reducer,
  [fromExhibitorTranslation.stateKey]: fromExhibitorTranslation.reducer,
  [fromExhibitorContactPerson.stateKey]: fromExhibitorContactPerson.reducer,
  [fromExhibitorContactPersonTranslation.stateKey]: fromExhibitorContactPersonTranslation.reducer,
  [fromExhibitorModal.stateKey]: fromExhibitorModal.reducer,
  [fromExhibitorImport.stateKey]: fromExhibitorImport.reducer,
  [fromExhibitorUpdates.stateKey]: fromExhibitorUpdates.reducer
});

export function reducer(state: State, action: Action): State {
  return combinedReducers(state, action);
}

export const selectExhibitorFeature = createFeatureSelector<State>(featureKey);

export const selectExhibitorState = createSelector(
  selectExhibitorFeature,
  state => state[fromExhibitor.stateKey]
);

export const selectAllExhibitors = createSelector(
  selectExhibitorState,
  fromExhibitor.selectAllExhibitors
);

export const selectExhibitors = createSelector(
  selectExhibitorState,
  fromExhibitor.selectExhibitors
);

export const selectExhibitorLoading = createSelector(
  selectExhibitorState,
  fromExhibitor.selectExhibitorLoading
);

export const selectExhibitorLoaded = createSelector(
  selectExhibitorState,
  fromExhibitor.selectExhibitorLoaded
);

export const selectExhibitorError = createSelector(
  selectExhibitorState,
  fromExhibitor.selectExhibitorError
);

export const selectUpdatedExhibitor = createSelector(
  selectExhibitorState,
  fromExhibitor.selectUpdatedExhibitor
);

export const selectExhibitorContactPersonState = createSelector(
  selectExhibitorFeature,
  state => state[fromExhibitorContactPerson.stateKey]
);

export const selectAllExhibitorContactPeople = createSelector(
  selectExhibitorContactPersonState,
  fromExhibitorContactPerson.selectAllExhibitorContactPeople
);

export const selectExhibitorContactPeople = () => createSelector(
  selectExhibitorContactPersonState,
  fromExhibitorContactPerson.selectExhibitorContactPeople()
);

export const selectExhibitorContactPersonLoading = createSelector(
  selectExhibitorContactPersonState,
  fromExhibitorContactPerson.selectExhibitorContactPersonLoading
);

export const selectExhibitorContactPersonLoaded = createSelector(
  selectExhibitorContactPersonState,
  fromExhibitorContactPerson.selectExhibitorContactPersonLoaded
);

export const selectExhibitorContactPersonError = createSelector(
  selectExhibitorContactPersonState,
  fromExhibitorContactPerson.selectExhibitorContactPersonError
);

export const selectExhibitorModalState = createSelector(
  selectExhibitorFeature,
  state => state[fromExhibitorModal.stateKey]
);

export const selectExhibitorModalSaving = createSelector(
  selectExhibitorModalState,
  fromExhibitorModal.selectExhibitorModalSaving
);

export const selectExhibitorModalSaved = createSelector(
  selectExhibitorModalState,
  fromExhibitorModal.selectExhibitorModalSaved
);

export const selectExhibitorModalError = createSelector(
  selectExhibitorModalState,
  fromExhibitorModal.selectExhibitorModalError
);

export const selectExhibitorTranslationState = createSelector(
  selectExhibitorFeature,
  state => state[fromExhibitorTranslation.stateKey]
);

export const selectExhibitorTranslations = createSelector(
  selectExhibitorTranslationState,
  fromExhibitorTranslation.selectExhibitorTranslations
);

export const selectExhibitorTranslation = createSelector(
  selectExhibitorTranslationState,
  fromExhibitorTranslation.selectExhibitorTranslation
);

export const selectExhibitorTranslationLoading = createSelector(
  selectExhibitorTranslationState,
  fromExhibitorTranslation.selectExhibitorTranslationLoading
);

export const selectExhibitorTranslationError = createSelector(
  selectExhibitorTranslationState,
  fromExhibitorTranslation.selectExhibitorTranslationError
);

export const selectExhibitorContactPersonTranslationState = createSelector(
  selectExhibitorFeature,
  state => state[fromExhibitorContactPersonTranslation.stateKey]
);

export const selectExhibitorContactPersonTranslations = createSelector(
  selectExhibitorContactPersonTranslationState,
  fromExhibitorContactPersonTranslation.selectExhibitorContactPersonTranslations
);

export const selectExhibitorContactPersonTranslation = createSelector(
  selectExhibitorContactPersonTranslationState,
  fromExhibitorContactPersonTranslation.selectExhibitorContactPersonTranslation
);

export const selectExhibitorContactPersonTranslationLoading = createSelector(
  selectExhibitorContactPersonTranslationState,
  fromExhibitorContactPersonTranslation.selectExhibitorContactPersonTranslationLoading
);

export const selectExhibitorContactPersonTranslationError = createSelector(
  selectExhibitorContactPersonTranslationState,
  fromExhibitorContactPersonTranslation.selectExhibitorContactPersonTranslationError
);

export const selectExhibitorImportState = createSelector(
  selectExhibitorFeature,
  state => state[fromExhibitorImport.stateKey]
);

export const selectExhibitorImporting = createSelector(
  selectExhibitorImportState,
  fromExhibitorImport.selectImporting
);

export const selectExhibitorImported = createSelector(
  selectExhibitorImportState,
  fromExhibitorImport.selectImported
);

export const selectExhibitorImportResults = createSelector(
  selectExhibitorImportState,
  fromExhibitorImport.selectResults
);

export const selectExhibitorImportError = createSelector(
  selectExhibitorImportState,
  fromExhibitorImport.selectError
);


// exhibitor updates state selectors

const selectExhibitorUpdatesState = createSelector(
  selectExhibitorFeature,
  state => state[fromExhibitorUpdates.stateKey]
);

const selectExhibitorUpdatesLoading = createSelector(
  selectExhibitorUpdatesState,
  fromExhibitorUpdates.selectLoading
);

const selectExhibitorUpdatesUpdating = createSelector(
  selectExhibitorUpdatesState,
  fromExhibitorUpdates.selectUpdating
);

const selectExhibitorUpdatesUpdatingId = createSelector(
  selectExhibitorUpdatesState,
  fromExhibitorUpdates.selectUpdatingId
);

const selectExhibitorUpdatesError = createSelector(
  selectExhibitorUpdatesState,
  fromExhibitorUpdates.selectError
);

const selectExhibitorUpdatesExhibitorUpdates = createSelector(
  selectExhibitorUpdatesState,
  fromExhibitorUpdates.selectExhibitorUpdates
);

const selectExhibitorUpdatesCurrentStatus = createSelector(
  selectExhibitorUpdatesState,
  fromExhibitorUpdates.selectCurrentStatus
);

export const ExhibitorUpdatesSelectors = {
  getLoading: selectExhibitorUpdatesLoading,
  getUpdating: selectExhibitorUpdatesUpdating,
  getUpdatingId: selectExhibitorUpdatesUpdatingId,
  getError: selectExhibitorUpdatesError,
  getExhibitorUpdates: selectExhibitorUpdatesExhibitorUpdates,
  getCurrentStatus: selectExhibitorUpdatesCurrentStatus
}
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, Update, createEntityAdapter } from '@ngrx/entity';

import { InteractiveMapModel } from '@components/interactive-map/models/interactive-map.model';

import * as InteractiveMapActions from '../actions/interactive-map.actions';

export const stateKey = 'interactiveMaps';

export interface State extends EntityState<InteractiveMapModel> {
  loading: boolean;
  loaded: boolean;
  updating: boolean;
  updatedId: number;
  error: Error;
}

export const adapter = createEntityAdapter<InteractiveMapModel>({
  sortComparer: (a, b) => a.order - b.order,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  updating: false,
  updatedId: 0,
  error: null,
});

export const interactiveMapReducer = createReducer(
  initialState,
  on(InteractiveMapActions.loadInteractiveMaps, state => ({
    ...initialState,
    loading: true,
  })),
  on(
    InteractiveMapActions.loadInteractiveMapsSuccess,
    (state, { interactiveMaps }) =>
      adapter.setAll(interactiveMaps, {
        ...state,
        loading: false,
        loaded: true,
      })
  ),
  on(InteractiveMapActions.loadInteractiveMapsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(
    InteractiveMapActions.createInteractiveMap,
    InteractiveMapActions.updateInteractiveMap,
    state => ({ ...state, updating: true })
  ),
  on(
    InteractiveMapActions.createInteractiveMapSuccess,
    InteractiveMapActions.updateInteractiveMapSuccess,
    (state, { interactiveMap }) =>
      adapter.upsertOne(interactiveMap, {
        ...state,
        updating: false,
        updatedId: interactiveMap.id,
      })
  ),
  on(
    InteractiveMapActions.createInteractiveMapFailure,
    InteractiveMapActions.updateInteractiveMapFailure,
    (state, { error }) => ({ ...state, updating: false, error })
  ),
  on(
    InteractiveMapActions.deleteInteractiveMapSuccess,
    (state, { interactiveMap }) => adapter.removeOne(interactiveMap.id, state)
  ),
  on(
    InteractiveMapActions.reorderMaps,
    (state, { interactiveMaps }) => {
      const updates: Update<InteractiveMapModel>[] = interactiveMaps.map((map, order) => ({
        id: map.id, changes: { order }
      }));
      return adapter.updateMany(updates, state);
    }
  ),
);

export function reducer(state: State, action: Action): State {
  return interactiveMapReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectAllInteractiveMaps = selectAll;

export const selectInteractiveMapLoading = (state: State) => state.loading;

export const selectInteractiveMapLoaded = (state: State) => state.loaded;

export const selectInteractiveMapError = (state: State) => state.error;

export const selectInteractiveMapUpdating = (state: State) => state.updating;

export const selectUpdatedInteractiveMapId = (state: State) => state.updatedId;

export const selectUpdatedInteractiveMap = createSelector(
  selectEntities,
  selectUpdatedInteractiveMapId,
  (maps, updatedId) => maps[updatedId]
);

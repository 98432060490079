<div #tag class="tag-form-wrapper" [formGroup]="form" (focusin)="handleFocusIn()" (focusout)="onChangeValue()" (keyup.enter)="showColorSelector = false">
  <input class="tag_name" #inputElement type="text" formControlName="value" (keyup.enter)="onChangeValue()" [readonly]="readOnly"/>

  <a *ngIf="!hideRemove" href="#" (click)="onRemoveClick($event)">
    <app-icon icon="/assets/icons/cross.svg"></app-icon>
  </a>

  <div #colorSelector class="color-selector" *ngIf="showColorSelector && !readOnly">
    <div class="arrow-up"></div>
    <div class="color-selector-content">
      <p>{{ 'color_picker.type_hex_number' | translate }}</p>
      <div class="form-group mb-2">
        <input type="text" class="form-control" placeholder="#ffffff" [formControl]="selectedColor"/>
      </div>
      <div class="predefined-colors">
        <span *ngFor="let color of colors" [style.backgroundColor]="color" (click)="onColorSelect($event, color)"></span>
      </div>
    </div>
  </div>

  <div #popoverContent *ngIf="valueCutted" class="popover-content">
    <div class="popover-text">
      {{ fullName }}
    </div>
  </div>
</div>

<span #helperElement class="helper"></span>
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AudioVisualAction } from './actions/audio-visual.actions';
import { AudioVisualFileApiModel, AudioVisualFileModel, AudioVisualFilePostModel, AudioVisualModel, AudioVisualPatchModel, AudioVisualPostModel } from './models/audio-visual.models';
import { Observable, combineLatest, concat, of } from 'rxjs';
import { AudioVisualSelectors, AudioVisualTranslationsSelectors } from '.';
import { AudioVisualTranslationAction } from './actions/audio-visual-translation.actions';
import { AudioVisualFilePatchTranslationModel, AudioVisualFileTranslationModel, AudioVisualTranslationModel } from './models/audio-visual-translation.models';
import { EventLanguageModel } from '@store/features/event/models/event-language.model';
import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { TagApiModel } from '@store/features/tags/tags.models';

@Injectable({
  providedIn: 'root',
})
export class AudioVisualProviderService {
  constructor(private store$: Store<any>) {}

  loadAudioVisuals(): void {
    this.store$.dispatch(AudioVisualAction.loadAudioVisuals());
  }

  loadAudioVisualsForDeepLinks(event: EventModel, component: ComponentModel): void {
    this.store$.dispatch(AudioVisualAction.loadAudioVisualsForDeepLinks({ event, component }));
  }

  createAudioVisual(payload: AudioVisualPostModel, tags: TagApiModel[]): void {
    this.store$.dispatch(AudioVisualAction.createAudioVisual({ payload, tags }));
  }

  updateAudioVisual(audioVisual: AudioVisualModel, payload: AudioVisualPatchModel): void {
    this.store$.dispatch(AudioVisualAction.patchAudioVisual({ audioVisual, payload }));
  }

  deleteAudioVisual(audioVisual: AudioVisualModel): void {
    this.store$.dispatch(AudioVisualAction.deleteAudioVisual({ audioVisual }));
  }

  reorderAudioVisuals(ordered: AudioVisualModel[]): void {
    this.store$.dispatch(AudioVisualAction.reorderAudioVisuals({ ordered }));
  }

  // files

  createAudioVisualFile(audioVisual: AudioVisualModel, payload: AudioVisualFilePostModel): void {
    this.store$.dispatch(AudioVisualAction.createAudioVisualFile({ audioVisual, payload }));
  }

  deleteAudioVisualFile(audioVisual: AudioVisualModel, file: AudioVisualFileModel): void {
    this.store$.dispatch(AudioVisualAction.deleteAudioVisualFile({ audioVisual, file }));
  }

  reorderAudioVisualFiles(audioVisual: AudioVisualModel, ordered: AudioVisualFileModel[]): void {
    this.store$.dispatch(AudioVisualAction.reorderAudioVisualFiles({ audioVisual, ordered }));
  }

  // other
  setError(error: Error | null): void {
    this.store$.dispatch(AudioVisualAction.setError({ error }));
  }

  setCollapsedId(id: number): void {
    this.store$.dispatch(AudioVisualAction.setExpandedId({ id }));
  }

  setActiveAudioVisualId(id: number): void {
    this.store$.dispatch(AudioVisualAction.setActiveAudioVisualId({ id }));
  }

  setCreateMode(status: boolean): void {
    this.store$.dispatch(AudioVisualAction.setCreateMode({ status }));
  }

  loadAudioVisualsTranslation(language: string): void {
    this.store$.dispatch(AudioVisualTranslationAction.loadAudioVisualsTranslation({ language }));
  }

  loadFilesTranslations(audioVisual: AudioVisualModel, language: string): void {
    this.store$.dispatch(AudioVisualTranslationAction.loadAudioVisualFilesTranslation({ audioVisual, language }));
  }

  updateAudioVisualTranslation(audioVisual: AudioVisualModel, language: string): void {
    this.store$.dispatch(AudioVisualTranslationAction.updateAudioVisualTranslation({ audioVisual, language }));
  }

  resetFilesTranslations(): void {
    this.store$.dispatch(AudioVisualTranslationAction.resetFilesTranslations());
  }

  updateAudioVisualFiles(audioVisual: AudioVisualModel, files: AudioVisualFilePatchTranslationModel[], language: string): void {
    this.store$.dispatch(AudioVisualAction.patchAudioVisualFiles({ audioVisual, files, language }));
  }

  createAudioVisualFiles(audioVisual: AudioVisualModel, audioVisualFiles: AudioVisualFilePostModel[]): void {
    this.store$.dispatch(AudioVisualAction.createAudioVisualFiles({ audioVisual, audioVisualFiles }));
  }

  deleteAudioVisualFiles(audioVisual: AudioVisualModel, ids: number[]): void {
    this.store$.dispatch(AudioVisualAction.deleteAudioVisualFiles({ audioVisual, ids }));
  }

  getAudioVisualFilesForDeepLink(audioVisual: AudioVisualModel): Observable<AudioVisualFileModel[]> {
    return this.audioVisuals
      .pipe(map(audioVisualsStore => audioVisualsStore.find(a => a.id === audioVisual.id).audioVisualFiles));
  }

  setActiveLanguage(language: EventLanguageModel): void {
    this.store$.dispatch(AudioVisualTranslationAction.setActiveLanguage({ language }));
    concat(
      of(null),
      this.store$.pipe(
        select(AudioVisualTranslationsSelectors.getActiveLanguage),
        take(1),
        withLatestFrom(this.store$.pipe(select(AudioVisualTranslationsSelectors.getForActiveLanguage))),
        tap(([activeLanguage, translations]) => {
          if (!translations) {
            this.loadAudioVisualsTranslation(activeLanguage.code);
          }
        }),
      ),
    ).subscribe();
  }

  // getters

  get audioVisuals(): Observable<AudioVisualModel[]> {
    return this.store$.pipe(select(AudioVisualSelectors.getAudioVisuals));
  }

  get activeAudioVisual(): Observable<AudioVisualModel> {
    return this.store$.pipe(select(AudioVisualSelectors.getActiveAudioVisual));
  }

  get activeAudioVisualId(): Observable<number> {
    return this.store$.pipe(select(AudioVisualSelectors.getActiveId));
  }


  get error(): Observable<Error> {
    return this.store$.pipe(select(AudioVisualSelectors.getError));
  }

  get createModeActive(): Observable<boolean> {
    return this.store$.pipe(select(AudioVisualSelectors.getCreateModeActive));
  }

  get isUpdating(): Observable<boolean> {
    return this.store$.pipe(select(AudioVisualSelectors.getUpdating));
  }

  get isLoading(): Observable<boolean> {
    return this.store$.pipe(select(AudioVisualSelectors.getLoading));
  }

  get expandedId(): Observable<number> {
    return this.store$.pipe(select(AudioVisualSelectors.getExpandedId));
  }

  get filesUpdateInProgress(): Observable<boolean> {
    return this.store$.pipe(select(AudioVisualSelectors.getFilesUpdateInProgress));
  }

  get filesCreateInProgress(): Observable<boolean> {
    return this.store$.pipe(select(AudioVisualSelectors.getFilesCreateInProgress));
  }

  get filesDeleteInProgress(): Observable<boolean> {
    return this.store$.pipe(select(AudioVisualSelectors.getFilesDeleteInProgress));
  }

  get filesAreSaving(): Observable<boolean> {
    return combineLatest([this.filesUpdateInProgress, this.filesCreateInProgress, this.filesDeleteInProgress])
      .pipe(map(([isUpdate, isCreate, isDelete]) => !!isUpdate || !!isCreate || !!isDelete));
  }

  getAudioVisualTranslations(language: string): Observable<AudioVisualTranslationModel[]> {
    return this.store$.pipe(select(AudioVisualTranslationsSelectors.getAll))
      .pipe(map(translations => translations[language]));
  }

  get audioVisualTranslationsForActiveLanguage(): Observable<AudioVisualTranslationModel[]> {
    return this.store$.pipe(select(AudioVisualTranslationsSelectors.getForActiveLanguage))
      .pipe(map(translations => translations ? translations : []));
  }

  get audioVisualTranslationsLoading(): Observable<boolean> {
    return this.store$.pipe(select(AudioVisualTranslationsSelectors.getLoading));
  }

  get filesTranslations(): Observable<{ [key: string]: AudioVisualFileTranslationModel[] }> {
    return this.store$.pipe(select(AudioVisualTranslationsSelectors.getFilesTranslations));
  }

  getFilesTranslations(language: string): Observable<AudioVisualFileTranslationModel[]> {
    return this.store$.pipe(select(AudioVisualTranslationsSelectors.getFilesTranslations))
      .pipe(map(translations => translations[language]));
  }
}
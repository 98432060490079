import { Subscription } from 'rxjs';
import { Component, OnChanges, Input } from '@angular/core';
import { LANDING_PAGE_BASE_URL } from '@env/environment';
import { EventModel } from '@store/features/event/models/event.model';

@Component({
  selector: 'app-live-preview',
  templateUrl: './live-preview.component.html',
  styleUrls: ['./live-preview.component.scss']
})
export class LivePreviewComponent {
  @Input() event: EventModel;
  @Input() mobilePreview: boolean = false;

  routeSub$?: Subscription;

  constructor() {}

  get landingPageUrl(): string {
    const slug = this.event && this.event.lpUrl;
    return `${LANDING_PAGE_BASE_URL}/${slug}`;
  }

  get qrcode(): string {
    return this.event && this.event.qrcode;
  }
}
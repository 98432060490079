import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import * as ApplicationActions from './actions/application.actions';
import * as fromApplication from './reducers';

@Injectable()
export class ApplicationFacadeService {
  constructor(private store$: Store<any>) {}

  getMenuExpanded(): Observable<boolean> {
    return this.store$.pipe(select(fromApplication.selectMenuExpanded));
  }

  getLoading(): Observable<boolean> {
    return this.store$.pipe(select(fromApplication.selectLoading));
  }

  toggleMenu() {
    this.store$.dispatch(ApplicationActions.toggleMenuExpand());
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateRange'
})
export class DateRangePipe implements PipeTransform {
  transform(current: any, against: any): any {
    const date = new Date(current);
    const to = new Date(against || current);

    if (date.getFullYear() !== to.getFullYear()) {
      return `${this.getDateFormat(date, 3)} - ${this.getDateFormat(to, 3)}`;
    }

    if (date.getMonth() !== to.getMonth()) {
      return `${this.getDateFormat(date, 2)} - ${this.getDateFormat(to, 3)}`;
    }

    if (date.getDay() !== to.getDay()) {
      return `${this.getDateFormat(date, 1)} - ${this.getDateFormat(to, 3)}`;
    }

    return this.getDateFormat(to, 3);
  }

  getDateFormat(date: Date, elements: number) {
    let format = '';

    if (elements > 0) {
      format += ` ${date.getDate()}`;
    }

    if (elements > 1) {
      const month = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
      ];

      format += ` ${month[date.getMonth()]}`;
    }

    if (elements > 2) {
      format += ` ${date.getFullYear()}`;
    }

    return format;
  }
}

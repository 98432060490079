import { extractErrorFrom } from '@utils/errors.util';
import { EventFacadeService } from '@store/features/event/event-facade.service';
import { EventModel } from '@store/features/event/models/event.model';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';

import { randomPastelColor, rgbToHex } from '@utils/colors.util';
import { TagsProviderService } from '@store/features/tags/tags-provider.service';
import { TagApiModel, TagGroup, TagType } from '@store/features/tags/tags.models';
import { ComponentModel } from '@shared/models/component.model';
import { filter, first, map } from 'rxjs/operators';
import { ComponentNames } from '@utils/component-names.util';
import { TagDatabaseModalService } from '../tag-database-modal.service';
import { Intercom } from '@utils/intercom.util';
import { USER_ROLE_PERMISSIONS } from '@shared/constants/user-role-permissions';
import { ComponentFacadeService } from '@store/features/component/component-facade.service';

@Component({
  selector: 'app-tag-database',
  templateUrl: './tag-database.component.html',
  styleUrls: ['./tag-database.component.scss']
})
export class TagDatabaseComponent implements OnInit, OnDestroy {

  public Intercom = Intercom;

  @Input() isModal = false;

  isSystemTag = isSystemTag;
  public TagGroup = TagGroup;

  systemTags: TagApiModel[] = [];
  userGroupTags: TagApiModel[] = [];
  userTags: TagApiModel[] = [];
  agendaPlaceTags: TagApiModel[] = [];
  agendaPathTags: TagApiModel[] = [];
  exhibitorTags: TagApiModel[] = [];

  event: EventModel;
  event$: Observable<EventModel>;
  loading = true;
  errorMessage: string;

  components$: Observable<ComponentModel[]>;
  allowedComponents$: Observable<ComponentModel[]>;

  hasAllowedAgendaComponent$: Observable<boolean>;
  hasAllowedAgendaOrBmComponent$: Observable<boolean>;
  hasAllowedExhibitorComponent$: Observable<boolean>;

  hasAgendaComponent$: Observable<boolean>;
  hasExhibitorComponent$: Observable<boolean>;

  get hasAccessToModifyUserGroups() {
    return this.event$.pipe(
      filter(event => !!event),
      map(event => USER_ROLE_PERMISSIONS.TAG_MODIFY_USER_GROUPS.includes(event.userRole)),
    );
  }

  get hasAccessToModifyUserTags() {
    return this.event$.pipe(
      filter(event => !!event),
      map(event => USER_ROLE_PERMISSIONS.TAG_MODIFY_USER_TAGS.includes(event.userRole)),
    );
  }

  get hasAccessToModifyAgendaPlaces() {
    return combineLatest([this.event$, this.hasAllowedAgendaOrBmComponent$])
    .pipe(
      filter(([event, _]) => !!event),
      map(([event, hasAgendaOrBm]) => !!hasAgendaOrBm ? true : USER_ROLE_PERMISSIONS.TAG_MODIFY_AGENDA_PLACES.includes(event.userRole)),
    );
  }

  get hasAccessToModifyAgendaPaths() {
    return combineLatest([this.event$, this.hasAllowedAgendaComponent$])
    .pipe(
      filter(([event, _]) => !!event),
      map(([event, hasAgenda]) => !!hasAgenda ? true : USER_ROLE_PERMISSIONS.TAG_MODIFY_AGENDA_PATHS.includes(event.userRole)),
    );
  }

  get hasAccessToModifyExhibitorTags() {
    return combineLatest([this.event$, this.hasAllowedExhibitorComponent$])
      .pipe(
        filter(([event, _]) => !!event),
        map(([event, hasExhibitor]) => !!hasExhibitor ? true : USER_ROLE_PERMISSIONS.TAG_MODIFY_EXHIBITOR_TAGS.includes(event.userRole)),
      );
  }


  private subs = new Subscription();
  private underEditProccessed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private tagService: TagsProviderService,
    private eventFacade: EventFacadeService,
    private componentFacade: ComponentFacadeService,
    private tagDatabaseModalService: TagDatabaseModalService
  ) {
    this.event$ = this.eventFacade.getActiveEvent();
  }

  ngOnInit(): void {
    this.loading = true;
    this.subs.add(this.event$
      .pipe(first(event => !!event))
      .subscribe(event => {
        this.event = event;
        this.loadTags();
      }
    ));

    this.components$ = this.componentFacade.getAllComponents();
    this.allowedComponents$ = this.componentFacade.getAllowedComponents().pipe(first());

    this.hasAgendaComponent$ = this.components$.pipe(
      map(components => components.some(component => [ComponentNames.AGENDA, ComponentNames.BUSINESS_MATCHING].includes(component.componentName)))
    );

    this.hasExhibitorComponent$ = this.components$.pipe(
      map(components => components.some(component => component.componentName === ComponentNames.EXHIBITOR))
    );

    this.hasAllowedAgendaComponent$ = this.allowedComponents$.pipe(
      map(components => components.some(component => component.componentName === ComponentNames.AGENDA))
    );

    this.hasAllowedExhibitorComponent$ = this.allowedComponents$.pipe(
      map(components => components.some(component => component.componentName === ComponentNames.EXHIBITOR))
    );

    this.hasAllowedAgendaOrBmComponent$ = this.allowedComponents$.pipe(
      map(components => components.some(component => [ComponentNames.AGENDA, ComponentNames.BUSINESS_MATCHING].includes(component.componentName)))
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  loadTags(): void {
    this.subs.add(this.tagService.getTags().subscribe(tags => {
      this.loading = false;
      if(tags.userGroupTags) {
        this.userGroupTags = [...tags.userGroupTags.filter(t => t.type_tag === TagType.User)];
        if (this.systemTags.length === 0) {
          this.systemTags = [...tags.userGroupTags.filter(t => t.type_tag === TagType.System)];
        }
      }
      if(tags.userTags) {
        this.userTags = [...tags.userTags];
      }
      if(tags.agendaPlaceTags) {
        this.agendaPlaceTags = [...tags.agendaPlaceTags];
      }
      if(tags.agendaPathTags) {
        this.agendaPathTags = [...tags.agendaPathTags];
      }
      if(tags.exhibitorTags) {
        this.exhibitorTags = [...tags.exhibitorTags];
      }
    }));
  }

  tagValueChanged(tag: TagApiModel, tagGroup: TagGroup): void {
    if(tag && tag.id) {
      // new tag
      if(tag.id === -1) {
        const payload = { value: tag.value, hex_color: tag.hex, type_tag: tagGroup };
        this.tagService.createTag(this.event, payload);
        this.underEditProccessed.next(false);
      } else {
        // edit existing tag
        const payload = { value: tag.value, hex_color: tag.hex };
        this.underEditProccessed.next(false);
        this.tagService.updateTag(this.event, tagGroup, tag.id, payload);  
      }
    }
  }

  onTagAdd(tagGroup: TagGroup): void {
    this.underEditProccessed.next(true);
    const tag = { ...createEmptyTag(), id: -1 };
    this.handleAddTagToLocalArray(tag, tagGroup);
  }

  onTagDelete(tag: TagApiModel, tagGroup: TagGroup): void {
    this.underEditProccessed.next(false);
    if(tag.id === -1) {
      this.handleDeleteTagFromLocalArray(tag, tagGroup);
    } else {
      this.subs.add(this.tagService.deleteTagWithoutLoading(this.event, tagGroup, tag.id).subscribe({
        next: () => this.handleDeleteTagFromLocalArray(tag, tagGroup),
        error: (error) => this.handleError(error)
      }));
    }
  }

  private handleDeleteTagFromLocalArray(tag: TagApiModel, tagGroup: TagGroup): void {
    switch (tagGroup) {
      case TagGroup.UserGroup:
        this.userGroupTags = this.userGroupTags.filter(t => t.id !== tag.id);
        break;
      case TagGroup.User:
        this.userTags = this.userTags.filter(t => t.id !== tag.id);
        break;
      case TagGroup.AgendaPlace:
        this.agendaPlaceTags = this.agendaPlaceTags.filter(t => t.id !== tag.id);
        break;
      case TagGroup.AgendaPath:
        this.agendaPathTags = this.agendaPathTags.filter(t => t.id !== tag.id);
        break;
      case TagGroup.ExhibitorTag:
        this.exhibitorTags = this.exhibitorTags.filter(t => t.id !== tag.id);
        break;
    }
    this.handleTagStateModify(tagGroup);
  }

  private handleAddTagToLocalArray(tag: TagApiModel, tagGroup: TagGroup): void {
    switch (tagGroup) {
      case TagGroup.UserGroup:
        this.userGroupTags.push(tag);
        break;
      case TagGroup.User:
        this.userTags.push(tag);
        break;
      case TagGroup.AgendaPlace:
        this.agendaPlaceTags.push(tag);
        break;
      case TagGroup.AgendaPath:
        this.agendaPathTags.push(tag);
        break;
      case TagGroup.ExhibitorTag:
        this.exhibitorTags.push(tag);
        break;
    }
    // this.handleTagStateModify(tagGroup);
  }

  private handleTagStateModify(tagGroup: TagGroup): void {
    switch (tagGroup) {
      case TagGroup.UserGroup:
        this.tagService.modifyStateTags(tagGroup, [...this.systemTags, ...this.userGroupTags]);
        break;
      case TagGroup.User:
        this.tagService.modifyStateTags(tagGroup, this.userTags);
        break;
      case TagGroup.AgendaPlace:
        this.tagService.modifyStateTags(tagGroup, this.agendaPlaceTags);
        break;
      case TagGroup.AgendaPath:
        this.tagService.modifyStateTags(tagGroup, this.agendaPathTags);
        break;
      case TagGroup.ExhibitorTag:
        this.tagService.modifyStateTags(tagGroup, this.exhibitorTags);
        break;
    }
  }

  closeDialog(): void {
    this.tagDatabaseModalService.dialogRef.close();
  }

  private handleError(error: Error) {
    this.errorMessage = extractErrorFrom(error);
  }
  
}

function isSystemTag(tag: TagApiModel): boolean {
  return tag.type_tag === TagType.System;
}

function createEmptyTag(): TagApiModel {
  const [r, g, b] = randomPastelColor();

  const tagColor = rgbToHex(r, g, b);
  const tagData: TagApiModel = {
    id: -1, hex: tagColor, value: '', type_tag: TagType.User, subtype_tag: null,
  };

  return tagData;
}

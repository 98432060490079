import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromIcons from './icon.reducer';

export { State, initialState, reducer } from './icon.reducer';

export const featureKey = 'iconFeature';

export const selectIconFeature = createFeatureSelector<fromIcons.State>(featureKey);

export const selectIconEntities = createSelector(
  selectIconFeature,
  fromIcons.selectIconEntities
);

export const selectAllIcons = createSelector(
  selectIconFeature,
  fromIcons.selectAllIcons
);

export const selectIconLoading = createSelector(
  selectIconFeature,
  fromIcons.selectIconLoading
);

export const selectIconLoaded = createSelector(
  selectIconFeature,
  fromIcons.selectIconLoaded
);

export const selectIconError = createSelector(
  selectIconFeature,
  fromIcons.selectIconError
);

export const selectRecommendedIcons = createSelector(
  selectAllIcons,
  (icons, { componentName }) => icons.filter(icon => icon.recommendedFor.includes(componentName))
);

import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

import { EventFacadeService } from '@store/features/event/event-facade.service';
import { ComponentFacadeService } from '@store/features/component/component-facade.service';

import { ComponentModel } from '@shared/models/component.model';
import { ComponentDefinitionModel } from '@app/construct-steps/models/component-definition.model';

import { reorder } from '@utils/collection.util';

@Injectable({
  providedIn: 'root'
})
export class ComponentService {
  constructor(private eventFacade: EventFacadeService, private componentFacade: ComponentFacadeService) {}

  getComponents(): Observable<ComponentModel[]> {
    return this.componentFacade.getAllowedComponents();
  }

  createComponent(definition: ComponentDefinitionModel): Observable<ComponentModel[]> {
    return this.createComponents([definition]);
  }

  createComponents(definitions: ComponentDefinitionModel[]): Observable<ComponentModel[]> {
    this.eventFacade.getActiveEvent().pipe(take(1)).subscribe(
      event => this.componentFacade.createComponents(event, definitions)
    );

    return of(null);
  }

  insertComponent(definition: ComponentDefinitionModel, at: number) {
    const definitions = [definition];

    this.eventFacade.getActiveEvent().pipe(take(1)).subscribe(
      event => this.componentFacade.insertComponents(event, definitions, at)
    );
  }

  updateComponent(component: ComponentModel): Observable<ComponentModel> {
    this.eventFacade.getActiveEvent().pipe(take(1)).subscribe(
      event => this.componentFacade.updateComponent(event, component, [])
    );

    return of(null);
  }

  updateOnboarding(component: ComponentModel): Observable<ComponentModel> {
    this.eventFacade.getActiveEvent().pipe(take(1)).subscribe(
      event => this.componentFacade.updateOnboarding(event, component, [])
    );

    return of(null);
  }

  deleteComponent(component: ComponentModel): Observable<ComponentModel[]> {
    this.eventFacade.getActiveEvent().pipe(take(1)).subscribe(
      event => this.componentFacade.deleteComponent(event, component)
    );

    return of(null);
  }

  reorderComponents(components: ComponentModel[]): Observable<ComponentModel[]> {
    this.eventFacade.getActiveEvent().pipe(take(1)).subscribe(
      event => this.componentFacade.reorderComponents(event, components)
    );

    return of(null);
  }

  reorderComponent(from: number, to: number): Observable<ComponentModel[]> {
    this.componentFacade.getAllComponents().pipe(take(1)).subscribe(
      components => this.reorderComponents(reorder(components, from, to))
    );

    return of(null);
  }

  setComponentVisibility(component: ComponentModel, visible: boolean): Observable<ComponentModel> {
    this.eventFacade.getActiveEvent().pipe(take(1)).subscribe(
      event => this.componentFacade.changeComponentVisibility(event, component, visible)
    );

    return of(null);
  }

  setComponentFirstToOpen(component: ComponentModel, enabled: boolean): Observable<ComponentModel> {
    this.eventFacade.getActiveEvent().pipe(take(1)).subscribe(
      event => this.componentFacade.setComponentFirstToOpen(event, component)
    );

    return of(null);
  }
}

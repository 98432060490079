import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { Observable, concat } from 'rxjs';
import { map, reduce } from 'rxjs/operators';

import { FullApiService } from '@shared/providers/full-api.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';

import {
  ExhibitorModel,
  ExhibitorPostModel,
  ExhibitorPatchModel
} from '@components/interactive-map/models/exhibitor.model';
import { ExhibitorApiModel } from '@store/features/exhibitor/models/exhibitor.api-model';

@Injectable({
  providedIn: 'root'
})
export class ExhibitorProviderService {
  
  private isExhibitorsView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private apiService: FullApiService,
  ) {}

  setIsExhibitorsView(state: boolean): void {
    this.isExhibitorsView$.next(state);
  }

  getIsExhibitorsView(): Observable<boolean> {
    return this.isExhibitorsView$.asObservable();
  }

  loadExhibitors(event: EventModel, component: ComponentModel): Observable<ExhibitorModel[]> {
    return this.apiService.getExhibitors(event.id, component.id);
  }

  loadAll(event: EventModel, maxId: number, limit: number, query: string): Observable<LoadAllExhibitorsResponse> {
    return this.apiService.getAllExhibitors(event.id, maxId, limit, query);
  }

  createExhibitor(event: EventModel, component: ComponentModel, exhibitor: ExhibitorModel): Observable<ExhibitorModel> {
    const exhibitorData = mapExhibitorPost(exhibitor);
    return this.apiService.createExhibitor(event.id, component.id, exhibitorData);
  }

  updateExhibitor(event: EventModel, component: ComponentModel, exhibitor: ExhibitorModel): Observable<ExhibitorModel> {
    const exhibitorData = mapExhibitorPatch(exhibitor);
    return this.apiService.updateExhibitor(event.id, component.id, exhibitor.id, exhibitorData);
  }

  deleteExhibitor(event: EventModel, component: ComponentModel, exhibitor: ExhibitorModel): Observable<boolean> {
    return this.apiService.deleteExhibitor(event.id, component.id, exhibitor.id).pipe(
      map(({ success }) => success)
    );
  }

  reorderExhibitors(event: EventModel, component: ComponentModel, exhibitors: ExhibitorModel[]): Observable<ExhibitorModel[]> {
    const updateObservables = exhibitors.map((exhibitor, order) => {
      const exhibitorData = { ...mapExhibitorPatch(exhibitor), order };
      return this.apiService.updateExhibitor(event.id, component.id, exhibitor.id, exhibitorData);
    });

    return concat(...updateObservables).pipe(
      reduce((acc, exhibitor) => ([...acc, exhibitor]), [])
    );
  }
}

function mapExhibitorPost(exhibitor: ExhibitorModel): ExhibitorPostModel {
  const exhibitorTagIds = exhibitor.tags_exhibitors.map(
    exhibitorTag => exhibitorTag.id
  );

  return {
    vip: exhibitor.vip, order: exhibitor.order, name: exhibitor.name,
    scope_description: exhibitor.scope_description, description: exhibitor.description,
    for_logged_contact: exhibitor.for_logged_contact, phone: exhibitor.phone,
    phone_country_code: exhibitor.phone_country_code, email: exhibitor.email,
    www: exhibitor.www, for_logged_sm: exhibitor.for_logged_sm,
    facebook_profile: exhibitor.facebook_profile, twitter_profile: exhibitor.twitter_profile,
    linked_profile: exhibitor.linked_profile, youtube_profile: exhibitor.youtube_profile,
    instagram_profile: exhibitor.instagram_profile,
    image: exhibitor.picture && exhibitor.picture.id,
    vip_image: exhibitor.vip_picture && exhibitor.vip_picture.id,
    tags_exhibitors: exhibitorTagIds
  };
}

function mapExhibitorPatch(exhibitor: ExhibitorModel): ExhibitorPatchModel {
  const exhibitorTagIds = exhibitor.tags_exhibitors.map(
    exhibitorTag => exhibitorTag.id
  );

  return {
    vip: exhibitor.vip, order: exhibitor.order, name: exhibitor.name,
    scope_description: exhibitor.scope_description, description: exhibitor.description,
    for_logged_contact: exhibitor.for_logged_contact, phone: exhibitor.phone,
    phone_country_code: exhibitor.phone_country_code, email: exhibitor.email,
    www: exhibitor.www, for_logged_sm: exhibitor.for_logged_sm,
    facebook_profile: exhibitor.facebook_profile, twitter_profile: exhibitor.twitter_profile,
    linked_profile: exhibitor.linked_profile, youtube_profile: exhibitor.youtube_profile,
    instagram_profile: exhibitor.instagram_profile,
    image: exhibitor.picture && exhibitor.picture.id,
    vip_image: exhibitor.vip_picture && exhibitor.vip_picture.id,
    tags_exhibitors: exhibitorTagIds
  };
}


export interface LoadAllExhibitorsResponse {
  exhibitors: ExhibitorApiModel[];
  meta: {
    is_more: boolean;
    all_items: number;
  }
}
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CollectionBaseService } from '@shared/providers/collection-base.service';

import { SocialMediumModel } from '@components/social-medium/models/social-medium.model';

@Injectable({
  providedIn: 'root'
})
export class SocialMediumStoreService extends CollectionBaseService<SocialMediumModel> {
  constructor() {
    super([]);
  }

  getSortedSocialMedia(): Observable<SocialMediumModel[]> {
    const sortByName = (socialMedia: SocialMediumModel[]) => {
      return socialMedia.sort((a, b) => a.name.localeCompare(b.name));
    };

    return this.getSocialMedia().pipe(
      map(socialMedia => sortByName(socialMedia))
    );
  }

  getOrderedSocialMedia(): Observable<SocialMediumModel[]> {
    const sortById = (socialMedia: SocialMediumModel[]) => {
      return socialMedia.sort((a, b) => a.id - b.id);
    };

    return this.getSocialMedia().pipe(
      map(socialMedia => sortById(socialMedia))
    );
  }

  getSocialMedia(): Observable<SocialMediumModel[]> {
    return this.getEntities();
  }

  updateEntity(entity: SocialMediumModel): void {
    super.updateEntity(entity, (a, b) => (a.id === b.id));
  }

  removeEntity(entity: SocialMediumModel): void {
    super.removeEntity(entity, (a, b) => (a.id === b.id));
  }
}

import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss']
})
export class HelpButtonComponent implements OnInit {

  @Input() urlPl?: string;
  @Input() urlEn?: string;
  @Input() withText = false;

  constructor(private i18n: TranslateService) { }

  ngOnInit(): void {
  }

  handleClick(): void {
    if(!this.urlEn || !this.urlPl) {
      return;
    }
    if(this.i18n.currentLang === 'pl') {
      window.open(this.urlPl);
    } else {
      window.open(this.urlEn);
    }
  }

}

import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot({}, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      }
    }),
    EffectsModule.forRoot([]),
    // TODO: disable store devtools on production build
    // !environment.production ? StoreDevtoolsModule.instrument() : []
    StoreDevtoolsModule.instrument(),
  ],
})
export class AppStoreModule {}

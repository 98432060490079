import { Action, createReducer, on, createSelector } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { AgendaAttendeeModel } from '@components/agenda/models/agenda-attendee.model';

import * as AttendeeActions from '../actions/attendee.actions';
import { selectEvent } from '@store/features/event/actions';

export const stateKey = 'attendees';

export interface State extends EntityState<AgendaAttendeeModel> {
  searchTerm: string;
  loading: boolean;
  loaded: boolean;
  allAttendees: number;
  maxAttendees: number;
  error: Error;
}

const adapter = createEntityAdapter<AgendaAttendeeModel>({
  selectId: attendee => attendee.uuid,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  searchTerm: '',
  loading: false,
  loaded: false,
  allAttendees: 0,
  maxAttendees: 0,
  error: null,
});

export const attendeeReducer = createReducer(
  initialState,
  on(AttendeeActions.loadAttendees, AttendeeActions.searchAttendees, () => ({
    ...initialState,
    loading: true,
  })),
  on(AttendeeActions.searchAttendees, (state, { searchTerm }) => ({
    ...state,
    searchTerm,
  })),
  on(
    AttendeeActions.loadAttendeesSuccess,
    AttendeeActions.searchAttendeesSuccess,
    (state, { attendees, maxAttendees, hasMore }) =>
      adapter.setAll(attendees, {
        ...state,
        loading: false,
        loaded: !hasMore,
        maxAttendees,
      })
  ),
  on(AttendeeActions.loadAttendeesSuccess, (state, { maxAttendees }) => ({
    ...state,
    allAttendees: maxAttendees,
  })),
  on(
    AttendeeActions.loadAttendeesFailure,
    AttendeeActions.searchAttendeesFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(AttendeeActions.loadNextAttendees, state => ({ ...state, loading: true })),
  on(
    AttendeeActions.loadNextAttendeesSuccess,
    (state, { attendees, maxAttendees, hasMore }) =>
      adapter.addMany(attendees, {
        ...state,
        loading: false,
        loaded: !hasMore,
        maxAttendees,
      })
  ),
  on(AttendeeActions.loadNextAttendeesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(
    selectEvent,
    () => ({ ...initialState }),
  )
);

export function reducer(state: State, action: Action): State {
  return attendeeReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectAttendeeIds = selectIds;

export const selectAttendeeEntities = selectEntities;

export const selectAllAttendees = selectAll;

export const selectAttendeeLoading = (state: State) => state.loading;

export const selectAttendeeLoaded = (state: State) => state.loaded;

export const selectAttendeeSearchTerm = (state: State) => state.searchTerm;

export const selectAttendeeError = (state: State) => state.error;

export const selectLastAttendee = createSelector(
  selectAttendeeIds,
  selectAttendeeEntities,
  (ids, attendees) => attendees[ids[ids.length - 1]]
);

import { concatMap, map, take } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { EventFacadeService } from '@store/features/event/event-facade.service';
import { Observable } from 'rxjs';
import { EventModel } from '@store/features/event/models/event.model';
import { API_AUTH_BASE_URL, API_BASE_URL, API_BASE_URLS } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AppIdInterceptor implements HttpInterceptor {
  event$: Observable<EventModel>;

  constructor(private eventFacade: EventFacadeService) {
    this.event$ = this.eventFacade.getActiveEvent()
      .pipe(take(1));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!isApiRequest(req)) {
      return next.handle(req);
    }
    if (hasAppIdHeader(req)) {
      return next.handle(req);
    }
    return this.handleRequest(req, next);
  }

  private handleRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isEventApiReq(req)) {
      return this.event$.pipe(
        map(event => event ? decorateRequest(req, event.appId) : req),
        concatMap(reqWithAppId => next.handle(reqWithAppId)),
      );
    } else {
      return next.handle(decorateRequest(req, 1));
    }
  }
}

function hasAppIdHeader(req: HttpRequest<any>): boolean {
  return req.headers.has('AppId');
}

function decorateRequest(req: HttpRequest<any>, appId: number): HttpRequest<any> {
  const headers = req.headers.set('AppId', `${appId}`);
  return req.clone({ headers });
}

function isApiRequest(req: HttpRequest<any>): boolean {
  return API_BASE_URLS.some(url => req.url.startsWith(url));
}

function isEventApiReq(req: HttpRequest<any>): boolean {
  return req.url.includes('/events/');
}

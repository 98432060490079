import { createAction, props } from '@ngrx/store';

import { ImportResultModel } from '@shared/models/import-result.model';

export const importPartners = createAction(
  '[Partner/Import Modal] Import Partners',
  props<{ file: File, language: string }>()
);

export const importPartnersSuccess = createAction(
  '[Partner/API] Import Partners Success',
  props<{ results: ImportResultModel[] }>()
);

export const importPartnersFailure = createAction(
  '[Partner/API] Import Partners Failure',
  props<{ error: Error }>()
);

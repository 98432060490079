import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { Collection, push, concat, insert, swap, remove } from '@utils/collection.util';

@Injectable()
export abstract class CollectionBaseService<T> {
  protected collection$: BehaviorSubject<Collection<T>>;

  constructor(initialCollection: Collection<T>) {
    this.collection$ = new BehaviorSubject<Collection<T>>(initialCollection);
  }

  getEntities(): Observable<Collection<T>> {
    return this.collection$.asObservable();
  }

  pushEntity(entity: T): void {
    this.collection$.next(
      push(this.collection$.value, entity)
    );
  }

  concatEntities(entities: T[]): void {
    this.collection$.next(
      concat(this.collection$.value, entities)
    );
  }

  insertEntity(entity: T, index: number): void {
    this.collection$.next(
      insert(this.collection$.value, entity, index)
    );
  }

  updateEntity(entity: T, compareWith: (a: T, b: T) => boolean): void {
    this.collection$.next(
      swap(this.collection$.value, entity, compareWith)
    );
  }

  removeEntity(entity: T, compareWith: (a: T, b: T) => boolean): void {
    this.collection$.next(
      remove(this.collection$.value, entity, compareWith)
    );
  }

  setup(entities: Collection<T>): void {
    this.collection$.next([...entities]);
  }

  reset(): void {
    this.collection$.next([]);
  }
}

import { BookingPlace, BookingRoom, BookingSessionPost, BookingSessionPostAPI } from '@components/booking/store/models/booking-session.model';
import { BookingReservationSlotAPI, BookingReservationSlot } from '@components/booking/store/models/booking-reservation.model';
import { Injectable } from "@angular/core";
import { BookingReservation, BookingReservationAPI, BookingReservationPost, BookingReservationPostApi } from '@components/booking/store/models/booking-reservation.model';
import { BookingDay, BookingDayAPI, BookingSession, BookingSessionAPI } from "@components/booking/store/models/booking-session.model";

@Injectable({
  providedIn: "root"
})
export class BookingAdapterService {

  constructor() {}

  adaptBookingSessionPostToApiModel(bookingSession: BookingSessionPost): BookingSessionPostAPI {
    return {
      ...(bookingSession.order ? { order: bookingSession.order } : { order: 0 }),
      title: bookingSession.title,
      reservations_per_user: bookingSession.reservationsPerUser,
      day_id: bookingSession.dayId,
      time_start: bookingSession.timeStart,
      time_end: bookingSession.timeEnd,
      time_period_slot: Number(bookingSession.timePeriodSlot),
      filter_ids: bookingSession.filterIds,
      room_ids: bookingSession.roomIds,
      ...(bookingSession.id ? { id: bookingSession.id } : undefined)
    }
  }

  adaptBookingSession(bookingSession: BookingSessionAPI): BookingSession {
    return {
      ...(bookingSession.order ? { order: bookingSession.order } : { order: 0 }),
      id: bookingSession.id,
      title: bookingSession.title,
      reservationsPerUser: bookingSession.reservations_per_user,
      day: this.adaptBookingDay(bookingSession.booking_day),
      timeStart: bookingSession.time_start,
      timeEnd: bookingSession.time_end,
      timePeriodSlot: Number(bookingSession.time_period_slot),
      filters: bookingSession.filters,
      places: bookingSession.places,
    }
  }

  adaptBookingSessionToPost(bookingSession: BookingSession): BookingSessionPost {
    return {
      ...(bookingSession.order ? { order: bookingSession.order } : { order: 0 }),
      title: bookingSession.title,
      reservationsPerUser: bookingSession.reservationsPerUser,
      dayId: bookingSession.day.id,
      timeStart: bookingSession.timeStart,
      timeEnd: bookingSession.timeEnd,
      timePeriodSlot: bookingSession.timePeriodSlot,
      filterIds: bookingSession.filters.map(filter => filter.id),
      roomIds: this.adaptPlacesToRoomIds(bookingSession.places),
      ...(bookingSession.id ? { id: bookingSession.id } : undefined)
    }
  }

  adaptBookingDayToApiModel(bookingDay: BookingDay): BookingDayAPI {
    return {
      only_date: bookingDay.onlyDate
    };
  }

  adaptBookingDay(bookingDay: BookingDayAPI): BookingDay {
    return {
      onlyDate: bookingDay.only_date,
      ...(bookingDay.id ? { id: bookingDay.id } : undefined)
    };
  }

  adaptBookingReservationPostToApiModel(bookingReservation: BookingReservationPost): BookingReservationPostApi {
    return {
      time_start: bookingReservation.timeStart,
      time_end: bookingReservation.timeEnd,
      room_id: bookingReservation.roomId,
      filter_id: bookingReservation.filterId,
      user_for_id: bookingReservation.userForId,
      day_id: bookingReservation.dayId,
      session_id: bookingReservation.sessionId,
    };
  }

  adaptBookingReservation(bookingReservation: BookingReservationAPI): BookingReservation {
    return {
      timeStart: bookingReservation.time_start,
      timeEnd: bookingReservation.time_end,
      place: bookingReservation.place,
      filter: bookingReservation.filter,
      status: bookingReservation.status,
      user: bookingReservation.user,
      sessionId: bookingReservation.session_id,
      ...(bookingReservation.id ? { id: bookingReservation.id } : undefined)
    };
  }

  adaptBookingReservationSlot(slot: BookingReservationSlotAPI): BookingReservationSlot {
    return {
      sessionId: slot.session_id,
      day: this.adaptBookingDay(slot.day),
      timeStart: slot.time_start,
      timeEnd: slot.time_end,
      place: slot.place,
      filters: slot.filters,
    };
  }

  adaptBookingReservationSlotToApiModel(slot: BookingReservationSlot): BookingReservationSlotAPI {
    return {
      session_id: slot.sessionId,
      day: this.adaptBookingDayToApiModel(slot.day),
      time_start: slot.timeStart,
      time_end: slot.timeEnd,
      place: slot.place,
      filters: slot.filters,
    };
  }

  adaptPlacesToRoomIds(places: BookingPlace[]): number[] {
    let ids = [];
    places.forEach(place => {
      place.rooms.map(room => ids.push(room.id));
    });
    return ids;
  }

  adaptPlacesToRoom(places: BookingPlace[]): BookingRoom[] {
    let rooms: BookingRoom[] = [];
    places.forEach(place => {
      place.rooms.map(room => rooms.push(room));
    });
    return rooms;
  }

}
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { profileFeatureKey } from './profile.reducer';
import { ProfileState } from './profile.state';

const selectProfileFeature = createFeatureSelector<ProfileState>(profileFeatureKey);

export const selectLoading = createSelector(selectProfileFeature, state => state.loading);
export const selectLoaded = createSelector(selectProfileFeature, state => state.loaded);

export const selectProfile = createSelector(selectProfileFeature, state => state.profile);
export const selectError = createSelector(selectProfileFeature, state => state.error);

export const selectPasswordUpdating = createSelector(selectProfileFeature, state => state.updating);

export enum AgendaRatingQuestionType {
  Closed = 'closed',
  Stars = 'stars',
  Open = 'open',
}

export interface AgendaRatingQuestionModel {
  id: number;
  componentId: number;
  title: string;
  type: AgendaRatingQuestionType;
  order: number;
  createdAt: string;
}

import { createAction, props } from '@ngrx/store';

import { PartnerModel } from '@components/partner/models/partner.model';
import { PartnerContactPersonModel } from '@components/partner/models/partner-contact-person.model';
import { PartnerContactPersonTranslationModel } from '@components/partner/models/partner-contact-person-translation.model';

export const loadPartnerContactPersonTranslation = createAction(
  '[Partner/Partner Modal] Load Partner Contact Person Translation',
  props<{ partner: PartnerModel, contact: PartnerContactPersonModel, language: string }>()
);

export const loadPartnerContactPersonTranslationSuccess = createAction(
  '[Partner/API] Load Partner Translation Contact Person Success',
  props<{ contact: PartnerContactPersonModel, translation: PartnerContactPersonTranslationModel }>()
);

export const loadPartnerContactPersonTranslationFailure = createAction(
  '[Partner/API] Load Partner Translation Contact Person Failure',
  props<{ contact: PartnerContactPersonModel, error: Error }>()
);

export const updatePartnerContactPersonTranslations = createAction(
  '[Partner/Partner Modal] Update Partner Contact Person Translations',
  props<{ partner: PartnerModel, contact: PartnerContactPersonModel, translations: PartnerContactPersonTranslationModel[] }>()
);

export const updatePartnerContactPersonTranslationsSuccess = createAction(
  '[Partner/API] Update Partner Translations Contact Person Success',
  props<{ contact: PartnerContactPersonModel, translations: PartnerContactPersonTranslationModel[] }>()
);

export const updatePartnerContactPersonTranslationsFailure = createAction(
  '[Partner/API] Update Partner Translations Contact Person Failure',
  props<{ contact: PartnerContactPersonModel, error: Error }>()
);

export const clearPartnerContactPersonTranslations = createAction(
  '[Partner/Partner Delete] Clear Partner Contact Person Translations',
  props<{ contact: PartnerContactPersonModel }>()
);

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { BehaviorSubject, Subject, Observable, Subscription, combineLatest } from 'rxjs';
import { map, takeUntil, skip, take, filter, publishReplay, refCount } from 'rxjs/operators';

import { IconFacadeService } from '@store/features/icon/icon-facade.service';
import { EventLanguageFacadeService } from '@store/features/event/event-language-facade.service';
import { ComponentFacadeService } from '@store/features/component/component-facade.service';
import { ComponentTranslationFacadeService } from '@store/features/component/component-translation-facade.service';

import { IconModel } from '@shared/models/icon.model';
import { EventModel } from '@store/features/event/models/event.model';
import { EventLanguageModel } from '@store/features/event/models/event-language.model';
import { ComponentModel } from '@shared/models/component.model';

import { ModelForm } from '@utils/model-form.util';
import { ComponentTranslationModel } from '@components/component/models/component-translation.model';
import { ComponentListPreview } from '@shared/components/colors-preview/colors-preview.component';

@Component({
  selector: 'app-component-modal',
  templateUrl: './component-modal.component.html',
  styleUrls: ['./component-modal.component.scss']
})
export class ComponentModalComponent implements OnInit, OnDestroy {
  @Input() event: EventModel;
  @Input() component: ComponentModel;

  icons$: Observable<IconModel[]>;
  recommendedIcons$: Observable<IconModel[]>;

  eventLanguages$: Observable<EventLanguageModel[]>;
  activeEventLanguage$: BehaviorSubject<EventLanguageModel>;

  hasRecommendedIcons$: Observable<boolean>;

  isReadonly$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  isSaving$: Observable<boolean>;

  componentForm: FormGroup;
  translatableForms: FormGroup;

  currentTranslatableForm$: Observable<FormGroup>;

  compPreviewData: ComponentListPreview = {
    name: '',
    icon: null,
  };

  private teardown$: Subject<void>;
  private subs = new Subscription();

  constructor(private dialogRef: MatDialogRef<any>,
    private iconFacade: IconFacadeService,
    private eventLanguageFacade: EventLanguageFacadeService,
    private componentFacade: ComponentFacadeService,
    private componentTranslationFacade: ComponentTranslationFacadeService) {

    this.activeEventLanguage$ = new BehaviorSubject(null);

    const componentDetails: ModelForm<ComponentModel> = {
      label: new FormControl(''),
      iconId: new FormControl(1, [Validators.required]),
    };

    this.componentForm = new FormGroup({
      ...componentDetails,
    });

    this.translatableForms = new FormGroup({});

    this.teardown$ = new Subject();
  }

  ngOnInit() {
    this.icons$ = this.iconFacade.getAllIcons();
    this.recommendedIcons$ = this.iconFacade.getRecommendedIcons(this.component.componentName);

    this.eventLanguages$ = this.eventLanguageFacade.getAllEventLanguagesWithNames();

    this.hasRecommendedIcons$ = this.recommendedIcons$.pipe(
      map(recommendedIcons => !!recommendedIcons.length)
    );

    this.isReadonly$ = this.activeEventLanguage$.pipe(
      map(language => !(language && language.default)),
      publishReplay(1),
      refCount()
    );

    this.isLoading$ = this.componentTranslationFacade.getComponentTranslationLoading();
    this.isSaving$ = this.componentFacade.getComponentUpdating();

    this.subs.add(this.eventLanguageFacade.getDefaultEventLanguage().pipe(take(1)).subscribe(
      language => this.activeEventLanguage$.next(language)
    ));


    this.subs.add(this.eventLanguageFacade.getAllEventLanguages().pipe(take(1)).subscribe(
      languages => languages.map(language => this.getTranslatableForm(language.code))
    ));

    this.currentTranslatableForm$ = this.activeEventLanguage$.pipe(
      map(language => {
        if(language.default) {
          return this.getTranslatableForm(language.code);
        }
        return this.getTranslatableForm(language.code);
      }),

    );

    if (this.component) {
      this.componentForm.patchValue(this.component);
    }

    this.subs.add(this.componentFacade.getUpdatedComponent().pipe(takeUntil(this.teardown$), skip(1)).subscribe(
      updatedComponent => this.dialogRef.close()
    ));

    // set preview icon
    this.subs.add(combineLatest([this.componentForm.valueChanges, this.icons$])
      .subscribe(([form, icons]) => {
        const iconId = form.iconId;
        this.compPreviewData = { ...this.compPreviewData, icon: iconId ? icons.find(i => i.id === iconId) : icons[0] };
      }));

    // set preview label
    this.subs.add(this.currentTranslatableForm$.subscribe(form => {
      this.compPreviewData = {
        ...this.compPreviewData,
        name: form.value.label,
      }
      this.subs.add(form.valueChanges.subscribe(formData => {
        this.compPreviewData = {
          ...this.compPreviewData,
          name: formData.label
          ? formData.label
          : this.componentForm.controls['label'].value,
        };
      }));
    }));

    // fake value changed emitter
    this.componentForm.patchValue({ ...this.componentForm.value });
  }

  private getTranslatableForm(language: string): FormGroup {
    let translatableForm = this.translatableForms.controls[language] as FormGroup;

    if (!translatableForm) {
      translatableForm = this.createTranslatableForm(language);
      this.translatableForms.addControl(language, translatableForm);
      this.loadTranslatableForm(translatableForm, language);
    }

    return translatableForm;
  }

  private createTranslatableForm(language: string): FormGroup {
    return new FormGroup({
      id: new FormControl(0),
      language: new FormControl(language),
      componentId: new FormControl(this.component.id),
      label: new FormControl('', [Validators.required])
    });
  }

  private loadTranslatableForm(form: FormGroup, language: string) {
    this.subs.add(this.componentTranslationFacade.getComponentTranslation(this.component, language).pipe(filter(x => !!x), take(1)).subscribe(
      translation => form.patchValue(translation)
    ));

    this.subs.add(this.componentTranslationFacade.getComponentTranslation(this.component, language).pipe(take(1)).subscribe(
      translation => !translation && this.loadTranslatableFormFor(language)
    ));
  }

  private loadTranslatableFormFor(language: string) {
    this.componentTranslationFacade.loadComponentTranslation(this.event, this.component, language);
  }

  ngOnDestroy() {
    this.teardown$.next();
    this.teardown$.complete();
  }

  isLanguageActive(language: EventLanguageModel) {
    return this.activeEventLanguage$.value.code === language.code;
  }

  onLanguageChange(ev: Event, language: EventLanguageModel) {
    if (this.activeEventLanguage$.value.code !== language.code) {
      this.activeEventLanguage$.next(language);
    }
  }

  onIconClick(ev: Event, icon: IconModel) {
    if (this.activeEventLanguage$.value.default) {
      this.componentForm.controls['iconId'].setValue(icon.id);
    }
  }

  onComponentSave(ev: Event) {
    if (this.componentForm.valid) {
      this.subs.add(this.eventLanguageFacade.getDefaultEventLanguage().pipe(take(1)).subscribe(
        defaultLanguage => this.saveComponentFor(defaultLanguage)
      ));
    }
  }

  private saveComponentFor(defaultLanguage: EventLanguageModel) {
    const component = { ...this.component, ...this.componentForm.getRawValue() };
    const translations = Object.values<ComponentTranslationModel>(this.translatableForms.value);

    const defaultTranslation = translations.find(t => t.language === defaultLanguage.code);
    const componentData = { ...component, label: defaultTranslation.label };

    this.componentFacade.updateComponent(this.event, componentData, translations);
  }
}

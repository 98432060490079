import { Injectable, InjectionToken, Inject } from '@angular/core';

import { Observable, from } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';

import {
  Stripe,
  StripeElements,
  StripeElementsOptions,
  loadStripe,
  StripeElementsOptionsClientSecret
} from '@stripe/stripe-js';

export const STRIPE_API_KEY = new InjectionToken<string>('Stripe API Key');

export const elementsOptions: StripeElementsOptionsClientSecret = {
  fonts: [
    { cssSrc: 'https://fonts.googleapis.com/css?family=Roboto:400,500,700&amp;subset=latin-ext' },
  ],
};

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private stripe$ = from(loadStripe(this.stripeApiKey)).pipe(
    shareReplay(1),
  );

  private elements$ = this.stripe$.pipe(
    map(stripe => stripe.elements(elementsOptions as StripeElementsOptionsClientSecret | undefined)),
    shareReplay(1),
  );

  constructor(@Inject(STRIPE_API_KEY) public readonly stripeApiKey: string) {}

  getStripe(): Observable<Stripe> {
    return this.stripe$;
  }

  getElements(): Observable<StripeElements> {
    return this.elements$;
  }
}

import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent {
  @Input() alignTo: string;

  @HostBinding('class.dropdown-menu') _dropdownMenu(): boolean {
    return true;
  }

  @HostBinding('class.dropdown-menu-center') get _dropdownMenuCenter(): boolean {
    return this.alignTo === 'center';
  }

  @HostBinding('class.dropdown-menu-left') get _dropdownMenuLeft(): boolean {
    return this.alignTo === 'left';
  }

  @HostBinding('class.dropdown-menu-right') get _dropdownMenuRight(): boolean {
    return this.alignTo === 'right';
  }
}

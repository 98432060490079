import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, Update, createEntityAdapter } from '@ngrx/entity';

import { SpeakerModel } from '@components/speaker/models/speaker.model';

import * as SpeakerActions from '../actions/speaker.actions';

export const stateKey = 'speaker';

export interface ComponentState {
  loading: boolean;
  loaded: boolean;
  error: Error;
  updating: boolean;
  updatedId: number;
  reordering: boolean;
}

export const componentInitialState: ComponentState = {
  loading: false,
  loaded: false,
  error: null,
  updating: false,
  updatedId: 0,
  reordering: false,
};

export interface State extends EntityState<SpeakerModel> {
  component: {
    [componentId: number]: ComponentState;
  };
}

export const adapter = createEntityAdapter<SpeakerModel>({
  sortComparer: (a, b) => a.order - b.order,
});

export const initialState: State = adapter.getInitialState({
  component: {}
});

export const speakerReducer = createReducer(
  initialState,
  on(
    SpeakerActions.loadSpeakers,
    (state, { component }) => {
      const mutated = substate(state, component.id, { ...componentInitialState, loading: true });
      return adapter.removeMany(speaker => speaker.componentId === component.id, mutated);
    }
  ),
  on(
    SpeakerActions.loadSpeakersSuccess,
    (state, { component, speakers }) => {
      const mutated = substate(state, component.id, { loading: false, loaded: true });
      return adapter.upsertMany(speakers, mutated);
    }
  ),
  on(
    SpeakerActions.loadSpeakersFailure,
    (state, { component, error }) => substate(state, component.id, { loading: false, error })
  ),
  on(
    SpeakerActions.createSpeaker,
    SpeakerActions.updateSpeaker,
    (state, { component }) => substate(state, component.id, { updating: true })
  ),
  on(
    SpeakerActions.createSpeakerSuccess,
    SpeakerActions.updateSpeakerSuccess,
    (state, { component, speaker }) => {
      const mutated = substate(state, component.id, { updating: false, updatedId: speaker.id });
      return adapter.upsertOne(speaker, mutated);
    }
  ),
  on(
    SpeakerActions.createSpeakerFailure,
    SpeakerActions.updateSpeakerFailure,
    (state, { component, error }) => substate(state, component.id, { updating: false, error })
  ),
  on(
    SpeakerActions.reorderSpeakers,
    (state, { speakers, component }) => {
      // Update locally stored speakers for instant preview
      // TODO: Add speakers state revert on failed request
      const updates: Update<SpeakerModel>[] = speakers.map((speaker, order) => ({
        id: speaker.id, changes: { order }
      }));
      const mutated = substate(state, component.id, { reordering: true });
      return adapter.updateMany(updates, mutated);
    }
  ),
  on(
    SpeakerActions.reorderSpeakersSuccess,
    (state, { speakers, component }) => {
      const mutated = substate(state, component.id, { reordering: false });
      return adapter.upsertMany(speakers, mutated);
    }
  ),
  on(
    SpeakerActions.reorderSpeakersFailure,
    (state, { component, error }) => substate(state, component.id, { error, reordering: false })
  ),
  on(
    SpeakerActions.deleteSpeakerSuccess,
    (state, { speaker }) => adapter.removeOne(speaker.id, state)
  ),
  on(
    SpeakerActions.deleteSpeakerFailure,
    (state, { component, error }) => substate(state, component.id, { error })
  ),
  on(
    SpeakerActions.clearSpeakers,
    (state, { component }) => {
      const mutated = substate(state, component.id, { ...componentInitialState });
      return adapter.removeMany(speaker => speaker.componentId === component.id, mutated);
    }
  ),
);

function substate(state: State, componentId: number, changes: Partial<ComponentState>): State {
  return { ...state, component: { ...state.component, [componentId]: { ...state.component[componentId], ...changes } } };
}

export function reducer(state: State, action: Action): State {
  return speakerReducer(state, action);
}

const {
  selectIds,
  selectEntities,
  selectAll,
} = adapter.getSelectors();

export const selectSpeakerIds = selectIds;

export const selectSpeakerEntities = selectEntities;

export const selectAllSpeakers = selectAll;

export const selectSpeakerComponent = (state: State, { componentId }) => state.component[componentId];

export const selectSpeakers = createSelector(
  selectAllSpeakers,
  (speakers: SpeakerModel[], { componentId }) => speakers.filter(speaker => speaker.componentId === componentId)
);

export const selectSpeakerLoading = createSelector(
  selectSpeakerComponent,
  component => component && component.loading
);

export const selectSpeakerLoaded = createSelector(
  selectSpeakerComponent,
  component => component && component.loaded
);

export const selectSpeakerError = createSelector(
  selectSpeakerComponent,
  component => component && component.error
);

export const selectSpeakerUpdating = createSelector(
  selectSpeakerComponent,
  component => component && component.updating
);

export const selectSpeakerReordering = createSelector(
  selectSpeakerComponent,
  component => component && component.reordering
);

export const selectUpdatedSpeakerId = createSelector(
  selectSpeakerComponent,
  component => component && component.updatedId
);

export const selectUpdatedSpeaker = createSelector(
  selectSpeakerEntities,
  selectUpdatedSpeakerId,
  (speakers, updatedId) => speakers[updatedId]
);


import { Action, createReducer, on } from '@ngrx/store';

import * as ImportActions from '../actions/agenda-import.actions';
import { selectEvent } from '@store/features/event/actions';
import { AgendaImportConfigModel } from '../models/agenda-import-config.model';

export const stateKey = 'import';

export interface State {
  loading: boolean;
  updating: boolean;
  config: AgendaImportConfigModel | null;
  importing: boolean;
  importingError: Error; // for sync
  error: Error; // for load or update
}

export const initialState: State = {
  loading: false,
  updating: false,
  config: null,
  importing: false,
  importingError: null,
  error: null,
};

export const importReducer = createReducer(
  initialState,
  on(
    ImportActions.loadAgendaImportConfig,
    state => ({ ...state, loading: true })
  ),
  on(
    ImportActions.loadAgendaImportConfigSuccess,
    (state, { config }) => ({ ...state, loading: false, config, error: null })
  ),
  on(
    ImportActions.loadAgendaImportConfigFailure,
    (state, { error }) => ({ ...state, loading: false })
  ),

  on(
    ImportActions.updateAgendaImportConfig,
    state => ({ ...state, updating: true })
  ),
  on(
    ImportActions.updateAgendaImportConfigSuccess,
    (state, { config }) => ({ ...state, updating: false, error: null, config })
  ),
  on(
    ImportActions.updateAgendaImportConfigFailure,
    (state, { error }) => ({ ...state, updating: false, error, config: { ...state.config } })
  ),

  on(
    ImportActions.syncAgendaImport,
    (state) => ({ ...state, importing: true })
  ),
  on(
    ImportActions.syncAgendaImportSuccess,
    (state) => ({ ...state, importing: false, error: null })
  ),
  on(
    ImportActions.syncAgendaImportFailure,
    (state, { error }) => ({ ...state, importing: false, importingError: error })
  ),
  on(
    ImportActions.setError,
    (state, { error }) => ({ ...state, error, })
  ),

  on(
    selectEvent,
    () => ({ ...initialState }),
  )
);

export function reducer(state: State, action: Action): State {
  return importReducer(state, action);
}

export const selectConfig = (state: State) => state.config;
export const selectLoading = (state: State) => state.loading;
export const selectUpdating = (state: State) => state.updating;
export const selectError = (state: State) => state.error;

export const selectImporting = (state: State) => state.importing;
export const selectImportingError = (state: State) => state.importingError;
import { Component, Input, HostBinding } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss'],
  animations: [
    trigger('fadeOut', [
      transition(':leave', [
        style({ opacity: 1.0 }),
        animate('100ms 200ms linear', style({ opacity: 0.0 }))
      ])
    ])
  ]
})
export class SpinnerOverlayComponent {
  @Input() background: string;
  @Input() float = false;
  @Input() message: string = null;

  @HostBinding('@fadeOut') get _fadeOut() {
    return true;
  }

  @HostBinding('style.background') get _backgroundColor() {
    return this.background;
  }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { LineChartConfiguration, LineChartData } from './line-chart-provider.service';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent implements OnInit, OnChanges {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  @Input() configuration: LineChartConfiguration;
  @Input() datasets: DatasetsModel[];
  @Input() translateLabels: boolean;
  @Input() yShowTicks: boolean | null = true;

  public options: ChartConfiguration['options'] = {}

  public data: ChartData<'line', number[], string | string[]> = {
    labels: [],
    datasets: []
  };
  
  public type: ChartType = 'line';
  public plugins: any[] = [];

  constructor(private i18n: TranslateService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datasets) {
      const currentData: number[] = changes.datasets.currentValue?.map((d: DatasetsModel) => d.value);
      const prevData: number[] = changes.datasets.previousValue?.map((d: DatasetsModel) => d.value);

      const currentLabels: number[] = changes.datasets.currentValue?.map((d: DatasetsModel) => d.label);
      const prevLabels: number[] = changes.datasets.previousValue?.map((d: DatasetsModel) => d.label);
      if (currentData?.toString() !== prevData?.toString() || currentLabels?.toString() !== prevLabels?.toString()) {
        this.populateData();
      }
    }
  }

  ngOnInit(): void {
    this.data = {
      labels: this.translateLabels ? this.datasets.map(dataset => this.i18n.instant(dataset.label)) : this.datasets.map(dataset => dataset),
      datasets: [{
        data: [...this.datasets.map(d => d.value)],
        label: this.i18n.instant('chart.amount'),
        tension: this.configuration.smoothLines ? 0.4 : 0,
        borderDash: this.configuration.datalineDashed ? [10, 5] : [],
        borderColor: this.configuration.colors[0],
        pointBackgroundColor: '#ffffff',
        pointBorderColor: '#00aeef',
        pointHoverBorderColor: '#00aeef',
        pointHoverBackgroundColor: '#ffffff',
        pointBorderWidth: 1
      }]
    }

    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: '#e9e9e9',
          padding: 10,
          bodyFont: {
            family: "'Poppins', sans-serif",
            size: 14,
          },
          bodyColor: '#000000',
          titleColor: '#000000',
          displayColors: false
        }
      },
      scales: {
        y: {
          beginAtZero: this.configuration.yBeginAtZero,
          ticks: {
            stepSize: 1,
            display: this.yShowTicks ? true : false,
            font: {
              size: 12,
              family: "'Poppins', sans-serif",
            },
            color: '#dedede'
          },
          grid: {
            display: true,
            color: '#efefef',
          },
        },
        x: {
          stacked: true,
          grid: {
            display: true,
            color: '#efefef',
          },
          ticks: {
            font: {
              size: 12,
              family: "'Poppins', sans-serif",
            },
            color: '#dedede'
          }
        },
      }
    }
  }

  private populateData(): void {
    this.data = {
      labels: this.translateLabels ? this.datasets.map(dataset => this.i18n.instant(dataset.label)) : this.datasets.map(dataset => dataset),
      datasets: [{
        data: [...this.datasets.map(d => d.value)],
        label: this.i18n.instant('chart.amount'),
        tension: this.configuration.smoothLines ? 0.4 : 0,
        borderDash: this.configuration.datalineDashed ? [10, 5] : [],
        borderColor: this.configuration.colors[0],
        pointBackgroundColor: '#ffffff',
        pointBorderColor: '#00aeef',
        pointHoverBorderColor: '#00aeef',
        pointHoverBackgroundColor: '#ffffff',
        pointBorderWidth: 1
      }]
    }
  }

}

export interface DatasetsModel {
  label: string;
  value: number;
}
export const Intercom = {
  agenda_component: {
    en: 'https://intercom.help/meetingapp/en/articles/7924644-agenda-of-your-event',
    pl: 'https://intercom.help/meetingapp/en/articles/4842342-agenda-wydarzenia',
  },
  tags: {
    en: 'https://intercom.help/meetingapp/en/articles/4842874-tag-database-application-content-managment',
    pl: 'https://intercom.help/meetingapp/en/articles/4843445-tag-database-zarzadzanie-trescia-aplikacji',
  },
  import_users_modal: {
    en: 'https://intercom.help/meetingapp/en/articles/4843389-users-import',
    pl: 'https://intercom.help/meetingapp/en/articles/4842491-import-uzytkownikow',
  },
  exhibitor_component: {
    en: 'https://intercom.help/meetingapp/en/articles/4843055-exhibitor-profiles',
    pl: 'https://intercom.help/meetingapp/en/articles/4842377-wystawcy',
  },
  exhibitor_updates: {
    en: 'https://intercom.help/meetingapp/en/articles/4843055-exhibitor-profiles',
    pl: 'https://intercom.help/meetingapp/en/articles/4842377-wystawcy',
  },
  speaker_component: {
    en: 'https://intercom.help/meetingapp/en/articles/4843372-import-data-to-speakers-component',
    pl: 'https://intercom.help/meetingapp/en/articles/4843372-import-data-to-speakers-component',
  },
  www_resources_component: {
    en: 'https://intercom.help/meetingapp/en/articles/4843107-www-resources',
    pl: 'https://intercom.help/meetingapp/en/articles/4842443-www-resources',
  },
  breadcrumbs_component: {
    en: 'https://intercom.help/meetingapp/en/collections/2745052-english-for-organizer',
    pl: 'https://intercom.help/meetingapp/en/collections/2745047-polski-dla-organizatora',
  },
  check_event: {
    en: 'https://intercom.help/meetingapp/en/articles/7895389-check-out-your-application',
    pl: 'https://intercom.help/meetingapp/en/articles/7895320-sprawdz-swoja-aplikacje',
  },
  basic_info_component: {
    en: 'https://intercom.help/meetingapp/en/articles/7987041-meeting-application-step-by-step',
    pl: 'https://intercom.help/meetingapp/en/articles/7979779-meeting-app-krok-po-kroku',
  },
  import_agenda_modal: {
    en: 'https://intercom.help/meetingapp/en/articles/4843189-import-data-to-agenda-component',
    pl: 'https://intercom.help/meetingapp/en/articles/4842474-import-agendy-wydarzenia',
  },
  coupons_component: {
    en: 'https://intercom.help/meetingapp/en/articles/8263268-kupony',
    pl: 'https://intercom.help/meetingapp/en/articles/8263268-kupony',
  },
  access_component: {
    en: 'https://intercom.help/meetingapp/en/articles/4842810-access-mode-application-access-settings',
    pl: 'https://intercom.help/meetingapp/en/articles/4842316-ustawienia-dostepu-access-mode',
  },
  ticketing: {
    en: 'https://intercom.help/meetingapp/en/articles/8034489-tickets',
    pl: 'https://intercom.help/meetingapp/en/articles/8032127-bilety',
  },
  dedicated_forms: {
    en: 'https://intercom.help/meetingapp/en/articles/8056801-registration-forms',
    pl: 'https://intercom.help/meetingapp/en/articles/8031756-formularze-rejestracyjne',
  },
  system_features: {
    en: 'https://intercom.help/meetingapp/en/articles/8010483-meeting-application-module-descriptions',
    pl: 'https://intercom.help/meetingapp/en/articles/8010450-opisy-modulow-meeting-application',
  },
  components: {
    en: 'https://intercom.help/meetingapp/en/collections/2745057-components',
    pl: 'https://intercom.help/meetingapp/en/collections/2745049-komponenty',
  }
};
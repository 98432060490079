export const editorSnippets = {
  fullName: '{{user_full_name}}',
  firstName: '{{user_first_name}}',
  lastName: '{{user_last_name}}',
  eventEmail: '{{event_email}}',
  eventLandingPage: '{{event_lp}}',
  userActivationUrl: '{{user_activation_url}}',
  getAndroidApp: '{{get_android_app}}',
  getIosApp: '{{get_ios_app}}',
  eventName: '{{event_name}}'
}

export function getEditorSnippets() {
  return [
    {
      type: 'menuitem',
      text: 'Full name',
      content: `<span contenteditable="false">${ editorSnippets.fullName }</span>`
    },
    {
      type: 'menuitem',
      text: 'Fist name',
      content: `<span contenteditable="false">${ editorSnippets.firstName }</span>`
    },
    {
      type: 'menuitem',
      text: 'Last name',
      content: `<span contenteditable="false">${ editorSnippets.lastName }</span>`
    },
    {
      type: 'menuitem',
      text: 'Event email',
      content: `<span contenteditable="false">${ editorSnippets.eventEmail }</span>`
    },
    {
      type: 'menuitem',
      text: 'Event name',
      content: `<span contenteditable="false">${ editorSnippets.eventName }</span>`
    },
    {
      type: 'menuitem',
      text: 'Event landing page',
      content: `<span contenteditable="false">${ editorSnippets.eventLandingPage }</span>`
    },
    {
      type: 'menuitem',
      text: 'User activation url',
      content: `<span contenteditable="false">${ editorSnippets.userActivationUrl }</span>`
    },
    {
      type: 'menuitem',
      text: 'Get android app',
      content: `<span contenteditable="false">${ editorSnippets.getAndroidApp }}</span>`
    },
    {
      type: 'menuitem',
      text: 'Get ios app',
      content: `<span contenteditable="false">${ editorSnippets.getIosApp }</span>`
    }
  ]
}
import { createAction, props } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { AgendaSessionModel } from '@components/agenda/models/agenda-session.model';
import { AgendaTicketModel } from '@components/agenda/models/agenda-ticket.model';

export const loadAgendaTickets = createAction(
  '[Agenda/Agenda Modal] Load Agenda Tickets',
  props<{ event: EventModel, component: ComponentModel, session: AgendaSessionModel }>()
);

export const loadAgendaTicketsSuccess = createAction(
  '[Agenda/API] Load Agenda Tickets Success',
  props<{ tickets: AgendaTicketModel[] }>()
);

export const loadAgendaTicketsFailure = createAction(
  '[Agenda/API] Load Agenda Tickets Failure',
  props<{ error: Error }>()
);

import { createAction, props } from '@ngrx/store';

import {
  WwwResourceModel,
  WwwResourceTranslationModel,
  WwwResourceCategoryModel,
  WwwResourceCategoryTranslationModel
} from '@components/www-resource/models/www-resource.model';

export const loadWwwResourceTranslation = createAction(
  '[WwwResource/WwwResource Modal] Load WwwResource Translation',
  props<{ wwwResource: WwwResourceModel, language: string }>()
);

export const loadWwwResourceTranslationSuccess = createAction(
  '[WwwResource/API] Load WwwResource Translation Success',
  props<{ wwwResource: WwwResourceModel, translation: WwwResourceTranslationModel }>()
);

export const loadWwwResourceTranslationFailure = createAction(
  '[WwwResource/API] Load WwwResource Translation Failure',
  props<{ wwwResource: WwwResourceModel, error: Error }>()
);

export const updateWwwResourceTranslations = createAction(
  '[WwwResource/WwwResource Modal] Update WwwResource Translations',
  props<{ wwwResource: WwwResourceModel, translations: WwwResourceTranslationModel[] }>()
);

export const updateWwwResourceTranslationsSuccess = createAction(
  '[WwwResource/API] Update WwwResource Translations Success',
  props<{ wwwResource: WwwResourceModel, translations: WwwResourceTranslationModel[] }>()
);

export const updateWwwResourceTranslationsFailure = createAction(
  '[WwwResource/API] Update WwwResource Translations Failure',
  props<{ wwwResource: WwwResourceModel, error: Error }>()
);

export const clearWwwResourceTranslations = createAction(
  '[WwwResource/WwwResource Delete] Clear WwwResource Translations',
  props<{ wwwResource: WwwResourceModel }>()
);

export const loadWwwResourceCategoryTranslation = createAction(
  '[WwwResource/Component] Load WwwResource Category Translation',
  props<{ category: WwwResourceCategoryModel, language: string }>()
);

export const loadWwwResourceCategoryTranslationSuccess = createAction(
  '[WwwResource/API] Load WwwResource Category Translation Success',
  props<{ category: WwwResourceCategoryModel, translation: WwwResourceCategoryTranslationModel, }>()
);

export const loadWwwResourceCategoryTranslationFailure = createAction(
  '[WwwResource/API] Load WwwResource Category Translation Failure',
  props<{ category: WwwResourceCategoryModel, error: Error }>()
);

export const updateWwwResourceCategoryTranslations = createAction(
  '[WwwResource/Component] Update WwwResource Category Translations',
  props<{ category: WwwResourceCategoryModel, translations: WwwResourceCategoryTranslationModel[] }>()
);

export const updateWwwResourceCategoryTranslationsSuccess = createAction(
  '[WwwResource/API] Update WwwResource Category Translations Success',
  props<{ category: WwwResourceCategoryModel, translations: WwwResourceCategoryTranslationModel[] }>()
);

export const updateWwwResourceCategoryTranslationsFailure = createAction(
  '[WwwResource/API] Update WwwResource Category Translations Failure',
  props<{ category: WwwResourceCategoryModel, error: Error }>()
);

export const clearWwwResourceCategoryTranslations = createAction(
  '[WwwResource/WwwResource Delete] Clear WwwResource Category Translations',
  props<{ category: WwwResourceCategoryModel }>()
);

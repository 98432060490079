import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromTimezone from '@store/features/timezone/reducers';
import * as fromLanguage from '@store/features/language/reducers';
import * as fromComponent from '@store/features/component/reducers';
import * as fromConstructor from './construct.reducer';

export const featureKey = 'constructorFeature';

export { State, initialState, reducer } from './construct.reducer';

export const selectConstructorFeature =
  createFeatureSelector<fromConstructor.State>(featureKey);

export const selectCurrentStep = createSelector(
  selectConstructorFeature,
  fromConstructor.selectCurrentStep
);

export const selectLoading = createSelector(
  fromTimezone.selectTimezoneLoading,
  fromLanguage.selectLanguageLoading,
  fromComponent.selectComponentLoading,
  fromComponent.selectComponentPlanLoading,
  // fromComponent.selectComponentDefinitionLoading,
  (l1, l2, l3, l4) => l1 || l2 || l3 || l4
);

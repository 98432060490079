import { BookingReservationMeta } from '@components/booking/store/models/booking-reservation.model';
import { BookingReservationSlot } from '@components/booking/store/models/booking-reservation.model';
import { Action, createReducer, on } from '@ngrx/store';
import { bookingReservationActions } from '@components/booking/store/actions/booking-reservation.actions';
import { BookingReservation, BookingReservationStatus } from '@components/booking/store/models/booking-reservation.model';

export const featureKey = 'bookingReservation';

export interface State {
  loading: boolean;
  error: Error;
  saving: boolean;
  creatorMode: boolean;
  activeStatus: BookingReservationStatus;
  reservations: BookingReservation[];
  slots: BookingReservationSlot[];
  meta: BookingReservationMeta;
  exporting: boolean;
}

export const initialState = {
  loading: false,
  error: null,
  saving: false,
  creatorMode: false,
  activeStatus: BookingReservationStatus.Pending,
  reservations: [],
  slots: [],
  meta: null,
  exporting: false
};

export const bookingReservationReducer = createReducer(
  initialState,
  on(bookingReservationActions.createBookingReservation,
    state => ({ ...state, saving: true })),

  on(bookingReservationActions.createBookingReservationSuccess,
    state => ({ ...state, saving: false })),

  on(bookingReservationActions.createBookingReservationFailure,
    (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingReservationActions.loadBookingReservations,
    state => ({ ...state, loading: true })),

  on(bookingReservationActions.loadBookingReservationsSuccess,
    (state, { reservations }) => ({ ...state, loading: false, reservations: reservations.data, meta: reservations.meta })),

  on(bookingReservationActions.loadBookingReservationsFailure,
    (state, { error }) => ({ ...state, loading: false, error })),

  on(bookingReservationActions.acceptBookingReservation,
    state => ({ ...state, saving: true })),
  on(bookingReservationActions.acceptBookingReservationSuccess,
    (state, { reservation }) => ({ ...state, saving: false, reservations: state.reservations
      .map(br => br.id === reservation.id ? {...reservation, status: BookingReservationStatus.Accepted } : br) })),

  on(bookingReservationActions.acceptBookingReservationFailure,
    (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingReservationActions.declineBookingReservation,
    state => ({ ...state, saving: true })),

  on(bookingReservationActions.declineBookingReservationSuccess,
    (state, { reservation }) => ({ ...state, saving: false, reservations: state.reservations
      .map(br => br.id === reservation.id ? {...reservation, status: BookingReservationStatus.Declined } : br) })),
      
  on(bookingReservationActions.declineBookingReservationFailure,
    (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingReservationActions.setBookingReservationActiveStatus,
    (state, { status }) => ({ ...state, activeStatus: status })),

  on(bookingReservationActions.setBookingReservationError,
    (state, { error }) => ({ ...state, error })),

  on(bookingReservationActions.setBookingReservationCreatorMode,
    (state, { mode }) => ({ ...state, creatorMode: mode })),

  on(bookingReservationActions.loadBookingReservationSlots,
    state => ({ ...state, loading: true })),

  on(bookingReservationActions.loadBookingReservationSlotsSuccess,
    (state, { slots }) => ({ ...state, loading: false, slots })),

  on(bookingReservationActions.loadBookingReservationSlotsFailure,
    (state, { error }) => ({ ...state, loading: false, error })),

  on(bookingReservationActions.addFromSocket,
    (state, { reservation }) => ({ ...state, reservations: [reservation].concat(state.reservations) })),

  on(bookingReservationActions.deleteFromSocket,
    (state, { id }) => ({ ...state, reservations: state.reservations.filter(br => br.id !== id) })),

  on(bookingReservationActions.exportBookingReservations,
    state => ({ ...state, exporting: true })),
  
  on(bookingReservationActions.exportBookingReservationsSuccess,
    state => ({ ...state, exporting: false })),
  
  on(bookingReservationActions.exportBookingReservationsFailure,
    (state, { error }) => ({ ...state, exporting: false, error }))

);

export function reducer(state: State, action: Action): State {
  return bookingReservationReducer(state, action);
}

export const selectAllBookingReservations = (state: State) => state.reservations;
export const selectBookingReservationById = (state: State, id: number) => state.reservations.find(reservation => reservation.id === id);
export const selectBookingReservationActiveStatus = (state: State) => state.activeStatus;
export const selectBookingReservationIsLoading = (state: State) => state.loading;
export const selectBookingReservationIsSaving = (state: State) => state.saving;
export const selectBookingReservationError = (state: State) => state.error;
export const selectBookingReservationCreatorMode = (state: State) => state.creatorMode;
export const selectBookingReservationSlots = (state: State) => state.slots;
export const selectBookingReservationMeta = (state: State) => state.meta;
export const selectBoookingReservationIsExporting = (state: State) => state.exporting;

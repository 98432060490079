import * as Moment from 'moment';

export function isNumber(value: any): value is number {

    return typeof value === 'number';
}

export function isNumberFinite(value: any): value is number {

    return isNumber(value) && isFinite(value);
}

// Not strict positive
export function isPositive(value: number): boolean {

    return value >= 0;
}


export function isInteger(value: number): boolean {

    // No rest, is an integer
    return (value % 1) === 0;
}


export function toDecimal(value: number, decimal: number): number {

    return Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

export function scrollToError() {
    setTimeout(() => {
        const errorDivs = document.getElementsByClassName('has-danger');

        if (errorDivs.length > 0) {
            errorDivs[0].scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, 50);
}

export {};

declare global {
    interface Date {
        timeBetween(startDate: Date, endDate: Date): boolean;

        withoutTimezone(): Date;

        toStringDate(): String;

        toDateTimeString(): String;
    }
}

Date.prototype.timeBetween = function (startDate: Date, endDate: Date): boolean {
    const minutes = (this.getHours() * 60) + this.getMinutes();
    const startDateMinutes = (startDate.getHours() * 60) + startDate.getMinutes();
    const endDateMinutes = (endDate.getHours() * 60) + endDate.getMinutes();

    return (startDateMinutes >= minutes) && (minutes <= endDateMinutes);
};

Date.prototype.withoutTimezone = function () {
    return new Date(this.toUTCString().replace('GMT', ''));
};

Date.prototype.toDateString = function () {
    return Moment(this).format('YYYY-MM-DD');
};

Date.prototype.toDateTimeString = function () {
    return Moment(this).format('YYYY-MM-DD hh:mm:ss');
};

import { ElementRef, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ModalErrorComponent } from "./modal-error.component";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ModalErrorProviderSerivce {
  constructor(
    private dialog: MatDialog,
    private i18n: TranslateService,
  ) {}
  
  open(title: string, desc: string): void {
    this.dialog.open(ModalErrorComponent, {
      width: '400px',
      height: '300px',
      data: {
        title,
        message: desc,
        buttonI18nKey: 'buttons.submit',
      }
    });
  }
}
import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, Validators, NG_VALUE_ACCESSOR } from '@angular/forms';

import { DateTime } from 'luxon';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true
    }
  ]
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {
  @Input() timezone: string;
  @Input() span: number;
  @Input() min: string;
  @Input() max: string;

  inputControl: FormControl;

  get isNextDay(): boolean {
    if (!this.dateTime) {
      return false;
    }

    const seconds = this.dateTimeMin && (this.dateTime.toSeconds() - this.dateTimeMin.toSeconds());
    return seconds && seconds >= 60 * 60 * 24;
  }

  private dateTime: DateTime;
  private dateTimeMin: DateTime;
  private dateTimeMax: DateTime;

  private onChange: (value: string) => void;
  private onTouched: () => void;

  constructor() {
    this.span = 15;

    this.inputControl = new FormControl('', [Validators.pattern(/^\d{2}:\d{2}$/)]);

    this.dateTime = DateTime.local().setZone(this.timezone).set({
      hour: 0, minute: 0, second: 0, millisecond: 0
    });
  }

  ngOnInit() {
    this.dateTimeMin = this.min && DateTime.fromISO(this.min, { zone: this.timezone });
    this.dateTimeMax = this.max && DateTime.fromISO(this.max, { zone: this.timezone });
  }

  clearNextDay() {
    if (this.inputControl.value === '') {
      this.dateTime = this.dateTimeMin;
    }
  }

  onSpanClick(ev: Event, spanBy: number) {
    if (!this.dateTime) {
      this.dateTime = this.dateTimeMin;
    }

    this.dateTime = this.dateTime.plus({ minute: spanBy });
    this.updateDisplayDate();

    if (this.onChange) {
      this.onChange(this.dateTime.toISO());
    }
  }

  onInputBlur(ev: Event) {
    const value = this.inputControl.value as string;
    const seg = value.split(':');

    if (!this.dateTime) {
      this.dateTime = this.dateTimeMin;
    }

    if (seg[0]) {
      this.dateTime = this.dateTime.set({ hour: parseInt(seg[0], 10) });
      this.dateTime = this.dateTime.set({ minute: parseInt(seg[1], 10) || 0 });
    } else {
      this.dateTime = this.dateTimeMin;
    }

    this.updateDisplayDate();

    if (this.onChange) {
      this.onChange(this.dateTime.toISO());
    }
  }

  writeValue(value: string): void {
    if (value !== null) {
      this.dateTime = DateTime.fromISO(value, { zone: this.timezone });
    } else {
      this.dateTime = null;
    }

    this.updateDisplayDate();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.inputControl.disable();
    } else {
      this.inputControl.enable();
    }
  }

  private updateDisplayDate() {
    if (this.dateTime === null) {
      this.inputControl.setValue('');
      return;
    }

    if (this.dateTimeMin && this.dateTime.toSeconds() < this.dateTimeMin.toSeconds()) {
      this.dateTime = this.dateTimeMin;
    }

    if (this.dateTimeMax && this.dateTime.toSeconds() > this.dateTimeMax.toSeconds()) {
      this.dateTime = this.dateTimeMax;
    }

    const formatted = this.dateTime.toFormat('HH:mm');
    this.inputControl.setValue(formatted);
  }
}

import { createAction, props } from '@ngrx/store';

import { EventModel } from '../models/event.model';
import { EventLanguageModel } from '../models/event-language.model';

export const loadEventLanguages = createAction(
  '[Event/Constructor] Load Event Languages',
  props<{ event: EventModel }>()
);

export const loadEventLanguagesSuccess = createAction(
  '[Event/API] Load Event Language Success',
  props<{ languages: EventLanguageModel[] }>()
);

export const loadEventLanguagesFailure = createAction(
  '[Event/API] Load Event Language Failure',
  props<{ error: Error }>()
);

import { createReducer, on } from '@ngrx/store';

import { initialState } from './profile.state';
import * as profileActions from './profile.actions';

export const profileFeatureKey = 'profile';

export const profileReducer = createReducer(
  initialState,
  on(
    profileActions.loadProfile,
    state => ({ ...initialState, loading: true })
  ),
  on(
    profileActions.loadProfileSuccess,
    (state, { profile }) => ({ ...state, loading: false, loaded: true, profile })
  ),
  on(
    profileActions.loadProfileFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    profileActions.changeProfilePictureSuccess,
    (state, { profile }) => ({ ...state, profile })
  ),
  on(
    profileActions.signOut,
    state => ({ ...initialState })
  ),
  on(
    profileActions.changePassword,
    (state) => ({ ...state, updating: true })
  ),
  on(
    profileActions.changePasswordSuccess,
    (state) => ({ ...state, updating: false, error: null })
  ),
  on(
    profileActions.changePasswordFailure,
    (state, { error }) => ({ ...state, error })
  )
);

import { createAction, props } from '@ngrx/store';

import { ExhibitorModel } from '@components/exhibitor/models/exhibitor.model';
import { ExhibitorContactPersonModel } from '@components/exhibitor/models/exhibitor-contact-person.model';
import { ExhibitorContactPersonTranslationModel } from '@components/exhibitor/models/exhibitor-contact-person-translation.model';

export const loadExhibitorContactPersonTranslation = createAction(
  '[Exhibitor/Exhibitor Modal] Load Exhibitor Contact Person Translation',
  props<{ exhibitor: ExhibitorModel, contact: ExhibitorContactPersonModel, language: string }>()
);

export const loadExhibitorContactPersonTranslationSuccess = createAction(
  '[Exhibitor/API] Load Exhibitor Translation Contact Person Success',
  props<{ contact: ExhibitorContactPersonModel, translation: ExhibitorContactPersonTranslationModel }>()
);

export const loadExhibitorContactPersonTranslationFailure = createAction(
  '[Exhibitor/API] Load Exhibitor Translation Contact Person Failure',
  props<{ contact: ExhibitorContactPersonModel, error: Error }>()
);

export const updateExhibitorContactPersonTranslations = createAction(
  '[Exhibitor/Exhibitor Modal] Update Exhibitor Contact Person Translations',
  props<{ exhibitor: ExhibitorModel, contact: ExhibitorContactPersonModel, translations: ExhibitorContactPersonTranslationModel[] }>()
);

export const updateExhibitorContactPersonTranslationsSuccess = createAction(
  '[Exhibitor/API] Update Exhibitor Translations Contact Person Success',
  props<{ contact: ExhibitorContactPersonModel, translations: ExhibitorContactPersonTranslationModel[] }>()
);

export const updateExhibitorContactPersonTranslationsFailure = createAction(
  '[Exhibitor/API] Update Exhibitor Translations Contact Person Failure',
  props<{ contact: ExhibitorContactPersonModel, error: Error }>()
);

export const clearExhibitorContactPersonTranslations = createAction(
  '[Exhibitor/Exhibitor Delete] Clear Exhibitor Contact Person Translations',
  props<{ contact: ExhibitorContactPersonModel }>()
);

import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter, Update } from '@ngrx/entity';

import { AgendaSessionModel } from '@components/agenda/models/agenda-session.model';

import * as AgendaSessionActions from '../actions/agenda-session.actions';
import { selectEvent } from '@store/features/event/actions';

export const featureKey = 'agendaSession';

export function sortAgendaSessions(a: AgendaSessionModel, b: AgendaSessionModel) {
  const dateA = Date.parse(a.timeStart);
  const dateB = Date.parse(b.timeStart);
  if (dateA - dateB === 0) {
    return a.order - b.order;
  }
  return dateA - dateB;
}

export interface State extends EntityState<AgendaSessionModel> {
  loading: boolean;
  loaded: boolean;
  error: Error;
  activeId: number;
  updating: boolean;
  reordering: boolean;
  updatedId: number;
  selectedId: number;
}

export const adapter = createEntityAdapter<AgendaSessionModel>({
  sortComparer: (a, b) => sortAgendaSessions(a, b),
});

export const initialState: State = {
  ...adapter.getInitialState(),
  loading: false,
  loaded: false,
  error: null,
  activeId: 0,
  updating: false,
  reordering: false,
  updatedId: 0,
  selectedId: null,
};

export const agendaSessionReducer = createReducer(
  initialState,
  on(AgendaSessionActions.loadAgendaSessions, state => ({
    ...initialState,
    loading: true,
  })),
  on(AgendaSessionActions.loadAgendaSessionsSuccess, (state, { sessions }) =>
    adapter.setAll(sessions, { ...state, loading: false, loaded: true })
  ),
  on(
    AgendaSessionActions.createAgendaSession,
    AgendaSessionActions.updateAgendaSession,
    state => ({ ...state, updating: true, error: null })
  ),
  on(
    AgendaSessionActions.rescheduleAgendaSession,
    AgendaSessionActions.rescheduleAgendaChildSession,
    (state, { session, timeStart, timeEnd }) =>
      adapter.updateOne(
        { id: session.id, changes: { timeStart, timeEnd } },
        { ...state, updating: true, error: null, loading: true }
      )
  ),
  on(
    AgendaSessionActions.updateSessionParentIdStatus,
    (state, { session, status }) =>
      adapter.updateOne(
        { id: session.id, changes: { agendaSessionParentId: status } },
        { ...state }
      )
  ),
  on(
    AgendaSessionActions.createAgendaSessionSuccess,
    (state, { session }) => adapter.removeMany([...session.agendaSessionChildren.map(s => s.id)], state)
  ),
  on(
    AgendaSessionActions.createAgendaSessionSuccess,
    AgendaSessionActions.updateAgendaSessionSuccess,
    AgendaSessionActions.rescheduleAgendaSessionSuccess,
    AgendaSessionActions.rescheduleAgendaChildSessionSuccess,
    (state, { session }) => {
      return adapter.upsertOne(session, {
        ...state,
        updating: false,
        updatedId: session.id,
        loading: false,
      });
    },
  ),
  on(
    AgendaSessionActions.updateAgendaSessionSuccess,
    (state, { session }) => {
      const hasParent = session.agendaSessionParentId !== null;
      let updatedSession: AgendaSessionModel;
      if (session.agendaSessionParentId !== null) {
        const parentSession = state.entities[session.agendaSessionParentId];
        if (!parentSession) updatedSession = session;
        else {
          updatedSession = { ...parentSession, agendaSessionChildren: [...parentSession.agendaSessionChildren].map(c => c.id === session.id ? session : c) };
        }
      } else {
        updatedSession = session;
      }
      return adapter.upsertOne(updatedSession, {
        ...state,
        updating: false,
        updatedId: updatedSession.id,
        loading: false,
      });
    },
  ),
  on(
    AgendaSessionActions.createAgendaSessionFailure,
    AgendaSessionActions.updateAgendaSessionFailure,
    AgendaSessionActions.rescheduleAgendaSessionFailure,
    AgendaSessionActions.rescheduleAgendaChildSessionFailure,
    (state, { error }) => ({ ...state, updating: false, error })
  ),
  on(AgendaSessionActions.deleteAgendaSession, state => ({
    ...state,
    error: null,
  })),
  on(AgendaSessionActions.deleteAgendaSessionSuccess, (state, { session }) =>
    adapter.removeOne(session.id, state)
  ),
  on(AgendaSessionActions.deleteAgendaSessionFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(
    AgendaSessionActions.changeAgendaSessionDiscussion,
    (state, { session, discussionOn }) => {
      const update: Update<AgendaSessionModel> = {
        id: session.id,
        changes: { discussionOn },
      };

      return adapter.updateOne(update, state);
    }
  ),
  on(
    AgendaSessionActions.changeAgendaSessionFeedback,
    (state, { session, enabled }) => {
      const update: Update<AgendaSessionModel> = {
        id: session.id,
        changes: { ratingsVisible: enabled },
      };

      return adapter.updateOne(update, state);
    }
  ),
  on(
    AgendaSessionActions.changeAgendaSessionDiscussionSuccess,
    AgendaSessionActions.changeAgendaSessionFeedbackSuccess,
    (state, { session }) => adapter.upsertOne(session, state)
  ),
  on(
    AgendaSessionActions.changeAgendaSessionDiscussionFailure,
    AgendaSessionActions.changeAgendaSessionFeedbackFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(
    AgendaSessionActions.changeAgendaSessionsDiscussionSuccess,
    (state, { enabled }) => {
      const updates = (state.ids as number[]).map<Update<AgendaSessionModel>>(
        id => ({
          id,
          changes: { discussionOn: enabled },
        })
      );

      return adapter.updateMany(updates, state);
    }
  ),
  on(
    AgendaSessionActions.changeAgendaSessionsFeedbackSuccess,
    (state, { enabled }) => {
      const updates = (state.ids as number[]).map<Update<AgendaSessionModel>>(
        id => ({
          id,
          changes: { ratingsVisible: enabled },
        })
      );

      return adapter.updateMany(updates, state);
    }
  ),
  on(AgendaSessionActions.selectAgendaSession, (state, { session }) => {
    const selectedId = session ? session.id : null;
    return { ...state, selectedId };
  }),
  on(AgendaSessionActions.changeAgendaSessionsDefault,
    (state) => ({ ...state, updating: true })),
  on(
    AgendaSessionActions.changeAgendaSessionsDefaultSuccess,
    (state) => ({ ...state, updating: false, error: null })
  ),
  on(
    AgendaSessionActions.changeAgendaSessionsDefaultFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(
    AgendaSessionActions.changeAgendaSessionsOrder,
    (state, { sessions }) => {
      // update order property localy
      const updatedSessions = sessions.map((s, i) => ({ ...s, order: i }));
      return adapter.setAll(updatedSessions, { ...state, reordering: true });
    },
  ),
  on(
    AgendaSessionActions.changeAgendaSessionsOrderSuccess,
    (state, { sessions }) => ({ ...state, reordering: false })
  ),
  on(
    AgendaSessionActions.changeAgendaSessionsOrderFailure,
    (state, { error }) => ({ ...state, error, reordering: false })
  ),
  
  on(
    AgendaSessionActions.changeAgendaChildSessionsOrder,
    (state, { parentSession, sessions }) => {
      // update order property localy
      const updatedSessions = sessions.map((s, i) => ({ ...s, order: i }))
        .sort((a, b) => sortAgendaSessions(a, b));

      const update: Update<AgendaSessionModel> = {
        id: parentSession.id,
        changes: { agendaSessionChildren: [...updatedSessions] },
      };

      return adapter.updateOne(update, { ...state, reordering: true });
    },
  ),
  on(
    AgendaSessionActions.changeAgendaChildSessionsOrderSuccess,
    (state) => ({ ...state, reordering: false })
  ),
  on(
    AgendaSessionActions.changeAgendaChildSessionsOrderFailure,
    (state, { error }) => ({ ...state, error, reordering: false })
  ),

  on(
    selectEvent,
    () => ({ ...initialState }),
  )
);

export function reducer(state: State, action: Action): State {
  return agendaSessionReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectAgendaSessionIds = selectIds;

export const selectAgendaSessionEntities = selectEntities;

export const selectAllAgendaSessions = selectAll;

export const selectAgendaSessionsFromComponent = (props: {componentId: number}) => createSelector(selectAllAgendaSessions, (agendaSessions: AgendaSessionModel[]) => {
  return agendaSessions.filter(agendaSession => agendaSession.eventComponentId === props.componentId)
})

export const selectAgendaSessionLoading = (state: State) => state.loading;

export const selectAgendaSessionLoaded = (state: State) => state.loaded;

export const selectAgendaSessionError = (state: State) => state.error;

export const selectActiveAgendaSessionId = (state: State) => state.activeId;

export const selectActiveAgendaSession = createSelector(
  selectAgendaSessionEntities,
  selectActiveAgendaSessionId,
  (sessions, activeId) => sessions[activeId]
);

export const selectAgendaSessionUpdating = (state: State) => state.updating;

export const selectAgendaSessionReordering = (state: State) => state.reordering;

export const selectUpdatedAgendaSessionId = (state: State) => state.updatedId;

export const selectUpdatedAgendaSession = createSelector(
  selectAgendaSessionEntities,
  selectUpdatedAgendaSessionId,
  (sessions, updatedId) => sessions[updatedId]
);

export const selectSelectedAgendaSessionId = (state: State) => state.selectedId;

export const selectSelectedAgendaSession = createSelector(
  selectAgendaSessionEntities,
  selectSelectedAgendaSessionId,
  (sessions, selectedId) => sessions[selectedId]
);

export const selectAgendaSessionsDiscussionEnabled = createSelector(
  selectAllAgendaSessions,
  sessions => sessions.some(session => session.discussionOn)
);

export const selectAgendaSessionsFeedbackEnabled = createSelector(
  selectAllAgendaSessions,
  sessions => sessions.some(session => session.ratingsVisible)
);

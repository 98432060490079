import { Component, OnInit, OnDestroy, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const predefinedColors = [
  '#fec603', '#4a90e2', '#e1c290', '#3b3eda',
  '#3fbae8', '#9586e4', '#3e3c38', '#d4d4d4',
];

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true
    }
  ]
})
export class ColorPickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() colors: string[];

  colorControl: FormControl;
  currentColor: string;

  private onChange: (color: string) => void;
  private onTouched: () => void;

  private teardown$: Subject<void>;

  constructor() {
    this.colors = predefinedColors;
    this.colorControl = new FormControl('');

    this.teardown$ = new Subject();
  }

  ngOnInit() {
    this.colorControl.valueChanges.pipe(takeUntil(this.teardown$)).subscribe(
      (color: string) => isHexColor(color) && this.populateColorChange(color)
    );
  }

  ngOnDestroy() {
    this.teardown$.next();
    this.teardown$.complete();
  }

  onColorSelect(ev: Event, color: string) {
    this.colorControl.setValue(color);
  }

  writeValue(color: string): void {
    this.colorControl.setValue(color, { emitEvent: false });
    this.currentColor = color;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    if (isDisabled) {
      this.colorControl.disable();
    } else {
      this.colorControl.enable();
    }
  }

  private populateColorChange(color) {
    this.currentColor = color;

    if (this.onChange) {
      this.onChange(color);
    }
  }
}

function isHexColor(hex: string) {
    return /^#[0-9a-fA-F]{6}$/i.test(hex);
}

import { Action, createReducer, createSelector, on } from '@ngrx/store';
import {
  EntityState,
  createEntityAdapter,
  Update,
  Dictionary,
} from '@ngrx/entity';

import { PartnerModel } from '@components/partner/models/partner.model';

import * as PartnerActions from '../actions/partner.actions';

export const stateKey = 'partner';

export interface ComponentState {
  loading: boolean;
  loaded: boolean;
  error: Error;
  updating: boolean;
  updatedId: number;
}

export const componentInitialState: ComponentState = {
  loading: false,
  loaded: false,
  error: null,
  updating: false,
  updatedId: 0,
};

export interface State extends EntityState<PartnerModel> {
  component: Dictionary<ComponentState>;
}

export const adapter = createEntityAdapter<PartnerModel>({
  sortComparer: (a, b) => a.order - b.order,
});

export const initialState: State = adapter.getInitialState({
  component: {},
});

export const partnerReducer = createReducer(
  initialState,
  on(PartnerActions.loadPartners, (state, { component }) => {
    const mutated = substate(state, component.id, {
      ...componentInitialState,
      loading: true,
    });
    return adapter.removeMany(
      partner => partner.componentId === component.id,
      mutated
    );
  }),
  on(PartnerActions.loadPartnersSuccess, (state, { component, partners }) => {
    const mutated = substate(state, component.id, {
      loading: false,
      loaded: true,
    });
    return adapter.upsertMany(partners, mutated);
  }),
  on(PartnerActions.loadPartnersFailure, (state, { component, error }) =>
    substate(state, component.id, { loading: false, error })
  ),
  on(
    PartnerActions.createPartner,
    PartnerActions.updatePartner,
    (state, { component }) => substate(state, component.id, { updating: true })
  ),
  on(
    PartnerActions.createPartnerSuccess,
    PartnerActions.updatePartnerSuccess,
    (state, { component, partner }) => {
      const mutated = substate(state, component.id, {
        updating: false,
        updatedId: partner.id,
      });
      return adapter.upsertOne(partner, mutated);
    }
  ),
  on(
    PartnerActions.createPartnerFailure,
    PartnerActions.updatePartnerFailure,
    (state, { component, error }) =>
      substate(state, component.id, { updating: false, error })
  ),
  on(PartnerActions.reorderPartners, (state, { partners }) => {
    const updates: Update<PartnerModel>[] = partners.map((partner, order) => ({
      id: partner.id,
      changes: { order },
    }));

    return adapter.updateMany(updates, state);
  }),
  on(PartnerActions.reorderPartnersSuccess, (state, { partners }) =>
    adapter.upsertMany(partners, state)
  ),
  on(PartnerActions.deletePartnersSuccess, (state, { partner }) =>
    adapter.removeOne(partner.id, state)
  ),
  on(
    PartnerActions.reorderPartnersFailure,
    PartnerActions.deletePartnersFailure,
    (state, { component, error }) => substate(state, component.id, { error })
  )
);

function substate(
  state: State,
  componentId: number,
  changes: Partial<ComponentState>
): State {
  const componentState = { ...state.component[componentId], ...changes };
  const component = { ...state.component, [componentId]: componentState };

  return { ...state, component };
}

export function reducer(state: State, action: Action): State {
  return partnerReducer(state, action);
}

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectPartnerEntities = selectEntities;

export const selectAllPartners = selectAll;

export const selectPartners = createSelector(
  selectAllPartners,
  (partners: PartnerModel[], { componentId, categoryId }) => {
    const filtered = partners.filter(
      partner => partner.componentId === componentId
    );
    return filtered;
  }
);

export const selectPartnerComponent = (state: State, { componentId }) =>
  state.component[componentId];

export const selectPartnerLoading = createSelector(
  selectPartnerComponent,
  component => component && component.loading
);

export const selectPartnerLoaded = createSelector(
  selectPartnerComponent,
  component => component && component.loaded
);

export const selectPartnerError = createSelector(
  selectPartnerComponent,
  component => component && component.error
);

export const selectUpdatedPartnerId = createSelector(
  selectPartnerComponent,
  component => component && component.updatedId
);

export const selectUpdatedPartner = createSelector(
  selectPartnerEntities,
  selectUpdatedPartnerId,
  (partners, updatedId) => partners[updatedId]
);

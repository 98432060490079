import { Component, OnInit, OnDestroy, Input, Output, NgZone, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/overlay';

import { Subject, Observable } from 'rxjs';
import { map, filter, takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { EventUserModel } from '@shared/models/event-user.model';
import { UserPickerSelector } from '@store/features/user';
import { UserPickerAction } from '@store/features/user/actions/user-picker.actions';

@Component({
  selector: 'app-user-select-dropdown',
  templateUrl: './user-select-dropdown.component.html',
  styleUrls: ['./user-select-dropdown.component.scss'],
  providers: [
    ScrollDispatcher,
  ],
})
export class UserSelectDropdownComponent implements OnInit, OnDestroy {
  @Input() event: EventModel;

  @Output() selectUser = new EventEmitter<EventUserModel>();

  searchControl = new FormControl('');

  eventUsers$: Observable<EventUserModel[]>;
  hasEventUsers$: Observable<boolean>;

  isLoading$: Observable<boolean>;

  private teardown$ = new Subject<void>();

  constructor(
    private ngZone: NgZone,
    private store$: Store<any>,
    private scrollDispatcher: ScrollDispatcher,
  ) {}

  ngOnInit() {
    this.eventUsers$ = this.store$.pipe(
      select(UserPickerSelector.selectPickerUsers),
    );

    this.hasEventUsers$ = this.eventUsers$.pipe(
      map(eventUsers => eventUsers.length > 0)
    );

    this.isLoading$ = this.store$.pipe(
      select(UserPickerSelector.selectPickerUsersLoading),
    );

    this.store$.dispatch(
      UserPickerAction.loadEventUsers({ event: this.event })
    );

    // Load next batch on scroll
    this.scrollDispatcher.scrolled(400).pipe(
      filter(scrollable => !!scrollable),
      takeUntil(this.teardown$),
    ).subscribe((scrollable: CdkScrollable) =>
      this.ngZone.run(() => this.handleScrollable(scrollable))
    );

    // Search event users by search term
    this.searchControl.valueChanges.pipe(
      map(searchTerm => searchTerm.length < 3 ? '' : String(searchTerm)),
      distinctUntilChanged(),
      debounceTime(400),
      takeUntil(this.teardown$),
    ).subscribe(searchTerm =>
      this.handleSearchTerm(searchTerm)
    );
  }

  ngOnDestroy() {
    this.teardown$.next();
  }

  private handleScrollable(scrollable: CdkScrollable) {
    const bottom = scrollable.measureScrollOffset('bottom');

    if (bottom <= 0) {
      this.store$.dispatch(
        UserPickerAction.loadNextEventUsers()
      );
    }
  }

  private handleSearchTerm(searchTerm: string) {
    if (searchTerm) {
      this.store$.dispatch(
        UserPickerAction.searchEventUsers({ searchTerm, event: this.event })
      )
    } else {
      this.store$.dispatch(
        UserPickerAction.loadEventUsers({ event: this.event })
      );
    }
  }
}

import { createAction, props } from '@ngrx/store';

import { PartnerModel } from '@components/partner/models/partner.model';
import { PartnerContactPersonModel } from '@components/partner/models/partner-contact-person.model';

export const fillPartnerContactPeople = createAction(
  '[Partner/API] Fill Partner Contact People',
  props<{ partners: PartnerModel[], contacts: PartnerContactPersonModel[] }>()
);

export const loadPartnerContactPeople = createAction(
  '[Partner/Partner Component] Load Partner Contact People',
  props<{ partner: PartnerModel }>()
);

export const loadPartnerContactPeopleSuccess = createAction(
  '[Partner/API] Load Partner Contact People Success',
  props<{ partner: PartnerModel, contacts: PartnerContactPersonModel[] }>()
);

export const loadPartnerContactPeopleFailure = createAction(
  '[Partner/API] Load Partner Contact People Failure',
  props<{ partner: PartnerModel, error: Error }>()
);

export const diffPartnerContactPeople = createAction(
  '[Partner/Partner Component] Diff Partner Contact People',
  props<{ partner: PartnerModel, contacts: PartnerContactPersonModel[] }>()
);

export const diffPartnerContactPeopleSuccess = createAction(
  '[Partner/API] Diff Partner Contact People Success',
  props<{ partner: PartnerModel, contacts: PartnerContactPersonModel[] }>()
);

export const diffPartnerContactPeopleFailure = createAction(
  '[Partner/API] Diff Partner Contact People Failure',
  props<{ partner: PartnerModel, error: Error }>()
);

import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges } from '@angular/core';

import { take, withLatestFrom } from 'rxjs/operators';

import { GoogleApiService } from '@shared/providers/google-api.service';
import { GoogleMapService } from '@shared/providers/google-map.service';

@Component({
  selector: 'app-google-map-marker',
  templateUrl: './google-map-marker.component.html',
  styleUrls: ['./google-map-marker.component.scss']
})
export class GoogleMapMarkerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() latitude: number;
  @Input() longitude: number;

  private markerInstance: any;

  constructor(private googleApiService: GoogleApiService, private googleMapService: GoogleMapService) {
    this.latitude = 0;
    this.longitude = 0;
  }

  ngOnInit() {
    this.googleApiService.getMapsApi().pipe(
      withLatestFrom(this.googleMapService.getMapInstance())
    ).subscribe(([mapsApi, currentMap]) => this.createMarker(mapsApi, currentMap));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.markerInstance) {
      return;
    }

    if ('latitude' in changes || 'longitude' in changes) {
      this.markerInstance.setPosition({ lat: this.latitude || 0, lng: this.longitude || 0 });
    }
  }

  ngOnDestroy() {
    if (this.markerInstance) {
      this.markerInstance.setMap(null);
    }
  }

  private createMarker({ Marker }, currentMap) {
    this.markerInstance = new Marker({
      position: { lat: this.latitude || 0, lng: this.longitude || 0 },
      map: currentMap
    });
  }
}

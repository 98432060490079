import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface SortByOption {
  role: string,
  name: string;
}

@Component({
  selector: 'app-sort-by',
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SortByComponent),
      multi: true
    }
  ]
})
export class SortByComponent implements OnInit, ControlValueAccessor {
  @Input() options: SortByOption[];
  @Input() alignTo: string;
  @Input() disabled: boolean;

  selectedOption: SortByOption;

  get alignNgClass(): any {
    return {
      'dropdown-menu-right': this.alignToRight,
      'dropdown-menu-center': this.alignToCenter,
      'dropdown-menu-left': this.alignToLeft
    };
  }

  get alignToRight(): boolean {
    return (this.alignTo === 'right' || !this.alignTo);
  }

  get alignToCenter(): boolean {
    return (this.alignTo === 'center');
  }

  get alignToLeft(): boolean {
    return (this.alignTo === 'left');
  }

  private _onChange: (value: SortByOption) => void;
  private _onTouch: () => void;

  constructor(private i18n: TranslateService) {
    this.options = [
      { role: 'a-z', name: `${this.i18n.instant('others.name')} A-Z` },
      { role: 'z-a', name: `${this.i18n.instant('others.name')} Z-A` },
      { role: 'custom', name: this.i18n.instant('others.custom') }
    ];
  }

  ngOnInit() {
    this.selectedOption = this.options[0];
  }

  selectOption(option: SortByOption) {
    this.selectedOption = option;

    if (this._onChange) {
      this._onChange(this.selectedOption);
    }
  }

  writeValue(value: SortByOption | string): void {
    if (typeof value === 'string') {
      this.selectedOption = this.options.find(
        option => (option.role === value)
      );
    } else {
      this.selectOption(value);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, DoCheck, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Directive({
  selector: '[appFormFeedback]',
})
export class FormFeedbackDirective implements DoCheck {
  @Input('appFormFeedback') fieldName: string;
  @Input('appFormFeedbackError') errorType: string;

  constructor(
    private parent: ControlContainer,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngDoCheck(): void {
    const control = this.parent.control?.get(this.fieldName);
    const feedbackVisible = control?.touched && !!control?.errors?.[this.errorType];
    this.toggleTemplateView(feedbackVisible);
  }

  private toggleTemplateView(visible: boolean): void {
    if (visible && this.viewContainerRef.length === 0) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (!visible && this.viewContainerRef.length > 0) {
      this.viewContainerRef.clear();
    }
  }
}

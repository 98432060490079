<app-spinner-overlay *ngIf="isLoading$ | async; else isLoaded"></app-spinner-overlay>

<ng-template #isLoaded>
  <section class="content-application-wrapper">
    <div class="row no-gutters">
      <div class="col-md-auto" style="min-width: 0;">
        <app-side-menu [components]="allComponents$ | async" [activeComponent]="activeComponent$ | async" [expanded]="menuExpanded$ | async" (appToggle)="onMenuToggle($event)"></app-side-menu>
      </div>
      <div class="col-md col-sm-12" style="min-width: 0;">
        <div class="content-application">
          <div class="content-application-inner">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>

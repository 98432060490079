import { createAction, props } from '@ngrx/store';
import { TokenModel } from '@app/core/models/token.model';

export const tokenRetrieve = createAction('[Core/Auth] Token Retrieve');
export const tokenRetrieveSuccess = createAction('[Core/Auth] Token Retrieve Success', props<{ token: TokenModel }>());
export const tokenRetrieveFailure = createAction('[Core/Auth] Token Retrieve Failure', props<{ error: Error }>());

export const tokenRefresh = createAction('[Core/Auth] Token Refresh', props<{ token: TokenModel }>());
export const tokenRefreshSuccess = createAction('[Core/Auth] Token Refresh Success', props<{ token: TokenModel }>());
export const tokenRefreshFailure = createAction('[Core/Auth] Token Refresh Failure', props<{ error: Error }>());

export const tokenSetup = createAction('[Core/Auth] Token Setup', props<{ token: TokenModel }>());
export const tokenClear = createAction('[Core/Auth] Token Clear');

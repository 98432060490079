import { Injectable } from '@angular/core';

import { Observable, combineLatest } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { EventModel } from './models/event.model';
import { EventLanguageModel } from './models/event-language.model';
import { EventTranslationModel } from './models/event-translation.model';

import * as EventActions from './actions/event.actions';
import * as fromEvent from './reducers';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventFacadeService {
  constructor(private store$: Store<any>) {}

  getActiveEvent(): Observable<EventModel> {
    return this.store$.pipe(select(fromEvent.selectActiveEvent));
  }

  getEventLoading(): Observable<boolean> {
    return this.store$.pipe(select(fromEvent.selectEventLoading));
  }

  getEventLoaded(): Observable<boolean> {
    return this.store$.pipe(select(fromEvent.selectEventLoaded));
  }

  getEventSaving(): Observable<boolean> {
    return this.store$.pipe(select(fromEvent.selectEventSaving));
  }

  getEventPublishing(): Observable<boolean> {
    return this.store$.pipe(select(fromEvent.selectEventPublishing));
  }

  getEventUnpublishing(): Observable<boolean> {
    return this.store$.pipe(select(fromEvent.selectEventUnpublishing));
  }

  getEventError(): Observable<Error> {
    return this.store$.pipe(select(fromEvent.selectEventError));
  }

  createEvent(event: EventModel, languages: EventLanguageModel[], translations: EventTranslationModel[], appId: number): void {
    this.store$.dispatch(EventActions.createEvent({ event, languages, translations, appId }));
  }

  updateEvent(event: EventModel, languages: EventLanguageModel[], translations: EventTranslationModel[]): void {
    this.store$.dispatch(EventActions.updateEvent({ event, languages, translations }));
  }

  updateEventHighlighted(event: EventModel, highlighted: boolean): void {
    this.store$.dispatch(EventActions.updateEventHighlighted({ event, highlighted }));
  }

  updateMainEventProperties(event: EventModel): void {
    this.store$.dispatch(EventActions.updateMainEventProperties({ event }));
  }

  updateOnlyBaseLanguageLayer(event: EventModel): void {
    this.store$.dispatch(EventActions.updateOnlyBaseLanguageLayer({ event }));
  }

  publishEvent(event: EventModel): void {
    this.store$.dispatch(EventActions.publishEvent({ event }));
  }

  unpublishEvent(event: EventModel): void {
    this.store$.dispatch(EventActions.unpublishEvent({ event }));
  }

  selectEvent(eventId: number): void {
    this.store$.dispatch(EventActions.selectEvent({ eventId }));
  }

  payEvent(event: EventModel): void {
    this.store$.dispatch(EventActions.payEvent({ event }));
  }

  clearEventState(): void {
    this.store$.dispatch(EventActions.clearEventState());
  }
}

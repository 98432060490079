<div *ngIf="!addedMode" class="component-item">
    <img src="{{componentModel.icon}}" alt="">
    <p>
        {{componentModel.label}}
    </p>
    <img (click)="addComponent()" class="add" src="./assets/img/icons/add-circle.svg" alt="">
</div>


<div *ngIf="addedMode" class="componet-list-item" [class.active]="active">
    <div class="componet-list-item-left" (click)="handleClick()" [class.clickable]="navigateOnClick">
      <img src="./assets/img/icons/drag.svg" alt="" style="cursor: move" *ngIf="!initComponent">
      <img class="component-icon" [ngClass]="{'init-icon': initComponent == true}" [src]="getComponentIcon(componentModel) | async" alt="">
      <p [title]="componentModel.label">
          {{componentModel.label}}
      </p>
    </div>
    <div class="component-controls" *ngIf="!initComponent">
        <img *ngIf="!componentModel.visibleInApp" src="./assets/img/icons/hide.svg" class="hide-component-img" alt="">
        <app-action-button icon="/assets/icons/three-dots.svg" [disabled]="updating$ | async">
            <app-action-menu alignTo="right">
        
              <app-action-menu-item icon="/assets/img/icons/edit.svg" (click)="editComponent(componentModel)">Edit Component</app-action-menu-item>

              <app-action-menu-item icon="/assets/img/icons/trash.svg" *ngIf="isAllowedToDeleteComponent$ | async" (click)="deleteComponent(componentModel)">Delete Component</app-action-menu-item>

              <app-action-menu-item icon="/assets/img/icons/tag-blue.svg" (click)="onChooseGroupClick(componentModel)">Choose User Group</app-action-menu-item>

              <ng-container *ngIf="!isAlwaysInvisible(componentModel)">
                <app-action-menu-item *ngIf="componentModel.visibleInApp" icon="/assets/img/icons/hide.svg" (click)="toggleShowComponent(componentModel)">Hide component</app-action-menu-item>

                <app-action-menu-item *ngIf="!componentModel.visibleInApp" icon="/assets/img/icons/eye.svg" (click)="toggleShowComponent(componentModel)">Show component</app-action-menu-item>
              </ng-container>
        
            </app-action-menu>
          </app-action-button>
    </div>
</div>

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-add-inline',
  templateUrl: './button-add-inline.component.html',
  styleUrls: ['./button-add-inline.component.scss']
})
export class ButtonAddInlineComponent {
  @Input() alt: string;
  @Input() disabled: boolean;

  @Output() appAdd: EventEmitter<Event>;
  @Output() add: EventEmitter<Event>;

  constructor() {
    this.appAdd = new EventEmitter<Event>();
    this.add = this.appAdd;
  }
}

import { Component, Input, forwardRef, HostListener, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { EventModel } from '@store/features/event/models/event.model';
import { EventUserModel } from '@shared/models/event-user.model';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UserSelectComponent), multi: true },
  ],
})
export class UserSelectComponent implements ControlValueAccessor {
  @Input() event: EventModel;
  @Input() static: boolean = false;

  @Output() onSelect: EventEmitter<EventUserModel>;

  eventUser: EventUserModel;
  showDropdown: boolean;

  private onChange: (user: EventUserModel) => void;
  private onTouched: () => void;

  // Helper to determine if the click was inside the element
  // Allows hidding dropdown only when clicked outside the component
  private clickedInside: boolean;

  constructor() {
    this.onSelect = new EventEmitter<EventUserModel>();
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  selectUser(user: EventUserModel) {
    this.onSelect.emit(user);
    if (this.onChange && user !== this.eventUser) {
      this.eventUser = user;
      this.onChange(user);
    }

    this.showDropdown = false;
  }

  writeValue(user: EventUserModel): void {
    this.eventUser = user;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener('click', ['$event'])
  onElementClick(ev: Event) {
    this.clickedInside = true;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(ev: Event) {
    if (!this.clickedInside) {
      this.showDropdown = false;
    }

    this.clickedInside = false;
  }
}

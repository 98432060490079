import { Subscription } from 'rxjs';
import { ToastMessage, ToastMessageService } from './toast-message.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss']
})
export class ToastMessageComponent implements OnInit {

  messages: ToastMessage[] = [];
  messagesSubscription?: Subscription;

  constructor(
    private toastMessageService: ToastMessageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.messagesSubscription = this.toastMessageService.getMessage().subscribe(
      message => this.handleMessage(message)
    );
  }

  ngOnDestroy(): void {
    this.messagesSubscription?.unsubscribe();
  }

  handleMessage(message: ToastMessage): void {
    this.messages.push(message);
    setTimeout(() => {
      this.handleMessageDelete(message);
    }, message.expirationTime * 1000);
  }

  handleMessageDelete(message: ToastMessage): void {
    const messageDomEl: NodeListOf<HTMLElement> = document.querySelectorAll('.toast-message__container');
    messageDomEl[this.messages.indexOf(message)]?.classList.remove('slide-in-right');
    messageDomEl[this.messages.indexOf(message)]?.classList.add('slide-out-right');
    setTimeout(() => {
      this.messages.splice(this.messages.indexOf(message), 1);
    }, 300);
  }

  navigate(message: ToastMessage): void {
    if(!message.navigate) return;
    const tree = message.navigate.split('/');
    this.router.navigate([...tree], { state: { ...message.navigationData } });
  }

  stackScrollTo(): void {
    const messagesWall = document.querySelector<HTMLElement>('.toast-message__wall');
    if (!messagesWall) {
      return;
    }
    messagesWall.scroll({
      top: messagesWall.scrollHeight,
      behavior: 'smooth'
    });
  }

}

<div class="input-group position-relative mb-1">
  <div class="input-group-prepend">
    <select (change)="onValueChanged($event)" [formControl]="codeInputControl"
      class="input-group-text btn-outline-secondary phone-number-prefix arrow-input" [class.glow]="glow">
      <option selected></option>
      <option [value]="mask.dialCode" *ngFor="let mask of phoneMasks">{{ mask.dialCode }}</option>
    </select>
  </div>

  <input (input)="onValueChanged($event)" [formControl]="phoneInputControl" (focus)="onInputFocus($event)" type="tel"
    maxlength="15" matInput #phoneInput class="form-control" [class.glow]="glow" id="input4" placeholder="{{ 'others.phone_number' | translate }}">
</div>

import { BookingSessionPost } from '@components/booking/store/models/booking-session.model';
import { BookingSession } from '@components/booking/store/models/booking-session.model';
import { createAction } from "@ngrx/store";

const loadBookingSessions = createAction('[Booking Session] Load Bookings');
const loadBookingSessionsSuccess = createAction('[Booking Session] Load Bookings Success', (bookings: BookingSession[]) => ({ bookings }));
const loadBookingSessionsFailure = createAction('[Booking Session] Load Bookings Failure', (error: any) => ({ error }));

const addBookingSession = createAction('[Booking Session] Add Booking', (booking: BookingSessionPost) => ({ booking }));
const addBookingSessionSuccess = createAction('[Booking Session] Add Bookings Success', (booking: BookingSession) => ({ booking }));
const addBookingSessionFailure = createAction('[Booking Session] Add Bookings Failure', (error: any) => ({ error }));

const updateBookingSession = createAction('[Booking Session] Update Booking', (booking: BookingSessionPost) => ({ booking }));
const updateBookingSessionSuccess = createAction('[Booking Session] Update Booking Success', (booking: BookingSession) => ({ booking }));
const updateBookingSessionFailure = createAction('[Booking Session] Update Booking Failure', (error: any) => ({ error }));

const deleteBookingSession = createAction('[Booking Session] Delete Booking', (booking: BookingSession) => ({ booking }));
const deleteBookingSessionSuccess = createAction('[Booking Session] Delete Booking Success', (booking: BookingSession) => ({ booking }));
const deleteBookingSessionFailure = createAction('[Booking Session] Delete Booking Failure', (error: any) => ({ error }));

const setBookingSessionActiveId = createAction('[Booking Session] Set Booking Session Active Id', (id: number) => ({ id }));

export const bookingAction = {
  loadBookingSessions,
  loadBookingSessionsSuccess,
  loadBookingSessionsFailure,
  addBookingSession,
  addBookingSessionSuccess,
  addBookingSessionFailure,
  updateBookingSession,
  updateBookingSessionSuccess,
  updateBookingSessionFailure,
  deleteBookingSession,
  deleteBookingSessionSuccess,
  deleteBookingSessionFailure,
  setBookingSessionActiveId
}
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FullApiService } from '@shared/providers/full-api.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { AgendaSessionModel } from '@components/agenda/models/agenda-session.model';
import { AgendaQuestionModel, AgendaQuestionPostModel } from '@components/agenda/models/agenda-question.model';

@Injectable({
  providedIn: 'root'
})
export class AgendaQuestionProviderService {
  constructor(private apiService: FullApiService) {}

  loadAgendaQuestions(event: EventModel, component: ComponentModel, session: AgendaSessionModel): Observable<AgendaQuestionModel[]> {
    return this.apiService.getAgendaQuestions2(event.id, component.id, session.id);
  }

  createAgendaQuestion(event: EventModel, component: ComponentModel, session: AgendaSessionModel,
    question: AgendaQuestionModel, anonymous?: boolean): Observable<AgendaQuestionModel> {

    const questionData = { ...mapAgendaQuestionPost(question), anonymous };
    return this.apiService.createAgendaQuestion(event.id, component.id, session.id, questionData);
  }

  deleteAgendaQuestion(event: EventModel, component: ComponentModel, session: AgendaSessionModel,
    question: AgendaQuestionModel): Observable<boolean> {

    return this.apiService.deleteAgendaQuestion(event.id, component.id, session.id, question.id).pipe(
      map(({ deleted }) => deleted)
    );
  }
}

function mapAgendaQuestionPost(question: AgendaQuestionModel): AgendaQuestionPostModel {
  return {
    question: question.question
  };
}

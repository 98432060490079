<div class="sidebar-menu" [ngClass]="{'open': expanded}">
  <ng-scrollbar visibility="hover">
    <ul>

      <ng-container *ngIf="predefinedComponents.length > 0">
        <li *ngFor="let component of predefinedComponents; trackBy: trackByComponentId" [ngClass]="{'active': isComponentActive(component) }">
          <a href="#" [routerLink]="['component', component.id]" [title]="component.label">
            <ng-container *ngIf="getComponentIcon(component) | async as iconUrl">
              <ng-container *ngIf="iconUrl.slice(-3) === 'svg'; else noSvgIcon">
                <app-icon [icon]="iconUrl"></app-icon>
              </ng-container>
              <ng-template #noSvgIcon>
                <img [src]="iconUrl" alt="">
              </ng-template>
            </ng-container>
            <p>{{ component.label }}</p>
          </a>
        </li>
      </ng-container>

      <div class="divider" *ngIf="predefinedComponents.length > 0"></div>
      
      <ng-container *ngIf="!(limitedAccess$ | async)">
        <li *ngFor="let component of eventComponents; trackBy: trackByComponentId" [ngClass]="{'active': isComponentActive(component) }">
          <a href="#" [routerLink]="['component', component.id]" [title]="component.label">
            <ng-container *ngIf="getComponentIcon(component) | async as iconUrl">
              <ng-container *ngIf="iconUrl.slice(-3) === 'svg'; else noSvgIcon">
                <app-icon [icon]="iconUrl"></app-icon>
              </ng-container>
              <ng-template #noSvgIcon>
                <img [src]="iconUrl" alt="">
              </ng-template>
            </ng-container>
            <p>{{ component.label }}</p>
          </a>
        </li>
      </ng-container>
    </ul>
  </ng-scrollbar>
</div>

<div class="sidebar-menu-toogle" [ngClass]="{'open': expanded}" (click)="onToggle($event)"></div>

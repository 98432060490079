import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class GoogleMapService {
  private currentMapInstance$: BehaviorSubject<any>;

  constructor() {
    this.currentMapInstance$ = new BehaviorSubject<any>(void 0);
  }

  setMapInstance(mapInstance: any) {
    this.currentMapInstance$.next(mapInstance);
  }

  getMapInstance(): Observable<any> {
    return this.currentMapInstance$;
  }
}

import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter, Dictionary } from '@ngrx/entity';

import { ExhibitorContactPersonModel } from '@components/exhibitor/models/exhibitor-contact-person.model';

import * as ExhibitorContactPersonActions from '../actions/exhibitor-contact-person.actions';

export const stateKey = 'exhibitorContactPerson';

export interface ExhibitorState {
  loading: boolean;
  loaded: boolean;
  error: Error;
}

export const exhibitorInitialState: ExhibitorState = {
  loading: false,
  loaded: false,
  error: null,
};

export interface State extends EntityState<ExhibitorContactPersonModel> {
  exhibitor: Dictionary<ExhibitorState>;
}

export const adapter = createEntityAdapter<ExhibitorContactPersonModel>({
  sortComparer: (a, b) => a.order - b.order,
});

export const initialState: State = adapter.getInitialState({
  exhibitor: {},
});

export const exhibitorContactPersonReducer = createReducer(
  initialState,
  on(
    ExhibitorContactPersonActions.fillExhibitorContactPeople,
    (state, { exhibitors, contacts }) => {
      const removed = adapter.removeMany(contact =>
        exhibitors.some(exhibitor => exhibitor.id === contact.exhibitorId), state
      );

      const mutated = exhibitors.reduce(
        (acc, exhibitor) => substate(acc, exhibitor.id, { ...exhibitorInitialState, loaded: true }), removed
      );

      return adapter.addMany(contacts, mutated);
    }
  ),
  on(
    ExhibitorContactPersonActions.loadExhibitorContactPeople,
    (state, { exhibitor }) => {
      const mutated = substate(state, exhibitor.id, { ...exhibitorInitialState, loading: true });
      return adapter.removeMany(contact => contact.exhibitorId === exhibitor.id, mutated);
    }
  ),
  on(
    ExhibitorContactPersonActions.loadExhibitorContactPeopleSuccess,
    (state, { exhibitor, contacts }) => {
      const mutated = substate(state, exhibitor.id, { ...exhibitorInitialState, loaded: true });
      return adapter.addMany(contacts, mutated);
    }
  ),
  on(
    ExhibitorContactPersonActions.diffExhibitorContactPeopleSuccess,
    (state, { exhibitor, contacts }) => {
      const mutated = adapter.removeMany(contact => contact.exhibitorId === exhibitor.id, state);
      return adapter.addMany(contacts, mutated);
    }
  ),
  on(
    ExhibitorContactPersonActions.loadExhibitorContactPeopleFailure,
    ExhibitorContactPersonActions.diffExhibitorContactPeopleFailure,
    (state, { exhibitor, error }) => substate(state, exhibitor.id, { ...exhibitorInitialState, error })
  ),
);

function substate(state: State, exhibitorId: number, changes: Partial<ExhibitorState>): State {
  const exhibitorState = { ...state.exhibitor[exhibitorId], ...changes };
  const exhibitor = { ...state.exhibitor, [exhibitorId]: exhibitorState };

  return { ...state, exhibitor };
}

export function reducer(state: State, action: Action): State {
  return exhibitorContactPersonReducer(state, action);
}

const {
  selectEntities,
  selectAll,
} = adapter.getSelectors();

export const selectExhibitorContactPersonEntities = selectEntities;

export const selectAllExhibitorContactPeople = selectAll;

export const selectExhibitorContactPeople = () => createSelector(
  selectAllExhibitorContactPeople,
  (exhibitors: ExhibitorContactPersonModel[], { exhibitorId }) =>
    exhibitors.filter(exhibitor => exhibitor.exhibitorId === exhibitorId)
);

export const selectExhibitorContactPersonExhibitor = (state: State, { exhibitorId }) => state.exhibitor[exhibitorId];

export const selectExhibitorContactPersonLoading = createSelector(
  selectExhibitorContactPersonExhibitor,
  component => component && component.loading
);

export const selectExhibitorContactPersonLoaded = createSelector(
  selectExhibitorContactPersonExhibitor,
  component => component && component.loaded
);

export const selectExhibitorContactPersonError = createSelector(
  selectExhibitorContactPersonExhibitor,
  component => component && component.error
);

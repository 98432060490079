import { createAction, props } from '@ngrx/store';

import { ImportResultModel } from '@shared/models/import-result.model';

export const importExhibitors = createAction(
  '[Exhibitor/Import Modal] Import Exhibitors',
  props<{ file: File, language: string }>()
);

export const importExhibitorsSuccess = createAction(
  '[Exhibitor/API] Import Exhibitors Success',
  props<{ results: ImportResultModel[] }>()
);

export const importExhibitorsFailure = createAction(
  '[Exhibitor/API] Import Exhibitors Failure',
  props<{ error: Error }>()
);

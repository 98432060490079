import { Component, Input, Output, HostBinding, HostListener, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-like-counter',
  templateUrl: './like-counter.component.html',
  styleUrls: ['./like-counter.component.scss']
})
export class LikeCounterComponent {
  @Input() likes: number;
  @Input() selfLiked: boolean;

  @Output() appLike: EventEmitter<Event>;
  @Output() appUnlike: EventEmitter<Event>;

  @HostBinding('class.self-liked') get _selfLiked(): boolean {
    return !!this.selfLiked;
  }

  constructor() {
    this.appLike = new EventEmitter<Event>();
    this.appUnlike = new EventEmitter<Event>();
  }

  @HostListener('click', ['$event'])
  onHostClick(ev: Event) {
    if (this.selfLiked) {
      this.appUnlike.emit(ev);
    } else {
      this.appLike.emit(ev);
    }
  }
}

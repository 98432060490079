import { Directive, ElementRef, Input, OnDestroy } from "@angular/core";
import { UserRoleEnum } from "@shared/enums/user-role.enum";
import { EventFacadeService } from "@store/features/event/event-facade.service";
import { EventModel } from "@store/features/event/models/event.model";
import { Observable, Subscription } from "rxjs";
import { first } from "rxjs/operators";

@Directive({
  selector: '[disabledForRole]'
})
export class DisabledForRoleDirective implements OnDestroy {

  activeEvent$: Observable<EventModel>;

  private subs = new Subscription();

  constructor(
    private domEl: ElementRef,
    private eventFacade: EventFacadeService,
  ) {
    this.activeEvent$ = this.eventFacade.getActiveEvent();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  @Input() set disabledForRole(roles: UserRoleEnum[]) {
    this.activeEvent$
      .pipe(first(event => !!event))
      .subscribe(event => {
        const hide = roles.some(role => role === event.userRole);
        this.modifyTemplate(hide);
      });
  }

  private modifyTemplate(hide: boolean): void {
    if (hide) {
      this.domEl.nativeElement.classList.add('disabled');
    } else {
      this.domEl.nativeElement.classList.remove('disabled');
    }
  }
}
import { Action, createReducer, on } from '@ngrx/store';
import { bookingFilterActions } from '@components/booking/store/actions/booking-filter.action';
import { BookingFilter } from '@components/booking/store/models/booking-session.model';

export const featureKey = 'bookingFilters';

export interface State {
  loading: boolean;
  error: Error;
  saving: boolean;
  bookingFilters: BookingFilter[];
}

export const initialState = {
  loading: false,
  error: null,
  saving: false,
  bookingFilters: [],
};

export const bookingFiltersReducer = createReducer(
  initialState,
  on(bookingFilterActions.loadBookingFilters,
    state => ({ ...initialState, loading: true })),

  on(bookingFilterActions.loadBookingFiltersSuccess,
    (state, { bookingFilters }) => ({ ...state, loading: false, bookingFilters })),

  on(bookingFilterActions.loadBookingFiltersFailure,
    (state, { error }) => ({ ...state, loading: false, error })),

  on(bookingFilterActions.addBookingFilter, state =>
    ({ ...state, saving: true })),

  on(bookingFilterActions.addBookingFilterSuccess,
    (state, { bookingFilter }) => ({ ...state, saving: false, bookingFilters: [...state.bookingFilters, bookingFilter] })),

  on(bookingFilterActions.addBookingFilterFailure,
    (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingFilterActions.updateBookingFilter, state =>
    ({ ...state, saving: true })),

  on(bookingFilterActions.updateBookingFilterSuccess,
    (state, { bookingFilter }) => ({ ...state, saving: false, bookingFilters: state.bookingFilters.map(bf => bf.id === bookingFilter.id ? bookingFilter : bf) })),

  on(bookingFilterActions.updateBookingFilterFailure,
    (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingFilterActions.deleteBookingFilter, state =>
    ({ ...state, saving: true })),

  on(bookingFilterActions.deleteBookingFilterSuccess,
    (state, { bookingFilter }) => ({ ...state, saving: false, bookingFilters: [...state.bookingFilters.filter(s => s.id !== bookingFilter.id)] })),
    
  on(bookingFilterActions.deleteBookingFilterFailure,
    (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingFilterActions.setBookingFilterError,
    (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State, action: Action): State {
  return bookingFiltersReducer(state, action);
}

export const selectAllBookingFilters = (state: State) => state.bookingFilters;
export const selectBookingFilterById = (state: State, id: number) => state.bookingFilters.find(bookingFilter => bookingFilter.id === id);
export const selectBookingFilterSaving = (state: State) => state.saving;
export const selectBookingFilterError = (state: State) => state.error;
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit, OnChanges {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  @Input() reached: number;
  @Input() max: number | null;

  public options: ChartConfiguration['options'] = {
    responsive: true,
    aspectRatio: 1,
    layout: {
      padding: 0
    },
    elements: {
      arc: {
        borderWidth: 0,
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      }
    },
  }

  public data: ChartData<'doughnut', number[], string | string[]> = {
    labels: ['null', 'null'],
    datasets: [
      {
        data: [0, 1],
        backgroundColor: ['#00aeef', '#00afef67'],
        weight: 0.2
      }
    ]
  }

  public type: ChartType = 'doughnut';
  public plugins = [];

  constructor() { }

  ngOnInit(): void {
    Chart.overrides.doughnut.cutout = '80%';
    this.populateData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reached || changes.max) {
      this.populateData();
    }
  }

  private populateData(): void {
    const maxVal = this.max ? this.max : (this.reached === 0 ? 1 : this.reached);
  
    const reachedValue = this.reached >= maxVal ? maxVal : this.reached;
    const remainingValue = maxVal - reachedValue;
    this.data = {
      ...this.data,
      datasets: [
        {
          data: [reachedValue, remainingValue],
          backgroundColor: ['#00aeef', '#00afef67'],
          weight: 0.2
        }
      ]
    }
  }

}

import { extractErrorFrom } from '@utils/errors.util';
import { ApplicationTagsModalEditFor } from './../application-tags-modal.service';
import { MatDialogRef } from '@angular/material/dialog';
import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { Subscription, Observable } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

import { map, first } from 'rxjs/operators';

import { EventFacadeService } from '@store/features/event/event-facade.service';
import { AccessGroupProviderService } from '@store/features/tags/access-group-provider.service';
import { TagApiModel, TagType } from '@store/features/tags/tags.models';
import { ValidationMessageType } from '@shared/components/validation-message/validation-message.component';
import { USER_ROLE_PERMISSIONS, isUserRoleAllowed } from '@shared/constants/user-role-permissions';

enum ItemStatus {
  UNKNOWN, // editing component groups (item is not defined)
  NEW, // item is under create (id will be null or -1)
  CREATED, // item is exist (auto save)
}

@Component({
  selector: 'app-application-tags-edit-modal',
  templateUrl: './application-tags-edit-modal.component.html',
  styleUrls: ['./application-tags-edit-modal.component.scss']
})
export class ApplicationTagsEditModalComponent implements OnInit, OnDestroy {
  public ValidationMessageType = ValidationMessageType;
  public ApplicationTagsModalEditFor = ApplicationTagsModalEditFor;
  
  userGroupTags: TagApiModel[];
  selectedUserGroupTags: TagApiModel[];

  itemStatus: ItemStatus = ItemStatus.UNKNOWN;

  @Input() component: ComponentModel;
  @Input() childItem: any;
  @Input() editFor: ApplicationTagsModalEditFor;

  userGroupControl: FormControl;
  event: EventModel;

  isSaving: boolean;

  loading$: Observable<boolean>;

  errorMessage$: Observable<string>;

  disabledForUserRole$: Observable<boolean>;

  get selectedSystemTags(): TagApiModel[] {
    return this.userGroupControl?.value.filter((t: TagApiModel) => t.type_tag === TagType.System);
  }

  private subs = new Subscription();
  
  constructor(
    private eventFacade: EventFacadeService,
    private accessGroupService: AccessGroupProviderService,
    private dialog: MatDialogRef<ApplicationTagsEditModalComponent>
  ) {
    this.disabledForUserRole$ = this.eventFacade.getActiveEvent()
      .pipe(
        map(event => isUserRoleAllowed(event, USER_ROLE_PERMISSIONS.ADD_COMPONENT_ACCESS_GROUP)),
        map(allowed => (!allowed && this.editFor === ApplicationTagsModalEditFor.Component) ? true : false)
      );

    this.userGroupControl = new FormControl([]);
    this.subs.add(this.eventFacade.getActiveEvent().pipe(first())
      .subscribe(event => {
        this.event = event;
      }));
  }

  ngOnInit() {
    if (this.childItem) {
      if (this.childItem.id === null || this.childItem.id === -1) {
        this.itemStatus = ItemStatus.NEW;
      } else {
        this.itemStatus = ItemStatus.CREATED;
      }
    }

    this.errorMessage$ = this.accessGroupService.getAccessGroupsError()
      .pipe(map(error => error && (extractErrorFrom(error))));

    this.subs.add(this.accessGroupService.cancelSave$
      .subscribe(status => status && (this.isSaving = false)));

    if (this.editFor === ApplicationTagsModalEditFor.Component) {
      this.subs.add(this.accessGroupService.getComponentAccessGroupsById(this.component.id)
        .pipe(first(groups => !!groups))
        .subscribe(groups => this.userGroupControl.setValue([...groups])));
    } else {
      this.subs.add(this.accessGroupService.getItemAccessGroupsByItemId(this.childItem.id)
        .pipe(first(groups => !!groups))
        .subscribe(groups => this.userGroupControl.setValue([...groups])));
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.accessGroupService.setError();
  }

  onTagSelectSave(ev: Event) {
    this.isSaving = true;
    const selectedTags = this.userGroupControl.value;

    if (this.selectedSystemTags.length > 1) {
      return;
    }

    if (this.editFor === ApplicationTagsModalEditFor.Component) {
      this.subs.add(this.accessGroupService.editComponentAccessGroups(this.event, this.component, selectedTags)
        .pipe(first())
        .subscribe((groups) => {
          this.dialog.close(groups);
          this.isSaving = false;
        }));
    } else {
      if (this.itemStatus === ItemStatus.CREATED) {
        this.subs.add(this.subs.add(this.accessGroupService.editAccessGroupItem(this.event, this.component, this.childItem, selectedTags)
          .pipe(first())
          .subscribe((groups) => {
            this.dialog.close(groups);
            this.isSaving = false;
          })));
      } else {
        this.dialog.close(selectedTags);
      }
    }
  }
}
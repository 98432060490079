import { BookingRoom, BookingPlace } from '@components/booking/store/models/booking-session.model';
import { createAction } from "@ngrx/store";

const addBookingRoom = createAction('[Booking Room] Add Booking Room', (place: BookingPlace, bookingRoom: BookingRoom) => ({ place, bookingRoom }));
const addBookingRoomSuccess = createAction('[Booking Room] Add Booking Room Success', (bookingRoom: BookingRoom) => ({ bookingRoom }));
const addBookingRoomFailure = createAction('[Booking Room] Add Booking Room Failure', (error: any) => ({ error }));

const updateBookingBuilding = createAction('[Booking Room] Set Booking Room Success', (place: BookingPlace) => ({ place }));

const updateBookingRoom = createAction('[Booking Room] Update Booking Room', (place: BookingPlace, bookingRoom: BookingRoom) => ({ place, bookingRoom }));
const updateBookingRoomSuccess = createAction('[Booking Room] Update Booking Room Success', (bookingRoom: BookingRoom) => ({ bookingRoom }));
const updateBookingRoomFailure = createAction('[Booking Room] Update Booking Room Failure', (error: any) => ({ error }));

const deleteBookingRoom = createAction('[Booking Room] Delete Booking Room', (place: BookingPlace, bookingRoom: BookingRoom) => ({ place, bookingRoom }));
const deleteBookingRoomSuccess = createAction('[Booking Room] Delete Booking Room Success', (bookingRoom: BookingRoom) => ({ bookingRoom }));
const deleteBookingRoomFailure = createAction('[Booking Room] Delete Booking Room Failure', (error: any) => ({ error }));

export const bookingRoomActions = {
  addBookingRoom,
  addBookingRoomSuccess,
  addBookingRoomFailure,
  updateBookingRoom,
  updateBookingRoomSuccess,
  updateBookingRoomFailure,
  deleteBookingRoom,
  deleteBookingRoomSuccess,
  deleteBookingRoomFailure,
  updateBookingBuilding
};
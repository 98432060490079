import { Directive, OnInit, OnDestroy, Input } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CheckboxGroupService } from '@shared/providers/checkbox-group.service';

@Directive({
  selector: '[appCheckboxGroup]'
})
export class CheckboxGroupDirective implements OnInit, OnDestroy {
  @Input() appCheckboxGroup: any;

  private teardown$: Subject<void>;

  constructor(private checkboxGroup: CheckboxGroupService, private checkboxRef: MatCheckbox) {
    this.teardown$ = new Subject<void>();
  }

  get data(): any {
    return {
      group: this.appCheckboxGroup,
      children: this.checkboxGroup.getCheckedData()
    };
  }

  get checked(): boolean {
    return this.checkboxRef.checked;
  }

  get indeterminate(): boolean {
    return this.checkboxRef.indeterminate;
  }

  ngOnInit() {
    this.checkboxGroup.addGroup(this);

    this.checkboxRef.change.pipe(takeUntil(this.teardown$)).subscribe(
      change => change.checked ? this.check() : this.uncheck()
    );

    this.checkboxGroup.getUpdate().pipe(takeUntil(this.teardown$)).subscribe(
      () => this.onGroupUpdate()
    );
  }

  ngOnDestroy() {
    this.checkboxGroup.removeGroup(this);

    this.teardown$.next();
    this.teardown$.complete();
  }

  onGroupUpdate() {
    if (this.checkboxGroup.isAllChecked()) {
      this.checkboxRef.checked = true;
      this.checkboxRef.indeterminate = false;
    } else if (this.checkboxGroup.isAnyChecked()) {
      this.checkboxRef.checked = true;
      this.checkboxRef.indeterminate = true;
    } else {
      this.checkboxRef.checked = false;
      this.checkboxRef.indeterminate = false;
    }
  }

  check() {
    this.checkboxRef.checked = true;
    this.checkboxGroup.checkAll();
  }

  uncheck() {
    this.checkboxRef.checked = false;
    this.checkboxGroup.uncheckAll();
  }
}

import { createAction, props } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { SpeakerModel } from '@components/speaker/models/speaker.model';
import { SpeakerTranslationModel } from '@components/speaker/models/speaker-translation.model';

export const loadSpeakerTranslation = createAction(
  '[Speaker/Speaker Modal] Load Speaker Translation',
  props<{ event: EventModel, component: ComponentModel, speaker: SpeakerModel, language: string }>()
);

export const loadSpeakerTranslationSuccess = createAction(
  '[Speaker/API] Load Speaker Translation Success',
  props<{ speaker: SpeakerModel, translation: SpeakerTranslationModel }>()
);

export const loadSpeakerTranslationFailure = createAction(
  '[Speaker/API] Load Speaker Translation Failure',
  props<{ speaker: SpeakerModel, error: Error }>()
);

export const updateSpeakerTranslations = createAction(
  '[Speaker/Speaker Modal] Update Speaker Translations',
  props<{ event: EventModel, component: ComponentModel, speaker: SpeakerModel, translations: SpeakerTranslationModel[] }>()
);

export const updateSpeakerTranslationsSuccess = createAction(
  '[Speaker/API] Update Speaker Translations Success',
  props<{ speaker: SpeakerModel, translations: SpeakerTranslationModel[] }>()
);

export const updateSpeakerTranslationsFailure = createAction(
  '[Speaker/API] Update Speaker Translations Failure',
  props<{ speaker: SpeakerModel, error: Error }>()
);

export const clearSpeakerTranslations = createAction(
  '[Speaker/Speaker Delete] Clear Speaker Translations',
  props<{ speaker: SpeakerModel }>()
);

<div class="live-preview-container">
  <h4 class="text-center mt-2">{{ 'lp.title' | translate }}</h4>
  <br>
  <div class="row align-items-end" *ngIf="event">
    <div class="col text-center">
      <a [href]="'https://qr.meetingapplication.com/?qr_url=' + qrcode" target="_blank">
        <img [src]="'https://qr.meetingapplication.com/?qr_url=' + qrcode + '&bgcolor=f8f8f8'" alt="" width="110">
        <br>QR-Code
      </a>
    </div>
    <div class="col text-center">
      <a [href]="landingPageUrl" target="_blank">
        <img src="./assets/img/priview-landing.png" alt="" width="110">
        <br>
        Landing Page
      </a>
    </div>
  </div>


  <div class="row" *ngIf="!event">
    <div class="col text-center">
      <img src="./assets/img/preview-qr.png" alt="">
      <br>QR-Code
    </div>
    <div class="col text-center">
      <img src="./assets/img/priview-landing.png" alt="">
      <br>
      {{ 'lp.title' | translate }}
    </div>
  </div>

  <div class="row mt-4 d-flex justify-content-center" *ngIf="mobilePreview">
    <app-check-event-button></app-check-event-button>
  </div>
</div>

<div class="preview-mobile mt-4" *ngIf="mobilePreview">
  <div class="preview-wrapper">
    <div class="preview-content">
      <app-aspect-canvas width="392" height="696">
        <app-mobile-preview></app-mobile-preview>
      </app-aspect-canvas>
    </div>

    <div class="overlay-layer"></div>
  </div>
</div>
import { TagStateKey } from './tags.const';
import { ComponentModel } from '@shared/models/component.model';
import { TagsApiService } from './tags-api.service';
import { first, map } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { TagApiModel, TagGroup, TagPatchModel, TagPostModel, UserGroupAddMulti, UserGroupManage, UserGroupTag } from './tags.models';
import { EventModel } from '@store/features/event/models/event.model';
import { TagActions } from './actions/tags.actions';
import { select, Store } from '@ngrx/store';
import { Injectable } from "@angular/core";
import { EventUserApiModel } from '@shared/models/event-user.api-model';
import { ApplicationTagsSelectors } from './reducers/index';
import { TagsStateModel } from './reducers/tags.reducer';

@Injectable({
  providedIn: "root"
})
export class TagsProviderService {
  constructor(private store$: Store<any>, private apiService: TagsApiService) {}

  // loadAll(event: EventModel): void {
  //   (Object.keys(TagGroup) as Array<keyof typeof TagGroup>).map(key => {
  //     this.loadTags(event, TagGroup[key]);
  //   });
  // }

  // API TAGS
  loadTags(event: EventModel, tagGroup: TagGroup): void {
    if(!event) return;
    this.store$.dispatch(TagActions.loadTags(event, tagGroup));
  }

  updateTag(event: EventModel, tagGroup: TagGroup, tagId: number, payload: TagPatchModel): void {
    if(!event) return;
    this.store$.dispatch(TagActions.updateTag(event, tagGroup, tagId, payload));
  }

  createTag(event: EventModel, payload: TagPostModel): void {
    if(!event) return;
    this.store$.dispatch(TagActions.createTag(event, payload));
  }

  deleteTag(event: EventModel, tagGroup: TagGroup, tagId: number): void {
    if(!event) return;
    this.store$.dispatch(TagActions.deleteTag(event, tagGroup, tagId));
  }

  reorderTags(event: EventModel, tagGroup: TagGroup, tagIds: number[]): void {
    if(!event) return;
    this.store$.dispatch(TagActions.reorderTags(event, tagGroup, tagIds));
  }

  // API USER GROUP TAGS
  loadUserGroupTags(event: EventModel, eventUsers: EventUserApiModel[]): void {
    if(!event) return;
    const uuids = eventUsers.map(eventUser => eventUser.user_identity_token);
    this.store$.dispatch(TagActions.loadUserGroupTags(event, uuids));
  }

  deleteUserGroupTag(event: EventModel, payload: UserGroupManage): void {
    if(!event) return;
    this.store$.dispatch(TagActions.deleteUserGroupTag(event, payload));
  }

  addUserGroupTag(event: EventModel, payload: UserGroupManage): void {
    if(!event) return;
    this.store$.dispatch(TagActions.addUserGroupTag(event, payload));
  }

  addMultiUserTags(event: EventModel, payload: UserGroupAddMulti): void {
    if(!event) return;
    this.store$.dispatch(TagActions.addMultiUserGroupTags(event, payload));
  }

  updateUserGroupTags(event: EventModel, user: EventUserApiModel, tags: TagApiModel[]): Observable<void[]> {
    if(!event) return;
    const eventUserTags = this.getUserTagsByUUID(user.user_identity_token).pipe(first());
    const newTags$ = eventUserTags.pipe(
      map(userTags => tags.filter(tag => !userTags.some(userTag => userTag.id === tag.id))
    ));
    const deleteTags$ = eventUserTags.pipe(
      map(userTags => userTags.filter(userTag => !tags.some(tag => tag.id === userTag.id))
    ));

    return combineLatest([newTags$, deleteTags$]).pipe(
      map(([newTags, deleteTags]) => {
        const observables = [
          newTags.forEach(tag => this.addUserGroupTag(event, { user_identity_token: user.user_identity_token, tag_id: tag.id })),
          deleteTags.forEach(tag => this.deleteUserGroupTag(event, { user_identity_token: user.user_identity_token, tag_id: tag.id }))
        ];
        return observables;
      }
    ));
  }

  // STORE
  getTags(): Observable<TagsStateModel> {
    return this.store$.pipe(select(ApplicationTagsSelectors.getTags));
  }

  getUserGroupTags(): Observable<TagApiModel[]> {
    return this.store$.pipe(select(ApplicationTagsSelectors.getUserGroupTags));
  }

  getUserTags(): Observable<TagApiModel[]> {
    return this.store$.pipe(select(ApplicationTagsSelectors.getUserTags));
  }

  getAgendaPathTags(): Observable<TagApiModel[]> {
    return this.store$.pipe(select(ApplicationTagsSelectors.getAgendaPathTags));
  }

  getAgendaPlaceTags(): Observable<TagApiModel[]> {
    return this.store$.pipe(select(ApplicationTagsSelectors.getAgendaPlaceTags));
  }

  getExhibitorTags(): Observable<TagApiModel[]> {
    return this.store$.pipe(select(ApplicationTagsSelectors.getExhibitorTags));
  }

  // TAGS WITH USERS
  getUsersWithTags(): Observable<UserGroupTag> {
    return this.store$.pipe(select(ApplicationTagsSelectors.getUserGroups));
  }

  getUserTagsByUUID(uuid: string): Observable<TagApiModel[]> {
    return this.store$.pipe(select(ApplicationTagsSelectors.getUserGroupsByUUID(uuid)));
  }

  getTagsLoading(): Observable<boolean> {
    return this.store$.pipe(select(ApplicationTagsSelectors.isLoading));
  }

  getReordering(): Observable<boolean> {
    return this.store$.pipe(select(ApplicationTagsSelectors.getReordering));
  }

  getTagsError(): Observable<Error> {
    return this.store$.pipe(select(ApplicationTagsSelectors.getError));
  }

  getLoadingAuthorizedUser(): Observable<Boolean> {
    return this.store$.pipe(select(ApplicationTagsSelectors.getLoadingAuthorizedUser));
  }

  getSortedUserGroups(): Observable<UserGroupTag> {
    return this.getUsersWithTags().pipe(
      map(usersGroups => {
        return usersGroups;
      })
    );
  }

  getSortedTags(tagGroup: TagGroup): Observable<TagApiModel[]> {
    let key = '';
    switch (tagGroup) {
      case TagGroup.User:
        key = TagStateKey.User;
        break;
      case TagGroup.UserGroup:
        key = TagStateKey.UserGroup;
        break;
      case TagGroup.AgendaPath:
        key = TagStateKey.AgendaPath;
        break;
      case TagGroup.AgendaPlace:
        key = TagStateKey.AgendaPlace;
        break;
      case TagGroup.ExhibitorTag:
        key = TagStateKey.ExhibitorTag;
        break;
    }
    return this.getTags().pipe(
      map(tags => {
        return this.sort(tags[key]);
      })
    );
  }

  sort(tags: TagApiModel[]): TagApiModel[] {
    if(!tags || tags.length === 0) return;
    return [...tags].sort((a, b) => {
      return a.value.localeCompare(b.value as string);
    });
  }


  // for dynamic getting data tags without store
  // for core tag settings
  
  getTagsWithoutLoading(event: EventModel, tagGroup: TagGroup, sorted: boolean): Observable<TagApiModel[]> {
    const tags = this.apiService.getTags(event, tagGroup);
    if(sorted) {
      return tags.pipe(
        map(tags => this.sort(tags))
      );
    }
    return tags;
  }

  createTagWithoutLoading(event: EventModel, payload: TagPostModel): Observable<TagApiModel> {
    return this.apiService.createTag(event, payload);
  }

  deleteTagWithoutLoading(event: EventModel, tagGroup: TagGroup, tagId: number): Observable<{ success: boolean }> {
    return this.apiService.deleteTag(event, tagGroup, tagId);
  }

  modifyStateTags(tagGroup: TagGroup, tags: TagApiModel[]): void {
    this.store$.dispatch(TagActions.modifyStateTags(tagGroup, tags));
  }

  deleteEmptyTags(tagGroup: TagGroup): void {
    this.store$.dispatch(TagActions.deleteEmptyTags(tagGroup));
  }

}
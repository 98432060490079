import { Component, Input } from '@angular/core';
import { getFlagUrl } from '@utils/flagUrl.util';

@Component({
  selector: 'app-language-flag',
  templateUrl: './language-flag.component.html',
  styleUrls: ['./language-flag.component.scss']
})
export class LanguageFlagComponent {
  @Input() language: string;

  get languageFlagUrl(): string {
    return getFlagUrl(this.language);
  }
}

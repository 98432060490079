import { Action, createReducer, on } from "@ngrx/store";
import { bookingAction } from '@components/booking/store/actions/booking-session.actions';
import { BookingSession } from "@components/booking/store/models/booking-session.model";

export const featureKey = 'bookingSession';

export interface State {
  loading: boolean;
  error: Error;
  activeId: number;
  saving: boolean;
  bookings: BookingSession[];
}

export const initialState = {
  loading: false,
  error: null,
  activeId: null,
  saving: false,
  bookings: [],
};

export const bookingSessionReducer = createReducer(
  initialState,
  on(bookingAction.loadBookingSessions,
    state => ({ ...initialState, loading: true })),

  on(bookingAction.loadBookingSessionsSuccess,

    (state, { bookings }) => ({ ...state, loading: false, bookings })),
  on(bookingAction.loadBookingSessionsFailure,

    (state, { error }) => ({ ...state, loading: false, error })),

  on(bookingAction.addBookingSession,
    state => ({ ...state, saving: true })),

  on(bookingAction.addBookingSessionSuccess,
    (state, { booking }) => ({ ...state, saving: false, bookings: [...state.bookings, booking] })),

  on(bookingAction.addBookingSessionFailure,
    (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingAction.updateBookingSession,
    state => ({ ...state, saving: true })),

  on(bookingAction.updateBookingSessionSuccess,
    (state, { booking }) => ({ ...state, saving: false, bookings: state.bookings.map(br => br.id === booking.id ? booking : br) })),

  on(bookingAction.updateBookingSessionFailure,
    (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingAction.deleteBookingSession,
    state => ({ ...state, saving: true })),

  on(bookingAction.deleteBookingSessionSuccess,
    (state, { booking }) => ({ ...state, saving: false, bookings: [...state.bookings.filter(bS => bS.id !== booking.id)] })),
    
  on(bookingAction.deleteBookingSessionFailure,
    (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingAction.setBookingSessionActiveId, (state, { id }) => ({ ...state, activeId: id })),
);

export function reducer(state: State, action: Action): State {
  return bookingSessionReducer(state, action);
}

export const selectAllBookingSessions = (state: State) => state.bookings;
export const selectBookingSessionById = (state: State, id: number) => state.bookings.find(booking => booking.id === id);
export const selectBookingSessionIsSaving = (state: State) => state.saving;
export const selectBookingSessionIsLoading = (state: State) => state.loading;
export const selectBookingSessionError = (state: State) => state.error;
export const selectActiveBookingSession = (state: State) => state.activeId;

export const setBookingSessionError = (state: State, error: Error | null) => ({ ...state, error });
export const setActiveBookingSession = (state: State, id: number) => ({ ...state, activeId: id });

import { Action, createReducer, on } from '@ngrx/store';

import * as AgendaActions from '../actions/agenda.actions';
import { selectEvent } from '@store/features/event/actions';

export const stateKey = 'agenda';

export interface State {
  exporting: boolean;
  error: Error;
}

export const initialState: State = {
  exporting: false,
  error: null,
};

export const agendaTicketReducer = createReducer(
  initialState,
  on(
    AgendaActions.exportAgendaRatings,
    AgendaActions.exportAgendaDiscussion,
    AgendaActions.exportAgendaAttendees,
    state => ({ ...initialState, exporting: true })
  ),
  on(
    AgendaActions.exportAgendaRatingsSuccess,
    AgendaActions.exportAgendaDiscussionSuccess,
    AgendaActions.exportAgendaAttendeesSuccess,
    AgendaActions.exportCheckedInAttendeesSuccess,
    AgendaActions.exportSelfQrCodesSuccess,
    state => ({ ...state, exporting: false })
  ),
  on(
    AgendaActions.exportAgendaRatingsFailure,
    AgendaActions.exportAgendaDiscussionFailure,
    AgendaActions.exportAgendaAttendeesFailure,
    AgendaActions.exportCheckedInAttendeesFailure,
    AgendaActions.exportSelfQrCodesFailure,
    (state, { error }) => ({ ...state, exporting: false, error })
  ),

  on(
    selectEvent,
    () => ({ ...initialState }),
  )
);

export function reducer(state: State, action: Action): State {
  return agendaTicketReducer(state, action);
}

export const selectExporting = (state: State) => state.exporting;

export const selectError = (state: State) => state.error;

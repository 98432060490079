<div mat-dialog-title>
  <h2 class="d-inline-block">
    {{ component.label }} {{ 'component_settings.dialog_title' | translate }}
  </h2>

  <button mat-button mat-dialog-close class="close" type="button">
    <img src="./assets/img/icons/delete-cross-black.svg" alt="Close dialog">
  </button>
</div>

<ng-container appFormContainer>
  <mat-dialog-content class="mat-typography">
    <p>
      {{ 'component_settings.dialog_desc' | translate }}
    </p>

    <app-language-tab-list class="mb-4">
      <app-language-tab *ngFor="let language of eventLanguages$ | async" [class.active]="isLanguageActive(language)" (click)="onLanguageChange($event, language)">
        <app-language-flag [language]="language.code"></app-language-flag>
      </app-language-tab>
    </app-language-tab-list>

    <div class="flex-container">
      <form class="position-relative form-container" [formGroup]="componentForm">
        <div class="form-row mb-4">
          <div class="col-12 form-group mb-3" appFormValidate [formGroup]="currentTranslatableForm$ | async">
            <label>{{ 'component_settings.component_name' | translate }} <span class="required-star">*</span></label>
            <input formControlName="label" type="text" class="form-control" placeholder="{{ 'component_settings.component_name_ph' | translate }}">
            <div *appFormValidateFeedback="['required']" class="form-control-feedback">
              {{ 'component_settings.component_name_req' | translate }}
            </div>
          </div>
        </div>

        <div class="form-row mb-4">
          <div class="col-12 mb-4">
            <span class="title-blue">{{ 'component_settings.icons' | translate }}</span>
          </div>

          <div class="col-12 mb-4">
            <p class="dark mb-2">{{ 'component_settings.recommended' | translate }}</p>

            <div *ngIf="hasRecommendedIcons$ | async; else noRecommended" class="icons-btn-group" [class.disabled]="isReadonly$ | async">
              <div *ngFor="let icon of recommendedIcons$ | async" class="item" [class.active]="icon.id === componentForm.value.iconId" (click)="onIconClick($event, icon)">
                <img [src]="icon.url">
              </div>
            </div>

            <ng-template #noRecommended>
              <p>{{ 'component_settings.no_recomended_to_display' | translate }}</p>
            </ng-template>
          </div>

          <div class="col-12 mb-4">
            <p class="dark mt-2 mb-2">{{ 'component_settings.other' | translate }}</p>
            <div class="icons-btn-group small" [class.disabled]="isReadonly$ | async">
              <div *ngFor="let icon of icons$ | async" class="item" [class.active]="icon.id === componentForm.value.iconId" (click)="onIconClick($event, icon)">
                <img [src]="icon.url">
              </div>
            </div>
          </div>
        </div>

        <app-spinner-overlay *ngIf="isLoading$ | async"></app-spinner-overlay>
      </form>

      <div class="colors-preview">
        <app-colors-preview [eventForm]="event" [componentListPreview]="compPreviewData"></app-colors-preview>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="start">
    <button mat-button mat-dialog-close type="button" class="btn btn-light pl-4 pr-4 mr-4">{{ 'buttons.cancel' | translate }}</button>
    <button mat-button type="button" class="btn btn-primary pl-4 pr-4" (click)="onComponentSave($event)" appFormSubmit>
      <ng-container *ngIf="isSaving$ | async; else noSaving">
        <app-spinner-progress></app-spinner-progress>
      </ng-container>
      <ng-template #noSaving>
        {{ 'buttons.save_changes' | translate }}
      </ng-template>
    </button>
  </mat-dialog-actions>
</ng-container>

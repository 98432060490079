import { Component, AfterContentChecked, Input, ViewChild, HostBinding, ElementRef } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements AfterContentChecked {
  @Input() icon: string;
  @Input() size: string;

  @ViewChild('labelElement', { static: true }) labelElement: ElementRef;

  @HostBinding('class.size-large') get _sizeLarge() {
    return this.size === 'large';
  }

  @HostBinding('class.size-small') get _sizeSmall() {
    return this.size === 'small';
  }

  isLabelEmpty: boolean;

  ngAfterContentChecked() {
    const label: HTMLElement = this.labelElement.nativeElement;
    this.isLabelEmpty = (label.childNodes.length === 0);
  }
}

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FullApiService } from '@shared/providers/full-api.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { BusinessMatchingMeetingApiModel } from '@components/business-matching/models/business-matching-meeting.api-model';

@Injectable({
  providedIn: 'root'
})
export class BusinessMatchingMeetingProviderService {
  constructor(private apiService: FullApiService) {}

  getBusinessMatchingMeetings(event: EventModel, component: ComponentModel): Observable<BusinessMatchingMeetingApiModel[]> {
    return this.apiService.getBusinessMatchingMeetings(event.id, component.id);
  }
}

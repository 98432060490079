<app-tag-group>
  <ng-container *ngFor="let tagControl of tagControls.controls; let i = index">
    <app-tag [formControl]="tagControl" [readOnly]="isSystemTag(tagControl.value)"
      [hideRemove]="isSystemTag(tagControl.value)" (appRemove)="onRemoveTagAt($event, i)"></app-tag>
  </ng-container>

  <button (click)="onAddTag($event)" type="button" class="btn btn-link btn-link-add">
    {{ label }}
  </button>
</app-tag-group>

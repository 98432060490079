import { Action, createReducer, createSelector, on } from '@ngrx/store';

import { InstanceState, createInstanceAdapter, createInstanceSelector } from '@store/adapters/instance.adapter';
import { PlaceholderState, createPlaceholderAdapter } from '@store/adapters/placeholder.adapter';

import * as WwwResourceActions from '../actions/www-resource.actions';
import * as CategoryActions from '../actions/category.actions';
import { selectEvent } from '@store/features/event/actions';

export interface State extends InstanceState<PlaceholderState> {
}

export const placeholderAdapter = createPlaceholderAdapter();

export const initialPlaceholderState: PlaceholderState = placeholderAdapter.getInitialState();

export const placeholderReducer = createReducer(
  initialPlaceholderState,
  on(
    CategoryActions.focusPlaceholder,
    state => placeholderAdapter.focus(state)
  ),
  on(
    CategoryActions.blurPlaceholder,
    state => placeholderAdapter.blur(state)
  ),
  on(
    WwwResourceActions.loadWwwResources,
    CategoryActions.hidePlaceholder,
    state => placeholderAdapter.hide(state)
  ),
);

export const instanceAdapter = createInstanceAdapter({
  reducer: placeholderReducer,
});

export const initialInstanceState: State = instanceAdapter.getInitialState();

export const instanceReducer = createReducer(
  initialInstanceState,
  on(
    WwwResourceActions.loadWwwResourcesRequest,
    CategoryActions.focusPlaceholder,
    CategoryActions.blurPlaceholder,
    CategoryActions.hidePlaceholder,
    (state, action) => instanceAdapter.update(action.componentId, state, action)
  ),

  on(
    selectEvent,
    () => ({ ...initialInstanceState }),
  )
);

export function reducer(state: State, action: Action): State {
  return instanceReducer(state, action);
}

export const selectPlaceholderState = createInstanceSelector(
  (state: State, { componentId }: { componentId: number }) => componentId
);

const {
  selectUuid,
  selectShown,
  selectFocused,
} = placeholderAdapter.getSelectors();

export const selectPlaceholderUuid = createSelector(
  selectPlaceholderState,
  selectUuid
);

export const selectPlaceholderShown = createSelector(
  selectPlaceholderState,
  selectShown
);

export const selectPlaceholderFocused = createSelector(
  selectPlaceholderState,
  selectFocused
);

import { Subscription, Subject } from 'rxjs';
import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: "[debounceClick]"
})
export class DebounceClick implements OnInit, OnDestroy {

  @Input() debounceTime = 300;
  @Output() debounceClick = new EventEmitter();
  
  private clicks = new Subject();
  private sub: Subscription;

  constructor() { }

  ngOnInit(): void {
    this.sub = this.clicks.pipe(
      debounceTime(this.debounceTime)
    ).subscribe(ev => this.debounceClick.emit(ev));
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  @HostListener("click", ["$event"])
  clickEvent(ev: Event) {
    ev.preventDefault();
    ev.stopPropagation();
    this.clicks.next(ev);
  }
}
import { Action, ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUsersReducer from './reducers/user.reducer';
import * as fromUsersPicker from './reducers/user-picker.reducer';

export const featureKey = 'usersFeature';

export interface State {
  [fromUsersReducer.featureKey]: fromUsersReducer.State;
  [fromUsersPicker.featureKey]: fromUsersPicker.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromUsersReducer.featureKey]: fromUsersReducer.reducer,
  [fromUsersPicker.featureKey]: fromUsersPicker.reducer,
}

export const selectFeature = createFeatureSelector<State>(featureKey);

export const selectUsersState = createSelector(
  selectFeature,
  state => state[fromUsersReducer.featureKey]
);

export const selectUsersPickerState = createSelector(
  selectFeature,
  state => state[fromUsersPicker.featureKey]
);

const selectLoading = createSelector(
  selectUsersState,
  fromUsersReducer.selectLoading
);

const selectUpdating = createSelector(
  selectUsersState,
  fromUsersReducer.selectUpdating
);

const selectError = createSelector(
  selectUsersState,
  fromUsersReducer.selectError
);

const selectCheckedViewActive = createSelector(
  selectUsersState,
  fromUsersReducer.selectCheckedViewActive
);

const selectColumns = createSelector(
  selectUsersState,
  fromUsersReducer.selectColumns
);

const selectEntitiesSetup = createSelector(
  selectUsersState,
  fromUsersReducer.selectEntitiesSetup
);

const selectPagination = createSelector(
  selectUsersState,
  fromUsersReducer.selectPagination
);

const selectSearchTerm = createSelector(
  selectUsersState,
  fromUsersReducer.selectSearchTerm
);

const selectFiltersOpen = createSelector(
  selectUsersState,
  fromUsersReducer.selectFiltersOpen
);

const selectMobileFiltersActive = createSelector(
  selectUsersState,
  fromUsersReducer.selectMobileFiltersActive
);

const selectUsers = createSelector(
  selectUsersState,
  fromUsersReducer.selectUsers
);

const selectMeta = createSelector(
  selectUsersState,
  fromUsersReducer.selectMeta
);

const selectUserTags = createSelector(
  selectUsersState,
  fromUsersReducer.selectUserTags
);

const selectSingleUser = createSelector(
  selectUsersState,
  fromUsersReducer.selectSingleUser
);

const selectAdminUser = createSelector(
  selectUsersState,
  fromUsersReducer.selectAdminUser
);

const selectCheckedUsers = createSelector(
  selectUsersState,
  fromUsersReducer.selectCheckedUsers
);

const selectUsersActionResults = createSelector(
  selectUsersState,
  fromUsersReducer.selectUsersActionResults,
);

const selectUserActionError = createSelector(
  selectUsersState,
  fromUsersReducer.selectUserActionError,
);

const selectUserActionSuccess = createSelector(
  selectUsersState,
  fromUsersReducer.selectUserActionSuccess,
);

// users picker selectors

const selectPickerUsers = createSelector(
  selectUsersPickerState,
  fromUsersPicker.selectPickerUsers,
);

const selectPickerUsersLoading = createSelector(
  selectUsersPickerState,
  fromUsersPicker.selectPickerUsersLoading,
);

const selectLoadedUserPicker = createSelector(
  selectUsersPickerState,
  fromUsersPicker.selectLoadedUserPicker,
);

const selectUserPickerSearchTerm = createSelector(
  selectUsersPickerState,
  fromUsersPicker.selectUserPickerSearchTerm,
);

const selectLastPickerEventUser = createSelector(
  selectUsersPickerState,
  fromUsersPicker.selectLastPickerEventUser,
);

export const UserPickerSelector = {
  selectPickerUsers,
  selectPickerUsersLoading,
  selectLoadedUserPicker,
  selectUserPickerSearchTerm,
  selectLastPickerEventUser,
}

export const UserSelector = {
  selectLoading,
  selectUpdating,
  selectError,
  selectCheckedViewActive,
  selectColumns,
  selectEntitiesSetup,
  selectPagination,
  selectSearchTerm,
  selectFiltersOpen,
  selectMobileFiltersActive,
  selectUsers,
  selectMeta,
  selectUserTags,
  selectSingleUser,
  selectAdminUser,
  selectCheckedUsers,
  selectUsersActionResults,
  selectUserActionError,
  selectUserActionSuccess,
}
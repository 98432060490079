import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter, Dictionary } from '@ngrx/entity';

import { PartnerContactPersonModel } from '@components/partner/models/partner-contact-person.model';

import * as PartnerContactPersonActions from '../actions/partner-contact-person.actions';

export const stateKey = 'partnerContactPerson';

export interface PartnerState {
  loading: boolean;
  loaded: boolean;
  error: Error;
}

export const partnerInitialState: PartnerState = {
  loading: false,
  loaded: false,
  error: null,
};

export interface State extends EntityState<PartnerContactPersonModel> {
  partner: Dictionary<PartnerState>;
}

export const adapter = createEntityAdapter<PartnerContactPersonModel>({
  sortComparer: (a, b) => a.order - b.order,
});

export const initialState: State = adapter.getInitialState({
  partner: {},
});

export const partnerContactPersonReducer = createReducer(
  initialState,
  on(
    PartnerContactPersonActions.fillPartnerContactPeople,
    (state, { partners, contacts }) => {
      const removed = adapter.removeMany(contact =>
        partners.some(partner => partner.id === contact.partnerId), state
      );

      const mutated = partners.reduce(
        (acc, partner) => substate(acc, partner.id, { ...partnerInitialState, loaded: true }), removed
      );

      return adapter.addMany(contacts, mutated);
    }
  ),
  on(
    PartnerContactPersonActions.loadPartnerContactPeople,
    (state, { partner }) => {
      const mutated = substate(state, partner.id, { ...partnerInitialState, loading: true });
      return adapter.removeMany(contact => contact.partnerId === partner.id, mutated);
    }
  ),
  on(
    PartnerContactPersonActions.loadPartnerContactPeopleSuccess,
    (state, { partner, contacts }) => {
      const mutated = substate(state, partner.id, { ...partnerInitialState, loaded: true });
      return adapter.addMany(contacts, mutated);
    }
  ),
  on(
    PartnerContactPersonActions.diffPartnerContactPeopleSuccess,
    (state, { partner, contacts }) => {
      const mutated = adapter.removeMany(contact => contact.partnerId === partner.id, state);
      return adapter.addMany(contacts, mutated);
    }
  ),
  on(
    PartnerContactPersonActions.loadPartnerContactPeopleFailure,
    PartnerContactPersonActions.diffPartnerContactPeopleFailure,
    (state, { partner, error }) => substate(state, partner.id, { ...partnerInitialState, error })
  ),
);

function substate(state: State, partnerId: number, changes: Partial<PartnerState>): State {
  const partnerState = { ...state.partner[partnerId], ...changes };
  const partner = { ...state.partner, [partnerId]: partnerState };

  return { ...state, partner };
}

export function reducer(state: State, action: Action): State {
  return partnerContactPersonReducer(state, action);
}

const {
  selectEntities,
  selectAll,
} = adapter.getSelectors();

export const selectPartnerContactPersonEntities = selectEntities;

export const selectAllPartnerContactPeople = selectAll;

export const selectPartnerContactPeople = () => createSelector(
  selectAllPartnerContactPeople,
  (partners: PartnerContactPersonModel[], { partnerId }) =>
    partners.filter(partner => partner.partnerId === partnerId)
);

export const selectPartnerContactPersonPartner = (state: State, { partnerId }) => state.partner[partnerId];

export const selectPartnerContactPersonLoading = createSelector(
  selectPartnerContactPersonPartner,
  component => component && component.loading
);

export const selectPartnerContactPersonLoaded = createSelector(
  selectPartnerContactPersonPartner,
  component => component && component.loaded
);

export const selectPartnerContactPersonError = createSelector(
  selectPartnerContactPersonPartner,
  component => component && component.error
);

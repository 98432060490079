import { createAction, props } from '@ngrx/store';

import { AttachmentModel } from '@shared/models/attachment.model';
import { InteractiveMapModel } from '@components/interactive-map/models/interactive-map.model';
import { InteractiveMapLocationModel } from '@components/interactive-map/models/interactive-map-location.model';
import { InteractiveMapShapeModel } from '@components/interactive-map/models/interactive-map-shape.model';

import { EditorTool } from '../reducers/editor.reducer';

export const cleanseEditor = createAction(
  '[Editor/Interactive Map Modal] Cleanse Editor',
);

export const prepareEditor = createAction(
  '[Editor/Interactive Map Modal] Prepare Editor',
  props<{ interactiveMap: InteractiveMapModel }>()
);

export const prepareEditorSuccess = createAction(
  '[Editor/Effect] Prepare Editor Success',
  props<{ image: AttachmentModel, locations: InteractiveMapLocationModel[] }>()
);

export const changeTool = createAction(
  '[Editor/Toolbar] Change Tool',
  props<{ tool: EditorTool }>()
);

export const changeImage = createAction(
  '[Editor/Toolbar] Change Image',
  props<{ image: AttachmentModel }>()
);

export const createLocation = createAction(
  '[Editor/Editor Wrapper] Create Location',
  props<{ location: InteractiveMapLocationModel }>()
);

export const updateLocationShape = createAction(
  '[Editor/Editor Wrapper] Update Location Shape',
  props<{ uuid: string, shape: InteractiveMapShapeModel }>()
);

export const createLocationSuccess = createAction(
  '[Editor/Effect] Create Location Success',
  props<{ location: InteractiveMapLocationModel }>()
);

export const updateLocation = createAction(
  '[Editor/Location List] Update Location',
  props<{ uuid: string, update: any }>()
);

export const deleteLocation = createAction(
  '[Editor/Location List] Delete Location',
  props<{ uuid: string }>()
);

export const selectLocation = createAction(
  '[Editor/Editor Wrapper] Select Location',
  props<{ uuid: string }>()
);

export const updateSelectedLocation = createAction(
  '[Editor/Sidebar] Update Selected Location',
  props<{ update: any }>()
);

export const deleteSelectedLocation = createAction(
  '[Editor/Sidebar] Delete Selected Location',
);

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[noSpaceLowerCase]'
})
export class NoSpaceLowerCaseDirective {
  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    const initialValue: string = this.el.nativeElement.value;
    const newValue: string = initialValue.replace(/\s+/g, '').toLowerCase();
    if (initialValue !== newValue) {
      this.el.nativeElement.value = newValue;
      event.stopPropagation();
    }
  }
}
import { BookingFilter } from '@components/booking/store/models/booking-session.model';
import { createAction } from '@ngrx/store';

const loadBookingFilters = createAction('[Booking Filter] Load Booking Filters');
const loadBookingFiltersSuccess = createAction('[Booking Filter] Load Booking Filters Success', (bookingFilters: BookingFilter[]) => ({ bookingFilters }));
const loadBookingFiltersFailure = createAction('[Booking Filter] Load Booking Filters Failure', (error: any) => ({ error }));

const addBookingFilter = createAction('[Booking Filter] Add Booking Filter', (bookingFilter: BookingFilter) => ({ bookingFilter }));
const addBookingFilterSuccess = createAction('[Booking Filter] Add Booking Filter Success', (bookingFilter: BookingFilter) => ({ bookingFilter }));
const addBookingFilterFailure = createAction('[Booking Filter] Add Booking Filter Failure', (error: any) => ({ error }));

const updateBookingFilter = createAction('[Booking Filter] Update Booking Filter', (bookingFilter: BookingFilter) => ({ bookingFilter }));
const updateBookingFilterSuccess = createAction('[Booking Filter] Update Booking Filter Success', (bookingFilter: BookingFilter) => ({ bookingFilter }));
const updateBookingFilterFailure = createAction('[Booking Filter] Update Booking Filter Failure', (error: any) => ({ error }));

const deleteBookingFilter = createAction('[Booking Filter] Delete Booking Filter', (bookingFilter: BookingFilter) => ({ bookingFilter }));
const deleteBookingFilterSuccess = createAction('[Booking Filter] Delete Booking Filter Success', (bookingFilter: BookingFilter) => ({ bookingFilter }));
const deleteBookingFilterFailure = createAction('[Booking Filter] Delete Booking Filter Failure', (error: any) => ({ error }));

const setBookingFilterError = createAction('[Booking Filter] Set Booking Filter Error', (error: any) => ({ error }));

export const bookingFilterActions = {
  loadBookingFilters,
  loadBookingFiltersSuccess,
  loadBookingFiltersFailure,
  addBookingFilter,
  addBookingFilterSuccess,
  addBookingFilterFailure,
  updateBookingFilter,
  updateBookingFilterSuccess,
  updateBookingFilterFailure,
  deleteBookingFilter,
  deleteBookingFilterSuccess,
  deleteBookingFilterFailure,
  setBookingFilterError
}
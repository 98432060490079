import { ComponentModel } from './../../../shared/models/component.model';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { VenueModel, VenueCategoryModel, VenueTranslationModel, VenueCategoryTranslationModel } from '@components/venue/models/venue.model';

import * as VenueActions from './actions/venue.actions';
import * as CategoryActions from './actions/category.actions';
import * as ModalActions from './actions/modal.actions';
import * as TranslationActions from './actions/translation.actions';
import * as IconActions from './actions/icon.actions';

import * as fromComponent from '@store/features/component/reducers';

import * as fromVenue from './reducers';

@Injectable({
  providedIn: 'root'
})
export class VenueFacadeService {
  constructor(private store$: Store<any>) {}

  getVenues(): Observable<VenueModel[]> {
    return this.getActiveComponentId().pipe(
      switchMap(componentId =>
        this.store$.pipe(select(fromVenue.selectVenues, { componentId }))
      )
    );
  }
  
  getVenuesByComponent(component: ComponentModel): Observable<VenueModel[]> {
    return this.store$.pipe(select(fromVenue.selectVenues, { componentId: component.id }));
  }

  getVenueLoading(): Observable<boolean> {
    return this.getActiveComponentId().pipe(
      switchMap(componentId =>
        this.store$.pipe(select(fromVenue.selectVenueComponentLoading, { componentId }))
      )
    );
  }

  getVenueCategories(): Observable<VenueCategoryModel[]> {
    return this.getActiveComponentId().pipe(
      switchMap(componentId =>
        this.store$.pipe(select(fromVenue.selectVenueCategories, { componentId }))
      )
    );
  }

  getVenueCategoriesByComponent(component: ComponentModel): Observable<VenueCategoryModel[]> {
    return this.store$.pipe(select(fromVenue.selectVenueCategories, { componentId: component.id }))
  }

  getVenueCategoriesWithPlaceholder(): Observable<VenueCategoryModel[]> {
    return this.getActiveComponentId().pipe(
      switchMap(componentId =>
        this.store$.pipe(select(fromVenue.selectVenueCategoriesWithPlaceholder, { componentId }))
      )
    );
  }

  getVenuePlaceholderShown(): Observable<boolean> {
    return this.getActiveComponentId().pipe(
      switchMap(componentId =>
        this.store$.pipe(select(fromVenue.selectVenuePlaceholderShown, { componentId }))
      )
    );
  }

  getVenuePlaceholderFocused(): Observable<boolean> {
    return this.getActiveComponentId().pipe(
      switchMap(componentId =>
        this.store$.pipe(select(fromVenue.selectVenuePlaceholderFocused, { componentId }))
      )
    );
  }

  getVenueModalSaving(): Observable<boolean> {
    return this.store$.pipe(select(fromVenue.selectVenueModalSaving));
  }

  getVenueModalSaved(): Observable<boolean> {
    return this.store$.pipe(select(fromVenue.selectVenueModalSaved));
  }

  getVenueTranslation(venue: VenueModel, language: string) {
    return this.store$.pipe(select(fromVenue.selectVenueTranslation, { venueId: venue.id, language }));
  }

  getVenueTranslationLoading(): Observable<boolean> {
    return this.store$.pipe(select(fromVenue.selectVenueTranslationLoading));
  }

  getVenueCategoryTranslation(category: VenueCategoryModel, language: string) {
    return this.store$.pipe(select(fromVenue.selectVenueCategoryTranslation(), { categoryId: category.id, language }));
  }

  getVenueCategoryTranslationLoading(): Observable<boolean> {
    return this.store$.pipe(select(fromVenue.selectVenueCategoryTranslationLoading));
  }

  loadVenues() {
    this.store$.dispatch(VenueActions.loadVenues());
  }

  createVenueCategory(category: VenueCategoryModel) {
    this.store$.dispatch(CategoryActions.createCategory({ category }));
  }

  updateVenueCategory(category: VenueCategoryModel) {
    this.store$.dispatch(CategoryActions.updateCategory({ category }));
  }

  reorderVenueCategories(from: number, to: number) {
    this.store$.dispatch(CategoryActions.reorderCategories({ from, to }));
  }

  deleteVenueCategory(category: VenueCategoryModel) {
    this.store$.dispatch(CategoryActions.deleteCategory({ category }));
  }

  selectVenueCategory(category: VenueCategoryModel) {
    this.store$.dispatch(CategoryActions.selectCategory({ category }));
  }

  createVenue(venue: VenueModel, translations: VenueTranslationModel[]) {
    this.store$.dispatch(ModalActions.modalCreateVenue({ venue, translations }));
  }

  updateVenue(venue: VenueModel, translations: VenueTranslationModel[]) {
    this.store$.dispatch(ModalActions.modalUpdateVenue({ venue, translations }));
  }

  deleteVenue(venue: VenueModel) {
    this.store$.dispatch(VenueActions.deleteVenue({ venue }));
  }

  focusCategoryPlaceholder() {
    this.getActiveComponentId().pipe(take(1)).subscribe(
      componentId => this.store$.dispatch(CategoryActions.focusPlaceholder({ componentId }))
    );
  }

  blurCategoryPlaceholder() {
    this.getActiveComponentId().pipe(take(1)).subscribe(
      componentId => this.store$.dispatch(CategoryActions.blurPlaceholder({ componentId }))
    );
  }

  loadVenueTranslation(venue: VenueModel, language: string) {
    this.store$.dispatch(TranslationActions.loadVenueTranslation({ venue, language }));
  }

  loadVenueCategoryTranslation(category: VenueCategoryModel, language: string) {
    this.store$.dispatch(TranslationActions.loadVenueCategoryTranslation({ category, language }));
  }

  updateVenueCategoryTranslations(category: VenueCategoryModel, translations: VenueCategoryTranslationModel[]) {
    this.store$.dispatch(TranslationActions.updateVenueCategoryTranslations({ category, translations }));
  }

  loadVenueIcons() {
    this.store$.dispatch(IconActions.loadVenueIcons());
  }

  getVenueIcons() {
    return this.store$.pipe(select(fromVenue.selectIcons));
  }

  getError() {
    return this.store$.pipe(select(fromVenue.selectIconsError));
  }

  getIsLoading() {
    return this.store$.pipe(select(fromVenue.selectIconsLoading));
  }

  private getActiveComponentId(): Observable<number> {
    return this.store$.pipe(select(fromComponent.selectActiveComponentId));
  }
}

<ng-container *ngFor="let item of selectedCountries; let i = index">
  <app-badge [color]="item.color" [removable]="true" (appRemove)="onCountryRemove($event, i)">{{ item.country.name }}</app-badge>
</ng-container>

<app-input-adaptive #input placeholder="Type country name" [formControl]="inputControl" (keydown)="onInputKeyDown($event)" (appFocus)="onInputFocus($event)" (appBlur)="onInputBlur($event)"></app-input-adaptive>
<div class="input-transfer" (click)="input.focus()"></div>

<div *ngIf="showAutocomplete" class="autocomplete">
  <ng-container *ngFor="let country of filteredCountries$ | async; let i = index">
    <div class="autocomplete-item" (mousedown)="onAutocompleteCountry($event, country)">{{ country.name }}<sub>{{ country.code }}</sub></div>
  </ng-container>
</div>

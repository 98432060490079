import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { AgendaTranslationModel } from '@components/agenda/models/agenda-translation.model';

import * as AgendaTranslationActions from '../actions/agenda-translation.actions';
import * as ImportActions from '../actions/agenda-import.actions';

export const featureKey = 'agendaTranslation';

export interface State extends EntityState<AgendaTranslationModel> {
  loading: boolean;
  error: Error;
}

export const adapter = createEntityAdapter<AgendaTranslationModel>({
  sortComparer: false,
});

export const initialState: State = {
  ...adapter.getInitialState(),
  loading: false,
  error: null,
};

export const agendaTranslationReducer = createReducer(
  initialState,
  on(
    AgendaTranslationActions.resetAgendaTranslations,
    state => ({ ...initialState })
  ),
  on(
    AgendaTranslationActions.loadAgendaTranslation,
    state => ({ ...state, loading: true })
  ),
  on(
    AgendaTranslationActions.loadAgendaTranslationSuccess,
    (state, { translation }) => adapter.upsertOne(translation, { ...state, loading: false })
  ),
  on(
    AgendaTranslationActions.loadAgendaTranslationFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    ImportActions.syncAgendaImportSuccess,
    state => ({ ...initialState })
  ),
);

export function reducer(state: State, action: Action): State {
  return agendaTranslationReducer(state, action);
}

const {
  selectIds,
  selectEntities,
  selectAll
} = adapter.getSelectors();

export const selectAgendaTranslationIds = selectIds;

export const selectAgendaTranslationEntities = selectEntities;

export const selectAllAgendaTranslations = selectAll;

export const selectAgendaTranslationLoading = (state: State) => state.loading;

export const selectAgendaTranslationError = (state: State) => state.error;

import { Injectable } from '@angular/core';

import { Observable, defer } from 'rxjs';
import { tap, take, map, switchMap } from 'rxjs/operators';

import { SurveyQuizProviderService } from '@components/survey-quiz/providers/survey-quiz-provider.service';
import { SurveyQuizStoreService } from '@components/survey-quiz/providers/survey-quiz-store.service';
import { SurveyQuizQuestionService } from '@components/survey-quiz/providers/survey-quiz-question.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { SurveyQuizModel } from '@components/survey-quiz/models/survey-quiz.model';

import { reorder } from '@utils/collection.util';

@Injectable({
  providedIn: 'root'
})
export class SurveyQuizService {
  protected currentEvent: EventModel;
  protected currentComponent: ComponentModel;

  constructor(private surveyQuizProvider: SurveyQuizProviderService, private surveyQuizStore: SurveyQuizStoreService,
    private surveyQuizQuestionService: SurveyQuizQuestionService) {}

  getOrderedSurveyQuizzes(): Observable<SurveyQuizModel[]> {
    return this.surveyQuizStore.getOrderedSurveyQuizzes();
  }

  getSurveyQuizzes(): Observable<SurveyQuizModel[]> {
    return this.surveyQuizStore.getSurveyQuizzes();
  }

  loadComponent(event: EventModel, component: ComponentModel): Observable<SurveyQuizModel[]> {
    return defer(() => this.loadSurveyQuizzesFor(event, component));
  }

  createSurveyQuiz(surveyQuiz: SurveyQuizModel): Observable<SurveyQuizModel> {
    const surveyQuizData$ = this.surveyQuizStore.getSurveyQuizzes().pipe(take(1)).pipe(
      map(surveyQuizzes => ({ ...surveyQuiz, order: surveyQuizzes.length }))
    );

    return surveyQuizData$.pipe(
      switchMap(surveyQuizData => this.surveyQuizProvider.createSurveyQuiz(this.currentEvent, this.currentComponent, surveyQuizData)),
      tap(createdSurveyQuiz => this.surveyQuizStore.pushEntity(createdSurveyQuiz))
    );
  }

  updateSurveyQuiz(surveyQuiz: SurveyQuizModel): Observable<SurveyQuizModel> {
    return this.surveyQuizProvider.updateSurveyQuiz(this.currentEvent, this.currentComponent, surveyQuiz).pipe(
      tap(updatedSurveyQuiz => this.surveyQuizStore.updateEntity(updatedSurveyQuiz))
    );
  }

  deleteSurveyQuiz(surveyQuiz: SurveyQuizModel): Observable<boolean> {
    return this.surveyQuizProvider.deleteSurveyQuiz(this.currentEvent, this.currentComponent, surveyQuiz).pipe(
      tap(success => success && this.surveyQuizStore.removeEntity(surveyQuiz))
    );
  }

  reorderSurveyQuizzes(surveyQuizzes: SurveyQuizModel[]): Observable<SurveyQuizModel[]> {
    return defer(() => {
      this.surveyQuizStore.setup(surveyQuizzes.map(
        (surveyQuiz, order) => ({ ...surveyQuiz, order })
      ));

      return this.surveyQuizProvider.reorderSurveyQuizzes(this.currentEvent, this.currentComponent, surveyQuizzes).pipe(
        tap(reorderedSurveyQuizzes => this.surveyQuizStore.setup(reorderedSurveyQuizzes))
      );
    });
  }

  reorderSurveyQuiz(from: number, to: number): Observable<SurveyQuizModel[]> {
     return this.surveyQuizStore.getOrderedSurveyQuizzes().pipe(take(1)).pipe(
      map(orderedSurveyQuizzes => reorder(orderedSurveyQuizzes, from, to)),
      switchMap(surveyQuizzes => this.reorderSurveyQuizzes(surveyQuizzes))
    );
  }

  exportSurveyQuiz(surveyQuiz: SurveyQuizModel): Observable<string> {
    return this.surveyQuizProvider.exportSurveyQuiz(this.currentEvent, this.currentComponent, surveyQuiz);
  }

  private loadSurveyQuizzesFor(event: EventModel, component: ComponentModel): Observable<SurveyQuizModel[]> {
    this.reset();

    return this.surveyQuizProvider.loadSurveyQuizzes(event, component).pipe(
      tap(surveyQuizzes => this.setup(event, component, surveyQuizzes))
    );
  }

  private setup(event: EventModel, component: ComponentModel, surveyQuizzes: SurveyQuizModel[]) {
    this.currentEvent = event;
    this.currentComponent = component;
    this.surveyQuizStore.setup(surveyQuizzes);
  }

  private reset() {
    this.currentEvent = null;
    this.currentComponent = null;
    this.surveyQuizStore.reset();
  }
}

import { first, map } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges, forwardRef, ViewChild, ElementRef, ChangeDetectorRef, OnInit, Sanitizer, OnDestroy } from '@angular/core';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { FullApiService } from '../../providers/full-api.service';
import { AttachmentModel } from '../../models/attachment.model';
import { Subscription, of } from 'rxjs';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SvgService } from '@shared/providers/svg.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ImageCropperService } from '@shared/components/image-cropper/image-cropper.service';
import { ModalErrorProviderSerivce } from '../modal-error/modal-error-provider.service';
import { TranslateService } from '@ngx-translate/core';

export enum UploadState {
  Pending = 0,
  Uploading = 1,
  Done = 2
}

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploaderComponent),
      multi: true
    }
  ]
})
export class FileUploaderComponent implements OnChanges, ControlValueAccessor, OnInit, OnDestroy {

  private file: any;
  private invalidFiles: any;
  imageSrc: String;

  progress = 0;

  svgContent: SafeHtml = null;

  @Input() predefinePic: AttachmentModel;
  private _predefinePic: AttachmentModel;

  @Input() roundedPic = false;
  @Input() cropper = false;

  @Input() svg = false;

  @Output()
  success = new EventEmitter<AttachmentModel>();

  @Output()
  started = new EventEmitter<void>();

  @Output()
  processing = new EventEmitter<boolean>();

  @Output()
  handleFormData = new EventEmitter<FormData>();

  @ViewChild('fileInp', { static: true }) fileInputElement: ElementRef;

  addedFileId: number;
  fileName: any;
  loadedBytes: any = 0;
  totalByte: any;

  state: UploadState = UploadState.Pending;

  statePending = UploadState.Pending;
  stateUploading = UploadState.Uploading;
  stateDone = UploadState.Done;

  request: Subscription;

  allowedFileTypes: string[] = ['png', 'jpg', 'jpeg', 'bmp', 'webp'];

  private onChange: (attachment: AttachmentModel) => void;
  private onTouched: () => void;

  private subs = new Subscription();

  constructor(
    private api: FullApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private svgService: SvgService,
    private sanitizer: DomSanitizer,
    private imageCropperService: ImageCropperService,
    private modalErrorProvider: ModalErrorProviderSerivce,
    private i18n: TranslateService,
  ) {}

  ngOnInit(): void {
    if (this.svg) {
      this.allowedFileTypes.push('svg');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('predefinePic' in changes) {
      const predefinePic: SimpleChange = changes.predefinePic;
      this._predefinePic = predefinePic.currentValue;

      if (this._predefinePic && this._predefinePic.id) {
        this.imageSrc = this._predefinePic.file_url;
        this.state = UploadState.Done;
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onFilesChange(fileList: File) {
    this.file = fileList;

    this.fileName = this.file.name;
    this.totalByte = this.file.size;

    const formData = new FormData();
    formData.append('file', fileList);

    // if (this.onChange) {
    //   this.onChange(null);
    // }

    if (this._predefinePic && this._predefinePic.id) {
      this.updateAttachment(this._predefinePic.id, formData);
    } else if (this.addedFileId > 0) {
      this.updateAttachment(this.addedFileId, formData);
    } else {
      this.addAttachment(formData);
    }
  }

  crop(): void {
    if (this.file || this._predefinePic) {
      this.subs.add(this.imageCropperService.open(this.file || this._predefinePic)
        .pipe(first())
        .subscribe(file => file && this.onFilesChange(file)));
    }
  }

  onFileInvalids(file: File) {
    const fileName = file.name;
    const fileEnxtension = fileName.split('.').pop();
    if (!this.allowedFileTypes.includes(fileEnxtension)) {
      const allowedFormatString = this.allowedFileTypes.join(', ');
      const errorMessage = `${this.i18n.instant('error.modal_desc_invalid_file_type')}: ${allowedFormatString}.`;
      this.modalErrorProvider.open(
        this.i18n.instant('error.modal_title_invalid_file_type'),
        errorMessage,
      );
    }

    this.invalidFiles = file;
  }

  readURL(file: File): void {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageSrc = e.target.result;
      };

      reader.readAsDataURL(file);
    }
  }

  addAttachment(formData) {
    this.processing.emit(true);
    this.handleFormData.next(formData);
    this.started.next();
    let preSize = 0;
    this.state = UploadState.Uploading;
    this.request = this.api.attachment(formData).subscribe((event: HttpEvent<any>) => {
      if (HttpEventType.Sent) {
        this.state = UploadState.Uploading;
        this.progress = 10;
      } else if (event.type === HttpEventType.UploadProgress) {
        this.state = UploadState.Uploading;

        if (event.loaded > preSize) {
          this.loadedBytes = event.loaded;
          this.progress = Math.round(100 * event.loaded / event.total);
          this.progress = Math.min(this.progress, 99);
          preSize = event.loaded;
        }
      } else if (event instanceof HttpResponse) {
        this.addedFileId = event.body.id;
        this.success.emit(event.body);
        this.state = UploadState.Done;
        this.imageSrc = event.body.file_url;
        this.readURL(this.file);
        this.processing.emit(false);

        if (this.onChange) {
          this.onChange(event.body);
          this.changeDetectorRef.markForCheck();
        }
      }

      this.changeDetectorRef.markForCheck();
    }, error1 => {
      // TODO err handler
      this.processing.emit(false);
      this.changeDetectorRef.markForCheck();
    });
  }

  updateAttachment(id, formData) {
    let preSize = 0;
    this.progress = 10;
    this.loadedBytes = 0;
    //this.imageSrc = null;
    this.state = UploadState.Uploading;
    this.addAttachment(formData);
    // this.request = this.api.updateAttachment(id, formData).subscribe((event: any) => {
    //   if (HttpEventType.Sent) {
    //     this.state = UploadState.Uploading;
    //     this.progress = 10;
    //   } else if (event.type === HttpEventType.UploadProgress) {
    //     this.state = UploadState.Uploading;

    //     if (event.loaded > preSize) {
    //       this.loadedBytes = event.loaded;
    //       this.progress = Math.round(100 * event.loaded / event.total);
    //       this.progress = Math.min(this.progress, 99);
    //       preSize = event.loaded;
    //     }
    //   } else if (event instanceof HttpResponse) {
    //     this.addedFileId = event.body.id;
    //     this.success.emit(event.body);
    //     this.state = UploadState.Done;
    //     this.readURL(this.file);

    //     if (this.onChange) {
    //       this.onChange(event.body);
    //     }
    //   }
    // }, error1 => {
    //   // TODO err handler
    // });
  }

  remove() {
    let removeId;
    if (this._predefinePic && this._predefinePic.id) {
      removeId = this._predefinePic.id;
    } else if (this.addedFileId) {
      removeId = this.addedFileId;
    }

    //this.request = this.api.removeAttachment(removeId).subscribe(resp => {
    this.request = of(true).subscribe(resp => {
      const attach: AttachmentModel = null;
      this.file = null;
      this.invalidFiles = null;
      this.imageSrc = null;
      this.predefinePic = attach;
      this._predefinePic = attach;
      this.addedFileId = null;

      this.fileName = null;
      this.loadedBytes = null;
      this.totalByte = null;
      this.state = this.statePending;

      this.success.emit(attach);

      if (this.onChange) {
        this.onChange(attach);
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  cancel() {
    this.processing.emit(false);
    if (this.request) {
      this.request.unsubscribe();

      this.state = UploadState.Pending;
      this.progress = 0;
      this.loadedBytes = 0;
    }
  }

  open() {
    const input: HTMLInputElement =  this.fileInputElement.nativeElement;
    input.click();
  }

  writeValue(attachment: AttachmentModel): void {
    this._predefinePic = attachment;
    if (this._predefinePic && this._predefinePic.id) {
      if (this._predefinePic.meta && this._predefinePic.meta.content_type === 'image/svg+xml') {
        this.subs.add(this.svgService.getSvg(this._predefinePic.file_url as string)
          .pipe(map(svgText => {
            svgText = (svgText as any).changingThisBreaksApplicationSecurity;
            let parser = new DOMParser();
            let svg = parser.parseFromString(String(svgText), 'image/svg+xml');
            let serializedSvg = new XMLSerializer().serializeToString(svg);
            return this.sanitizer.bypassSecurityTrustHtml(serializedSvg);
          })).subscribe(result => {
          this.svgContent = result;
        }));
      } else {
        this.svgContent = null;
      }

      this.addedFileId = this._predefinePic.id;
      this.imageSrc = this._predefinePic.file_url;
      this.state = UploadState.Done;
    } else {
      this.addedFileId = 0;
      this.imageSrc = null;
      this.state = UploadState.Pending;

      // force reset file input
      const fileInput: HTMLInputElement = this.fileInputElement.nativeElement;
      fileInput.value = '';
      fileInput.type = '';
      fileInput.type = 'file';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  openFullResolution(): void {
    window.open(this.imageSrc as string, '_blank');
  }

}

import { createAction, props } from '@ngrx/store';

import { ExhibitorModel } from '@components/exhibitor/models/exhibitor.model';
import { ExhibitorTranslationModel } from '@components/exhibitor/models/exhibitor-translation.model';
import { ExhibitorContactPersonModel } from '@components/exhibitor/models/exhibitor-contact-person.model';
import { ExhibitorContactPersonTranslationModel } from '@components/exhibitor/models/exhibitor-contact-person-translation.model';

export const modalCreateExhibitor = createAction(
  '[Exhibitor/Exhibitor Modal] Modal Create Exhibitor',
  props<{ exhibitor: ExhibitorModel, translations: ExhibitorTranslationModel[], contacts: ExhibitorContactPersonModel[], translations2: ExhibitorContactPersonTranslationModel[][] }>()
);

export const modalCreateExhibitorSuccess = createAction(
  '[Exhibitor/API] Modal Create Exhibitor Success',
  props<{ exhibitor: ExhibitorModel, contacts: ExhibitorContactPersonModel[] }>()
);

export const modalCreateExhibitorFailure = createAction(
  '[Exhibitor/API] Modal Create Exhibitor Failure',
  props<{ error: Error }>()
);

export const modalUpdateExhibitor = createAction(
  '[Exhibitor/Exhibitor Modal] Modal Update Exhibitor',
  props<{ exhibitor: ExhibitorModel, translations: ExhibitorTranslationModel[], contacts: ExhibitorContactPersonModel[], translations2: ExhibitorContactPersonTranslationModel[][] }>()
);

export const modalUpdateExhibitorSuccess = createAction(
  '[Exhibitor/API] Modal Update Exhibitor Success',
  props<{ exhibitor: ExhibitorModel, contacts: ExhibitorContactPersonModel[] }>()
);

export const modalUpdateExhibitorFailure = createAction(
  '[Exhibitor/API] Modal Update Exhibitor Failure',
  props<{ error: Error }>()
);

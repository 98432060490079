<div class="dropdown-item" (click)="onCheckChange($event)">
  <mat-checkbox
    class="mr-2"
    [checked]="checked"
    (click)="$event.stopPropagation()"
    (change)="onCheckChange()">
  </mat-checkbox>
  <div class="mt-1">
    <ng-content></ng-content>
  </div>
</div>
import { createAction, props } from '@ngrx/store';

import { VenueModel } from '@components/venue/models/venue.model';

export const loadVenues = createAction(
  '[Venue/Component] Load Venues',
);

export const loadVenuesRequest = createAction(
  '[Venue/Effect] Load Venues Request',
  props<{ componentId: number }>()
);

export const loadVenuesSuccess = createAction(
  '[Venue/API] Load Venues Success',
  props<{ componentId: number, venues: VenueModel[] }>()
);

export const loadVenuesFailure = createAction(
  '[Venue/API] Load Venues Failure',
  props<{ componentId: number, error: Error }>()
);

export const createVenue = createAction(
  '[Venue/Modal] Create Venue',
  props<{ venue: VenueModel }>()
);

export const createVenueSuccess = createAction(
  '[Venue/API] Create Venue Success',
  props<{ venue: VenueModel }>()
);

export const createVenueFailure = createAction(
  '[Venue/API] Create Venue Failure',
  props<{ error: Error }>()
);

export const updateVenue = createAction(
  '[Venue/Modal] Update Venue',
  props<{ venue: VenueModel }>()
);

export const updateVenueSuccess = createAction(
  '[Venue/API] Update Venue Success',
  props<{ venue: VenueModel }>()
);

export const updateVenueFailure = createAction(
  '[Venue/API] Update Venue Failure',
  props<{ error: Error }>()
);

export const deleteVenue = createAction(
  '[Venue/Modal] Delete Venue',
  props<{ venue: VenueModel }>()
);

export const deleteVenueSuccess = createAction(
  '[Venue/API] Delete Venue Success',
  props<{ venue: VenueModel }>()
);

export const deleteVenueFailure = createAction(
  '[Venue/API] Delete Venue Failure',
  props<{ error: Error }>()
);

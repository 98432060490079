import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, Update, createEntityAdapter } from '@ngrx/entity';

import { WwwResourceCategoryModel } from '@components/www-resource/models/www-resource.model';

import * as WwwResourceActions from '../actions/www-resource.actions';
import * as CategoryActions from '../actions/category.actions';
import { selectEvent } from '@store/features/event/actions';

export interface State extends EntityState<WwwResourceCategoryModel> {
  createdId: number;
  updatedId: number;
  activeId: number;
  error: Error;
}

export const categoryAdapter = createEntityAdapter<WwwResourceCategoryModel>({
  sortComparer: (a, b) => a.order - b.order,
});

export const initialCategoryState: State = categoryAdapter.getInitialState({
  createdId: 0,
  updatedId: 0,
  activeId: 0,
  error: null,
});

export const categoryReducer = createReducer(
  initialCategoryState,
  on(
    WwwResourceActions.loadWwwResourcesRequest,
    (state, { componentId }) => categoryAdapter.removeMany(cat => cat.componentId === componentId, state)
  ),
  on(
    CategoryActions.loadCategoriesSuccess,
    (state, { categories }) => categoryAdapter.addMany(categories, state)
  ),
  on(
    CategoryActions.createCategorySuccess,
    (state, { category }) => categoryAdapter.addOne(category, { ...state, createdId: category.id })
  ),
  on(
    CategoryActions.updateCategorySuccess,
    (state, { category }) => categoryAdapter.upsertOne(category, { ...state, updatedId: category.id })
  ),
  on(
    CategoryActions.reorderCategoriesInplace,
    (state, { categories }) => {
      const updates: Update<WwwResourceCategoryModel>[] = categories.map((exhibitor, order) => ({
        id: exhibitor.id, changes: { order }
      }));

      return categoryAdapter.updateMany(updates, state);
    }
  ),
  on(
    CategoryActions.reorderCategoriesSuccess,
    (state, { categories }) => categoryAdapter.upsertMany(categories, state)
  ),
  on(
    CategoryActions.deleteCategorySuccess,
    (state, { category }) => categoryAdapter.removeOne(category.id, state)
  ),
  on(
    CategoryActions.createCategoryFailure,
    CategoryActions.updateCategoryFailure,
    CategoryActions.reorderCategoriesFailure,
    CategoryActions.deleteCategoryFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(
    CategoryActions.selectCategory,
    (state, { category }) => ({ ...state, activeId: category ? category.id : null })
  ),

  on(
    selectEvent,
    () => ({ ...initialCategoryState }),
  )
);

export function reducer(state: State, action: Action): State {
  return categoryReducer(state, action);
}

const {
  selectIds,
  selectEntities,
  selectAll,
} = categoryAdapter.getSelectors();

export const selectCategoryIds = selectIds;

export const selectCategoryEntities = selectEntities;

export const selectAllCategories = selectAll;

export const selectCategories = createSelector(
  selectAllCategories,
  (categories: WwwResourceCategoryModel[], { componentId }: { componentId: number }) =>
    categories.filter(cat => cat.componentId === componentId)
);

export const selectCreatedCategoryId = (state: State) => state.createdId;

export const selectCreatedCategory = createSelector(
  selectCategoryEntities,
  selectCreatedCategoryId,
  (categories, activeId) => categories[activeId]
);

export const selectUpdatedCategoryId = (state: State) => state.updatedId;

export const selectUpdatedCategory = createSelector(
  selectCategoryEntities,
  selectUpdatedCategoryId,
  (categories, activeId) => categories[activeId]
);

export const selectActiveCategoryId = (state: State) => state.activeId;

export const selectActiveCategory = createSelector(
  selectCategoryEntities,
  selectActiveCategoryId,
  (categories, activeId) => categories[activeId]
);

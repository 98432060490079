import {Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ComponentPlanFacadeService } from '@store/features/component/component-plan-facade.service';

@Injectable({
    providedIn: 'root'
})

export class LangService {

    private readonly lcKey = 'cl_lng';
    // short name of client lang -> its name of item in localStore
    // to do -> backend local client lang in profile obj -> implementation language store

    constructor(
        private translate: TranslateService,
        private componentPlanFacade: ComponentPlanFacadeService,
    ) {}

    langInit(): void {
        // this.setCurrentUserLang(UserLang.EN);
        const currentLang = this.getCurrentUserLang();
        if(currentLang) {
            this.setCurrentUserLang(currentLang);
        } else {
            this.setCurrentUserLang(UserLang.EN);
        }

        // here should be reloaded all translatable services
        this.translate.onLangChange.subscribe(() => {
            this.componentPlanFacade.loadComponentPlans();
        });
    }

    getCurrentUserLang(): UserLang {
        const currentLang = localStorage.getItem(this.lcKey);
        if(this.isLangValid(currentLang)) {
            return currentLang as UserLang;
        }
        // try to get user lang from browser
        const userLang = navigator.language.toLowerCase().slice(0, 2);
        return this.isLangValid(userLang) ? userLang as UserLang : UserLang.EN;
    }

    setCurrentUserLang(lang: UserLang): void {
        localStorage.setItem(this.lcKey, lang);
        this.translate.use(lang);
    }

    isLangValid(toValidate: string): boolean {
        return Object.values(UserLang).includes(toValidate as UserLang);
    }
}

export enum UserLang {
    EN = 'en',
    PL = 'pl'
}
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { RouterAccountView, RouterAuthorizedView, RouterMainView, RouterProviderService, RouterQueryParams } from "@shared/providers/router-provider.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ParamsRequiredGuard implements CanActivate {
  constructor(
    private routerProvider: RouterProviderService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const requiredParams: RouterQueryParams[] = route.data.requiredParams as RouterQueryParams[];
    
    if (!requiredParams || requiredParams.length === 0) {
      return true;
    }


    const urlParams = state.root.queryParams;

    for (const param of requiredParams) {
      if (!urlParams[param]) {
        const currentMainView = this.routerProvider.getRouterMainView(state.url);
        if (currentMainView === RouterMainView.ACCOUNT) {
          this.routerProvider.toAccountView(RouterAccountView.LOGIN);
        } else if (RouterMainView.BACKEND) {
          this.routerProvider.toAuthorizedView(RouterAuthorizedView.DASHBOARD);
        }
        return false;
      }
    }

    return true;
  }
}
import { Action, createReducer, on } from '@ngrx/store';

import * as SpeakerModalActions from '../actions/speaker-modal.actions';

export const stateKey = 'speakerModal';

export interface State {
  updating: boolean;
  updatedId: number;
  error: Error;
}

export const initialState: State = {
  updating: false,
  updatedId: 0,
  error: null,
};

export const speakerModalReducer = createReducer(
  initialState,
  on(
    SpeakerModalActions.modalCreateSpeaker,
    SpeakerModalActions.modalUpdateSpeaker,
    state => ({ ...state, updating: true })
  ),
  on(
    SpeakerModalActions.modalCreateSpeakerSuccess,
    SpeakerModalActions.modalUpdateSpeakerSuccess,
    (state, { speaker }) => ({ ...state, updating: false, updatedId: speaker.id })
  ),
  on(
    SpeakerModalActions.modalCreateSpeakerFailure,
    SpeakerModalActions.modalUpdateSpeakerFailure,
    (state, { error }) => ({ ...state, updating: false, error })
  ),
);

export function reducer(state: State, action: Action): State {
  return speakerModalReducer(state, action);
}

export const selectSpeakerModalUpdating = (state: State) => state.updating;

export const selectUpdatedSpeakerModalId = (state: State) => state.updatedId;

export const selectSpeakerModalError = (state: State) => state.error;

import { createAction, props } from '@ngrx/store';

import { PartnerModel } from '@components/partner/models/partner.model';
import { PartnerTranslationModel } from '@components/partner/models/partner-translation.model';

export const loadPartnerTranslation = createAction(
  '[Partner/Partner Modal] Load Partner Translation',
  props<{ partner: PartnerModel, language: string }>()
);

export const loadPartnerTranslationSuccess = createAction(
  '[Partner/API] Load Partner Translation Success',
  props<{ partner: PartnerModel, translation: PartnerTranslationModel }>()
);

export const loadPartnerTranslationFailure = createAction(
  '[Partner/API] Load Partner Translation Failure',
  props<{ partner: PartnerModel, error: Error }>()
);

export const updatePartnerTranslations = createAction(
  '[Partner/Partner Modal] Update Partner Translations',
  props<{ partner: PartnerModel, translations: PartnerTranslationModel[] }>()
);

export const updatePartnerTranslationsSuccess = createAction(
  '[Partner/API] Update Partner Translations Success',
  props<{ partner: PartnerModel, translations: PartnerTranslationModel[] }>()
);

export const updatePartnerTranslationsFailure = createAction(
  '[Partner/API] Update Partner Translations Failure',
  props<{ partner: PartnerModel, error: Error }>()
);

export const clearPartnerTranslations = createAction(
  '[Partner/Partner Delete] Clear Partner Translations',
  props<{ partner: PartnerModel }>()
);

import { BookingDay } from '@components/booking/store/models/booking-session.model';
import { createAction } from "@ngrx/store";

const loadBookingDays = createAction('[Booking Day] Load Booking Days');
const loadBookingDaysSuccess = createAction('[Booking Day] Load Booking Days Success', (bookingDays: BookingDay[]) => ({ bookingDays }));
const loadBookingDaysFailure = createAction('[Booking Day] Load Booking Days Failure', (error: any) => ({ error }));

const addBookingDay = createAction('[Booking Day] Add Booking Day', (bookingDay: BookingDay) => ({ bookingDay }));
const addBookingDaySuccess = createAction('[Booking Day] Add Booking Day Success', (bookingDay: BookingDay) => ({ bookingDay }));
const addBookingDayFailure = createAction('[Booking Day] Add Booking Day Failure', (error: any) => ({ error }));

const updateBookingDay = createAction('[Booking Day] Update Booking Day', (bookingDay: BookingDay) => ({ bookingDay }));
const updateBookingDaySuccess = createAction('[Booking Day] Update Booking Day Success', (bookingDay: BookingDay) => ({ bookingDay }));
const updateBookingDayFailure = createAction('[Booking Day] Update Booking Day Failure', (error: any) => ({ error }));

const deleteBookingDay = createAction('[Booking Day] Delete Booking Day', (bookingDay: BookingDay) => ({ bookingDay }));
const deleteBookingDaySuccess = createAction('[Booking Day] Delete Booking Day Success', (bookingDay: BookingDay) => ({ bookingDay }));
const deleteBookingDayFailure = createAction('[Booking Day] Delete Booking Day Failure', (error: any) => ({ error }));

const setBookingDayActiveId = createAction('[Booking Day] Set BookingDay Active Id', (id: number) => ({ id }));

export const bookingDayActions = {
    loadBookingDays,
    loadBookingDaysSuccess,
    loadBookingDaysFailure,
    addBookingDay,
    addBookingDaySuccess,
    addBookingDayFailure,
    updateBookingDay,
    updateBookingDaySuccess,
    updateBookingDayFailure,
    deleteBookingDay,
    deleteBookingDaySuccess,
    deleteBookingDayFailure,
    setBookingDayActiveId,
}
import { Action, ActionReducerMap, createFeatureSelector, createSelector, combineReducers } from '@ngrx/store';

import * as fromInteractiveMaps from './interactive-map.reducer';
import * as fromLocations from './location.reducer';
import * as fromEditor from './editor.reducer';

export const featureKey = 'interactiveMapComponent';

export interface State {
  [fromInteractiveMaps.stateKey]: fromInteractiveMaps.State;
  [fromLocations.stateKey]: fromLocations.State;
  [fromEditor.stateKey]: fromEditor.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromInteractiveMaps.stateKey]: fromInteractiveMaps.reducer,
  [fromLocations.stateKey]: fromLocations.reducer,
  [fromEditor.stateKey]: fromEditor.reducer,
};

export const combinedReducers = combineReducers(reducers);

export function reduce(state: State, action: Action): State {
  return combinedReducers(state, action);
}

export const selectFeature = createFeatureSelector<State>(featureKey);

export const selectInteractiveMapState = createSelector(
  selectFeature,
  state => state[fromInteractiveMaps.stateKey]
);

export const selectInteractiveMapLoading = createSelector(
  selectInteractiveMapState,
  fromInteractiveMaps.selectInteractiveMapLoading
);

export const selectInteractiveMapLoaded = createSelector(
  selectInteractiveMapState,
  fromInteractiveMaps.selectInteractiveMapLoaded
);

export const selectInteractiveMapUpdating = createSelector(
  selectInteractiveMapState,
  fromInteractiveMaps.selectInteractiveMapUpdating
);

export const selectInteractiveMapError = createSelector(
  selectInteractiveMapState,
  fromInteractiveMaps.selectInteractiveMapError
);

export const selectAllInteractiveMaps = createSelector(
  selectInteractiveMapState,
  fromInteractiveMaps.selectAllInteractiveMaps
);

export const selectUpdatedInteractiveMap = createSelector(
  selectInteractiveMapState,
  fromInteractiveMaps.selectUpdatedInteractiveMap
);

export const selectLocationState = createSelector(
  selectFeature,
  state => state[fromLocations.stateKey]
);

export const selectAllLocations = createSelector(
  selectLocationState,
  fromLocations.selectAllLocations
);

export const selectInteractiveMapLocations = () => createSelector(
  selectLocationState,
  fromLocations.selectInteractiveMapLocations()
);

// Editor selectors
export const selectEditorState = createSelector(
  selectFeature,
  state => state[fromEditor.stateKey]
);

export const selectEditorAllLocations = createSelector(
  selectEditorState,
  fromEditor.selectAllLocations
);

export const selectEditorImage = createSelector(
  selectEditorState,
  fromEditor.selectImage
);

export const selectEditorActiveTool = createSelector(
  selectEditorState,
  fromEditor.selectActiveTool
);

export const selectUpdatedLocation = createSelector(
  selectEditorState,
  fromEditor.selectUpdatedLocation
);

export const selectEditorActiveLocation = createSelector(
  selectEditorState,
  fromEditor.selectActiveLocation
);

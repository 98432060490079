import { createFeatureSelector, createSelector } from '@ngrx/store';

import { tokenFeatureKey } from './token.reducer';
import { TokenState, TokenStatus } from './token.state';

const selectTokenFeature = createFeatureSelector<TokenState>(tokenFeatureKey);

const selectTokenStatus = createSelector(selectTokenFeature, state => state.tokenStatus);
export const selectIsAuthenticated = createSelector(selectTokenStatus, s => s === TokenStatus.Authenticated);
export const selectIsRetrieving = createSelector(selectTokenStatus, s => s === TokenStatus.Retrieving);
export const selectIsRefreshing = createSelector(selectTokenStatus, s => s === TokenStatus.Refreshing);

export const selectToken = createSelector(selectTokenFeature, state => state.token);
export const selectError = createSelector(selectTokenFeature, state => state.error);

import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromLanguage from './language.reducer';

export const featureKey = 'languageFeature';

export { State, initialState, reducer } from './language.reducer';

export const selectLanguageFeature = createFeatureSelector<fromLanguage.State>(featureKey);

export const selectLanguageEntitites = createSelector(
  selectLanguageFeature,
  fromLanguage.selectLanguageEntities
);

export const selectAllLanguages = createSelector(
  selectLanguageFeature,
  fromLanguage.selectAllLanguages
);

export const selectLanguageLoading = createSelector(
  selectLanguageFeature,
  fromLanguage.selectLanguageLoading
);

export const selectLanguageLoaded = createSelector(
  selectLanguageFeature,
  fromLanguage.selectLanguageLoaded
);

export const selectLanguageError = createSelector(
  selectLanguageFeature,
  fromLanguage.selectLanguageError
);

export const selectLanguageNames = createSelector(
  selectAllLanguages,
  languages => languages.reduce((acc, l) => ({ ...acc, [l.code]: l.name }), {})
);

import { createAction, props } from '@ngrx/store';

import { ComponentModel } from '@shared/models/component.model';
import { PartnerModel } from '@components/partner/models/partner.model';

export const loadPartners = createAction(
  '[Partner/Partner Component] Load Partners',
  props<{ component: ComponentModel }>()
);

export const loadPartnersSuccess = createAction(
  '[Partner/API] Load Partners Success',
  props<{ component: ComponentModel, partners: PartnerModel[] }>()
);

export const loadPartnersFailure = createAction(
  '[Partner/API] Load Partners Failure',
  props<{ component: ComponentModel, error: Error }>()
);

export const createPartner = createAction(
  '[Partner/Partner Modal] Create Partner',
  props<{ component: ComponentModel, partner: PartnerModel }>()
);

export const createPartnerSuccess = createAction(
  '[Partner/API] Create Partner Success',
  props<{ component: ComponentModel, partner: PartnerModel }>()
);

export const createPartnerFailure = createAction(
  '[Partner/API] Create Partner Failure',
  props<{ component: ComponentModel, error: Error }>()
);

export const updatePartner = createAction(
  '[Partner/Partner Modal] Update Partner',
  props<{ component: ComponentModel, partner: PartnerModel }>()
);

export const updatePartnerSuccess = createAction(
  '[Partner/API] Update Partner Success',
  props<{ component: ComponentModel, partner: PartnerModel }>()
);

export const updatePartnerFailure = createAction(
  '[Partner/API] Update Partner Failure',
  props<{ component: ComponentModel, error: Error }>()
);

export const reorderPartners = createAction(
  '[Partner/Partner Component] Reorder Partners',
  props<{ component: ComponentModel, partners: PartnerModel[] }>()
);

export const reorderPartnersSuccess = createAction(
  '[Partner/API] Reorder Partners Success',
  props<{ component: ComponentModel, partners: PartnerModel[] }>()
);

export const reorderPartnersFailure = createAction(
  '[Partner/API] Reorder Partners Failure',
  props<{ component: ComponentModel, error: Error }>()
);

export const deletePartner = createAction(
  '[Partner/Partner Component] Delete Partner',
  props<{ component: ComponentModel, partner: PartnerModel }>()
);

export const deletePartnersSuccess = createAction(
  '[Partner/API] Delete Partners Success',
  props<{ component: ComponentModel, partner: PartnerModel }>()
);

export const deletePartnersFailure = createAction(
  '[Partner/API] Delete Partners Failure',
  props<{ component: ComponentModel, error: Error }>()
);

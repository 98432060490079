import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ComponentModel } from '@shared/models/component.model';
import { AccessGroupProviderService } from '@store/features/tags/access-group-provider.service';
import { TagsProviderService } from '@store/features/tags/tags-provider.service';
import { TagApiModel, TagType } from '@store/features/tags/tags.models';
import { sortByTagType } from '@utils/sort-by-tag-type.util';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { DropdownOption } from '../dropdown-with-search/dropdown-with-search.component';
import { EventFacadeService } from '@store/features/event/event-facade.service';
import { filter, first, map } from 'rxjs/operators';
import { USER_ROLE_PERMISSIONS, isUserRoleAllowed } from '@shared/constants/user-role-permissions';
import { TagDatabaseModalService } from '@components/tag-database/tag-database-modal.service';
import { ConfirmModalService } from '../confirm-modal/confirm-modal.service';
import { EventModel } from '@store/features/event/models/event.model';

@Component({
  selector: 'app-access-group-control',
  templateUrl: './access-group-control.component.html',
  styleUrls: ['./access-group-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccessGroupControlComponent),
      multi: true
    }
  ]
})
export class AccessGroupControlComponent implements OnInit, OnDestroy, ControlValueAccessor {

  // for control with is responsible for adding groups to component (not child) should be a true
  @Input() isComponentControl: boolean = false;

  @Input() skipParentValidation: boolean = false;
  @Input() event: EventModel;

  // component will be null if the view isn't connected with backend component, like application users
  // then we will operate only on entities (they are not in the relation with the component)
  @Input() component: ComponentModel | null = null;

  // skip dialog confirmation on adding child TAG which is not added to the component. Required in push notifications.
  @Input() componentConfirmationRequired = true;

  // custom placeholder
  @Input() placeholder: string | null = null;

  @Input() readonly: boolean = false;
  @Input() hideRemove: boolean = false;

  selectedTags: TagApiModel[];

  userGroups$: Observable<TagApiModel[]>;
  userGroupOptions$: Observable<DropdownOption[]>;
  isAllowedToAddParentGroups$: Observable<boolean>;
  componentAccessGroups$: Observable<TagApiModel[]>;

  private onChange: (values: TagApiModel[]) => void;
  private onTouched: () => void;

  private subs = new Subscription();

  constructor(
    private accessGroupProvider: AccessGroupProviderService,
    private tagsProvider: TagsProviderService,
    private i18n: TranslateService,
    private eventFacade: EventFacadeService,
    private tagDatabaseModal: TagDatabaseModalService,
    private confirmModal: ConfirmModalService,
  ) {
    this.selectedTags = [];
    if (!this.placeholder) {
      this.placeholder = this.i18n.instant('others.choose_user_groups');
    }
  }

  ngOnInit(): void {
    this.userGroups$ = this.tagsProvider.getUserGroupTags();
    this.componentAccessGroups$ = this.component ? this.accessGroupProvider.getComponentAccessGroupsById(this.component.id) : of([]);
    this.isAllowedToAddParentGroups$ = this.eventFacade.getActiveEvent()
      .pipe(
        filter(event => !!event),
        map(event => isUserRoleAllowed(event, USER_ROLE_PERMISSIONS.ADD_COMPONENT_ACCESS_GROUP)),
      );
    
    this.userGroupOptions$ = combineLatest([
      this.isAllowedToAddParentGroups$,
      this.componentAccessGroups$,
      this.userGroups$
    ]).pipe(map(([isAllowed, componentGroups, userGroups]) => {
      let tagsArray: TagApiModel[] = [];
      if (!isAllowed) {
        tagsArray = [...componentGroups];
      } else if (this.isComponentControl) {
        tagsArray = [...userGroups];
      } else if (this.component) {
        const systemComponentGroups = this.getTagsTypeSystem(componentGroups);
        tagsArray = [...systemComponentGroups, ...this.getTagsTypeUser(userGroups)]
      } else {
        tagsArray = [...userGroups];
      }
      tagsArray = [...tagsArray].sort(sortByTagType);
      return tagsArray.map(tag => ({ id: tag.id, value: tag, name: tag.value }));
    }));

    // to remove deleted event tags & handle changes tags values
    this.subs.add(this.userGroups$.subscribe(eventAccessGroups => {
      this.selectedTags.forEach(selectedAccessGroup => {
        if (!eventAccessGroups.some(g => g.id === selectedAccessGroup.id)) {
          this.removeTag(selectedAccessGroup);
        } else {
          const savedAccessGroup = eventAccessGroups.find(g => g.id === selectedAccessGroup.id);
          if (savedAccessGroup === undefined) return; // it shouldn't happend
          if (savedAccessGroup.hex !== selectedAccessGroup.hex || savedAccessGroup.value !== selectedAccessGroup.value) {
            this.selectedTags = [...this.selectedTags.filter(t => t.id !== selectedAccessGroup.id), savedAccessGroup];
          }
        }
      });
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  tagSelected(option: DropdownOption): void {
    if (option === null) return;
    this.addTag(option.value);
  }

  addTag(tag: TagApiModel) {
    if (!this.hasTag(tag)) {
      if (this.isComponentControl || !this.component) {
        this.handleAddingTagConfirmed(tag);
        return;
      }
      this.subs.add(this.componentAccessGroups$.pipe(first()).subscribe(componentAccessGroups => {
        const isGroupExistInComponent = componentAccessGroups.some(group => group.id === tag.id);
        if (!isGroupExistInComponent && this.componentConfirmationRequired) {
          this.addTagConfirmationRequired(tag);
        } else {
          this.handleAddingTagConfirmed(tag);
        }
      }));
    }
  }

  onTagsEdit(): void {
    this.tagDatabaseModal.openEditDialog();
  }

  removeTag(tag: TagApiModel) {
    const tagIndex = this.findTagIndex(tag);

    if (tagIndex !== -1) {
      this.selectedTags = [...this.selectedTags];
      this.selectedTags.splice(tagIndex, 1);
      this.sortTags();
      this.propagateChanges();
    }
  }

  writeValue(selectedTags: TagApiModel[]): void {
    if (Array.isArray(selectedTags)) {
      this.selectedTags = selectedTags;
    } else {
      this.selectedTags = [];
    }

    this.sortTags();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private hasTag(tag: TagApiModel) {
    return (this.findTagIndex(tag) !== -1);
  }

  private findTagIndex(tag: TagApiModel) {
    if (!tag) return;
    return this.selectedTags.findIndex(
      selectedTag => (selectedTag.id === tag.id)
    );
  }

  private propagateChanges() {
    if (this.onChange) {
      this.onChange(this.selectedTags);
    }
  }

  private sortTags(): void {
    this.selectedTags = [...this.selectedTags].sort(sortByTagType);
  }

  private getTagsTypeSystem(tags: TagApiModel[]): TagApiModel[] {
    return tags.filter(t => t.type_tag === TagType.System);
  }

  private getTagsTypeUser(tags: TagApiModel[]): TagApiModel[] {
    return tags.filter(t => t.type_tag === TagType.User);
  }

  private addTagConfirmationRequired(tag: TagApiModel): void {
    this.subs.add(this.confirmModal.open({
      title: this.i18n.instant('application_tags.adding_child_tag_confirm_title'),
      desc: this.i18n.instant('application_tags.adding_child_tag_confirm_desc')
    }, false).afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.accessGroupProvider.addComponentAccessGroup(this.event, this.component, tag);
        this.handleAddingTagConfirmed(tag);
      }
    }));
  }

  private handleAddingTagConfirmed(tag: TagApiModel): void {
    this.selectedTags = [...this.selectedTags, tag];
    this.sortTags();
    this.propagateChanges();
  }

}

import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class FormValidateService {
  get errors(): ValidationErrors {
    return this.errors$.value;
  }

  private errors$: BehaviorSubject<ValidationErrors>;

  constructor() {
    this.errors$ = new BehaviorSubject({});
  }

  setErrors(errors: ValidationErrors) {
    this.errors$.next(errors);
  }

  getErrors(): Observable<ValidationErrors> {
    return this.errors$.asObservable();
  }
}

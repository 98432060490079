import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CollectionBaseService } from './collection-base.service';

import { CurrencyModel } from '@shared/models/currency.model';

@Injectable({
  providedIn: 'root'
})
export class CurrencyStoreService extends CollectionBaseService<CurrencyModel> {
  constructor() {
    super([]);
  }

  getCurrencyByCode(code: string): Observable<CurrencyModel> {
    return this.getCurrencies().pipe(
      map(currencies => currencies.find(currency => currency.code === code))
    );
  }

  getSortedCurrencies(): Observable<CurrencyModel[]> {
    const sortCurrenciesByCode = (currencies: CurrencyModel[]) => {
      return currencies.sort((a, b) => a.code.localeCompare(b.code));
    };

    return this.getCurrencies().pipe(
      map(currencies => sortCurrenciesByCode(currencies))
    );
  }

  getCurrencies(): Observable<CurrencyModel[]> {
    return this.getEntities();
  }

  updateEntity(entity: CurrencyModel): void {
    super.updateEntity(entity, (a, b) => (a.code === b.code));
  }

  removeEntity(entity: CurrencyModel): void {
    super.removeEntity(entity, (a, b) => (a.code === b.code));
  }
}

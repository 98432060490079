import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { EventModel } from './models/event.model';
import { EventLanguageModel } from './models/event-language.model';

import * as EventLanguageActions from './actions/event-language.actions';
import * as fromEvent from './reducers';

@Injectable({
  providedIn: 'root'
})
export class EventLanguageFacadeService {
  constructor(private store$: Store<any>) {}

  getAllEventLanguages(): Observable<EventLanguageModel[]> {
    return this.store$.pipe(select(fromEvent.selectAllEventLanguages));
  }

  getAllEventLanguagesWithNames(): Observable<EventLanguageModel[]> {
    return this.store$.pipe(select(fromEvent.selectAllEventLanguagesWithNames));
  }

  getDefaultEventLanguage(): Observable<EventLanguageModel> {
    return this.store$.pipe(select(fromEvent.selectDefaultEventLanguage));
  }

  getDefaultEventLanguageWithName(): Observable<EventLanguageModel> {
    return this.store$.pipe(select(fromEvent.selectDefaultEventLanguageWithName));
  }

  getEventLanguageLoading(): Observable<boolean> {
    return this.store$.pipe(select(fromEvent.selectEventLanguageLoading));
  }

  loadEventLanguages(event: EventModel) {
    return this.store$.dispatch(EventLanguageActions.loadEventLanguages({ event }));
  }
}

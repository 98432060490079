import { createAction, props } from '@ngrx/store';

import { EventTranslationModel } from '../models/event-translation.model';

export const loadEventTranslation = createAction(
  '[Event/Basic Info] Load Event Translation',
  props<{ language: string }>()
);

export const loadEventTranslationSuccess = createAction(
  '[Event/API] Load Event Translation Success',
  props<{ translation: EventTranslationModel }>()
);

export const loadEventTranslationFailure = createAction(
  '[Event/API] Load Event Translation Failure',
  props<{ error: Error }>()
);

import { Subscription, combineLatest } from 'rxjs';
import { ConfirmModalService } from '@shared/components/confirm-modal/confirm-modal.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import { Observable, of, from } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';

import { ConstructFacadeService } from '@store/features/construct/construct-facade.service';
import { EventFacadeService } from '@store/features/event/event-facade.service';
import { ComponentFacadeService } from '@store/features/component/component-facade.service';
import { ComponentService } from '@shared/providers/component.service';
import { ComponentModalService } from '@components/component/providers/component-modal.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel, FirstToOpenMode } from '@shared/models/component.model';

import { nextTick } from '@utils/next-tick.util';
import { TranslateService } from '@ngx-translate/core';
import { ComponentNames } from '@utils/component-names.util';
import { Intercom } from '@utils/intercom.util';
import { LangService } from '@shared/providers/lang.service';
import { USER_ROLE_PERMISSIONS, isUserRoleAllowed, isUserRoleComponentHidden } from '@shared/constants/user-role-permissions';
import { extractErrorFrom } from '@utils/errors.util';
import { RouterProviderService } from '@shared/providers/router-provider.service';

export const tagDatabaseComponent: ComponentModel = {
  id: -2,
  eventId: null,
  order: -2,
  defaultComponent: false,
  visibleInApp: false,
  uuid: '',
  additionalInfo: null,
  label: 'Tag Database',
  componentName: ComponentNames.TAG_DATABASE,
  iconId: -4,
  firstToOpenMode: FirstToOpenMode.Disabled,
};

export const couponsComponent: ComponentModel = {
  id: -3,
  eventId: null,
  order: -3,
  defaultComponent: false,
  visibleInApp: false,
  uuid: '',
  additionalInfo: null,
  label: 'Coupons',
  componentName: ComponentNames.COUPONS,
  iconId: -4,
  firstToOpenMode: FirstToOpenMode.Disabled,
};

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, OnDestroy {

  public Intercom = Intercom;

  event$: Observable<EventModel>;

  builtInComponents$: Observable<ComponentModel[]>;
  components$: Observable<ComponentModel[]>;
  activeComponentId$: Observable<number>;

  isPaid$: Observable<boolean>;
  isNotAllowed$: Observable<boolean>;

  currentComponent = {};

  error$: Observable<string>;

  private subs = new Subscription();

  get langKey(): string {
    const lang = this.langService.getCurrentUserLang();
    return lang === 'en' || lang === 'pl' ? lang : 'en';
  }

  constructor(
    private constructorFacade: ConstructFacadeService,
    private eventFacade: EventFacadeService,
    private componentFacade: ComponentFacadeService,
    private componentService: ComponentService,
    private componentModal: ComponentModalService,
    private i18n: TranslateService,
    private confirmModal: ConfirmModalService,
    private langService: LangService,
    private routerProvider: RouterProviderService,
  ) {
    this.error$ = this.componentFacade.getComponentError().pipe(
      map(error => error ? extractErrorFrom(error) : null)
    );
  }

  ngOnInit() {
    this.event$ = this.eventFacade.getActiveEvent();
    this.isNotAllowed$ = this.event$.pipe(
      map(event => !isUserRoleAllowed(event, USER_ROLE_PERMISSIONS.REORED_COMPONENTS_LIST))
    );

    this.builtInComponents$ = this.event$
      .pipe(
        map(event => {
          let builtInComponents = [];
          [tagDatabaseComponent, { ...couponsComponent, label: this.i18n.instant('coupons.comp_title') }].forEach(builtInComp => {
            !isUserRoleComponentHidden(event, builtInComp.componentName) && builtInComponents.push(builtInComp);
          });
          return builtInComponents;
        }),
      );

    this.components$ = combineLatest([this.event$, this.componentService.getComponents()])
        .pipe(
          map(([event, components]) => {
            let accessedComponents = [];
            components.forEach(comp => {
              !isUserRoleComponentHidden(event, comp.componentName) && accessedComponents.push(comp);
            });
            return accessedComponents;
          })
        );

    this.activeComponentId$ = this.componentFacade
      .getActiveComponentId()
      .pipe(switchMap(result => from(Promise.resolve(result))));

    this.isPaid$ = this.event$.pipe(map(event => event?.paid));

    nextTick(() => this.constructorFacade.setCurrentStep(4));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getComponentRouterArray(comp: ComponentModel): string[] {
    return this.routerProvider.getEventConstructorComponentRouterArray(comp);
  }

  onComponentEdit(component: ComponentModel) {
    if (component && component.id) {
      this.event$
        .pipe(take(1))
        .subscribe(event =>
          this.componentModal.openComponentModal(event, component)
        );
    }
  }

  onComponentToggleVisibility(ev: Event, component: ComponentModel) {
    this.componentService
      .setComponentVisibility(component, !component.visibleInApp)
      .subscribe({
        error: err =>
          console.error('Failed to change component visibility', err),
      });
  }

  onComponentDelete(ev: Event, component: ComponentModel) {
    this.subs.add(this.confirmModal.open({
      desc: this.i18n.instant('content_step.confirm')
    }).afterClosed().subscribe(
        result => {
          if(result) {
            this.componentService.deleteComponent(component).subscribe({
              error: err => console.error('Failed to delete component', err),
            });
          }
        }
    ));
  }

  onComponentReorder(ev: CdkDragDrop<any>) {
    const from = ev.previousIndex;
    const to = ev.currentIndex;

    if (from !== to) {
      this.componentService.reorderComponent(from, to).subscribe({
        error: err => console.error('Failed to reorder components', err),
      });
    }
  }

  trackByComponentId(index: number, component: ComponentModel) {
    return component.id;
  }
}

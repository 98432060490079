import { createAction, props } from '@ngrx/store';

import { ExhibitorModel } from '@components/exhibitor/models/exhibitor.model';
import { ExhibitorContactPersonModel } from '@components/exhibitor/models/exhibitor-contact-person.model';

export const fillExhibitorContactPeople = createAction(
  '[Exhibitor/API] Fill Exhibitor Contact People',
  props<{ exhibitors: ExhibitorModel[], contacts: ExhibitorContactPersonModel[] }>()
);

export const loadExhibitorContactPeople = createAction(
  '[Exhibitor/Exhibitor Component] Load Exhibitor Contact People',
  props<{ exhibitor: ExhibitorModel }>()
);

export const loadExhibitorContactPeopleSuccess = createAction(
  '[Exhibitor/API] Load Exhibitor Contact People Success',
  props<{ exhibitor: ExhibitorModel, contacts: ExhibitorContactPersonModel[] }>()
);

export const loadExhibitorContactPeopleFailure = createAction(
  '[Exhibitor/API] Load Exhibitor Contact People Failure',
  props<{ exhibitor: ExhibitorModel, error: Error }>()
);

export const diffExhibitorContactPeople = createAction(
  '[Exhibitor/Exhibitor Component] Diff Exhibitor Contact People',
  props<{ exhibitor: ExhibitorModel, contacts: ExhibitorContactPersonModel[] }>()
);

export const diffExhibitorContactPeopleSuccess = createAction(
  '[Exhibitor/API] Diff Exhibitor Contact People Success',
  props<{ exhibitor: ExhibitorModel, contacts: ExhibitorContactPersonModel[] }>()
);

export const diffExhibitorContactPeopleFailure = createAction(
  '[Exhibitor/API] Diff Exhibitor Contact People Failure',
  props<{ exhibitor: ExhibitorModel, error: Error }>()
);

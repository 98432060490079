export function randomPastelColor(): [number, number, number] {
  const base = Math.random();
  const newL = Math.floor(Math.random() * 16) + 75;

  return hslToRgb(base, 1, newL * 0.01);
}

export function rgbToHex(r: number, g: number, b: number) {
  return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
}

export function hslToRgb(h: number, s: number, l: number): [number, number, number] {
  let r: number, g: number, b: number;

  const rd = (a: number) => {
    return Math.floor(Math.max(Math.min(a * 256, 255), 0));
  };

  const hueToRGB = (m: number, n: number, o: number) => {
    if (o < 0.0) { o += 1.0; }
    if (o > 1.0) { o -= 1.0; }
    if (o < 1.0 / 6.0) { return m + (n - m) * 6.0 * o; }
    if (o < 1.0 / 2.0) { return n; }
    if (o < 2.0 / 3.0) { return m + (n - m) * (2.0 / 3.0 - o) * 6.0; }
    return m;
  }

  const q = l < 0.5 ? l * (1.0 + s) : l + s - l * s;
  const p = 2.0 * l - q;

  r = hueToRGB(p, q, h + 1.0 / 3.0);
  g = hueToRGB(p, q, h);
  b = hueToRGB(p, q, h - 1.0 / 3.0);

  return [rd(r), rd(g), rd(b)];
}

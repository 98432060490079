import { createAction, props } from "@ngrx/store";
import { ComponentModel } from "@shared/models/component.model";
import { ComponentUtilsPatchType, ComponentUtilsType } from "../models/component-utils.model";
import { EventLanguageModel } from "@store/features/event/models/event-language.model";

export const loadComponentUtils = createAction(
  '[Component/Utils] Load Component Utils',
  props<{ component: ComponentModel, lang: EventLanguageModel }>(),
);

export const loadComponentUtilsSuccess = createAction(
  '[Component/Utils] Load Component Utils Success',
  props<{ component: ComponentModel, lang: EventLanguageModel, utils: ComponentUtilsType }>(),
);

export const loadComponentUtilsFailure = createAction(
  '[Component/Utils] Load Component Utils Failure',
  props<{ error: Error }>(),
);

export const patchComponentUtils = createAction(
  '[Component/Utils] Patch Component Utils',
  props<{ component: ComponentModel, lang: EventLanguageModel, utils: ComponentUtilsPatchType }>(),
);

export const patchComponentUtilsSuccess = createAction(
  '[Component/Utils] Patch Component Utils Success',
  props<{ component: ComponentModel, lang: EventLanguageModel, utils: ComponentUtilsType }>(),
);

export const patchComponentUtilsFailure = createAction(
  '[Component/Utils] Patch Component Utils Failure',
  props<{ error: Error }>(),
);

export const clearComponentUtilsState = createAction(
  '[Component/Utils] Clear Component Utils State',
);
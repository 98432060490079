<div class="toast-message__wall">
  <div *ngFor="let message of messages" class="toast-message__container slide-in-right" (click)="navigate(message)">
    <div class="toast-message__content">
      <div class="toast-message__header" [class]="message.type">
        <div class="toast-message__img" *ngIf="message.img">
          <img [src]="message.img" alt="">
        </div>
        <div class="toast-message__title">
          <h4>{{ message.title }}</h4>
        </div>

        <button mat-button mat-dialog-close class="close" type="button" (click)="handleMessageDelete(message)">
          <img src="/assets/img/icons/delete-cross-black.svg" alt="Close message">
        </button>
      </div>
      <p>{{ message.content }}</p>
    </div>
  </div>
</div>
export enum TagType {
  System = 'system_generated',
  User = 'user_generated'
}

export enum TagGroup {
  UserGroup = 'tag_user_groups',
  User = 'tag_users',
  AgendaPlace = 'tag_agenda_places',
  AgendaPath = 'tag_agenda_paths',
  TagEvents = 'tag_events',
  ExhibitorTag = 'tag_exhibitors',
}

export class TagApiModel {
  id?: number;
  event_id?: number;
  additional_value?: string;
  type_tag?: TagType;
  value: string;
  hex: string;
  order?: number;
  subtype_tag: TagSubType | null;
}

export class UserGroupTag {
  [key: string]: TagApiModel[];
}

export class TagPatchModel {
  value: string;
  hex_color: string;
}

export class TagPostModel {
  value: string;
  hex_color: string;
  type_tag: TagGroup;
}

export class UserGroupManage {
  tag_id: number;
  user_identity_token: string;
}

export class UserGroupAddMulti {
  user_identity_tokens: string[];
  tag_ids: number[];
}

export enum TagSubType {
  All = 'all',
  LoggedIn = 'logged_in',
  NotLoggedIn = 'not_logged_in',
  NoTicket = 'no_ticket',
}
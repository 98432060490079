import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, forwardRef, OnDestroy, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TagApiModel } from '@store/features/tags/tags.models';
import { Subscription } from 'rxjs';
import { TagControlComponent } from '../tag-control/tag-control.component';

@Component({
  selector: 'app-tags-control',
  templateUrl: './tags-control.component.html',
  styleUrls: ['./tags-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsControlComponent),
      multi: true
    }
  ]
})
export class TagsControlComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @ViewChild(TagControlComponent) tagControlComponent: TagControlComponent;

  @Input() tags: TagApiModel[];
  @Input() placeholder: string;
  @Input() hideRemove = false;
  @Input() readonly: boolean = false;

  selectControl: FormControl;
  selectedTags: TagApiModel[];

  get hasSelectedTags(): boolean {
    return !!(this.selectedTags && this.selectedTags.length);
  }

  private onChange: (values: TagApiModel[]) => void;
  private onTouched: () => void;

  private subs = new Subscription();

  constructor(
    private i18n: TranslateService,
  ) {
    this.tags = [];
    this.placeholder = this.i18n.instant('others.choose_tag');
    
    this.selectControl = new FormControl(null);
    this.selectedTags = [];
  }

  ngOnInit() {
    this.subs.add(this.selectControl.valueChanges.subscribe(selectedTag => this.addTag(selectedTag)));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getSelectedTagFromTags(tag: TagApiModel): TagApiModel {
    return this.tags.find(t => t.id === tag.id);
  }

  addTag(tag: TagApiModel) {
    if (!this.hasTag(tag)) {
      this.selectedTags = [...this.selectedTags, tag];
      this.propagateChanges();
      this.selectControl.setValue(null, { emitEvent: false });
    }
    this.tagControlComponent?.reset();
  }

  removeTag(tag: TagApiModel) {
    const tagIndex = this.findTagIndex(tag);

    if (tagIndex !== -1) {
      this.selectedTags = [...this.selectedTags];
      this.selectedTags.splice(tagIndex, 1);
      this.propagateChanges();
    }
  }

  hasTag(tag: TagApiModel) {
    return (this.findTagIndex(tag) !== -1);
  }

  writeValue(selectedTags: TagApiModel[]): void {
    if (Array.isArray(selectedTags)) {
      this.selectedTags = selectedTags?.filter(
        selectedTag => !!this.tags?.find(tag => (tag.id === selectedTag.id))
      );
    } else {
      this.selectedTags = [];
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectControl.disable();
    } else {
      this.selectControl.enable();
    }
  }

  private findTagIndex(tag: TagApiModel) {
    if (!tag) return;
    return this.selectedTags.findIndex(
      selectedTag => (selectedTag.id === tag.id)
    );
  }

  private propagateChanges() {
    if (this.onChange) {
      this.onChange(this.selectedTags);
    }
  }
}

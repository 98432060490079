import { Action, createReducer, on } from '@ngrx/store';
import { bookingRoomActions } from '@components/booking/store/actions/booking-room.action';

export const featureKey = 'bookingRooms';

export interface State {
  loading: boolean;
  error: Error;
  saving: boolean;
}

export const initialState = {
  loading: false,
  error: null,
  saving: false,
};

export const bookingRoomReducer = createReducer(
  initialState,
  on(bookingRoomActions.addBookingRoom, state => ({ ...state, loading: true })),
  on(bookingRoomActions.addBookingRoomSuccess, state => ({ ...state, loading: false })),
  on(bookingRoomActions.addBookingRoomFailure, (state, { error }) => ({ ...state, loading: false, error })),

  on(bookingRoomActions.updateBookingRoom, state => ({ ...state, saving: true })),
  on(bookingRoomActions.updateBookingRoomSuccess, state => ({ ...state, saving: false })),
  on(bookingRoomActions.updateBookingRoomFailure, (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingRoomActions.deleteBookingRoom, state => ({ ...state, saving: true })),
  on(bookingRoomActions.deleteBookingRoomSuccess, state => ({ ...state, saving: false })),
  on(bookingRoomActions.deleteBookingRoomFailure, (state, { error }) => ({ ...state, saving: false, error })),
);

export function reducer(state: State, action: Action): State {
  return bookingRoomReducer(state, action);
}

export const selectBookingRoomSaving = (state: State) => state.saving;
export const selectBookingRoomError = (state: State) => state.error;
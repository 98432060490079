import { InjectionToken } from '@angular/core';
import { Router } from '@angular/router';

export const EXTERNAL_REDIRECT_HANDLER = new InjectionToken('External Redirect Handler');

export function externalRedirectHandlerFactory(router: Router) {
  return () => {
    const url = window.location.href;
    
    magicLinkRedirect(router, url);
    changePasswordRedirect(router, url);
    
    return Promise.resolve();
  };
}

const magicLinkRedirect = (router: Router, url: string) => {
  const magicLinkParam = new URL(url).searchParams.get('magic_link');

  if (magicLinkParam) {
    router.navigate(['/account/login'], { queryParams: { magic_link: magicLinkParam } });
  }
};

const changePasswordRedirect = (router: Router, url: string) => {
  const resetPasswordUrlPart = url.includes('/reset-password');
  const tokenParam = new URL(url).searchParams.get('token');
  const emailParam = new URL(url).searchParams.get('email');
  const firstNameParam = new URL(url).searchParams.get('first_name');
  const lastNameParam = new URL(url).searchParams.get('last_name');
  if (resetPasswordUrlPart && tokenParam && emailParam) {
    router.navigate(['/account', 'forgot-password-submit'], {
      queryParams: {
        'token': tokenParam,
        'email': emailParam,
        'first_name': firstNameParam, // optional
        'last_name': lastNameParam, // optional
      },
    });
  }
}
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { EventLanguageModel } from '../models/event-language.model';

import * as EventActions from '../actions/event.actions';
import * as EventLanguageActions from '../actions/event-language.actions';
import { clearEventState } from '../actions/event.actions';

export const featureKey = 'eventLanguage';

export interface State extends EntityState<EventLanguageModel> {
  loading: boolean;
  loaded: boolean;
  error: Error;
}

export const adapter = createEntityAdapter<EventLanguageModel>({
  selectId: language => language.code,
  sortComparer: false,
});

export const initialState: State = {
  ...adapter.getInitialState(),
  loading: false,
  loaded: false,
  error: null,
};

export const eventLanguageReducer = createReducer<State>(
  initialState,
  on(EventLanguageActions.loadEventLanguages, state => ({
    ...initialState,
    loading: true,
  })),
  on(EventLanguageActions.loadEventLanguagesSuccess, (state, { languages }) =>
    adapter.setAll(languages, { ...state, loading: false, loaded: true })
  ),
  on(EventLanguageActions.loadEventLanguagesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(EventActions.updateEventSuccess, (state, { languages }) =>
    adapter.setAll(languages, state)
  ),

  on(clearEventState, (state) => ({ ...initialState })),
);

export function reducer(state: State, action: Action): State {
  return eventLanguageReducer(state, action);
}

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectEventLanguageIds = selectIds;

export const selectEventLanguageEntiites = selectEntities;

export const selectAllEventLanguages = createSelector(
  selectAll,
  // Move default language to begin of the array
  languages => languages.sort((a, b) => (a.default ? -1 : 0))
);

export const selectEventLanguageTotal = selectTotal;

export const selectEventLanguageLoading = (state: State) => state.loading;

export const selectDefaultEventLanguage = createSelector(
  selectAllEventLanguages,
  languages => languages.find(lang => lang.default)
);

import { ComponentModel } from '@shared/models/component.model';
import { createAction, props } from '@ngrx/store';

import { WwwResourceModel } from '@components/www-resource/models/www-resource.model';

export const loadWwwResources = createAction(
  '[WwwResource/Component] Load WwwResources',
);

export const loadWwwResourcesByComponent = createAction(
  '[WwwResource/Component] Load WwwResources By Component',
  props<{ component: ComponentModel, loadGroups: boolean }>(),
)

export const loadWwwResourcesRequest = createAction(
  '[WwwResource/Effect] Load WwwResources Request',
  props<{ componentId: number }>()
);

export const loadWwwResourcesSuccess = createAction(
  '[WwwResource/API] Load WwwResources Success',
  props<{ componentId: number, wwwResources: WwwResourceModel[] }>()
);

export const loadWwwResourcesFailure = createAction(
  '[WwwResource/API] Load WwwResources Failure',
  props<{ componentId: number, error: Error }>()
);

export const createWwwResource = createAction(
  '[WwwResource/Modal] Create WwwResource',
  props<{ wwwResource: WwwResourceModel }>()
);

export const createWwwResourceSuccess = createAction(
  '[WwwResource/API] Create WwwResource Success',
  props<{ wwwResource: WwwResourceModel }>()
);

export const createWwwResourceFailure = createAction(
  '[WwwResource/API] Create WwwResource Failure',
  props<{ error: Error }>()
);

export const updateWwwResource = createAction(
  '[WwwResource/Modal] Update WwwResource',
  props<{ wwwResource: WwwResourceModel }>()
);

export const updateWwwResourceSuccess = createAction(
  '[WwwResource/API] Update WwwResource Success',
  props<{ wwwResource: WwwResourceModel }>()
);

export const updateWwwResourceFailure = createAction(
  '[WwwResource/API] Update WwwResource Failure',
  props<{ error: Error }>()
);

export const deleteWwwResource = createAction(
  '[WwwResource/Modal] Delete WwwResource',
  props<{ wwwResource: WwwResourceModel }>()
);

export const deleteWwwResourceSuccess = createAction(
  '[WwwResource/API] Delete WwwResource Success',
  props<{ wwwResource: WwwResourceModel }>()
);

export const deleteWwwResourceFailure = createAction(
  '[WwwResource/API] Delete WwwResource Failure',
  props<{ error: Error }>()
);


export const reorderItems = createAction(
  '[WwwResource/Component] Items Categories',
  props<{ from: number, to: number, categoryId: number }>()
);

export const reorderItemsInplace = createAction(
  '[WwwResource/Effect] Reorder Items Inplace',
  props<{ items: WwwResourceModel[], categoryId: number }>()
);

export const reorderItemsSuccess = createAction(
  '[WwwResource/API] Reorder Items Success',
  props<{ items: WwwResourceModel[], categoryId: number }>()
);

export const reorderItemsFailure = createAction(
  '[WwwResource/API] Reorder Items Failure',
  props<{ error: Error }>()
);

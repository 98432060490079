import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { SpeakerTranslationModel } from '@components/speaker/models/speaker-translation.model';

import * as SpeakerTranslationActions from '../actions/speaker-translation.actions';
import * as ImportActions from '../actions/speaker-import.actions';

export const stateKey = 'speakerTranslation';

export interface SpeakerState {
  loading: boolean;
  loadedId: string;
  error: Error;
}

export const speakerInitialState: SpeakerState = {
  loading: false,
  loadedId: null,
  error: null,
};

export interface State extends EntityState<SpeakerTranslationModel> {
  speaker: {
    [speakerId: number]: SpeakerState;
  };
}

export const adapter = createEntityAdapter<SpeakerTranslationModel>({
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  speaker: {},
});

export const speakerTranslationReducer = createReducer(
  initialState,
  on(SpeakerTranslationActions.loadSpeakerTranslation, (state, { speaker }) =>
    substate(state, speaker.id, { ...speakerInitialState, loading: true })
  ),
  on(
    SpeakerTranslationActions.loadSpeakerTranslationSuccess,
    (state, { speaker, translation }) => {
      const mutated = substate(state, speaker.id, {
        loading: false,
        loadedId: translation.id,
      });
      return adapter.upsertOne(translation, mutated);
    }
  ),
  on(
    SpeakerTranslationActions.loadSpeakerTranslationFailure,
    (state, { speaker, error }) =>
      substate(state, speaker.id, { loading: false, error })
  ),
  on(
    SpeakerTranslationActions.updateSpeakerTranslationsSuccess,
    (state, { translations }) => adapter.upsertMany(translations, state)
  ),
  on(
    SpeakerTranslationActions.updateSpeakerTranslationsFailure,
    (state, { speaker, error }) => substate(state, speaker.id, { error })
  ),
  on(ImportActions.importSpeakersSuccess, state => ({ ...initialState }))
);

function substate(
  state: State,
  speakerId: number,
  changes: Partial<SpeakerState>
) {
  const speakerState = { ...state.speaker[speakerId], ...changes };
  const speaker = { ...state.speaker, [speakerId]: speakerState };

  return { ...state, speaker };
}

export function reducer(state: State, action: Action): State {
  return speakerTranslationReducer(state, action);
}

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectSpeakerTranslationEntities = selectEntities;

export const selectAllSpeakerTranslations = selectAll;

export const selectSpeakerTranslations = createSelector(
  selectAllSpeakerTranslations,
  (speakers: SpeakerTranslationModel[], { speakerId, language }) =>
    speakers.filter(speaker => speaker.speakerId === speakerId)
);

export const selectSpeakerTranslation = createSelector(
  selectSpeakerTranslations,
  (speakers: SpeakerTranslationModel[], { language }) =>
    speakers.find(speaker => speaker.language === language)
);

export const selectSpeakerTranslationSpeaker = createSelector(
  (state: State) => state.speaker,
  (speaker, { speakerId }) => speaker[speakerId] as SpeakerState
);

export const selectSpeakerTranslationLoading = createSelector(
  selectSpeakerTranslationSpeaker,
  speaker => speaker.loading
);

export const selectLoadedSpeakerTranslationId = createSelector(
  selectSpeakerTranslationSpeaker,
  speaker => speaker.loadedId
);

export const selectLoadedSpeakerTranslation = createSelector(
  selectSpeakerTranslationEntities,
  selectLoadedSpeakerTranslationId,
  (speakers, loadedId) => speakers[loadedId]
);

export const selectSpeakerTranslationError = createSelector(
  selectSpeakerTranslationSpeaker,
  speaker => speaker.error
);

import { DropdownOption } from './../dropdown-with-search/dropdown-with-search.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { countries } from 'country-data';
@Component({
  selector: 'app-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: ['./country-dropdown.component.scss']
})
export class CountryDropdownComponent implements OnInit {
  
  @Input() disabled = false;
  @Input() staticLabel = false;
  @Output() onSelect: EventEmitter<DropdownOption>;

  countryOptions: DropdownOption[];

  constructor() {
    this.onSelect = new EventEmitter<DropdownOption>();

    this.countryOptions = [...countries.all].map((c, i) => ({
      id: i,
      value: c.alpha2,
      name: c.name
    }));
  }

  ngOnInit(): void {
  }

  select(option: DropdownOption): void {
    this.onSelect.emit(option);
  }

}

import { createAction, props } from '@ngrx/store';

import { AgendaImportConfigModel, AgendaImportConfigPatchModel } from '../models/agenda-import-config.model';

export const loadAgendaImportConfig = createAction(
  '[Agenda/Import Modal] Load Agenda Import Config',
  props<{ eventId: number }>()
);

export const loadAgendaImportConfigSuccess = createAction(
  '[Agenda/Import Modal] Load Agenda Import Config Success',
  props<{ config: AgendaImportConfigModel }>()
);

export const loadAgendaImportConfigFailure = createAction(
  '[Agenda/Import Modal] Load Agenda Import Config Failure',
  props<{ error: Error }>()
);

export const updateAgendaImportConfig = createAction(
  '[Agenda/Import Modal] Update Agenda Import Config',
  props<{ eventId: number, payload: AgendaImportConfigPatchModel }>()
);

export const updateAgendaImportConfigSuccess = createAction(
  '[Agenda/Import Modal] Update Agenda Import Config Success',
  props<{ config: AgendaImportConfigModel }>()
);

export const updateAgendaImportConfigFailure = createAction(
  '[Agenda/Import Modal] Update Agenda Import Config Failure',
  props<{ error: Error }>()
);

export const syncAgendaImport = createAction(
  '[Agenda/Import Modal] Sync Agenda Import',
  props<{ eventId: number }>()
);

export const syncAgendaImportSuccess = createAction(
  '[Agenda/Import Modal] Sync Agenda Import Success',
);

export const syncAgendaImportFailure = createAction(
  '[Agenda/Import Modal] Sync Agenda Import Failure',
  props<{ error: Error }>()
);

export const setError = createAction(
  '[Agenda/Import Modal] Set Error',
  props<{ error: Error | null }>()
);
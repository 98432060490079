import { Action, ActionReducer, createSelector, Selector, SelectorWithProps } from '@ngrx/store';

export type InstanceId = string | number;

export interface InstanceState<T> {
  [instanceId: string]: T;
}

export interface InstanceStateAdapter<T> {
  update(instanceId: InstanceId, state: InstanceState<T>, action: Action): InstanceState<T>;
  remove(instanceId: InstanceId, state: InstanceState<T>): InstanceState<T>;
}

export interface InstanceAdapter<T> extends InstanceStateAdapter<T> {
  getInitialState(): InstanceState<T>;
  getInitialState<S>(state: S): InstanceState<T> & S;
}

export interface InstanceAdapterOptions<T, V extends Action> {
  reducer: ActionReducer<T, V>;
}

export function createInstanceAdapter<T, V extends Action = Action>(options: InstanceAdapterOptions<T, V>): InstanceAdapter<T> {
  const initialStateFactory = createInitialStateFactory<T>();
  const stateUpdaters = createStateAdapterFactor<T, V>(options.reducer);

  return {
    ...initialStateFactory,
    ...stateUpdaters,
  };
}

export function createInstanceSelector<T>(selectId: (state: InstanceState<T>) => InstanceId): Selector<InstanceState<T>, T>;
export function createInstanceSelector<T, P>(selectId: (state: InstanceState<T>, props: P) => InstanceId): SelectorWithProps<InstanceState<T>, P, T>;
export function createInstanceSelector(selectId: (state: InstanceState<any>, props?: any) => InstanceId): Function {
  return (state: InstanceState<any>, props: any) => state[selectId(state, props)];
}

function createStateAdapterFactor<T, V extends Action>(reducer: ActionReducer<T, V>): InstanceStateAdapter<T> {
  function updateInstance(instanceId: InstanceId, state: InstanceState<T>, action: V): InstanceState<T> {
    // Skip actions without instanceId parameter
    if (instanceId === (void 0) || instanceId === null) {
      console.warn('Instance ID is invalid', action.type);
      return state;
    }

    const mutatedInstance = reducer(state[instanceId], action);
    return { ...state, [instanceId]: mutatedInstance };
  }

  function removeInstance(instanceId: InstanceId, state: InstanceState<T>): InstanceState<T> {
    // No need to remove instance state if not exists
    if (instanceId === (void 0) || instanceId === null || !state[instanceId]) {
      return state;
    }

    const mutated = { ...state };
    delete mutated[instanceId];

    return mutated;
  }

  return {
    update: updateInstance,
    remove: removeInstance,
  };
}

function createInitialStateFactory<T>() {
  function getInitialState(): InstanceState<T>;
  function getInitialState<S>(additionalState: S): InstanceState<T> & S;
  function getInitialState(additionalState: any = {}): InstanceState<T> {
    return Object.assign({}, additionalState);
  }

  return {
    getInitialState,
  };
}

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { first, filter } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { ComponentPlanModel } from '../../../construct-steps/models/component-plan.model';

import * as ComponentPlanActions from './actions/component-plan.actions';
import * as fromComponent from './reducers';

@Injectable({
  providedIn: 'root'
})
export class ComponentPlanFacadeService {
  constructor(private store$: Store<any>) {}

  getComponentPlans(): Observable<ComponentPlanModel[]> {
    return this.store$.pipe(select(fromComponent.selectAllComponentPlan));
  }

  getComponentPlanLoading(): Observable<boolean> {
    return this.store$.pipe(select(fromComponent.selectComponentPlanLoading));
  }

  getComponentPlanLoaded(): Observable<boolean> {
    return this.store$.pipe(select(fromComponent.selectComponentPlanLoaded));
  }

  getComponentPlanError(): Observable<Error> {
    return this.store$.pipe(select(fromComponent.selectComponentPlanError));
  }

  loadComponentPlans() {
    this.store$.dispatch(ComponentPlanActions.loadComponentPlans());
  }

  requestComponentPlansLoad() {
    this.getComponentPlanLoaded().pipe(first(), filter(loaded => !loaded)).subscribe(
      () => this.loadComponentPlans()
    );
  }
}

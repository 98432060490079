import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { AgendaRatingAnswerModel } from '@components/agenda/models/agenda-rating-answer.model';

import * as RatingAnswerActions from '../actions/rating-answer.actions';
import { selectEvent } from '@store/features/event/actions';

export const stateKey = 'ratingAnswers';

export interface State extends EntityState<AgendaRatingAnswerModel> {
  loading: boolean;
  loaded: boolean;
  error: Error;
}

const adapter = createEntityAdapter<AgendaRatingAnswerModel>({
  sortComparer: (a, b) => a.order - b.order,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
});

export const ratingAnswerReducer = createReducer(
  initialState,
  on(RatingAnswerActions.loadRatingAnswers, state => ({
    ...initialState,
    loading: true,
  })),
  on(RatingAnswerActions.loadRatingAnswersSuccess, (state, { answers }) =>
    adapter.setAll(answers, { ...state, loading: false, loaded: true })
  ),
  on(RatingAnswerActions.loadRatingAnswersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(
    RatingAnswerActions.createRatingAnswersSuccess,
    RatingAnswerActions.updateRatingAnswersSuccess,
    (state, { answers }) => adapter.addMany(answers, state)
  ),
  on(RatingAnswerActions.purgeRatingAnswers, (state, { questionId }) => {
    return adapter.removeMany(
      answer => answer.questionId === questionId,
      state
    );
  }),

  on(
    selectEvent,
    () => ({ ...initialState }),
  )
);

export function reducer(state: State, action: Action): State {
  return ratingAnswerReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectRatingAnswerIds = selectIds;

export const selectRatingAnswerEntities = selectEntities;

export const selectAllRatingAnswers = selectAll;

export const selectRatingAnswers = createSelector(
  selectAllRatingAnswers,
  (ratings, { questionId }) =>
    ratings.filter(rating => rating.questionId === questionId)
);

import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { VenueModel } from '@components/venue/models/venue.model';

import * as VenueActions from '../actions/venue.actions';

export interface State extends EntityState<VenueModel> {
  error: Error;
}

export const venueAdapter = createEntityAdapter<VenueModel>({
  sortComparer: (a, b) => b.name.localeCompare(a.name),
});

export const initialVenueState: State = venueAdapter.getInitialState({
  error: null,
});

export const venueReducer = createReducer(
  initialVenueState,
  on(
    VenueActions.loadVenuesRequest,
    (state, { componentId }) => venueAdapter.removeMany(venue => venue.componentId === componentId, state)
  ),
  on(
    VenueActions.loadVenuesSuccess,
    (state, { venues }) => venueAdapter.addMany(venues, state)
  ),
  on(
    VenueActions.createVenueSuccess,
    VenueActions.updateVenueSuccess,
    (state, { venue }) => venueAdapter.upsertOne(venue, state)
  ),
  on(
    VenueActions.deleteVenueSuccess,
    (state, { venue }) => venueAdapter.removeOne(venue.id, state)
  ),
  on(
    VenueActions.createVenueFailure,
    VenueActions.updateVenueFailure,
    VenueActions.deleteVenueFailure,
    (state, { error }) => ({ ...state, error })
  ),
);

export function reducer(state: State, action: Action): State {
  return venueReducer(state, action);
}

/**
 * Venue selectors
 */
const {
  selectIds,
  selectEntities,
  selectAll,
} = venueAdapter.getSelectors();

export const selectVenueIds = selectIds;

export const selectVenueEntities = selectEntities;

export const selectAllVenues = selectAll;

export const selectVenues = createSelector(
  selectAllVenues,
  (venues: VenueModel[], { componentId }: { componentId: number }) =>
    venues.filter(venue => venue.componentId === componentId )
);

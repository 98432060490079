import { createAction, props } from '@ngrx/store';

import { LanguageModel } from '../models/language.model';

export const loadLanguages = createAction(
  '[Language/Backend] Load Languages'
);

export const loadLanguagesSuccess = createAction(
  '[Language/API] Load Languages Success',
  props<{ languages: LanguageModel[] }>()
);

export const loadLanguagesFailure = createAction(
  '[Language/API] Load Languages Failure',
  props<{ error: Error }>()
);

import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { CountryPhone, PhoneMaskService } from '@shared/providers/phone-mask.service';

import { PhoneNumberModel } from '@shared/models/phone-number.model';

@Component({
  selector: 'app-input-phone-number',
  templateUrl: './input-phone-number.component.html',
  styleUrls: ['./input-phone-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPhoneNumberComponent),
      multi: true
    }
  ]
})
export class InputPhoneNumberComponent implements ControlValueAccessor {
  phoneMasks: CountryPhone[];
  phoneInputControl: FormControl;
  codeInputControl: FormControl;

  @Input() glow = false;

  private onChange: (value: PhoneNumberModel) => void;
  private onTouched: () => void;

  constructor(private phoneMaskService: PhoneMaskService) {
    this.phoneInputControl = new FormControl('');
    this.codeInputControl = new FormControl('');
    this.phoneMasks = phoneMaskService.getCountries()
      .sort((a, b) => Number(a.dialCode) - Number(b.dialCode))
      .map(country => ({...country, dialCode: `+${country.dialCode}`}));
  }

  writeValue(phoneNumber: PhoneNumberModel): void {
    this.phoneInputControl.setValue(phoneNumber.number);
    this.codeInputControl.setValue(phoneNumber.countryCode);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.phoneInputControl.disable();
      this.codeInputControl.disable();
    } else {
      this.phoneInputControl.enable();
      this.codeInputControl.enable();
    }
  }

  onValueChanged(ev: Event) {
    const strippedNumber = stripLetters(this.phoneInputControl.value);
    this.phoneInputControl.setValue(strippedNumber);

    const phoneNumber: PhoneNumberModel = {
      countryCode: this.codeInputControl.value,
      number: strippedNumber,
    };

    this.onChange(phoneNumber);
  }

  onInputFocus(ev: Event) {
    this.onTouched();
  }
}

function stripLetters(input: string): string {
  return input?.replace(/[\D]/g, '');
}

import { Injectable } from '@angular/core';
import { ExhibitorApiModel } from '@store/features/exhibitor/models/exhibitor.api-model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExhibitorsSelectDropdownService {

  private loadedExhibitors$: BehaviorSubject<ExhibitorApiModel[]> = new BehaviorSubject<ExhibitorApiModel[]>([]);

  constructor() {}

  setLoadedExhibitors(exhibitors: ExhibitorApiModel[]): void {
    this.loadedExhibitors$.next(exhibitors);
  }

  getLoadedExhibitors(): Observable<ExhibitorApiModel[]> {
    return this.loadedExhibitors$.asObservable();
  }

}
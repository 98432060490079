import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'trim'
})
export class TrimmerPipe implements PipeTransform {
  transform(value: string, maxLength?: number): string {
    if(value && value.length > maxLength) {
      return value.substring(0, maxLength) + '...';
    }
    return value;
  }
}
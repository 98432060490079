import { Action, createReducer, on } from '@ngrx/store';

import * as ConstructorActions from '../actions/construct.actions';

export interface State {
  currentStep: number;
}

export const initialState: State = {
  currentStep: 0,
};

export const constructorReducer = createReducer(
  initialState,
  on(ConstructorActions.setCurrentStep, (state, { currentStep }) => ({
    ...state,
    currentStep,
  }))
);

export function reducer(state: State, action: Action) {
  return constructorReducer(state, action);
}

export const selectCurrentStep = (state: State) => state.currentStep;

import { ComponentModel } from './../../../../shared/models/component.model';
import { BookingReservationsGet, BookingReservationSlot, BookingReservationsResponse } from '@components/booking/store/models/booking-reservation.model';
import { BookingReservationPost } from '@components/booking/store/models/booking-reservation.model';
import { BookingReservation, BookingReservationStatus } from '@components/booking/store/models/booking-reservation.model';
import { createAction } from '@ngrx/store';
import { EventModel } from '@store/features/event/models/event.model';

const createBookingReservation = createAction('[Booking Reservation] Create Booking Reservation', (reservation: BookingReservationPost) => ({ reservation }));
const createBookingReservationSuccess = createAction('[Booking Reservation] Create Booking Reservation Success');
const createBookingReservationFailure = createAction('[Booking Reservation] Create Booking Reservation Failure', (error: any) => ({ error }));

const loadBookingReservations = createAction('[Booking Reservation] Load Booking Reservations', (preset: BookingReservationsGet) => ({ preset }));
const loadBookingReservationsSuccess = createAction('[Booking Reservation] Load Booking Reservations Success', (reservations: BookingReservationsResponse) => ({ reservations }));
const loadBookingReservationsFailure = createAction('[Booking Reservation] Load Booking Reservations Failure', (error: any) => ({ error }));

const acceptBookingReservation = createAction('[Booking Reservation] Accept Reservation', (reservation: BookingReservation) => ({ reservation }));
const acceptBookingReservationSuccess = createAction('[Booking Reservation] Accept Reservation Success', (reservation: BookingReservation) => ({ reservation }));
const acceptBookingReservationFailure = createAction('[Booking Reservation] Accept Reservation Failure', (error: any) => ({ error }));

const declineBookingReservation = createAction('[Booking Reservation] Decline Reservation', (reservation: BookingReservation) => ({ reservation }));
const declineBookingReservationSuccess = createAction('[Booking Reservation] Decline Reservation Success', (reservation: BookingReservation) => ({ reservation }));
const declineBookingReservationFailure = createAction('[Booking Reservation] Decline Reservation Failure', (error: any) => ({ error }));

const setBookingReservationActiveStatus = createAction('[Booking Reservation] Set Active Status', (status: BookingReservationStatus) => ({ status }));
const setBookingReservationError = createAction('[Booking Reservation] Set Error', (error: any) => ({ error }));
const setBookingReservationCreatorMode = createAction('[Booking Reservation] Set Creator Mode', (mode: boolean) => ({ mode }));

const loadBookingReservationSlots = createAction('[Booking Reservation] Load Booking Reservation Slots');
const loadBookingReservationSlotsSuccess = createAction('[Booking Reservation] Load Booking Reservation Slots Success', (slots: BookingReservationSlot[]) => ({ slots }));
const loadBookingReservationSlotsFailure = createAction('[Booking Reservation] Load Booking Reservation Slots Failure', (error: any) => ({ error }));

const addFromSocket = createAction('[Booking Reservation] Add From Socket', (reservation: BookingReservation) => ({ reservation }));
const deleteFromSocket = createAction('[Booking Reservation] Delete From Socket', (id: number) => ({ id })); // for accept and decline actions if user view is not collapsing to the handled pusher action.

const exportBookingReservations = createAction('[Booking Reservation] Export Booking Reservations');
const exportBookingReservationsSuccess = createAction('[Booking Reservation] Export Booking Reservations Success', (exportedData: string) => ({ exportedData }));
const exportBookingReservationsFailure = createAction('[Booking Reservation] Export Booking Reservations Failure', (error: any) => ({ error }));

const loadBookingReservationsForDlBuilder = createAction('[Booking Reservation] Load Booking Reservations By Component', (event: EventModel, component: ComponentModel) => ({ event, component }));

export const bookingReservationActions = {
    createBookingReservation,
    createBookingReservationSuccess,
    createBookingReservationFailure,
    loadBookingReservations,
    loadBookingReservationsSuccess,
    loadBookingReservationsFailure,
    acceptBookingReservation,
    acceptBookingReservationSuccess,
    acceptBookingReservationFailure,
    declineBookingReservation,
    declineBookingReservationSuccess,
    declineBookingReservationFailure,
    setBookingReservationActiveStatus,
    setBookingReservationError,
    setBookingReservationCreatorMode,
    loadBookingReservationSlots,
    loadBookingReservationSlotsSuccess,
    loadBookingReservationSlotsFailure,
    addFromSocket,
    deleteFromSocket,
    exportBookingReservations,
    exportBookingReservationsSuccess,
    exportBookingReservationsFailure,
    loadBookingReservationsForDlBuilder
};
import { UserRoleEnum } from "@shared/enums/user-role.enum";
import { EventModel } from "@store/features/event/models/event.model";
import { ComponentNames } from "@utils/component-names.util";
import { TagApiModel, TagType } from "@store/features/tags/tags.models";

export const USER_ROLE_PERMISSIONS = {
  ADD_COMPONENT_ACCESS_GROUP: [UserRoleEnum.APP_OWNER, UserRoleEnum.APP_ADMIN, UserRoleEnum.EVENT_ADMIN],

  SECTION_LANDING_PAGE: [UserRoleEnum.APP_OWNER, UserRoleEnum.APP_ADMIN, UserRoleEnum.EVENT_ADMIN],

  AGENDA_ATTENDEES_LIST: [UserRoleEnum.APP_OWNER, UserRoleEnum.APP_ADMIN, UserRoleEnum.EVENT_ADMIN],

  MODIFY_EVENT_HIGHLIGHTED_STATUS: [UserRoleEnum.APP_OWNER, UserRoleEnum.APP_ADMIN],

  // if role is not allowed for this rule, then will be loaded components which have at least one group same like authorized user
  LOAD_ALL_COMPONENTS: [UserRoleEnum.APP_OWNER, UserRoleEnum.APP_ADMIN, UserRoleEnum.EVENT_ADMIN],
  REORED_COMPONENTS_LIST: [UserRoleEnum.APP_OWNER, UserRoleEnum.APP_ADMIN, UserRoleEnum.EVENT_ADMIN],
  DELETE_COMPONENT: [UserRoleEnum.APP_OWNER, UserRoleEnum.APP_ADMIN, UserRoleEnum.EVENT_ADMIN],
  
  // Tags modify
  TAG_MODIFY_USER_GROUPS: [UserRoleEnum.APP_OWNER, UserRoleEnum.APP_ADMIN, UserRoleEnum.EVENT_ADMIN],
  TAG_MODIFY_USER_TAGS: [UserRoleEnum.APP_OWNER, UserRoleEnum.APP_ADMIN, UserRoleEnum.EVENT_ADMIN],
  TAG_MODIFY_AGENDA_PLACES: [UserRoleEnum.APP_OWNER, UserRoleEnum.APP_ADMIN, UserRoleEnum.EVENT_ADMIN],
  TAG_MODIFY_AGENDA_PATHS: [UserRoleEnum.APP_OWNER, UserRoleEnum.APP_ADMIN, UserRoleEnum.EVENT_ADMIN],
  TAG_MODIFY_EXHIBITOR_TAGS: [UserRoleEnum.APP_OWNER, UserRoleEnum.APP_ADMIN, UserRoleEnum.EVENT_ADMIN],

  GET_EVENT_TICKETS: [UserRoleEnum.APP_OWNER, UserRoleEnum.APP_ADMIN, UserRoleEnum.EVENT_ADMIN],
  GET_PRICE: [UserRoleEnum.APP_OWNER, UserRoleEnum.APP_ADMIN, UserRoleEnum.EVENT_ADMIN],
}

export const USER_ROLE_HIDDEN_COMPONENTS = {
  [UserRoleEnum.EVENT_MODERATOR]: [
    ComponentNames.COUPONS,
    ComponentNames.APPLICATION_USERS,
    ComponentNames.APPLICATION_TAGS,
    ComponentNames.TICKET_STATS,
    ComponentNames.CUSTOM_EMAILS,
  ],
  [UserRoleEnum.EVENT_ADMIN]: [],
  [UserRoleEnum.APP_ADMIN]: [],
  [UserRoleEnum.APP_OWNER]: [],
}

export function isUserRoleAllowed(event: EventModel, allowed: UserRoleEnum[]): boolean {
  return allowed.some(role => role === event.userRole);
}

export function isUserRoleComponentHidden(event: EventModel, component: string): boolean {
  if (!event || !component) return;
  return USER_ROLE_HIDDEN_COMPONENTS[event.userRole].some(comp => comp === component);
}

export function userAllowedByGroup(userGroups: TagApiModel[], subjectGroups: TagApiModel[]): boolean {
  const filteredUserGroups = [...userGroups].filter(g => g.type_tag !== TagType.System);
  for (const subjectGroup of subjectGroups) {
    if (filteredUserGroups.some(userGroup => userGroup.id === subjectGroup.id)) {
        return true;
    }
  }
  return false;
}
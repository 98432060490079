import { createAction, props } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { InteractiveMapModel } from '@components/interactive-map/models/interactive-map.model';
import { InteractiveMapLocationModel } from '@components/interactive-map/models/interactive-map-location.model';

export const loadInteractiveMaps = createAction(
  '[Content/Interactive Map] Load Interactive Maps',
  props<{ event: EventModel, component: ComponentModel }>()
);

export const loadInteractiveMapsSuccess = createAction(
  '[API/Interactive Map] Load Interactive Maps Success',
  props<{ interactiveMaps: InteractiveMapModel[], locations: InteractiveMapLocationModel[] }>()
);

export const loadInteractiveMapsFailure = createAction(
  '[API/Interactive Map] Load Interactive Maps Failure',
  props<{ error: Error }>()
);

export const createInteractiveMap = createAction(
  '[Content/Interactive Map] Create Interactive Map',
  props<{ event: EventModel, component: ComponentModel, interactiveMap: InteractiveMapModel, locations: InteractiveMapLocationModel[] }>()
);

export const createInteractiveMapSuccess = createAction(
  '[API/Interactive Map] Create Interactive Map Success',
  props<{ interactiveMap: InteractiveMapModel, locations: InteractiveMapLocationModel[] }>()
);

export const createInteractiveMapFailure = createAction(
  '[API/Interactive Map] Create Interactive Map Failure',
  props<{ error: Error }>()
);

export const updateInteractiveMap = createAction(
  '[Content/Interactive Map] Update Interactive Map',
  props<{ event: EventModel, component: ComponentModel, interactiveMap: InteractiveMapModel, locations: InteractiveMapLocationModel[] }>()
);

export const updateInteractiveMapSuccess = createAction(
  '[API/Interactive Map] Update Interactive Map Success',
  props<{ interactiveMap: InteractiveMapModel, locations: InteractiveMapLocationModel[] }>()
);

export const updateInteractiveMapFailure = createAction(
  '[API/Interactive Map] Update Interactive Map Failure',
  props<{ error: Error }>()
);

export const deleteInteractiveMap = createAction(
  '[Content/Interactive Map] Delete Interactive Map',
  props<{ event: EventModel, component: ComponentModel, interactiveMap: InteractiveMapModel }>()
);

export const deleteInteractiveMapSuccess = createAction(
  '[API/Interactive Map] Delete Interactive Map Success',
  props<{ interactiveMap: InteractiveMapModel }>()
);

export const deleteInteractiveMapFailure = createAction(
  '[API/Interactive Map] Delete Interactive Map Failure',
  props<{ error: Error }>()
);

export const reorderMaps = createAction(
  '[API/Interactive Map] Reorder Interactive Maps',
  props<{ event: EventModel, component: ComponentModel, interactiveMaps: InteractiveMapModel[] }>()
);

export const reorderMapsSuccess = createAction(
  '[API/Interactive Map] Reorder Interactive Maps Success',
  props<{ component: ComponentModel, interactiveMaps: InteractiveMapModel[] }>()
);

export const reorderMapsFailure = createAction(
  '[API/Interactive Map] Reorder Interactive Maps Failure',
  props<{ component: ComponentModel, error: Error }>()
);
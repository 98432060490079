import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-category-level',
  templateUrl: './category-level.component.html',
  styleUrls: ['./category-level.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CategoryLevelComponent),
      multi: true
    }
  ]
})
export class CategoryLevelComponent implements ControlValueAccessor {
  @Input() levels: number[];

  selectedLevel: number;

  private onChange: (level: number) => void;
  private onTouched: () => void;

  constructor() {
    this.levels = [1, 2, 3];
  }

  onLevelClick(ev: Event, level: number) {
    this.selectedLevel = level;

    if (this.onChange) {
      this.onChange(this.selectedLevel);
    }
  }

  writeValue(level: number): void {
    this.selectedLevel = level;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}

import { Injectable } from '@angular/core';

import { Observable, defer } from 'rxjs';
import { tap, first } from 'rxjs/operators';

import { FeedWallChannelAdapterService } from './feed-wall-channel-adapter.service';
import { FeedWallChannelStoreService } from './feed-wall-channel-store.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { FeedWallChannelModel } from './models/feed-wall-channel.model';

@Injectable({
  providedIn: 'root'
})
export class FeedWallChannelService {
  protected currentEvent: EventModel;
  protected currentComponent: ComponentModel;

  constructor(private adapter: FeedWallChannelAdapterService, private store: FeedWallChannelStoreService) {}

  getFeedWallChannelById(channelId: number): Observable<FeedWallChannelModel> {
    return this.store.getFeedWallChannelById(channelId);
  }

  getSortedFeedWallChannels(): Observable<FeedWallChannelModel[]> {
    return this.store.getSortedFeedWallChannels();
  }

  getFeedWallChannels(): Observable<FeedWallChannelModel[]> {
    return this.store.getFeedWallChannels();
  }

  loadComponent(event: EventModel, component: ComponentModel): Observable<FeedWallChannelModel[]> {
    return defer(() => this.loadFeedWallChannelsFor(event, component));
  }

  createFeedWallChannel(channel: FeedWallChannelModel): Observable<FeedWallChannelModel> {
    return this.adapter.createFeedWallChannel(this.currentEvent, this.currentComponent, channel).pipe(
      tap(createdChannel => this.store.pushEntity(createdChannel))
    );
  }

  updateFeedWallChannel(channel: FeedWallChannelModel): Observable<FeedWallChannelModel> {
    return this.adapter.updateFeedWallChannel(this.currentEvent, this.currentComponent, channel).pipe(
      tap(updatedChannel => this.store.updateEntity(updatedChannel))
    );
  }

  updateDefaultChannelEntities(defaultChannel: FeedWallChannelModel): void {
    this.store.getEntities()
      .pipe(first(entities => !!entities && entities.length > 0))
      .subscribe(entities => {
        entities.map(e => e.id === defaultChannel.id ? this.store.updateEntity(({ ...e, default: true })) : this.store.updateEntity(({ ...e, default: false })));
      });
  }

  deleteFeedWallChannel(channel: FeedWallChannelModel): Observable<boolean> {
    return this.adapter.deleteFeedWallChannel(this.currentEvent, this.currentComponent, channel).pipe(
      tap(success => success && this.store.removeEntity(channel))
    );
  }

  private loadFeedWallChannelsFor(event: EventModel, component: ComponentModel): Observable<FeedWallChannelModel[]> {
    this.reset();

    return this.adapter.loadFeedWallChannels(event, component).pipe(
      tap(channels => this.setup(event, component, channels))
    );
  }

  private setup(event: EventModel, component: ComponentModel, channels: FeedWallChannelModel[]) {
    this.currentEvent = event;
    this.currentComponent = component;
    this.store.setup(channels);
  }

  private reset() {
    this.currentEvent = null;
    this.currentComponent = null;
    this.store.reset();
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TokenService } from '@app/core/services/token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private tokenService: TokenService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.getAuthGuard();
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.getAuthGuard();
  }

  private getAuthGuard(): Observable<boolean | UrlTree> {
    const returnUrl = this.router.createUrlTree(['/account', 'login']);
    return this.tokenService.getIsAuthenticated().pipe(
      map(authenticated => authenticated ? true : returnUrl)
    )
  }
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ComponentModalComponent } from '../component-modal/component-modal.component';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';

@Injectable()
export class ComponentModalService {
  constructor(private dialog: MatDialog) {}

  openComponentModal(event: EventModel, component: ComponentModel): MatDialogRef<any> {
    return this.openModal(event, component);
  }

  private openModal(event: EventModel, component: ComponentModel): MatDialogRef<any> {
    const dialogRef = this.dialog.open(ComponentModalComponent, {
      width: '50%', height: '100%', disableClose: true, position: { right: '0' },
    });

    dialogRef.componentInstance.event = event;
    dialogRef.componentInstance.component = component;

    return dialogRef;
  }
}

import {DeepLinkConstants} from '@utils/deep-link/deep-link-const';

type Constructor<T> = new(...args: any[]) => T;

export class DeepLink {
  appId: number

  constructor(appId: number) {
    this.appId = appId;
  }

  getDeepLink(): string {
    return `${DeepLinkConstants.DEEP_LINK_SCHEME}://${DeepLinkConstants.DEEP_LINK_HOST}/${DeepLinkConstants.APP_SEGMENT_NAME}/${this.appId}`;
  }
}

export class FriendsDeepLink extends DeepLink {
  tabName: string;
  itemId: number;

  constructor(appId: number, tabName: string, itemId: number) {
    super(appId);
    this.tabName = tabName;
    this.itemId = itemId;
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${this.tabName}/${this.itemId}`;
  }
}

export class InboxDeepLink extends DeepLink {
  inboxThreadId: number;

  constructor(appId: number, inboxThreadId: number) {
    super(appId);
    this.inboxThreadId = inboxThreadId;
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.INBOX_SEGMENT_NAME}/${DeepLinkConstants.INBOX_THREAD_SEGMENT_NAME}/${this.inboxThreadId}`;
  }
}

export class NotificationDeepLink extends DeepLink {
  notificationId: number;

  constructor(appId: number, notificationId: number) {
    super(appId);
    this.notificationId = notificationId;
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.NOTIFICATIONS_SEGMENT_NAME}/${this.notificationId}`;
  }
}

export class MyProfileDeepLink extends DeepLink {
  constructor(appId: number) {
    super(appId);
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.MY_PROFILE_SEGMENT_NAME}`;
  }
}
export class SettingsDeepLink extends DeepLink {
  constructor(appId: number) {
    super(appId);
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.SETTINGS_SEGMENT_NAME}`
  }
}

export class UserDeepLink extends DeepLink {
  userUuid: string;
  userHash: string;

  constructor(appId: number, userUuid: string) {
    super(appId);
    this.userUuid = userUuid;
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.USER_SEGMENT_NAME}/${this.userUuid}`;
  }
}

export class UserVideoCallDeepLink extends DeepLink {
  userUuid: string;
  jitsiRoom: string;

  constructor(appId: number, userUuid: string, jitsiRoom: string) {
    super(appId);
    this.userUuid = userUuid;
    this.jitsiRoom = jitsiRoom;
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.USER_SEGMENT_NAME}/${this.userUuid}/${this.jitsiRoom}`;
  }
}

export class EventDeepLink extends DeepLink {
  eventId: number;

  constructor(appId: number, eventId: number) {
    super(appId);
    this.eventId = eventId;
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.EVENT_SEGMENT_NAME}/${this.eventId}`;
  }
}

export class ComponentDeepLink extends EventDeepLink {
  componentId: number;

  constructor(appId: number, eventId: number, componentId: number) {
    super(appId, eventId);
    this.componentId = componentId;
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.COMPONENT_SEGMENT_NAME}/${this.componentId}`;
  }
}

export class AgendaDeepLink extends ComponentDeepLink {
  agendaSessionId: string;
  questionId?: number;

  constructor(appId: number, eventId: number, componentId: number, agendaSessionId: number, questionId?: number) {
    super(appId, eventId, componentId);
    this.agendaSessionId = agendaSessionId !== undefined ? `/${agendaSessionId}` : '';
    this.questionId = questionId;
  }

  getDeepLink(): string {
    let string = `${super.getDeepLink()}/${DeepLinkConstants.AGENDA_COMPONENT_SEGMENT_NAME}${this.agendaSessionId}`;
    if(this.questionId) {
      string = `${string}/${DeepLinkConstants.AGENDA_SESSION_QUESTION_SEGMENT_NAME}/${this.questionId}`;
    }
    return string;
  }
}

export class ExhibitorsDeepLink extends ComponentDeepLink {
  exhibitorId: string;

  constructor(appId: number, eventId: number, componentId: number, exhibitorId: number) {
    super(appId, eventId, componentId);
    this.exhibitorId = exhibitorId !== undefined ? `/${exhibitorId}` : '';
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.EXHIBITORS_COMPONENT_SEGMENT_NAME}${this.exhibitorId}`;
  }
}

export class FormsDeepLink extends ComponentDeepLink {
  formId?: string;

  constructor(appId: number, eventId: number, componentId: number, formId?: number) {
    super(appId, eventId, componentId);
    this.formId = formId ? `/${formId}` : '';
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.FORM_COMPONENT_SEGMENT_NAME}${this.formId}`;
  }
}

export class FeedWallDeepLink extends ComponentDeepLink {
  channelId: string;
  threadId?: number;

  constructor(appId: number, eventId: number, componentId: number, channelId: number, threadId?: number) {
    super(appId, eventId, componentId);
    this.channelId = channelId ? `/${channelId}` : '';
    this.threadId = threadId;
  }

  getDeepLink(): string {
    let string = `${super.getDeepLink()}/${DeepLinkConstants.FEEDWALL_COMPONENT_SEGMENT_NAME}${this.channelId}`;
    if(this.threadId) {
      string = `${string}/${DeepLinkConstants.FEEDWALL_THREAD_SEGMENT_NAME}/${this.threadId}`;
    }
    return string;
  }
}

export class VenuesDeepLink extends ComponentDeepLink {
  categoryId: string;
  locationId?: number;

  constructor(appId: number, eventId: number, componentId: number, categoryId: number, locationId?: number) {
    super(appId, eventId, componentId);
    this.categoryId = categoryId !== undefined ? `/${categoryId}` : '';
    this.locationId = locationId
  }

  getDeepLink(): string {
    let string =`${super.getDeepLink()}/${DeepLinkConstants.VENUE_COMPONENT_SEGMENT_NAME}${this.categoryId}`;
    if(this.locationId) {
      string = `${string}/${DeepLinkConstants.VENUE_LOCATION_SEGMENT_NAME}/${this.locationId}`;
    }
    return string;
  }
}

export class SpeakersDeepLink extends ComponentDeepLink {
  speakerId: string;

  constructor(appId: number, eventId: number, componentId: number, speakerId: number) {
    super(appId, eventId, componentId);
    this.speakerId = speakerId !== undefined ? `/${speakerId}` : '';
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.SPEAKER_COMPONENT_SEGMENT_NAME}${this.speakerId}`;
  }
}

export class AttendeesDeepLink extends ComponentDeepLink {
  userUuid: string;

  constructor(appId: number, eventId: number, componentId: number, userUuid: string) {
    super(appId, eventId, componentId);
    this.userUuid = userUuid !== undefined ? `/${userUuid}` : '';
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.ATTENDEES_COMPONENT_SEGMENT_NAME}${this.userUuid}`;
  }
}

export class PartnersDeepLink extends ComponentDeepLink {
  partnerId: string;

  constructor(appId: number, eventId: number, componentId: number, partnerId: number) {
    super(appId, eventId, componentId);
    this.partnerId = partnerId !== undefined ? `/${partnerId}` : '';
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.PARTNER_COMPONENT_SEGMENT_NAME}${this.partnerId}`;
  }
}

export class ResourcesDeepLink extends ComponentDeepLink {
  categoryId: string;
  resourceId?: number;

  constructor(appId: number, eventId: number, componentId: number, categoryId: number, resourceId?: number) {
    super(appId, eventId, componentId);
    this.categoryId = categoryId !== undefined ? `/${categoryId}` : '';
    this.resourceId = resourceId;
  }

  getDeepLink(): string {
    let string =`${super.getDeepLink()}/${DeepLinkConstants.RESOURCE_COMPONENT_SEGMENT_NAME}${this.categoryId}`;
    if(this.resourceId) {
      string = `${string}/${DeepLinkConstants.RESOURCE_SEGMENT_NAME}/${this.resourceId}`;
    }
    return string;
  }
}

export class SocialMediaDeepLink extends ComponentDeepLink {
  postId: string;

  constructor(appId: number, eventId: number, componentId: number, postId: number) {
    super(appId, eventId, componentId);
    this.postId = postId !== undefined ? `/${postId}` : '';
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.SOCIAL_MEDIA_COMPONENT_SEGMENT_NAME}${this.postId}`;
  }
}

export class BusinessMatchingDeepLink extends ComponentDeepLink {
  tabName?: string;
  meetingId?: number;

  constructor(appId: number, eventId: number, componentId: number, tabName: string, meetingId: number) {
    super(appId, eventId, componentId);
    this.tabName = tabName !== undefined ? `/${tabName}` : '';
    this.meetingId = meetingId;
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.BUSINESS_MATCHING_COMPONENT_SEGMENT_NAME}${this.tabName}/${this.meetingId}`;
  }
}

export class BusinessMatchingVideoCallDeepLink extends ComponentDeepLink {
  jitsiRoom: string;

  constructor(appId: number, eventId: number, componentId: number, jitsiRoom: number) {
    super(appId, eventId, componentId);
    this.jitsiRoom = jitsiRoom !== undefined ? `/${jitsiRoom}` : '';
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.BUSINESS_MATCHING_COMPONENT_SEGMENT_NAME}/${DeepLinkConstants.BUSINESS_MATCHING_ACCEPTED_TAB_SEGMENT_NAME}/${DeepLinkConstants.BUSINESS_MATCHING_MEETING_ROOM_SEGMENT_NAME}${this.jitsiRoom}`;
  }
}

export class QuizzesDeepLink extends ComponentDeepLink {
  quizId: string;

  constructor(appId: number, eventId: number, componentId: number, quizId: number) {
    super(appId, eventId, componentId);
    this.quizId = quizId !== undefined ? `/${quizId}` : '';
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.QUIZ_COMPONENT_SEGMENT_NAME}${this.quizId}`;
  }
}

export class InteractiveMapDeepLink extends ComponentDeepLink {
  mapId: string;
  locationId?: number;

  constructor(appId: number, eventId: number, componentId: number, mapId: number, locationId?: number) {
    super(appId, eventId, componentId);
    this.mapId = mapId !== undefined ? `/${mapId}` : '';
    this.locationId = locationId;
  }

  getDeepLink(): string {
    let string = `${super.getDeepLink()}/${DeepLinkConstants.INTERACTIVE_MAP_COMPONENT_SEGMENT_NAME}${this.mapId}`;
    if(this.locationId) {
      string = `${string}/${DeepLinkConstants.INTERACTIVE_MAP_LOCATION_SEGMENT_NAME}/${this.locationId}`;
    }
    return string;
  }
}

export class AudioVisualDeepLink extends ComponentDeepLink {
  audioVisualCategoryId: string;
  fileId?: number;

  constructor(appId: number, eventId: number, componentId: number, audioVisualCategoryId: number, fileId?: number) {
    super(appId, eventId, componentId);
    this.audioVisualCategoryId = audioVisualCategoryId !== undefined ? `/${audioVisualCategoryId}` : '';
    this.fileId = fileId;
  }

  getDeepLink(): string {
    let string = `${super.getDeepLink()}/${DeepLinkConstants.AUDIO_VISUAL_COMPONENT_SEGMENT_NAME}${this.audioVisualCategoryId}`;
    if(this.fileId) {
      string = `${string}/${DeepLinkConstants.AUDIO_VISUAL_FILE_SEGMENT_NAME}/${this.fileId}`;
    }
    return string;
  }
}

export class ContentUnlockerDeepLink extends ComponentDeepLink {
  qrCodeUuid: string;

  constructor(appId: number, eventId: number, componentId: number, qrCodeUuid: string) {
    super(appId, eventId, componentId);
    this.qrCodeUuid = qrCodeUuid !== undefined ? `/${qrCodeUuid}` : '';
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.QR_CODE_USER_GROUPS_COMPONENT_SEGMENT_NAME}${this.qrCodeUuid}`;
  }
}

export class BookingDeepLink extends ComponentDeepLink {
  tabName: string;
  reservationId: number;

  constructor(appId: number, eventId: number, componentId: number, tabName: string, reservationId: number) {
    super(appId, eventId, componentId);
    this.tabName = tabName !== undefined ? `/${tabName}` : '';
    this.reservationId = reservationId;
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.BOOKING_COMPONENT_SEGMENT_NAME}${this.tabName}/${this.reservationId}`;
  }
}

export class TaxiDeepLink extends ComponentDeepLink {
  destinationId: string;

  constructor(appId: number, eventId: number, componentId: number, destinationId: number) {
    super(appId, eventId, componentId);
    this.destinationId = destinationId !== undefined ? `/${destinationId}` : '';
  }

  getDeepLink(): string {
    return `${super.getDeepLink()}/${DeepLinkConstants.TAXI_COMPONENT_SEGMENT_NAME}/${DeepLinkConstants.TAXI_DESTINATION_SEGMENT_NAME}${this.destinationId}`;
  }
}

// export class DeepLinkDynamic {
//
// }

// export enum DeepLinks {
//   Friends = 'FriendsDeepLink',
//   Inbox = 'InboxDeepLink',
//   Notification = 'NotificationDeepLink',
//   MyProfile = 'MyProfileDeepLink',
//   Settings = 'SettingsDeepLink',
//   UserDeepLink = 'UserDeepLink',
//   UserVideoCall = 'UserVideoCallDeepLink',
//   Event = 'EventDeepLink',
//   Component = 'ComponentDeepLink',
//   Agenda = 'AgendaDeepLink',
//   Exhibitors = 'ExhibitorsDeepLink',
//   FeedWall = 'FeedWallDeepLink',
//   Venues = 'VenuesDeepLink',
//   Speakers = 'SpeakersDeepLink',
//   Attendees = 'AttendeesDeepLink',
//   Partners = 'PartnersDeepLink',
//   Resources = 'ResourcesDeepLink',
//   BusinessMatching = 'BusinessMatchingDeepLink',
//   BusinessMatchingVideoCall = 'BusinessMatchingVideoCallDeepLink',
//   Quizzes = 'QuizzesDeepLink',
//   InteractiveMap = 'InteractiveMapDeepLink',
//   ContentUnlocker = 'ContentUnlockerDeepLink',
//   Booking = 'BookingDeepLink',
//   Taxi = 'TaxiDeepLink'
// }

<div class="input-group">
  <input type="text" class="form-control" placeholder="00:00" [formControl]="inputControl" (blur)="onInputBlur($event)" (keydown.backspace)="clearNextDay()">

  <div class="input-group-append">
    <div class="button-group">
      <button class="btn btn-outline-secondary" type="button" (click)="onSpanClick($event, span)" [disabled]="inputControl.disabled">+</button>
      <button class="btn btn-outline-secondary" type="button" (click)="onSpanClick($event, -span)" [disabled]="inputControl.disabled">-</button>
    </div>
  </div>
</div>

<p *ngIf="isNextDay">{{ 'others.next_day' | translate }}</p>

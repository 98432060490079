import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { AgendaSessionModel } from '@components/agenda/models/agenda-session.model';
import { AgendaTranslationModel } from '@components/agenda/models/agenda-translation.model';
import { AgendaTicketModel } from '@components/agenda/models/agenda-ticket.model';

import * as AgendaSessionActions from './actions/agenda-session.actions';
import * as fromAgenda from './reducers';
import { TagApiModel } from '../tags/tags.models';

@Injectable({
  providedIn: 'root'
})
export class AgendaSessionFacadeService {

  constructor(private store$: Store<any>) {}

  getAllAgendaSessions(): Observable<AgendaSessionModel[]> {
    return this.store$.pipe(select(fromAgenda.selectAllAgendaSessions));
  }

  getAgendaSessions(component: ComponentModel): Observable<AgendaSessionModel[]> {
    return this.store$.pipe(select(fromAgenda.selectAgendaSessionsFromComponent({componentId: component.id})))
  }

  getAgendaSessionLoading(): Observable<boolean> {
    return this.store$.pipe(select(fromAgenda.selectAgendaSessionLoading));
  }

  getAgendaSessionLoaded(): Observable<boolean> {
    return this.store$.pipe(select(fromAgenda.selectAgendaSessionLoaded));
  }

  getAgendaSessionError(): Observable<Error> {
    return this.store$.pipe(select(fromAgenda.selectAgendaSessionError));
  }

  getAgendaSessionUpdating(): Observable<boolean> {
    return this.store$.pipe(select(fromAgenda.selectAgendaSessionUpdating));
  }
  
  getAgendaSessionReordering(): Observable<boolean> {
    return this.store$.pipe(select(fromAgenda.selectAgendaSessionReordering));
  }

  getUpdatedAgendaSession(): Observable<AgendaSessionModel> {
    return this.store$.pipe(select(fromAgenda.selectUpdatedAgendaSession));
  }

  getVisibleAgendaSessions(): Observable<AgendaSessionModel[]> {
    return this.store$.pipe(select(fromAgenda.selectVisibleAgendaSessions));
  }

  getSelectedAgendaSession(): Observable<AgendaSessionModel> {
    return this.store$.pipe(select(fromAgenda.selectSelectedAgendaSession));
  }

  getAgendaSessionsDiscussionEnabled(): Observable<boolean> {
    return this.store$.pipe(select(fromAgenda.selectAgendaSessionsDiscussionEnabled));
  }

  getAgendaSessionsFeedbackEnabled(): Observable<boolean> {
    return this.store$.pipe(select(fromAgenda.selectAgendaSessionsFeedbackEnabled));
  }

  loadAgendaSessions(event: EventModel, component: ComponentModel, loadGroups = true) {
    this.store$.dispatch(AgendaSessionActions.loadAgendaSessions({ event, component, loadGroups }));
  }

  createAgendaSession(event: EventModel, component: ComponentModel, session: AgendaSessionModel, translations: AgendaTranslationModel[], tickets: AgendaTicketModel[], userGroupTags: TagApiModel[]) {
    this.store$.dispatch(AgendaSessionActions.createAgendaSession({ event, component, session, translations, tickets, userGroupTags }));
  }

  updateSessionParentIdStatus(session: AgendaSessionModel, status: number | null): void {
    this.store$.dispatch(AgendaSessionActions.updateSessionParentIdStatus({ session, status }));
  }

  updateAgendaSession(event: EventModel, component: ComponentModel, session: AgendaSessionModel, translations: AgendaTranslationModel[], tickets: AgendaTicketModel[], userGroupTags: TagApiModel[]) {
    this.store$.dispatch(AgendaSessionActions.updateAgendaSession({ event, component, session, translations, tickets, userGroupTags }));
  }

  changeAgendaSessionDiscussion(event: EventModel, component: ComponentModel, session: AgendaSessionModel, discussionOn: boolean) {
    this.store$.dispatch(AgendaSessionActions.changeAgendaSessionDiscussion({ event, component, session, discussionOn }));
  }

  changeAgendaSessionFeedback(event: EventModel, component: ComponentModel, session: AgendaSessionModel, enabled: boolean) {
    this.store$.dispatch(AgendaSessionActions.changeAgendaSessionFeedback({ event, component, session, enabled }));
  }

  changeAgendaSessionsDiscussion(event: EventModel, component: ComponentModel, enabled: boolean) {
    this.store$.dispatch(AgendaSessionActions.changeAgendaSessionsDiscussion({ event, component, enabled }));
  }

  changeAgendaSessionsFeedback(event: EventModel, component: ComponentModel, enabled: boolean) {
    this.store$.dispatch(AgendaSessionActions.changeAgendaSessionsFeedback({ event, component, enabled }));
  }

  rescheduleAgendaSession(event: EventModel, component: ComponentModel, session: AgendaSessionModel, timeStart: string, timeEnd: string, orderedSessions: AgendaSessionModel[]) {
    this.store$.dispatch(AgendaSessionActions.rescheduleAgendaSession({ event, component, session, timeStart, timeEnd, orderedSessions }));
  }

  rescheduleAgendaChildSession(event: EventModel, component: ComponentModel, parentSession: AgendaSessionModel, session: AgendaSessionModel, timeStart: string, timeEnd: string, orderedSessions: AgendaSessionModel[]) {
    this.store$.dispatch(AgendaSessionActions.rescheduleAgendaChildSession({ event, component, parentSession, session, timeStart, timeEnd, orderedSessions }));
  }

  reorderAgendaSession(orderedSessions: AgendaSessionModel[]): void {
    this.store$.dispatch(AgendaSessionActions.changeAgendaSessionsOrder({ sessions: orderedSessions }));
  }

  reorderAgendaChildSession(parentSession: AgendaSessionModel, orderedSessions: AgendaSessionModel[]): void {
    this.store$.dispatch(AgendaSessionActions.changeAgendaChildSessionsOrder({ parentSession, sessions: orderedSessions }));
  }

  deleteAgendaSession(event: EventModel, component: ComponentModel, session: AgendaSessionModel) {
    this.store$.dispatch(AgendaSessionActions.deleteAgendaSession({ event, component, session }));
  }

  selectAgendaSession(session: AgendaSessionModel) {
    this.store$.dispatch(AgendaSessionActions.selectAgendaSession({ session }));
  }

  setAgendaSessionsDefault(agendaComponents: ComponentModel[], target: ComponentModel): void {
    this.store$.dispatch(AgendaSessionActions.changeAgendaSessionsDefault({ agendaComponents, target }));
  }

  changeAgendaSessionsOrder(sessions: AgendaSessionModel[]): void {
    this.store$.dispatch(AgendaSessionActions.changeAgendaSessionsOrder({ sessions }));
  }

}

import { Component, OnChanges, Input, Output, HostBinding, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';

import { Observable, Subscription, combineLatest } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { IconFacadeService } from '@store/features/icon/icon-facade.service';

import { IconModel } from '@shared/models/icon.model';
import { ComponentModel } from '@shared/models/component.model';
import { ProfileService } from '@app/backend/services/profile.service';
import { EventFacadeService } from '@store/features/event/event-facade.service';
import { hideForEmail } from '@utils/hide-for.util';
import { ComponentNames } from '@utils/component-names.util';

export const builtInIcons: { [id: string]: IconModel } = {
  '-1': { id: -1, url: '/assets/icons/tag.svg', recommendedFor: [] },
  '-5': { id: -5, url: '/assets/icons/discount.svg', recommendedFor: [] },
  '-2': { id: -2, url: '/assets/icons/friends.svg', recommendedFor: [] },
  '-3': { id: -3, url: '/assets/icons/email.svg', recommendedFor: [] },
};

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnChanges, OnDestroy {
  @Input() components: ComponentModel[];
  @Input() activeComponent: ComponentModel;
  @Input() expanded: boolean;

  @Output() appToggle: EventEmitter<Event>;

  @HostBinding('class.touched') touched: boolean;

  eventComponents: ComponentModel[];
  predefinedComponents: ComponentModel[];

  limitedAccess$: Observable<boolean>;

  private subs = new Subscription();

  constructor(
    private iconFacade: IconFacadeService,
    private profileFacade: ProfileService,
    private eventFacade: EventFacadeService,
  ) {
    this.appToggle = new EventEmitter();

    this.limitedAccess$ = combineLatest([this.eventFacade.getActiveEvent(), this.profileFacade.getProfile()])
      .pipe(first())
      .pipe(map(([event, profile]) => hideForEmail(event, profile)));

  }

  ngOnChanges(changes: SimpleChanges) {
    if ('expanded' in changes && !changes['expanded'].firstChange) {
      this.touched = true;
    }
    this.eventComponents = this.components?.filter(c => c.id > -1);

    this.subs.add(this.limitedAccess$
      .pipe(first())
      .subscribe(limited => {
        if (limited) {
          this.predefinedComponents = this.components?.filter(c => c.componentName === ComponentNames.APPLICATION_USERS);
        } else {
          this.predefinedComponents = this.components?.filter(c => c.id < 0);
        }
      }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getComponentIcon(component: ComponentModel): Observable<string> {
    return this.iconFacade.getIconEntities().pipe(
      map(icons => icons[component.iconId] || builtInIcons[component.iconId]),
      map(icon => icon && icon.url)
    );
  }

  isComponentActive(component: ComponentModel): boolean {
    return component && this.activeComponent && component.id === this.activeComponent.id;
  }

  onToggle(ev: Event) {
    this.appToggle.emit(ev);
  }

  trackByComponentId(index: number, component: ComponentModel) {
    return component.id;
  }
}

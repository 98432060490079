import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import {
  WwwResourceModel,
  WwwResourceCategoryModel,
  WwwResourceTranslationModel,
  WwwResourceCategoryTranslationModel
} from '@components/www-resource/models/www-resource.model';

import * as WwwResourceActions from './actions/www-resource.actions';
import * as CategoryActions from './actions/category.actions';
import * as ModalActions from './actions/modal.actions';
import * as TranslationActions from './actions/translation.actions';

import * as fromComponent from '@store/features/component/reducers';

import * as fromWwwResource from './reducers';
import { ComponentModel } from '@shared/models/component.model';

@Injectable({
  providedIn: 'root'
})
export class WwwResourceFacadeService {
  constructor(private store$: Store<any>) {}

  getWwwResources(): Observable<WwwResourceModel[]> {
    return this.getActiveComponentId().pipe(
      switchMap(componentId =>
        this.store$.pipe(select(fromWwwResource.selectWwwResources, { componentId }))
      )
    );
  }

  getWwwResourcesByComponent(component: ComponentModel): Observable<WwwResourceModel[]> {
    return this.store$.pipe(select(fromWwwResource.selectWwwResources, { componentId: component.id }));
  }

  getWwwResourceLoading(): Observable<boolean> {
    return this.getActiveComponentId().pipe(
      switchMap(componentId =>
        this.store$.pipe(select(fromWwwResource.selectWwwResourceComponentLoading, { componentId }))
      )
    );
  }

  getWwwResourceCategories(): Observable<WwwResourceCategoryModel[]> {
    return this.getActiveComponentId().pipe(
      switchMap(componentId =>
        this.store$.pipe(select(fromWwwResource.selectWwwResourceCategories, { componentId }))
      )
    );
  }

  getWwwResourcesCategoriesByComponent(component: ComponentModel): Observable<WwwResourceCategoryModel[]> {
    return this.store$.pipe(select(fromWwwResource.selectWwwResourceCategories, { componentId: component.id }));
  }

  getWwwResourceCategoriesWithPlaceholder(): Observable<WwwResourceCategoryModel[]> {
    return this.getActiveComponentId().pipe(
      switchMap(componentId =>
        this.store$.pipe(select(fromWwwResource.selectWwwResourceCategoriesWithPlaceholder, { componentId }))
      )
    );
  }

  getWwwResourcePlaceholderShown(): Observable<boolean> {
    return this.getActiveComponentId().pipe(
      switchMap(componentId =>
        this.store$.pipe(select(fromWwwResource.selectWwwResourcePlaceholderShown, { componentId }))
      )
    );
  }

  getWwwResourcePlaceholderFocused(): Observable<boolean> {
    return this.getActiveComponentId().pipe(
      switchMap(componentId =>
        this.store$.pipe(select(fromWwwResource.selectWwwResourcePlaceholderFocused, { componentId }))
      )
    );
  }

  getWwwResourceModalSaving(): Observable<boolean> {
    return this.store$.pipe(select(fromWwwResource.selectWwwResourceModalSaving));
  }

  getWwwResourceModalSaved(): Observable<boolean> {
    return this.store$.pipe(select(fromWwwResource.selectWwwResourceModalSaved));
  }

  getWwwResourceTranslation(wwwResource: WwwResourceModel, language: string) {
    return this.store$.pipe(select(fromWwwResource.selectWwwResourceTranslation, { wwwResourceId: wwwResource.id, language }));
  }

  getWwwResourceTranslationLoading(): Observable<boolean> {
    return this.store$.pipe(select(fromWwwResource.selectWwwResourceTranslationLoading));
  }

  getWwwResourceCategoryTranslation(category: WwwResourceCategoryModel, language: string) {
    return this.store$.pipe(select(fromWwwResource.selectWwwResourceCategoryTranslation(), { categoryId: category.id, language }));
  }

  getWwwResourceCategoryTranslationLoading(): Observable<boolean> {
    return this.store$.pipe(select(fromWwwResource.selectWwwResourceCategoryTranslationLoading));
  }

  loadWwwResources() {
    this.store$.dispatch(WwwResourceActions.loadWwwResources());
  }

  loadWwwResourcesByComponent(component: ComponentModel, loadGroups = true) {
    this.store$.dispatch(WwwResourceActions.loadWwwResourcesByComponent({ component, loadGroups }));
  }

  createWwwResourceCategory(category: WwwResourceCategoryModel) {
    this.store$.dispatch(CategoryActions.createCategory({ category }));
  }

  updateWwwResourceCategory(category: WwwResourceCategoryModel) {
    this.store$.dispatch(CategoryActions.updateCategory({ category }));
  }

  reorderWwwResourceCategories(from: number, to: number) {
    this.store$.dispatch(CategoryActions.reorderCategories({ from, to }));
  }

  reorderWwwResourceItems(from: number, to: number, categoryId: number) {
    this.store$.dispatch(WwwResourceActions.reorderItems({ from, to, categoryId }));
  }

  deleteWwwResourceCategory(category: WwwResourceCategoryModel) {
    this.store$.dispatch(CategoryActions.deleteCategory({ category }));
  }

  selectWwwResourceCategory(category: WwwResourceCategoryModel) {
    this.store$.dispatch(CategoryActions.selectCategory({ category }));
  }

  createWwwResource(wwwResource: WwwResourceModel, translations: WwwResourceTranslationModel[]) {
    this.store$.dispatch(ModalActions.modalCreateWwwResource({ wwwResource, translations }));
  }

  updateWwwResource(wwwResource: WwwResourceModel, translations: WwwResourceTranslationModel[]) {
    this.store$.dispatch(ModalActions.modalUpdateWwwResource({ wwwResource, translations }));
  }

  deleteWwwResource(wwwResource: WwwResourceModel) {
    this.store$.dispatch(WwwResourceActions.deleteWwwResource({ wwwResource }));
  }

  focusCategoryPlaceholder() {
    this.getActiveComponentId().pipe(take(1)).subscribe(
      componentId => this.store$.dispatch(CategoryActions.focusPlaceholder({ componentId }))
    );
  }

  blurCategoryPlaceholder() {
    this.getActiveComponentId().pipe(take(1)).subscribe(
      componentId => this.store$.dispatch(CategoryActions.blurPlaceholder({ componentId }))
    );
  }

  loadWwwResourceTranslation(wwwResource: WwwResourceModel, language: string) {
    this.store$.dispatch(TranslationActions.loadWwwResourceTranslation({ wwwResource, language }));
  }

  loadWwwResourceCategoryTranslation(category: WwwResourceCategoryModel, language: string) {
    this.store$.dispatch(TranslationActions.loadWwwResourceCategoryTranslation({ category, language }));
  }

  updateWwwResourceCategoryTranslations(category: WwwResourceCategoryModel, translations: WwwResourceCategoryTranslationModel[]) {
    this.store$.dispatch(TranslationActions.updateWwwResourceCategoryTranslations({ category, translations }));
  }

  private getActiveComponentId(): Observable<number> {
    return this.store$.pipe(select(fromComponent.selectActiveComponentId));
  }
}

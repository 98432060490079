import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, ElementRef, SimpleChanges } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GoogleApiService } from '@shared/providers/google-api.service';
import { GoogleMapService } from '@shared/providers/google-map.service';

export interface GoogleMapRightClickEvent {
  latitude: number;
  longitude: number;
}

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
  providers: [GoogleMapService]
})
export class GoogleMapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() zoom: number;
  @Input() latitude: number;
  @Input() longitude: number;

  @Output() appRightClick: EventEmitter<GoogleMapRightClickEvent>;

  private mapInstance: any;
  private disposeInstance: () => void;

  private teardown$: Subject<void>;

  constructor(private elementRef: ElementRef, private googleApiService: GoogleApiService, private googleMapService: GoogleMapService) {
    this.zoom = 12;
    this.latitude = 10;
    this.longitude = 1;

    this.appRightClick = new EventEmitter<GoogleMapRightClickEvent>();

    this.teardown$ = new Subject<void>();
  }

  ngOnInit() {
    this.googleApiService.getMapsApi().pipe(
      takeUntil(this.teardown$)
    ).subscribe(mapsApi => this.createMap(mapsApi, mapsApi.event));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.mapInstance) {
      return;
    }

    if ('latitude' in changes || 'longitude' in changes) {
      this.mapInstance.setCenter({ lat: this.latitude || 0, lng: this.longitude || 0 });
    }

    if ('zoom' in changes) {
      this.mapInstance.setZoom(this.zoom);
    }
  }

  ngOnDestroy() {
    this.teardown$.next();
    this.teardown$.complete();

    if (this.mapInstance) {
      this.disposeInstance();
      this.mapInstance = null;
    }
  }

  private createMap({ Map }, event: any) {
    const hostElement: HTMLElement = this.elementRef.nativeElement;

    this.mapInstance = new Map(hostElement, {
      center: { lat: this.latitude || 0, lng: this.longitude || 0 },
      zoom: this.zoom
    });

    this.googleMapService.setMapInstance(this.mapInstance);

    event.addDomListener(this.mapInstance, 'rightclick', ev => {
      this.appRightClick.emit({
        latitude: ev.latLng.lat(),
        longitude: ev.latLng.lng()
      });
    });

    this.disposeInstance = () => {
      event.clearInstanceListeners(this.mapInstance);
    };
  }
}

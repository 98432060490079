import { ProfileModel } from "@app/backend/models/profile.model";

export interface ProfileState {
  loading: boolean;
  loaded: boolean;
  profile: ProfileModel;
  error: Error;
  updating: boolean;
}

export const initialState: ProfileState = {
  loading: false,
  loaded: false,
  profile: null,
  error: null,
  updating: false
};

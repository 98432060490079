import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { randomPastelColor, rgbToHex } from '@utils/colors.util';
import { TagApiModel, TagType } from '@store/features/tags/tags.models';

@Component({
  selector: 'app-tag-database-list',
  templateUrl: './tag-database-list.component.html',
  styleUrls: ['./tag-database-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagDatabaseListComponent),
      multi: true
    }
  ]
})
export class TagDatabaseListComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;

  tagControls: UntypedFormArray;

  private onChange: (tags: TagApiModel[]) => void;
  private onTouched: () => void;

  constructor(private i18n: TranslateService) {
    this.label = this.i18n.instant('others.add_tag');

    this.tagControls = new UntypedFormArray([]);
  }

  ngOnInit() {
    this.tagControls.valueChanges.subscribe(
      value => this.propagateChanges(value)
    );
  }

  isUserTag(tag: TagApiModel) {
    return (tag.type_tag === TagType.User);
  }

  isSystemTag(tag: TagApiModel) {
    return (tag.type_tag === TagType.System);
  }

  onAddTag(ev: Event) {
    const tagControl = new FormControl(this.createTag());
    this.tagControls.push(tagControl);
  }

  onRemoveTagAt(ev: Event, index: number) {
    this.tagControls.removeAt(index);
  }

  writeValue(tags: TagApiModel[]): void {
    this.recreateTagControlsFor(tags);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.tagControls.disable();
    } else {
      this.tagControls.enable();
    }
  }

  private recreateTagControlsFor(tags: TagApiModel[]) {
    this.tagControls.clear();

    for (const tag of tags) {
      this.tagControls.push(new FormControl({ ...tag }));
    }
  }

  private createTag(): TagApiModel {
    const [r, g, b] = randomPastelColor();

    const tagColor = rgbToHex(r, g, b);
    const tagData: TagApiModel = {
      id: 0, event_id: 0, hex: tagColor, value: '', type_tag: TagType.User, subtype_tag: null,
    };

    return tagData;
  }

  private propagateChanges(tags: TagApiModel[]) {
    if (this.onChange) {
      this.onChange([...tags]);
    }
  }
}

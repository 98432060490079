import { createAction, props } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { SpeakerModel } from '@components/speaker/models/speaker.model';

export const loadSpeakers = createAction(
  '[Speaker/Speaker Component] Load Speakers',
  props<{ event: EventModel, component: ComponentModel }>()
);

export const loadSpeakersSuccess = createAction(
  '[Speaker/API] Load Speakers Success',
  props<{ component: ComponentModel, speakers: SpeakerModel[] }>()
);

export const loadSpeakersFailure = createAction(
  '[Speaker/API] Load Speakers Failure',
  props<{ component: ComponentModel, error: Error }>()
);

export const createSpeaker = createAction(
  '[Speaker/Speaker Modal] Create Speaker',
  props<{ event: EventModel, component: ComponentModel, speaker: SpeakerModel }>()
);

export const createSpeakerSuccess = createAction(
  '[Speaker/API] Create Speaker Success',
  props<{ component: ComponentModel, speaker: SpeakerModel }>()
);

export const createSpeakerFailure = createAction(
  '[Speaker/API] Create Speaker Failure',
  props<{ component: ComponentModel, error: Error }>()
);

export const updateSpeaker = createAction(
  '[Speaker/Speaker Modal] Update Speaker',
  props<{ event: EventModel, component: ComponentModel, speaker: SpeakerModel }>()
);

export const updateSpeakerSuccess = createAction(
  '[Speaker/API] Update Speaker Success',
  props<{ component: ComponentModel, speaker: SpeakerModel }>()
);

export const updateSpeakerFailure = createAction(
  '[Speaker/API] Update Speaker Failure',
  props<{ component: ComponentModel, error: Error }>()
);

export const reorderSpeakers = createAction(
  '[Speaker/Speaker Component] Reorder Speakers',
  props<{ event: EventModel, component: ComponentModel, speakers: SpeakerModel[] }>()
);

export const reorderSpeakersSuccess = createAction(
  '[Speaker/API] Reorder Speakers Success',
  props<{ component: ComponentModel, speakers: SpeakerModel[] }>()
);

export const reorderSpeakersFailure = createAction(
  '[Speaker/API] Reorder Speakers Failure',
  props<{ component: ComponentModel, error: Error }>()
);

export const deleteSpeaker = createAction(
  '[Speaker/Speaker Component] Delete Speaker',
  props<{ event: EventModel, component: ComponentModel, speaker: SpeakerModel }>()
);

export const deleteSpeakerSuccess = createAction(
  '[Speaker/API] Delete Speaker Success',
  props<{ component: ComponentModel, speaker: SpeakerModel }>()
);

export const deleteSpeakerFailure = createAction(
  '[Speaker/API] Delete Speaker Failure',
  props<{ component: ComponentModel, error: Error }>()
);

export const clearSpeakers = createAction(
  '[Speaker/Components] Clear Speakers',
  props<{ component: ComponentModel }>()
);

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { ComponentModel } from '@shared/models/component.model';
import { ExhibitorModel } from '@components/exhibitor/models/exhibitor.model';

import * as ExhibitorActions from './actions/exhibitor.actions';
import * as fromExhibitor from './reducers';

@Injectable({
  providedIn: 'root'
})
export class ExhibitorFacadeService {
  constructor(private store$: Store<any>) {}

  getAllExhibitors(): Observable<ExhibitorModel[]> {
    return this.store$.pipe(select(fromExhibitor.selectAllExhibitors));
  }

  getExhibitors(component: ComponentModel): Observable<ExhibitorModel[]> {
    return this.store$.pipe(select(fromExhibitor.selectExhibitors, { componentId: component.id }));
  }

  getExhibitorLoading(component: ComponentModel): Observable<boolean> {
    return this.store$.pipe(select(fromExhibitor.selectExhibitorLoading, { componentId: component.id }));
  }

  getExhibitorLoaded(component: ComponentModel): Observable<boolean> {
    return this.store$.pipe(select(fromExhibitor.selectExhibitorLoaded, { componentId: component.id }));
  }

  loadExhibitors(component: ComponentModel) {
    this.store$.dispatch(ExhibitorActions.loadExhibitors({ component }));
  }

  deleteExhibitor(component: ComponentModel, exhibitor: ExhibitorModel) {
    this.store$.dispatch(ExhibitorActions.deleteExhibitor({ component, exhibitor }));
  }

  reorderExhibitors(component: ComponentModel, exhibitors: ExhibitorModel[]) {
    this.store$.dispatch(ExhibitorActions.reorderExhibitors({ component, exhibitors }));
  }

  sortExhibitorsAscending() {
    this.store$.dispatch(ExhibitorActions.sortExhibitorsAscending());
  }

  sortExhibitorsDescending() {
    this.store$.dispatch(ExhibitorActions.sortExhibitorsDescending());
  }
}

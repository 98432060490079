import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import { AgendaRatingQuestionType } from '@components/agenda/models/agenda-rating-question.model';
import { AgendaRatingGroupModel } from '@components/agenda/models/agenda-rating-group.model';

import * as fromAgenda from './agenda.reducer';
import * as fromAgendaDay from './agenda-day.reducer';
import * as fromAgendaSession from './agenda-session.reducer';
import * as fromAgendaTicket from './agenda-ticket.reducer';
import * as fromAgendaTranslation from './agenda-translation.service';
import * as fromAgendaImport from './agenda-import.reducer';

import * as fromRatingQuestion from './rating-question.reducer';
import * as fromRatingAnswer from './rating-answer.reducer';
import * as fromRating from './rating.reducer';

import * as fromAttendee from './attendee.reducer';

import * as fromSpeakerCategory from './speaker-category.reducer';

export const featureKey = 'agendaFeature';

export interface State {
  [fromAgenda.stateKey]: fromAgenda.State;
  [fromAgendaDay.featureKey]: fromAgendaDay.State;
  [fromAgendaSession.featureKey]: fromAgendaSession.State;
  [fromAgendaTicket.featureKey]: fromAgendaTicket.State;
  [fromAgendaTranslation.featureKey]: fromAgendaTranslation.State;
  [fromAgendaImport.stateKey]: fromAgendaImport.State;
  [fromRatingQuestion.stateKey]: fromRatingQuestion.State;
  [fromRatingAnswer.stateKey]: fromRatingAnswer.State;
  [fromRating.stateKey]: fromRating.State;
  [fromAttendee.stateKey]: fromAttendee.State;
  [fromSpeakerCategory.stateKey]: fromSpeakerCategory.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromAgenda.stateKey]: fromAgenda.reducer,
  [fromAgendaDay.featureKey]: fromAgendaDay.reducer,
  [fromAgendaSession.featureKey]: fromAgendaSession.reducer,
  [fromAgendaTicket.featureKey]: fromAgendaTicket.reducer,
  [fromAgendaTranslation.featureKey]: fromAgendaTranslation.reducer,
  [fromAgendaImport.stateKey]: fromAgendaImport.reducer,
  [fromRatingQuestion.stateKey]: fromRatingQuestion.reducer,
  [fromRatingAnswer.stateKey]: fromRatingAnswer.reducer,
  [fromRating.stateKey]: fromRating.reducer,
  [fromAttendee.stateKey]: fromAttendee.reducer,
  [fromSpeakerCategory.stateKey]: fromSpeakerCategory.reducer,
};

export const selectAgendaFeature = createFeatureSelector<State>(featureKey);

export const selectAgendaState = createSelector(
  selectAgendaFeature,
  state => state[fromAgenda.stateKey]
);

export const selectAgendaExporting = createSelector(
  selectAgendaState,
  fromAgenda.selectExporting
);

export const selectAgendaError = createSelector(
  selectAgendaState,
  fromAgenda.selectError
);

export const selectAgendaDayState = createSelector(
  selectAgendaFeature,
  state => state[fromAgendaDay.featureKey]
);

export const selectAllAgendaDays = createSelector(
  selectAgendaDayState,
  fromAgendaDay.selectAllAgendaDays
);

export const selectAgendaDayLoading = createSelector(
  selectAgendaDayState,
  fromAgendaDay.selectAgendaDayLoading
);

export const selectAgendaDayLoaded = createSelector(
  selectAgendaDayState,
  fromAgendaDay.selectAgendaDayLoaded
);

export const selectAgendaDayError = createSelector(
  selectAgendaDayState,
  fromAgendaDay.selectAgendaDayError
);

export const selectActiveAgendaDay = createSelector(
  selectAgendaDayState,
  fromAgendaDay.selectActiveAgendaDay
);

export const selectUpdatedAgendaDay = createSelector(
  selectAgendaDayState,
  fromAgendaDay.selectUpdatedAgendaDay
);

export const selectAgendaSessionState = createSelector(
  selectAgendaFeature,
  state => state[fromAgendaSession.featureKey]
);

export const selectAllAgendaSessions = createSelector(
  selectAgendaSessionState,
  fromAgendaSession.selectAllAgendaSessions
);

export const selectAgendaSessionsFromComponent = (props: {componentId: number}) => createSelector(
  selectAgendaSessionState,
  fromAgendaSession.selectAgendaSessionsFromComponent(props)
)

export const selectAgendaSessionLoading = createSelector(
  selectAgendaSessionState,
  fromAgendaSession.selectAgendaSessionLoading
);

export const selectAgendaSessionLoaded = createSelector(
  selectAgendaSessionState,
  fromAgendaSession.selectAgendaSessionLoaded
);

export const selectAgendaSessionError = createSelector(
  selectAgendaSessionState,
  fromAgendaSession.selectAgendaSessionError
);

export const selectActiveAgendaSession = createSelector(
  selectAgendaSessionState,
  fromAgendaSession.selectActiveAgendaSession
);

export const selectAgendaSessionUpdating = createSelector(
  selectAgendaSessionState,
  fromAgendaSession.selectAgendaSessionUpdating
);

export const selectAgendaSessionReordering = createSelector(
  selectAgendaSessionState,
  fromAgendaSession.selectAgendaSessionReordering
);

export const selectUpdatedAgendaSession = createSelector(
  selectAgendaSessionState,
  fromAgendaSession.selectUpdatedAgendaSession
);

export const selectAgendaSessionsDiscussionEnabled = createSelector(
  selectAgendaSessionState,
  fromAgendaSession.selectAgendaSessionsDiscussionEnabled
);

export const selectAgendaSessionsFeedbackEnabled = createSelector(
  selectAgendaSessionState,
  fromAgendaSession.selectAgendaSessionsFeedbackEnabled
);

export const selectVisibleAgendaSessions = createSelector(
  selectAllAgendaSessions,
  selectActiveAgendaDay,
  (sessions, activeDay) => {
    if (activeDay) {
      const dayId = activeDay.id;
      return sessions.filter(session => session.agendaSessionDayId === dayId);
    } else {
      // Return empty collection on invalid active day
      return [];
    }
  }
);

export const selectSelectedAgendaSession = createSelector(
  selectAgendaSessionState,
  fromAgendaSession.selectSelectedAgendaSession
);

export const selectAgendaTicketState = createSelector(
  selectAgendaFeature,
  state => state[fromAgendaTicket.featureKey]
);

export const selectAllAgendaTickets = createSelector(
  selectAgendaTicketState,
  fromAgendaTicket.selectAllAgendaTickets
);

export const selectAgendaTicketLoading = createSelector(
  selectAgendaTicketState,
  fromAgendaTicket.selectAgendaTicketLoading
);

export const selectAgendaTicketLoaded = createSelector(
  selectAgendaTicketState,
  fromAgendaTicket.selectAgendaTicketLoaded
);

export const selectAgendaTicketError = createSelector(
  selectAgendaTicketState,
  fromAgendaTicket.selectAgendaTicketError
);

export const selectAgendaTranslationState = createSelector(
  selectAgendaFeature,
  state => state[fromAgendaTranslation.featureKey]
);

export const selectAgendaTranslationEntities = createSelector(
  selectAgendaTranslationState,
  fromAgendaTranslation.selectAgendaTranslationEntities
);

export const selectAllAgendaTranslations = createSelector(
  selectAgendaTranslationState,
  fromAgendaTranslation.selectAllAgendaTranslations
);

export const selectAgendaTranslation = () => createSelector(
  selectAgendaTranslationEntities,
  (translations, params) => translations[params.language]
);

export const selectAgendaTranslationLoading = createSelector(
  selectAgendaTranslationState,
  fromAgendaTranslation.selectAgendaTranslationLoading
);

export const selectAgendaImportState = createSelector(
  selectAgendaFeature,
  state => state[fromAgendaImport.stateKey]
);

export const selectAgendaImporting = createSelector(
  selectAgendaImportState,
  fromAgendaImport.selectImporting
);

export const selectAgendaImportImportingError = createSelector(
  selectAgendaImportState,
  fromAgendaImport.selectImportingError
);

export const selectAgendaImportLoading = createSelector(
  selectAgendaImportState,
  fromAgendaImport.selectLoading
);

export const selectAgendaImportUpdating = createSelector(
  selectAgendaImportState,
  fromAgendaImport.selectUpdating
);

export const selectAgendaImportImporting = createSelector(
  selectAgendaImportState,
  fromAgendaImport.selectImporting
);

export const selectAgendaImportConfig = createSelector(
  selectAgendaImportState,
  fromAgendaImport.selectConfig
);

export const selectAgendaImportError = createSelector(
  selectAgendaImportState,
  fromAgendaImport.selectError
);

export const selectRatingQuestionState = createSelector(
  selectAgendaFeature,
  state => state[fromRatingQuestion.stateKey]
);

export const selectRatingQuestionEntities = createSelector(
  selectRatingQuestionState,
  fromRatingQuestion.selectRatingQuestionEntities
);

export const selectAllRatingQuestions = createSelector(
  selectRatingQuestionState,
  fromRatingQuestion.selectAllRatingQuestions
);

export const selectRatingQuestionLoading = createSelector(
  selectRatingQuestionState,
  fromRatingQuestion.selectRatingQuestionLoading
);

export const selectRatingQuestionLoaded = createSelector(
  selectRatingQuestionState,
  fromRatingQuestion.selectRatingQuestionLoaded
);

export const selectRatingQuestionCreating = createSelector(
  selectRatingQuestionState,
  fromRatingQuestion.selectRatingQuestionCreating
);

export const selectCreatedRatingQuestion = createSelector(
  selectRatingQuestionState,
  fromRatingQuestion.selectCreatedRatingQuestion
);

export const selectRatingQuestionUpdating = createSelector(
  selectRatingQuestionState,
  fromRatingQuestion.selectRatingQuestionUpdating
);

export const selectUpdatedRatingQuestion = createSelector(
  selectRatingQuestionState,
  fromRatingQuestion.selectUpdatedRatingQuestion
);

export const selectRatingQuestionError = createSelector(
  selectRatingQuestionState,
  fromRatingQuestion.selectRatingQuestionError
);

export const selectRatingAnswerState = createSelector(
  selectAgendaFeature,
  state => state[fromRatingAnswer.stateKey]
);

export const selectRatingAnswerEntities = createSelector(
  selectRatingAnswerState,
  fromRatingAnswer.selectRatingAnswerEntities
);

export const selectAllRatingAnswers = createSelector(
  selectRatingAnswerState,
  fromRatingAnswer.selectAllRatingAnswers
);

export const selectRatingAnswers = createSelector(
  selectRatingAnswerState,
  fromRatingAnswer.selectRatingAnswers
);

export const selectRatingState = createSelector(
  selectAgendaFeature,
  state => state[fromRating.stateKey]
);

export const selectAllRatings = createSelector(
  selectRatingState,
  fromRating.selectAllRatings
);

export const selectRatingLoading = createSelector(
  selectRatingState,
  fromRating.selectRatingLoading
);

export const selectRatingLoaded = createSelector(
  selectRatingState,
  fromRating.selectRatingLoaded
);

export const selectRatingError = createSelector(
  selectRatingState,
  fromRating.selectRatingError
);

export const selectStarRatings = createSelector(
  selectRatingQuestionEntities,
  selectAllRatings,
  (questions, ratings) => {
    return ratings.filter(
      rate => questions[rate.questionId].type === AgendaRatingQuestionType.Stars
    );
  }
);

export const selectOverallRating = createSelector(
  selectRatingAnswerEntities,
  selectStarRatings,
  (answers, ratings) => {
    const summedRatings = ratings.map(
      rate => answers[rate.responseIds[0]].order
    ).reduce(
      (acc, rate) => acc + rate, 0
    );

    return summedRatings / ratings.length;
  }
);

export const selectGroupedRatings = createSelector(
  selectAllRatings,
  ratings => {
    const grouped: AgendaRatingGroupModel[] = [];
    for (const rating of ratings) {
      const last = grouped[grouped.length - 1];

      if (last && last.deviceId === rating.deviceId) {
        last.ratings.push(rating);
      } else {
        grouped.push({
          deviceId: rating.deviceId,
          user: rating.user,
          ratings: [rating]
        });
      }
    }

    return grouped;
  }
);

export const selectAttendeeState = createSelector(
  selectAgendaFeature,
  state => state[fromAttendee.stateKey]
);

export const selectAllAttendees = createSelector(
  selectAttendeeState,
  fromAttendee.selectAllAttendees
);

export const selectAttendeeLoading = createSelector(
  selectAttendeeState,
  fromAttendee.selectAttendeeLoading
);

export const selectAttendeeLoaded = createSelector(
  selectAttendeeState,
  fromAttendee.selectAttendeeLoaded
);

export const selectAttendeeError = createSelector(
  selectAttendeeState,
  fromAttendee.selectAttendeeError
);

export const selectAttendeeSearchTerm = createSelector(
  selectAttendeeState,
  fromAttendee.selectAttendeeSearchTerm
);

export const selectLastAttendee = createSelector(
  selectAttendeeState,
  fromAttendee.selectLastAttendee
);


// speaker category selectors - START

export const selectSpeakerCategoryState = createSelector(
  selectAgendaFeature,
  state => state[fromSpeakerCategory.stateKey]
);

export const selectSpeakerCategoryBaseLoading = createSelector(
  selectSpeakerCategoryState,
  fromSpeakerCategory.selectBaseLoading
);

export const selectSpeakerCategoryBaseUpdating = createSelector(
  selectSpeakerCategoryState,
  fromSpeakerCategory.selectBaseUpdating
);

export const selectSpeakerCategoryTranslationsLoading = createSelector(
  selectSpeakerCategoryState,
  fromSpeakerCategory.selectTranslationsLoading
);

export const selectSpeakerCategoryTranslationsUpdating = createSelector(
  selectSpeakerCategoryState,
  fromSpeakerCategory.selectTranslationsUpdating
);

export const selectSpeakerCategoryError = createSelector(
  selectSpeakerCategoryState,
  fromSpeakerCategory.selectError
);

export const selectSpeakerCategoryEntities = createSelector(
  selectSpeakerCategoryState,
  fromSpeakerCategory.selectEntities
);

export const selectSpeakerCategoryTranslations = createSelector(
  selectSpeakerCategoryState,
  fromSpeakerCategory.selectTranslations
);

// speaker category selectors - END
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { PartnerTranslationModel } from '@components/partner/models/partner-translation.model';

import * as PartnerTranslationActions from '../actions/partner-translation.actions';
import * as ImportActions from '../actions/partner-import.actions';

export const stateKey = 'partnerTranslation';

export interface State extends EntityState<PartnerTranslationModel> {
  loading: boolean;
  error: Error;
}

export const adapter = createEntityAdapter<PartnerTranslationModel>({
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});

export const partnerTranslationReducer = createReducer(
  initialState,
  on(
    PartnerTranslationActions.loadPartnerTranslation,
    (state, { partner }) => ({ ...initialState, loading: true })
  ),
  on(
    PartnerTranslationActions.loadPartnerTranslationSuccess,
    (state, { partner, translation }) =>
      adapter.upsertOne(translation, { ...state, loading: false })
  ),
  on(
    PartnerTranslationActions.loadPartnerTranslationFailure,
    (state, { partner, error }) => ({ ...state, loading: false, error })
  ),
  on(
    PartnerTranslationActions.updatePartnerTranslationsSuccess,
    (state, { partner, translations }) =>
      adapter.upsertMany(translations, state)
  ),
  on(
    PartnerTranslationActions.updatePartnerTranslationsFailure,
    (state, { partner, error }) => ({ ...state, error })
  ),
  on(ImportActions.importPartnersSuccess, state => ({ ...initialState }))
);

export function reducer(state: State, action: Action): State {
  return partnerTranslationReducer(state, action);
}

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectPartnerTranslationEntities = selectEntities;

export const selectAllPartnerTranslations = selectAll;

export const selectPartnerTranslations = createSelector(
  selectAllPartnerTranslations,
  (partners: PartnerTranslationModel[], { partnerId, language }) =>
    partners.filter(partner => partner.partnerId === partnerId)
);

export const selectPartnerTranslation = createSelector(
  selectPartnerTranslations,
  (partners: PartnerTranslationModel[], { language }) =>
    partners.find(partner => partner.language === language)
);

export const selectPartnerTranslationLoading = (state: State) => state.loading;

export const selectPartnerTranslationError = (state: State) => state.error;

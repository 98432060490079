import { TagGroup, TagPatchModel, UserGroupAddMulti, UserGroupManage, UserGroupTag } from './../tags.models';
import { TagPostModel } from './../tags.models';
import { TagApiModel } from './../tags.models';
import { createAction } from "@ngrx/store";
import { EventModel } from '@store/features/event/models/event.model';

const loadAllTags = createAction(
  '[Tags] Load All Tags',
  (event: EventModel) => ({ event })
);

const loadTags = createAction(
  '[Tags] Load Tags',
  (event: EventModel, tagGroup: TagGroup) => ({ event, tagGroup })
);

const loadTagsSuccess = createAction(
  '[Tags] Load Tags Success',
  (tags: TagApiModel[], tagGroup) => ({ tags, tagGroup })
);

const loadTagsFailure = createAction(
  '[Tags] Load Tags Failure',
  (error: any) => ({ error })
);

const createTag = createAction(
  '[TAGS] Create Tag',
  (event: EventModel, payload: TagPostModel) => ({ event, payload })
);

const createTagSuccess = createAction(
  '[TAGS] Create Tag Success',
  (tag: TagApiModel, tagGroup: TagGroup) => ({ tag, tagGroup })
);

const createTagFailure = createAction(
  '[TAGS] Create Tag Failure',
  (error: any) => ({ error })
);

const updateTag = createAction(
  '[TAGS] Update Tag',
  (event: EventModel, tagGroup: TagGroup, tagId: number, payload: TagPatchModel) => ({ event, tagGroup, tagId, payload })
);

const updateTagSuccess = createAction(
  '[TAGS] Update Tag Success',
  (tag: TagApiModel, tagGroup: TagGroup) => ({ tag, tagGroup })
);

const updateTagFailure = createAction(
  '[TAGS] Update Tag Failure',
  (error: any) => ({ error })
);

const deleteTag = createAction(
  '[TAGS] Delete Tag',
  (event: EventModel, tagGroup: TagGroup, tagId: number) => ({ event, tagGroup, tagId })
);

const deleteTagSuccess = createAction(
  '[TAGS] Delete Tag Success',
  (tagId: number, tagGroup: TagGroup) => ({ tagId, tagGroup })
);

const deleteTagFailure = createAction(
  '[TAGS] Delete Tag Failure',
  (error: any) => ({ error })
);

const reorderTags = createAction(
  '[TAGS] Reorder Tags',
  (event: EventModel, tagGroup: TagGroup, tagIds: number[]) => ({ event, tagGroup, tagIds })
);

const reorderTagsSuccess = createAction(
  '[TAGS] Reorder Tags Success',
  (tagGroup: TagGroup, tags: TagApiModel[]) => ({ tagGroup, tags })
);

const reorderTagsFailure = createAction(
  '[TAGS] Reorder Tags Failure',
  (error: Error) => ({ error })
);

const loadUserGroupTags = createAction(
  '[Tags] Load User Group Tags',
  (event: EventModel, uuids: string[]) => ({ event, uuids })
);

const loadUserGroupTagsSuccess = createAction(
  '[Tags] Load User Group Tags Success',
  (tags: UserGroupTag) => ({ tags })
);

const loadUserGroupTagsFailure = createAction(
  '[Tags] Load User Group Tags Failure',
  (error: any) => ({ error })
);

const addUserGroupTag = createAction(
  '[TAGS] Add User Group Tag',
  (event: EventModel, payload: UserGroupManage) => ({ event, payload })
);

const addUserGroupTagSuccess = createAction(
  '[TAGS] Add User Group Tag Success',
  (tags: TagApiModel[], uuid: string) => ({ tags, uuid })
);

const addUserGroupTagFailure = createAction(
  '[TAGS] Add User Group Tag Failure',
  (error: any) => ({ error })
);

const addMultiUserGroupTags = createAction(
  '[TAGS] Add Multi User Group Tags',
  (event: EventModel, payload: UserGroupAddMulti) => ({ event, payload })
);

const addMultiUserGroupTagsSuccess = createAction(
  '[TAGS] Add Multi User Group Tags Success',
  (tags: TagApiModel[], uuids: string[]) => ({ tags, uuids })
);

const addMultiUserGroupTagsFailure = createAction(
  '[TAGS] Add Multi User Group Tags Failure',
  (error: any) => ({ error })
);

const deleteUserGroupTag = createAction(
  '[TAGS] Delete User Group Tag',
  (event: EventModel, payload: UserGroupManage) => ({ event, payload })
);

const deleteUserGroupTagSuccess = createAction(
  '[TAGS] Delete User Group Tag Success',
  (tags: TagApiModel[], uuid: string) => ({ tags, uuid })
);

const deleteUserGroupTagFailure = createAction(
  '[TAGS] Delete User Group Tag Failure',
  (error: any) => ({ error })
);

// HELPERS
const modifyStateTags = createAction(
  '[TAGS] Modify State Tags',
  (tagGroup: TagGroup, tags: TagApiModel[]) => ({ tagGroup, tags })
);

const deleteEmptyTags = createAction(
  '[TAGS] Delete Empty Tags',
  (tagGroup: TagGroup) => ({ tagGroup })
);

export const TagActions = {
  loadAllTags,

  loadTags,
  loadTagsSuccess,
  loadTagsFailure,

  createTag,
  createTagSuccess,
  createTagFailure,

  updateTag,
  updateTagSuccess,
  updateTagFailure,

  deleteTag,
  deleteTagSuccess,
  deleteTagFailure,

  reorderTags,
  reorderTagsSuccess,
  reorderTagsFailure,

  loadUserGroupTags,
  loadUserGroupTagsSuccess,
  loadUserGroupTagsFailure,
  
  addUserGroupTag,
  addUserGroupTagSuccess,
  addUserGroupTagFailure,

  addMultiUserGroupTags,
  addMultiUserGroupTagsSuccess,
  addMultiUserGroupTagsFailure,

  deleteUserGroupTag,
  deleteUserGroupTagSuccess,
  deleteUserGroupTagFailure,

  modifyStateTags,
  deleteEmptyTags,
}
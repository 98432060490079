import { Component, OnChanges, OnDestroy, Input, SimpleChanges, ElementRef, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import codeMirrorFactor from 'codemirror';

import 'codemirror/addon/display/placeholder';

// TODO: add required language modes
import 'codemirror/mode/css/css';

@Component({
  selector: 'app-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CodeEditorComponent),
      multi: true
    }
  ]
})
export class CodeEditorComponent implements OnChanges, OnDestroy, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() readonly: boolean;
  @Input() mode: string;

  @Input() set value(value: string) {
    this.editorInstance.setValue(value);
  }

  @HostBinding('class.readonly') get _readOnly(): boolean {
    return !!this.readonly;
  }

  get value(): string {
    return this.editorInstance.getValue();
  }

  private editorInstance: any;
  private editorChangeHandler: (editor, ev) => void;
  private editorBlurHandler: (editor, ev) => void;

  private onChange: (value: any) => void;
  private onTouched: () => void;

  constructor(private elementRef: ElementRef) {
    this.editorChangeHandler = (editor, ev) => this.onEditorChange(editor, ev);
    this.editorBlurHandler = (editor, ev) => this.onEditorBlur(editor, ev);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.editorInstance) {
      this.createEditorInstance();
    }

    if ('readonly' in changes) {
      this.editorInstance.setOption('readOnly', this.readonly);
    }
  }

  ngOnDestroy() {
    this.deleteEditorInstance();
  }

  onEditorChange(editor, ev: any) {
    if (this.onChange) {
      this.onChange(editor.getValue());
    }
  }

  onEditorBlur(editor, ev: any) {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  writeValue(value: any): void {
    this.editorInstance.setValue(value || '');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.editorInstance.readOnly = isDisabled;
  }

  private createEditorInstance() {
    const { nativeElement } = this.elementRef;

    this.editorInstance = codeMirrorFactor(nativeElement, {
      placeholder: this.placeholder,
      mode: this.mode,
      lineNumbers: true,
      gutter: true,
      extraKeys: {
        // replaces tab indent by two spaces
        'Tab': editor => {
          editor.replaceSelection('  ', 'end');
        }
      }
    });

    this.editorInstance.on('change', this.editorChangeHandler);
    this.editorInstance.on('blur', this.editorBlurHandler);
  }

  private deleteEditorInstance() {
    this.editorInstance.off('change', this.editorChangeHandler);
    this.editorInstance.off('blur', this.editorBlurHandler);
    this.editorInstance = null;
  }
}

import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, Update, createEntityAdapter } from '@ngrx/entity';

import { ComponentModel } from '@shared/models/component.model';

import * as ComponentActions from '../actions/component.actions';

export const featureKey = 'component';

export interface State extends EntityState<ComponentModel> {
  loading: boolean;
  loaded: boolean;
  error: Error;
  activeId: number;
  updating: boolean;
  updatedId: number;

  allowedComponentIds: number[];
  allowedComponentsLoadError: boolean;
}

export const adapter = createEntityAdapter<ComponentModel>({
  sortComparer: (a, b) => a.order - b.order,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  activeId: 0,
  updating: false,
  updatedId: 0,

  allowedComponentIds: [],
  allowedComponentsLoadError: false,
});

export const componentReducer = createReducer(
  initialState,
  on(ComponentActions.loadComponents, state => ({
    ...initialState,
    loading: true,
  })),
  on(ComponentActions.loadComponentsSuccess, (state, { components }) =>
    adapter.setAll(components, { ...state, loading: false, loaded: true })
  ),
  on(ComponentActions.loadComponentsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(
    ComponentActions.setAllowedComponents,
    (state, { ids }) => ({ ...state, allowedComponentIds: ids, allowedComponentsLoadError: false }),
  ),
  on(
    ComponentActions.setAllowedComponentsFailure,
    (state) => ({ ...state, allowedComponentsLoadError: true }),
  ),
  on(
    ComponentActions.updateComponent,
    ComponentActions.updateOnboarding,
    ComponentActions.updateComponentAdditionalInfo,
    state => ({ ...state, updating: true })
  ),
  on(
    ComponentActions.createComponents,
    ComponentActions.insertComponents,
    ComponentActions.reorderComponents,
    state => ({ ...state, updating: true })
  ),
  on(
    ComponentActions.updateComponentSuccess,
    ComponentActions.updateOnboardingSuccess,
    ComponentActions.updateComponentAdditionalInfoSuccess,
    (state, { component }) =>
      adapter.upsertOne(component, {
        ...state,
        updating: false,
        updatedId: component.id,
      })
  ),
  on(
    ComponentActions.updateComponentFailure,
    ComponentActions.updateOnboardingFailure,
    ComponentActions.updateComponentAdditionalInfoFailure,
    (state, { error }) => ({ ...state, updating: false, error })
  ),
  on(
    ComponentActions.changeComponentVisibility,
    (state, { component, visible }) => {
      // Update locally stored components for instant preview
      // TODO: Add components state revert on failed request
      const update: Update<ComponentModel> = {
        id: component.id,
        changes: { ...component, visibleInApp: visible },
      };

      return adapter.updateOne(update, { ...state, updating: true });
    }
  ),
  on(
    ComponentActions.setComponentFirstToOpen,
    ComponentActions.setComponentFirstToOpenSuccess,
    (state, { component }) => {
      // Update locally stored components for instant preview
      // TODO: Add components state revert on failed request
      const updates: Update<ComponentModel>[] = (state.ids as any[]).map(
        id => ({
          id,
          changes:
            id === component.id
              ? { ...component, defaultComponent: true }
              : { defaultComponent: false },
        })
      );

      return adapter.updateMany(updates, state);
    }
  ),
  on(ComponentActions.reorderComponents, (state, { components }) => {
    // Update locally stored components for instant preview
    // TODO: Add components state revert on failed request
    const updates: Update<ComponentModel>[] = components.map(
      (component, order) => ({
        id: component.id,
        changes: { order },
      })
    );

    return adapter.updateMany(updates, state);
  }),
  on(
    ComponentActions.createComponentsSuccess,
    ComponentActions.insertComponentsSuccess,
    ComponentActions.reorderComponentsSuccess,
    (state, { components }) => adapter.setAll(components, { ...state, updating: false })
  ),
  on(
    ComponentActions.changeComponentVisibilitySuccess,
    (state, { component }) => adapter.upsertOne(component, { ...state, updating: false })
  ),
  on(ComponentActions.deleteComponentSuccess, (state, { components }) =>
    adapter.setAll(components, state),
  ),
  on(
    ComponentActions.createComponentsFailure,
    ComponentActions.insertComponentsFailure,
    ComponentActions.reorderComponentsFailure,
    ComponentActions.changeComponentVisibilityFailure,
    ComponentActions.setComponentFirstToOpenFailure,
    ComponentActions.deleteComponentFailure,
    (state, { error }) => ({ ...state, error, updating: false })
  ),
  on(ComponentActions.selectComponent, (state, { componentId }) => ({
    ...state,
    activeId: componentId,
  })),
);

export function reducer(state: State, action: Action): State {
  return componentReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectComponentIds = selectIds;

export const selectComponentEntities = selectEntities;

export const selectAllComponents = selectAll;

export const selectAllowedComponentIds = (state: State) => state.allowedComponentIds;

export const selectAllowedComponentsLoadError = (state: State) => state.allowedComponentsLoadError;

export const selectComponentLoading = (state: State) => state.loading;

export const selectComponentLoaded = (state: State) => state.loaded;

export const selectComponentError = (state: State) => state.error;

export const selectActiveComponentId = (state: State) => state.activeId;

export const selectActiveComponent = createSelector(
  selectComponentEntities,
  selectActiveComponentId,
  (components, activeId) => components[activeId]
);

export const selectComponentUpdating = (state: State) => state.updating;

export const selectUpdatedComponentId = (state: State) => state.updatedId;

export const selectUpdatedComponent = createSelector(
  selectComponentEntities,
  selectUpdatedComponentId,
  (components, updatedId) => components[updatedId]
);

export const selectComponentsByName = () =>
  createSelector(
    selectAllComponents,
    (
      components: ComponentModel[],
      { componentName }: { componentName: string | string[] }
    ) => {
      if (Array.isArray(componentName)) {
        return components.filter(c => componentName.includes(c.componentName));
      } else {
        return components.filter(c => c.componentName === componentName);
      }
    }
  );

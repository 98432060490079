import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { tokenFeatureKey, tokenReducer } from './store/token.reducer';
import { TokenEffects } from './store/token.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(tokenFeatureKey, tokenReducer), //
    EffectsModule.forFeature([TokenEffects]),
  ],
})
export class CoreStoreModule {}

<h2 mat-dialog-title>{{ title }}</h2>

<ng-container *ngIf="safeMessage; else escapedMessage">
  <mat-dialog-content [innerHTML]="safeMessage">
    <!-- Uses innerHTML parameter binding -->
  </mat-dialog-content>
</ng-container>

<ng-template #escapedMessage>
  <mat-dialog-content>
    {{ message }}
  </mat-dialog-content>
</ng-template>

<mat-dialog-actions class="d-flex justify-content-end">
  <button class="btn btn-primary" type="button" mat-dialog-close>{{ 'others.ok' | translate }}</button>
</mat-dialog-actions>

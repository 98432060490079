import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-settings',
  templateUrl: './button-settings.component.html',
  styleUrls: ['./button-settings.component.scss']
})
export class ButtonSettingsComponent {
  @Input() alt: string;
}

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TagApiModel, TagGroup, TagPatchModel, TagPostModel, UserGroupAddMulti, UserGroupManage, UserGroupTag } from './tags.models';
import { EventModel } from './../event/models/event.model';
import { Injectable } from "@angular/core";
import { API_BASE_URL } from '@env/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class TagsApiService {
  constructor(private http: HttpClient) {}

  getTags(event: EventModel, tagGroup: TagGroup): Observable<TagApiModel[]> {
    return this.http.get<TagApiModel[]>(getBaseTagsUrl(event, tagGroup));
  }

  updateTag(event: EventModel, tagGroup: TagGroup, tagId: number, payload: TagPatchModel): Observable<TagApiModel> {
    if (!payload.value) return; // hotfix for empty tags save
    return this.http.patch<TagApiModel>(getBaseTagsUrl(event, tagGroup, tagId), { ...payload, value: payload.value.toUpperCase() });
  }

  deleteTag(event: EventModel, tagGroup: TagGroup, tagId: number): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(getBaseTagsUrl(event, tagGroup, tagId));
  }

  createTag(event: EventModel, payload: TagPostModel): Observable<TagApiModel> {
    if (!payload.value) return; // hotfix for empty tags save
    return this.http.post<TagApiModel>(getBaseTagsUrl(event, payload.type_tag), { ...payload, value: payload.value.toUpperCase() });
  }

  reorderTags(event: EventModel, tagGroup: TagGroup, tagIds: number[]): Observable<TagApiModel[]> {
    return this.http.patch<TagApiModel[]>(`${getBaseTagsUrl(event, tagGroup)}/order`, { orders: tagIds });
  }

  // USER GROUP TAGS
  getUserGroupTags(event: EventModel, uuids: string[]): Observable<UserGroupTag> {
    return this.http.post<UserGroupTag[]>(`${getBaseUserTagsUrl(event)}/show_tags`, {
      user_identity_tokens: uuids
    }).pipe(
      map(
        data => data.reduce((acc, item) => {
          const key = Object.keys(item)[0];
          acc[key] = item[key];
          return acc;
        })
      )
    );
  }

  deleteUserGroupTag(event: EventModel, payload: UserGroupManage): Observable<TagApiModel[]> {
    return this.http.delete<TagApiModel[]>(`${getBaseUserTagsUrl(event)}/tag`,
      { params: { user_identity_token: payload.user_identity_token, tag_id: payload.tag_id.toString() }});
  }

  addUserGroupTag(event: EventModel, payload: UserGroupManage): Observable<TagApiModel[]> {
    return this.http.post<TagApiModel[]>(`${getBaseUserTagsUrl(event)}/tag`, payload);
  }

  addMultiUsersGroupTags(event: EventModel, payload: UserGroupAddMulti) {
    return this.http.post<TagApiModel[]>(`${getBaseUserTagsUrl(event)}/tags`, payload);
  }

}

function getBaseTagsUrl(event: EventModel, tagGroup?: TagGroup, tagId?: number): string {
  return `${API_BASE_URL}/events/${event.id}/tags` + (tagGroup ? `/${tagGroup}` : '') + (tagId ? `/${tagId}` : '');
}

function getBaseUserTagsUrl(event: EventModel) {
  return `${API_BASE_URL}/events/${event.id}/users`;
}
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { switchMap, exhaustMap, map, tap, catchError } from 'rxjs/operators';

import { TokenStorageService } from '@app/core/services/token-storage.service';
import { TokenApiService } from '../services/token-api.service';

import { ModalMessageComponent } from '@shared/components/modal-message/modal-message.component';

import * as tokenActions from './token.actions';
import { RouterMainView, RouterProviderService } from '@shared/providers/router-provider.service';

@Injectable({
  providedIn: 'root'
})
export class TokenEffects implements OnInitEffects {
  ngrxOnInitEffects(): Action {
    return tokenActions.tokenRetrieve();
  }

  tokenRetrieve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tokenActions.tokenRetrieve),
      switchMap(() =>
        this.tokenStorageService.retrieveLocalToken().pipe(
          map(token => tokenActions.tokenRetrieveSuccess({ token })),
          catchError(error => of(tokenActions.tokenRetrieveFailure({ error })))
        )
      )
    )
  );

  tokenRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tokenActions.tokenRefresh),
      exhaustMap(action =>
        this.tokenApiService.refreshToken(action.token).pipe(
          tap(token => this.tokenStorageService.storeLocalToken(token)),
          map(token => tokenActions.tokenRefreshSuccess({ token })),
          catchError(error => {
            this.tokenStorageService.clearLocalToken();

            const title = 'Session Has Expired';
            const message = 'Your session has expired, please try to login again.';

            return this.openErrorModal(title, message).pipe(
              tap(() => this.routerProvider.toMainView(RouterMainView.ACCOUNT)),
              map(() => tokenActions.tokenRefreshFailure({ error }))
            );
          })
        )
      )
    )
  );

  tokenSetup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(tokenActions.tokenSetup),
        tap(({ token }) => this.tokenStorageService.storeLocalToken(token))
      ),
    { dispatch: false }
  );

  tokenClear$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(tokenActions.tokenClear),
        tap(() => this.tokenStorageService.clearLocalToken())
      ),
    { dispatch: false }
  );

  private openErrorModal(title: string, message: string): Observable<void> {
    const dialogRef = this.dialog.open(ModalMessageComponent, {
      width: '400px',
      height: '300px',
      data: { title, message },
    });

    return dialogRef.afterClosed();
  }

  constructor(
    private routerProvider: RouterProviderService,
    private dialog: MatDialog,
    private actions$: Actions,
    private tokenStorageService: TokenStorageService,
    private tokenApiService: TokenApiService,
  ) {}
}

import { createAction, props } from '@ngrx/store';

import { ComponentModel } from '@shared/models/component.model';
import { ExhibitorModel } from '@components/exhibitor/models/exhibitor.model';

export const loadExhibitors = createAction(
  '[Exhibitor/Exhibitor Component] Load Exhibitors',
  props<{ component: ComponentModel }>()
);

export const loadExhibitorsSuccess = createAction(
  '[Exhibitor/API] Load Exhibitors Success',
  props<{ component: ComponentModel, exhibitors: ExhibitorModel[] }>()
);

export const loadExhibitorsFailure = createAction(
  '[Exhibitor/API] Load Exhibitors Failure',
  props<{ component: ComponentModel, error: Error }>()
);

export const createExhibitor = createAction(
  '[Exhibitor/Exhibitor Modal] Create Exhibitor',
  props<{ component: ComponentModel, exhibitor: ExhibitorModel }>()
);

export const createExhibitorSuccess = createAction(
  '[Exhibitor/API] Create Exhibitor Success',
  props<{ component: ComponentModel, exhibitor: ExhibitorModel }>()
);

export const createExhibitorFailure = createAction(
  '[Exhibitor/API] Create Exhibitor Failure',
  props<{ component: ComponentModel, error: Error }>()
);

export const updateExhibitor = createAction(
  '[Exhibitor/Exhibitor Modal] Update Exhibitor',
  props<{ component: ComponentModel, exhibitor: ExhibitorModel }>()
);

export const updateExhibitorSuccess = createAction(
  '[Exhibitor/API] Update Exhibitor Success',
  props<{ component: ComponentModel, exhibitor: ExhibitorModel }>()
);

export const updateExhibitorFailure = createAction(
  '[Exhibitor/API] Update Exhibitor Failure',
  props<{ component: ComponentModel, error: Error }>()
);

export const reorderExhibitors = createAction(
  '[Exhibitor/Exhibitor Component] Reorder Exhibitors',
  props<{ component: ComponentModel, exhibitors: ExhibitorModel[] }>()
);

export const reorderExhibitorsSuccess = createAction(
  '[Exhibitor/API] Reorder Exhibitors Success',
  props<{ component: ComponentModel, exhibitors: ExhibitorModel[] }>()
);

export const reorderExhibitorsFailure = createAction(
  '[Exhibitor/API] Reorder Exhibitors Failure',
  props<{ component: ComponentModel, error: Error }>()
);

export const deleteExhibitor = createAction(
  '[Exhibitor/Exhibitor Component] Delete Exhibitor',
  props<{ component: ComponentModel, exhibitor: ExhibitorModel }>()
);

export const deleteExhibitorsSuccess = createAction(
  '[Exhibitor/API] Delete Exhibitors Success',
  props<{ component: ComponentModel, exhibitor: ExhibitorModel }>()
);

export const deleteExhibitorsFailure = createAction(
  '[Exhibitor/API] Delete Exhibitors Failure',
  props<{ component: ComponentModel, error: Error }>()
);

export const sortExhibitorsAscending = createAction(
  '[Exhibitor/Exhibitor Component] Sort Exhibitors Ascending',
);

export const sortExhibitorsAscendingSuccess = createAction(
  '[Exhibitor/API] Reorder Exhibitors Ascending Success',
  props<{ component: ComponentModel, exhibitors: ExhibitorModel[] }>()
);

export const sortExhibitorsAscendingFailure = createAction(
  '[Exhibitor/API] Reorder Exhibitors Ascending Failure',
  props<{ component: ComponentModel, error: Error }>()
);

export const sortExhibitorsDescending = createAction(
  '[Exhibitor/Exhibitor Component] Sort Exhibitors Descending',
);

export const sortExhibitorsDescendingSuccess = createAction(
  '[Exhibitor/API] Reorder Exhibitors Descending Success',
  props<{ component: ComponentModel, exhibitors: ExhibitorModel[] }>()
);

export const sortExhibitorsDescendingFailure = createAction(
  '[Exhibitor/API] Reorder Exhibitors Descending Failure',
  props<{ component: ComponentModel, error: Error }>()
);

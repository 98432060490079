<p>{{ 'category_level.choose_priority_size' | translate }}</p>

<div class="level-stars">
  <app-icon
    *ngFor="let level of levels"
    icon="/assets/icons/star.svg"
    [class.active]="selectedLevel === level"
    (click)="onLevelClick($event, level)">
  </app-icon>
</div>

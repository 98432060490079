import { createAction, props } from '@ngrx/store';
import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { SpeakerModel } from '@components/speaker/models/speaker.model';
import { SpeakerTranslationModel } from '@components/speaker/models/speaker-translation.model';

export const modalCreateSpeaker = createAction(
  '[Speaker/Speaker Modal] Modal Create Speaker',
  props<{ event: EventModel, component: ComponentModel, speaker: SpeakerModel, translations: SpeakerTranslationModel[] }>()
);

export const modalCreateSpeakerSuccess = createAction(
  '[Speaker/Speaker Modal] Modal Create Speaker Success',
  props<{ speaker: SpeakerModel, translations: SpeakerTranslationModel[] }>()
);

export const modalCreateSpeakerFailure = createAction(
  '[Speaker/Speaker Modal] Modal Create Speaker Failure',
  props<{ error: Error }>()
);

export const modalUpdateSpeaker = createAction(
  '[Speaker/Speaker Modal] Modal Update Speaker',
  props<{ event: EventModel, component: ComponentModel, speaker: SpeakerModel, translations: SpeakerTranslationModel[] }>()
);

export const modalUpdateSpeakerSuccess = createAction(
  '[Speaker/Speaker Modal] Modal Update Speaker Success',
  props<{ speaker: SpeakerModel, translations: SpeakerTranslationModel[] }>()
);

export const modalUpdateSpeakerFailure = createAction(
  '[Speaker/Speaker Modal] Modal Update Speaker Failure',
  props<{ error: Error }>()
);

import { createAction, props } from '@ngrx/store';

import { ImportResultModel } from '@shared/models/import-result.model';

export const importSpeakers = createAction(
  '[Speaker/Import Modal] Import Speakers',
  props<{ file: File, language: string }>()
);

export const importSpeakersSuccess = createAction(
  '[Speaker/API] Import Speakers Success',
  props<{ results: ImportResultModel[] }>()
);

export const importSpeakersFailure = createAction(
  '[Speaker/API] Import Speakers Failure',
  props<{ error: Error }>()
);

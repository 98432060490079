import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TagApiModel } from '@store/features/tags/tags.models';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const predefinedColors = [
  '#f7f1e3',
  '#c7ecee',
  '#7ed6df',
  '#74b9ff',
  '#95afc0',
  '#f9ca24',
  '#ffbe76',
  '#ff7675',
  '#00b894',
  '#6ab04c',
];

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagComponent),
      multi: true,
    },
  ],
})
export class TagComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input() tag: TagApiModel;
  @Input() readOnly: boolean;
  @Input() hideRemove: boolean;
  @Input() maxLength: number = null;
   
  showColorSelector = false;
  selectedColor: FormControl;
  colors: string[];

  valueCutted = false;

  @Output() appRemove: EventEmitter<Event>;
  @Output() change: EventEmitter<TagApiModel>;

  @HostBinding('style.backgroundColor') get _backgroundColor() {
    return this.selectedColor?.value ? this.selectedColor?.value : this.form.value.hex;
  }

  @ViewChild('inputElement', { static: true }) inputElementRef: ElementRef;
  @ViewChild('helperElement', { static: true }) helperElementRef: ElementRef;
  @ViewChild('colorSelector') colorSelector: ElementRef;
  @ViewChild('tag', { static: true }) tagElementRef: ElementRef;

  form: FormGroup;

  private inputElement: HTMLInputElement;
  private helperElement: HTMLSpanElement;

  private onChange: (value: TagApiModel) => void;
  private onTouched: () => void;

  private teardown$: Subject<void>;
  private subs = new Subscription();

  constructor(private renderer: Renderer2) {
    this.createForm();
    this.teardown$ = new Subject();

    this.readOnly = false;
    this.hideRemove = false;

    this.appRemove = new EventEmitter<Event>();
    this.change = new EventEmitter<TagApiModel>();

    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        !this.colorSelector?.nativeElement.contains(e.target) &&
        !this.inputElementRef?.nativeElement.contains(e.target)
      ) {
        this.showColorSelector = false;
        this.propagateChanges({ ...this.form.value, hex: this.selectedColor.value });
        if (this.tag.hex !== this.selectedColor.value) {
          this.onChangeValue();
        }
      }
    });
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.tagElementRef.nativeElement.contains(event.target)) {
      this.showColorSelector = false;
    }
  }

  ngOnInit() {
    if (this.maxLength) {
      const currentValue = this.form.get('value').value;
      const truncatedValue = currentValue.length > this.maxLength ? currentValue.slice(0, this.maxLength - 3) + '...' : currentValue;
        
      this.valueCutted = truncatedValue !== currentValue;
      this.form.patchValue({ ...this.form.value, value: truncatedValue });
    }
    this.colors = predefinedColors;
    this.selectedColor = new FormControl(this.tag.hex);
    this.subs.add(this.form.valueChanges.subscribe(value => this.propagateChanges(value)));
    this.subs.add(
      this.selectedColor.valueChanges.pipe(takeUntil(this.teardown$)).subscribe(
        (color: string) =>
          isHexColor(color) &&
          this.propagateChanges({
            ...this.form.value,
            hex: color,
          })
      )
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('tag' in changes) {
      this.form.patchValue({ ...this.tag });
      if (this.selectedColor && this.selectedColor.value !== this.tag.hex) {
        this.selectedColor.setValue(this.tag.hex);
      }
    }
  }

  ngAfterViewInit() {
    this.inputElement = this.inputElementRef.nativeElement;
    this.helperElement = this.helperElementRef.nativeElement;

    this.updateSize();
  }

  get fullName() {
    return this.tag.value;
  }

  handleFocusIn(): void {
    // to prevent color selector if delete has been clicked
    setTimeout(() => (this.showColorSelector = true), 200);
  }

  onColorSelect(ev: Event, color: string) {
    this.selectedColor.setValue(color);
  }

  onRemoveClick(ev: Event) {
    this.appRemove.emit(ev);
    ev.preventDefault();
  }

  writeValue(value: TagApiModel): void {
    this.form.setValue(value);
    this.updateSize();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChangeValue(): void {
    if (!this.inputElementRef.nativeElement.value || this.inputElementRef.nativeElement.value.length === 0) {
      return;
    }
    this.tag = { ...this.tag, value: this.inputElementRef.nativeElement.value, hex: this.selectedColor.value };
    setTimeout(() => {
      if (!this.showColorSelector) {
        this.change.emit(this.tag);
      }
    }, 100);
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  private createForm() {
    this.form = new FormGroup({
      id: new FormControl(0),
      event_id: new FormControl(0),
      type_tag: new FormControl(''),
      hex: new FormControl(''),
      value: new FormControl(''),
    });
  }

  private propagateChanges(tagData: TagApiModel) {
    if (!this.showColorSelector && this.onChange) {
      this.onChange({ ...tagData, value: tagData.value.toUpperCase() });
    }
    this.updateSize();
  }

  private updateSize() {
    if (!this.helperElement || !this.inputElement) {
      return;
    }

    const width = this.calculateSizeFor(this.form.value.value);

    // add 2px gap for editing cursor too
    this.inputElement.style.width = `${width + 2}px`;
  }

  private calculateSizeFor(value: string) {
    this.helperElement.innerText = value;
    return this.helperElement.offsetWidth;
  }
}

function isHexColor(hex: string) {
  return /^#[0-9a-fA-F]{6}$/i.test(hex);
}

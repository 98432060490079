import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss']
})
export class DropdownItemComponent {
  @Input() isCategory: boolean = false;
  @Input() value: any;

  @Output() selectChange: EventEmitter<any>;

  isSelected: boolean;

  constructor() {
    this.selectChange = new EventEmitter<any>();
  }

  onSelect(): void {
    this.selectChange.emit(this.value);
  }
}

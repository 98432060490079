<div class="preview-container">
  <div class="mobile">
    <div class="topbar" [style.background-color]="eventForm?.mainColor">
      <div class="logo" [style.background-color]="eventForm?.mainColor">
        <img *ngIf="getLogoUrl()" [src]="getLogoUrl()" alt="Company logo" />
        <img *ngIf="!getLogoUrl()" src="/assets/img/preview/logo-placeholder.jpg" alt="Company logo" />
      </div>
      <div class="event_name" [style.color]="eventForm?.mainTextColor">{{ eventForm?.title }}</div>
    </div>
    <div class="body" [style.background-color]="sidenavBgColor">
      <div class="sidenav" [style.background-color]="sidenavBgColor">
        <div class="sidenav-top">
          <div class="user">
            <div class="user-logo">
              {{ (profile$ | async).firstName.slice(0, 1) }}{{ (profile$ | async).lastName.slice(0, 1) }}
            </div>
            <span>{{ (profile$ | async).firstName }} {{ (profile$ | async).lastName }}</span>
          </div>
  
          <div class="sidenav-top-items">
            <div class="sidenav-top-item" [style]="{border: '2px solid ' + eventForm?.menuItemTextColor}">
              <app-icon icon="/assets/icons/edit.svg" [style.fill]="eventForm?.menuItemTextColor" alt=""></app-icon>
            </div>
            <div class="sidenav-top-item" [style]="{border: '2px solid ' + eventForm?.menuItemTextColor}">
              <app-icon icon="/assets/icons/qr.svg" [style.fill]="eventForm?.menuItemTextColor" alt=""></app-icon>
            </div>
            <div class="sidenav-top-item" [style]="{border: '2px solid ' + eventForm?.menuItemTextColor}">
              <app-icon class="ticket-custom-size" icon="/assets/icons/ticket2.svg" [style.fill]="eventForm?.menuItemTextColor" alt=""></app-icon>
            </div>
            <div class="sidenav-top-item" [style]="{border: '2px solid ' + eventForm?.menuItemTextColor}">
              <app-icon icon="/assets/icons/calendar.svg" [style.fill]="eventForm?.menuItemTextColor" alt=""></app-icon>
            </div>
          </div>
        </div>
  
        <div class="sidenav-content">
          <div class="nav-item">
            <app-icon icon="/assets/icons/heart.svg" class="nav-icon" [style.fill]="eventForm?.menuItemTextColor"></app-icon>
            <span [style.color]="eventForm?.menuItemTextColor">Partners</span>
          </div>
          <div class="nav-item" [style.background-color]="eventForm?.selectedMenuItemBackgroundColor">
            <app-icon *ngIf="!componentListPreview || !componentListPreview.icon" icon="/assets/icons/add-circle.svg" class="nav-icon" [style.fill]="eventForm?.selectedMenuItemTextColor"></app-icon>
            <img *ngIf="componentListPreview && componentListPreview.icon" [src]="componentListPreview.icon.url" class="nav-icon" [style.fill]="eventForm?.selectedMenuItemTextColor" />
            <span [style.color]="eventForm?.selectedMenuItemTextColor">{{ activeSidenavItemLabel }}</span>
          </div>
          <div class="nav-item">
            <app-icon icon="/assets/icons/calendar.svg" class="nav-icon" [style.fill]="eventForm?.menuItemTextColor"></app-icon>
            <span [style.color]="eventForm?.menuItemTextColor">Push Notifications</span>
          </div>
          <div class="nav-item">
            <app-icon icon="/assets/icons/picture.svg" class="nav-icon" [style.fill]="eventForm?.menuItemTextColor"></app-icon>
            <span [style.color]="eventForm?.menuItemTextColor">Photo Booth</span>
          </div>
          <div class="nav-item">
            <app-icon icon="/assets/icons/edit.svg" class="nav-icon" [style.fill]="eventForm?.menuItemTextColor"></app-icon>
            <span [style.color]="eventForm?.menuItemTextColor">Exhibitors PL</span>
          </div>
          <div class="nav-item">
            <app-icon icon="/assets/icons/export.svg" class="nav-icon" [style.fill]="eventForm?.menuItemTextColor"></app-icon>
            <span [style.color]="eventForm?.menuItemTextColor">WWW / Resources</span>
          </div>
          <div class="nav-item">
            <app-icon icon="/assets/icons/eye.svg" class="nav-icon" [style.fill]="eventForm?.menuItemTextColor"></app-icon>
            <span [style.color]="eventForm?.menuItemTextColor">Venues</span>
          </div>
          <div class="nav-item">
            <app-icon icon="/assets/icons/envelope.svg" class="nav-icon" [style.fill]="eventForm?.menuItemTextColor"></app-icon>
            <span [style.color]="eventForm?.menuItemTextColor">Feed Wall</span>
          </div>
          <div class="nav-item">
            <app-icon icon="/assets/icons/calendar.svg" class="nav-icon" [style.fill]="eventForm?.menuItemTextColor"></app-icon>
            <span [style.color]="eventForm?.menuItemTextColor">Agenda</span>
          </div>
        </div>
  
        <div class="svg_wrapper">
          <div *ngIf="innerHtmlSideNav" id="svg_container" [innerHTML]="innerHtmlSideNav"></div>
        </div>
        <div class="img_wrapper" *ngIf="urlSideNav">
          <img [src]="urlSideNav" alt="" class="img_container">
        </div>
      </div>
  
      <div class="right-side">
        <div class="right-side-topbar" [style.background-color]="eventForm?.mainColor">
          <span [style.color]="eventForm?.mainTextColor">Speakers</span>
        </div>
        <div class="right-side-belt">
          <span>VIP</span>
        </div>
        <div class="right-side-content">
          <div class="right-side-item">
            <div class="item-logo">JD</div>
            <div class="item-content">
              <div class="item-content-top">
                <span class="item-content-top-name">John Doe</span>
              </div>
              <span>position CEO </span>
            </div>
          </div>
          <div class="right-side-item">
            <div class="item-logo">JD</div>
            <div class="item-content">
              <div class="item-content-top">
                <span class="item-content-top-name">John Doe</span>
              </div>
              <span>position CEO</span>
            </div>
          </div>
          <div class="right-side-item">
            <div class="item-logo">RR</div>
            <div class="item-content">
              <div class="item-content-top">
                <span class="item-content-top-name">Ritik Richardson</span>
              </div>
              <span>position DevOps</span>
            </div>
          </div>
          <div class="right-side-item">
            <div class="item-logo">JD</div>
            <div class="item-content">
              <div class="item-content-top">
                <span class="item-content-top-name">John Doe</span>
              </div>
              <span>position CEO</span>
            </div>
          </div>
          <div class="right-side-belt">
            <span>SPEAKERS</span>
          </div>
          <div class="right-side-item">
            <div class="item-logo">SB</div>
            <div class="item-content">
              <div class="item-content-top">
                <span class="item-content-top-name">Shayla Barron</span>
              </div>
              <span>company Kovacek-Wehner</span>
            </div>
          </div>
          <div class="right-side-item">
            <div class="item-logo">ZL</div>
            <div class="item-content">
              <div class="item-content-top">
                <span class="item-content-top-name">Zohaib Lin</span>
              </div>
              <span>company Ernser LLC</span>
            </div>
          </div>
        </div>
  
        <div class="right-side-footer">
          <app-icon [style.fill]="'#888888'" icon="/assets/icons/menu2.svg" class="footer-item custom-footer-item-size"></app-icon>
          <app-icon [style.fill]="'#888888'" icon="/assets/icons/user.svg" class="footer-item"></app-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay-layer"></div>
</div>
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent {
  @Input() stars: number;
  @Input() rating: number;

  get activeStars(): number {
    return Math.round(this.rating * this.stars);
  }

  get iterableStars(): number[] {
    return [...Array(this.stars)].map((value, index) => index);
  }

  constructor() {
    this.stars = 5;
    this.rating = 0;
  }
}

import { createAction, props } from '@ngrx/store';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { AgendaRatingAnswerModel } from '@components/agenda/models/agenda-rating-answer.model';

// Answers are loaded with rating questions
export const loadRatingAnswers = createAction(
  '[Agenda/Component] Load Rating Answers',
  props<{ event: EventModel, component: ComponentModel }>()
);

export const loadRatingAnswersSuccess = createAction(
  '[Agenda/API] Load Rating Answers Success',
  props<{ answers: AgendaRatingAnswerModel[] }>()
);

export const loadRatingAnswersFailure = createAction(
  '[Agenda/API] Load Rating Answers Failure',
  props<{ error: Error }>()
);

export const createRatingAnswersSuccess = createAction(
  '[Agenda/API] Create Rating Answers Success',
  props<{ answers: AgendaRatingAnswerModel[] }>()
);

export const updateRatingAnswersSuccess = createAction(
  '[Agenda/API] Update Rating Answers Success',
  props<{ answers: AgendaRatingAnswerModel[] }>()
);

export const purgeRatingAnswers = createAction(
  '[Agenda/Component] Purge Rating Answers',
  props<{ questionId: number }>()
);

import { createAction, props } from '@ngrx/store';
import { PartnerCategoryModel } from '@components/partner/models/partner-category.model';

import {
  PartnerCategoryTranslatableModel,
  PartnerCategoryTranslationModel
} from '@components/partner/models/partner-category-translation.model';

export const loadCategories = createAction(
  '[Partner/Component] Load Categories'
);

export const loadCategoriesSuccess = createAction(
  '[Partner/API] Load Categories Success',
  props<{ categories: PartnerCategoryModel[] }>()
);

export const loadCategoriesFailure = createAction(
  '[Partner/API] Load Categories Failure',
  props<{ error: Error }>()
);

export const createCategory = createAction(
  '[Partner/Component] Create Category',
  props<{ category: PartnerCategoryModel }>()
);

export const createCategorySuccess = createAction(
  '[Partner/API] Create Category Success',
  props<{ category: PartnerCategoryModel }>()
);

export const createCategoryFailure = createAction(
  '[Partner/API] Create Category Failure',
  props<{ error: Error }>()
);

export const updateCategory = createAction(
  '[Partner/Component] Update Category',
  props<{ category: PartnerCategoryModel }>()
);

export const updateCategorySuccess = createAction(
  '[Partner/API] Update Category Success',
  props<{ category: PartnerCategoryModel }>()
);

export const updateCategoryFailure = createAction(
  '[Partner/API] Update Category Failure',
  props<{ error: Error }>()
);

export const reorderCategories = createAction(
  '[Partner/Component] Reorder Categories',
  props<{ categories: PartnerCategoryModel[] }>()
);

export const reorderCategoriesSuccess = createAction(
  '[Partner/API] Reorder Categories Success',
  props<{ categories: PartnerCategoryModel[] }>()
);

export const reorderCategoriesFailure = createAction(
  '[Partner/API] Reorder Categories Failure',
  props<{ error: Error }>()
);

export const deleteCategory = createAction(
  '[Partner/Component] Delete Category',
  props<{ category: PartnerCategoryModel }>()
);

export const deleteCategorySuccess = createAction(
  '[Partner/API] Delete Category Success',
  props<{ category: PartnerCategoryModel }>()
);

export const deleteCategoryFailure = createAction(
  '[Partner/API] Delete Category Failure',
  props<{ error: Error }>()
);

/**
 * Category Translation Actions
 */
export const loadCategoryTranslation = createAction(
  '[Partner/Component] Load Category Translation',
  props<{ category: PartnerCategoryModel, language: string }>()
);

export const loadCategoryTranslationSuccess = createAction(
  '[Partner/API] Load Category Translation Success',
  props<{ translation: PartnerCategoryTranslationModel }>()
);

export const loadCategoryTranslationFailure = createAction(
  '[Partner/API] Load Category Translation Failure',
  props<{ error: Error }>()
);

export const updateCategoryTranslation = createAction(
  '[Partner/Component] Update Category Translation',
  props<{ category: PartnerCategoryModel, translation: PartnerCategoryTranslatableModel, language: string }>()
);

export const updateCategoryTranslationSuccess = createAction(
  '[Partner/API] Update Category Translation Success',
  props<{ translation: PartnerCategoryTranslationModel }>()
);

export const updateCategoryTranslationFailure = createAction(
  '[Partner/API] Update Category Translation Failure',
  props<{ error: Error }>()
);

/**
 * Category Placeholder Actions
 */

export const addCategoryPlaceholder = createAction(
  '[Partner/Component] Add Category Placeholder',
  props<{ uuid: string }>()
);

export const focusCategoryPlaceholder = createAction(
  '[Partner/Component] Focus Category Placeholder'
);

export const blurCategoryPlaceholder = createAction(
  '[Partner/Component] Blur Category Placeholder'
);

export const resetCategoryPlaceholder = createAction(
  '[Partner/Component] Reset Category Placeholder'
);

export const removeCategoryPlaceholder = createAction(
  '[Partner/Component] Remove Category Placeholder'
);

import { Component, OnInit, forwardRef, Input, ViewChild, HostListener, ElementRef } from '@angular/core';
import { ControlValueAccessor, Validator, FormControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { Observable } from 'rxjs';

import { CountryModel } from '@shared/models/country.model';
import { InputAdaptiveComponent } from '@shared/components/input-adaptive/input-adaptive.component';
import { map, startWith } from 'rxjs/operators';

export interface CountryItem {
  color: string | number;
  country: CountryModel;
}

@Component({
  selector: 'app-country-input',
  templateUrl: './country-input.component.html',
  styleUrls: ['./country-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CountryInputComponent),
      multi: true
    }
  ]
})
export class CountryInputComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() countries: CountryModel[];

  @ViewChild('input', { static: true, read: InputAdaptiveComponent }) inputAdaptive: InputAdaptiveComponent;

  inputControl: FormControl;
  selectedCountries: CountryItem[];

  autofocus: boolean;
  showAutocomplete: boolean;
  filteredCountries$: Observable<CountryModel[]>;

  private onChange: (value: string) => void;
  private onTouched: () => void;

  constructor(private elementRef: ElementRef) {
    this.inputControl = new FormControl('');
    this.selectedCountries = [];

    this.autofocus = false;
    this.showAutocomplete = false;
  }

  ngOnInit() {
    const filterCountryByName = (term: string) => this.countries.filter(
      country => country.name.toLowerCase().startsWith(term.toLowerCase())
    );

    this.filteredCountries$ = this.inputControl.valueChanges.pipe(
      startWith(''), map(searchTerm => filterCountryByName(searchTerm))
    );
  }

  onCountryRemove(ev: Event, index: number) {
    this.selectedCountries.splice(index, 1);
    this.propagateChanges();
  }

  onInputKeyDown(ev: KeyboardEvent) {
    const triggerKeys = [',', '.', 'Enter'];

    if (triggerKeys.includes(ev.key)) {
      this.addCountryItem(this.inputControl.value);
      ev.preventDefault();
    }

    if (ev.key === 'Backspace' && this.inputControl.value === '') {
      this.selectedCountries.splice(-1, 1);
    }

    if (ev.key === 'Tab') {
      this.showAutocomplete = false;
    }
  }

  onInputFocus(ev: Event) {
    this.showAutocomplete = true;
  }

  onInputBlur(ev: Event) {
    this.addCountryItem(this.inputControl.value);

    // re-focus on autocomplete click
    if (this.autofocus) {
      this.inputAdaptive.focus();
      this.autofocus = false;
    }
  }

  onAutocompleteCountry(ev: Event, country: CountryModel) {
    this.addCountryItem(country.code);
    this.autofocus = true;
  }

  @HostListener('window:click', ['$event'])
  onWindowClick(ev: Event) {
    let node = ev.target as Node;

    while (node) {
      if (node === this.elementRef.nativeElement) {
        return;
      }

      node = node.parentNode;
    }

    this.showAutocomplete = false;
  }

  writeValue(countries: string | string[]): void {
    let countryCodeList = [];

    if (typeof countries === 'string') {
      countryCodeList = countries.split(',');
    } else if (Array.isArray(countries)) {
      countryCodeList = countries;
    }

    countryCodeList.forEach(
      code => this.addCountryItem(code)
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(): ValidationErrors {
    return null;
  }

  private addCountryItem(value: string) {
    this.inputControl.setValue('');

    const trimmedValue = value.trim();
    if (trimmedValue === '') {
      return;
    }

    const countryItem = this.createCountryItem(trimmedValue);
    if (countryItem) {
      this.selectedCountries.push(countryItem);
      this.propagateChanges();
    }
  }

  private createCountryItem(value: string): CountryItem | null {
    const foundCountry = this.findCountryFor(value);

    if (foundCountry) {
      return { color: '#e8e8e8', country: foundCountry };
    }

    return null;
  }

  private findCountryFor(value: string): CountryModel {
    const findPasses = [findByCountryName, findByCountryCode];

    const foundCountry = findPasses.reduce<CountryModel>(
      (acc, pass) => (acc ? acc : pass(this.countries, value)), null
    );

    return foundCountry ? foundCountry : null;
  }

  private propagateChanges() {
    const countryCodes = this.selectedCountries.map(country => country.country.code);
    const validCodes = countryCodes.filter(code => !!code);

    if (this.onChange) {
      const commaSeparatedCodes = validCodes.join(',');
      this.onChange(commaSeparatedCodes);
    }
  }
}

function findByCountryName(countries: CountryModel[], value: string): CountryModel {
  return countries.find(country => (country.name.toLowerCase() === value.toLowerCase()));
}

function findByCountryCode(countries: CountryModel[], value: string): CountryModel {
  return countries.find(country => (country.code.toLowerCase() === value.toLowerCase()));
}

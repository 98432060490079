export enum AccessGroupType {
  Component = 'component',
  Item = 'item',
}

// GROUP - COMPONENT

export interface AccessGroupPostModel {
  component_id: number;
  tag_id: number;
  access_type: AccessGroupType.Component;
}

export interface AccessGroupDeleteModel {
  component_id: number;
  tag_id: number;
}

export interface AccessGroupModel {
  component_id: number;
  tag_id: number;
}

// ITEM

export interface AccessGroupItemPostModel {
  component_id: number;
  target_id: number;
  tag_id: number;
  access_type: AccessGroupType.Item;
}

export interface AccessGroupItemDeleteModel {
  component_id: number;
  item_id: number;
  tag_id: number;
}

export interface AccessGroupItemModel {
  component_id: number;
  item_id: number;
  tag_id: number;
}

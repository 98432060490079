import { Action, createReducer, on } from '@ngrx/store';
import { bookingBuildingActions } from '@components/booking/store/actions/booking-building.actions';
import { BookingPlace } from '@components/booking/store/models/booking-session.model';

export const featureKey = 'bookingBuildings';

export interface State {
  loading: boolean;
  error: Error;
  updatedId: number;
  saving: boolean;
  bookingBuildings: BookingPlace[];
}

export const initialState = {
  loading: false,
  error: null,
  updatedId: 0,
  saving: false,
  bookingBuildings: [],
};

export const bookingBuildingsReducer = createReducer(
  initialState,
  on(bookingBuildingActions.loadBookingBuildings,
    state => ({ ...initialState, loading: true })),

  on(bookingBuildingActions.loadBookingBuildingsSuccess,
    (state, { bookingBuildings }) => ({ ...state, loading: false, bookingBuildings })),

  on(bookingBuildingActions.loadBookingBuildingsFailure,
    (state, { error }) => ({ ...state, loading: false, error })),

  on(bookingBuildingActions.addBookingBuilding,
    state => ({ ...state, saving: true })),

  on(bookingBuildingActions.addBookingBuildingSuccess,
    (state, { bookingBuilding }) => ({ ...state, saving: false, bookingBuildings: [...state.bookingBuildings, bookingBuilding] })),

  on(bookingBuildingActions.addBookingBuildingFailure,
    (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingBuildingActions.updateBookingBuilding,
    state => ({ ...state, saving: true })),

  on(bookingBuildingActions.updateBookingBuildingSuccess,
    (state, { bookingBuilding }) => ({ ...state, saving: false, bookingBuildings: state.bookingBuildings
      .map(bb => bb.id === bookingBuilding.id ? bookingBuilding : bb) })),
  
  on(bookingBuildingActions.updateBookingBuildingFailure,
      (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingBuildingActions.deleteBookingBuilding, state =>
    ({ ...state, saving: true })),

  on(bookingBuildingActions.deleteBookingBuildingSuccess,
    (state, { bookingBuilding }) => ({ ...state, saving: false, bookingBuildings: [...state.bookingBuildings.filter(bb => bb.id !== bookingBuilding.id)] })),

  on(bookingBuildingActions.deleteBookingBuildingFailure,
    (state, { error }) => ({ ...state, saving: false, error })),

  on(bookingBuildingActions.setBookingBuildingError,
    (state, { error }) => ({ ...state, error })),
  
  on(bookingBuildingActions.setBookingBuildingUpdatedId,
    (state, { id }) => ({ ...state, updatedId: id })),

  // connector - booking rooms -> booking buildings
  // used in room effects for booking room update success or booking room created success
  on(bookingBuildingActions.setupBuildingRoom,
    (state, { place }) =>
      ({ ...state, loading: false,
        bookingBuildings: [...state.bookingBuildings.map(p => p.id === place.id ? place : p)]
    })),
);

export function reducer(state: State, action: Action): State {
  return bookingBuildingsReducer(state, action);
}

export const selectAllBookingBuildings = (state: State) => state.bookingBuildings;
export const selectBookingBuildingById = (state: State, id: number) => state.bookingBuildings.find(bookingBuilding => bookingBuilding.id === id);
export const selectBookingBuildingSaving = (state: State) => state.saving;
export const selectBookingBuildingError = (state: State) => state.error;
export const selectBookingBuildingUpdatedId = (state: State) => state.updatedId;

import { Directive, OnInit, OnDestroy, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { Subject, combineLatest, of, EMPTY, iif } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

import { FormContainerService } from '@shared/providers/form-container.service';
import { FormValidateService } from '@shared/providers/form-validate.service';

@Directive({
  selector: '[appFormValidateFeedback]'
})
export class FormValidateFeedbackDirective implements OnInit, OnDestroy {
  @Input('appFormValidateFeedback') feedbackErrors: string[];
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('appFormValidateFeedbackAlwaysVisible') alwaysVisible: boolean;

  private hasView: boolean;

  private teardown$: Subject<void>;

  constructor(private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private formContainer: FormContainerService,
    private formValidate: FormValidateService) {

    this.teardown$ = new Subject();
  }

  ngOnInit() {
    this.getFeedbackErrors().pipe(takeUntil(this.teardown$)).subscribe(
      errors => this.handleErrors(errors)
    );
  }

  ngOnDestroy() {
    this.teardown$.next();
    this.teardown$.complete();
  }

  private getFeedbackErrors() {
    return iif(
      () => this.alwaysVisible,
      this.formValidate.getErrors(),
      this.getSubmitFeedbackErrors()
    );
  }

  private getSubmitFeedbackErrors() {
    return combineLatest([
      this.formContainer.getSubmitted(),
      this.formValidate.getErrors()
    ]).pipe(
      switchMap(([submitted, errors]) => {
        if (submitted) {
          return of(errors);
        } else {
          this.clearFeedbackView();
          return EMPTY;
        }
      })
    );
  }

  private handleErrors(errors: ValidationErrors) {
    const errorFields = Object.keys(errors);
    const feedbackVisible = this.feedbackErrors.some(
      feedbackError => errorFields.includes(feedbackError)
    );

    this.toggleFeedbackView(feedbackVisible);
  }

  private toggleFeedbackView(visible: boolean) {
    if (visible && !this.hasView) {
      this.createFeedbackView();
    } else if (!visible && this.hasView) {
      this.clearFeedbackView();
    }
  }

  private createFeedbackView() {
    if (!this.hasView) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }

  private clearFeedbackView() {
    if (this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }
}

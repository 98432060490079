import { Observable, Subscription } from 'rxjs';
import { ProfileService } from '@app/backend/services/profile.service';
import { ProfileModel } from '@app/backend/models/profile.model';
import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { SvgService } from '@shared/providers/svg.service';
import { EventModel } from '@store/features/event/models/event.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IconModel } from '@shared/models/icon.model';

@Component({
  selector: 'app-colors-preview',
  templateUrl: './colors-preview.component.html',
  styleUrls: ['./colors-preview.component.scss']
})
export class ColorsPreviewComponent implements OnInit, OnDestroy, OnChanges {

  profile$: Observable<ProfileModel>;
  @Input() eventForm: EventModel;
  @Input() componentListPreview?: ComponentListPreview;

  hasSideNav = false;
  innerHtmlSideNav: SafeHtml = null; // for svg
  urlSideNav: string = null; // for img

  showBgColor = true;

  private subs = new Subscription();

  constructor(
    private profileService: ProfileService,
    private svgService: SvgService,
    private sanitizer: DomSanitizer,
  ) {
    this.profile$ = this.profileService.getProfile().pipe(
      filter(profile => !!profile)
    );
  }

  ngOnInit(): void {
    if (this.eventForm.mobileSideMenu) {
      this.populateMobileSideMenuImage();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.eventForm && changes.eventForm.currentValue.mobileSideMenu) {
      if (!changes.eventForm.previousValue?.mobileSideMenu || changes.eventForm.currentValue.mobileSideMenu.id !== changes.eventForm.previousValue.mobileSideMenu.id) {
        this.populateMobileSideMenuImage();
      }
    } else {
      this.showBgColor = true;
      this.urlSideNav = null;
      this.innerHtmlSideNav = null;
    }
    this.populateMobileSideMenuImage();
  }

  get sidenavBgColor(): string {
    return this.eventForm.sideMenuBackgroundColor ? this.eventForm.sideMenuBackgroundColor : 'transparent';
  }

  get activeSidenavItemLabel(): string {
    return (this.componentListPreview && this.componentListPreview.name) ? this.componentListPreview.name : 'Speakers';
  }

  getLogoUrl(): string {
    if(this.eventForm && this.eventForm.logo) {
      return this.eventForm.logo.file_url as string;
    }
  }

  private populateMobileSideMenuImage(): void {
    if (this.eventForm.mobileSideMenu?.meta.content_type === 'image/svg+xml') {
      this.showBgColor = true;
      this.urlSideNav = null;
      this.subs.add(this.svgService.getSvg(this.eventForm.mobileSideMenu?.file_url as string)
        .pipe(map(svgText => {
          svgText = (svgText as any).changingThisBreaksApplicationSecurity;
          let parser = new DOMParser();
          let svg = parser.parseFromString(String(svgText), 'image/svg+xml');
          svg.documentElement.setAttribute('width', '100%');
          svg.documentElement.setAttribute('height', '100%');
          let serializedSvg = new XMLSerializer().serializeToString(svg);
          return this.sanitizer.bypassSecurityTrustHtml(serializedSvg);
        })).subscribe(result => {
          this.innerHtmlSideNav = result;
        }));
    } else {
      this.showBgColor = false;
      this.innerHtmlSideNav = null;
      this.urlSideNav = this.eventForm.mobileSideMenu?.file_url as string;
    }
  }

}

export interface ComponentListPreview {
  name: string;
  icon: IconModel;
}
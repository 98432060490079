import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { TokenModel } from '@app/core/models/token.model';

import { TOKEN_KEY } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  retrieveLocalToken(): Observable<TokenModel> {
    return of(window.localStorage.getItem(TOKEN_KEY)).pipe(
      map(serializedToken => parseToken(serializedToken)),
      catchError(err => {
        this.clearLocalToken();
        return throwError(err);
      })
    );
  }

  storeLocalToken(token: TokenModel): void {
    const serializedToken = JSON.stringify(token);
    window.localStorage.setItem(TOKEN_KEY, serializedToken);
  }

  clearLocalToken(): void {
    window.localStorage.removeItem(TOKEN_KEY);
  }
}

function parseToken(serializedToken: string): TokenModel {
  if (!serializedToken) {
    throw new Error('Failed to retrieve token');
  }

  const token = JSON.parse(serializedToken) as TokenModel;

  // Handle edge cases where token is not a token
  if (typeof token !== 'object' || !token.token || !token.refreshToken) {
    throw new Error('Invalid token from localStorage');
  }

  return token;
}

import { Action, createReducer, on } from '@ngrx/store';

import { SummaryModel } from '@app/construct-steps/models/summary.model';
import { PriceTagModel } from '@app/construct-steps/models/price-tag.model';

import * as SummaryActions from '../actions/summary.actions';
import { clearEventState } from '../actions/event.actions';

export const stateKey = 'summary';

export interface State extends SummaryModel {
  loading: boolean;
  loaded: boolean;
  error: Error;
  priceTags: PriceTagModel[];
}

export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  plan: null,
  currency: 'USD',
  total: 0.0,
  contentUploadPrice: 0.0,
  dedicatedAppPrice: 0.0,
  priceTags: [],
};

export const summaryReducer = createReducer(
  initialState,
  on(
    SummaryActions.loadSummary,
    state => ({
      ...initialState,
      // skip reseting special feature prices
      contentUploadPrice: state.contentUploadPrice,
      dedicatedAppPrice: state.dedicatedAppPrice,
    })
  ),
  on(
    SummaryActions.loadSummary,
    SummaryActions.refreshSummary,
    state => ({ ...state, loading: true })
  ),
  on(
    SummaryActions.loadSummarySuccess,
    (state, { summary, priceTags }) => ({
      ...state, ...summary, priceTags, loading: false, loaded: true,
    })
  ),
  on(
    SummaryActions.loadSummaryFailure,
    (state, { error }) => ({ ...state, error, loading: false })
  ),

  on(clearEventState, (state) => ({ ...initialState })),
);

export function reducer(state: State, action: Action): State {
  return summaryReducer(state, action);
}

export const selectLoading = (state: State) => state.loading;

export const selectLoaded = (state: State) => state.loaded;

export const selectError = (state: State) => state.error;

export const selectPlan = (state: State) => state.plan;

export const selectCurrency = (state: State) => state.currency;

export const selectTotal = (state: State) => state.total;

export const selectContentUploadPrice = (state: State) => state.contentUploadPrice;

export const selectDedicatedAppPrice = (state: State) => state.dedicatedAppPrice;

export const selectPriceTags = (state: State) => state.priceTags;

<input
  [class.draggable]="draggable"
  [class.disabled]="isDisabled"
  #nativeInput
  type="text"
  [placeholder]="placeholder"
  (change)="onValueChange($event)"
  (input)="onValueChange($event)"
  (blur)="onBlur($event)"
  (keydown)="onKeydown($event)"
>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AttachmentModel } from '@shared/models/attachment.model';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable, Subscription } from 'rxjs';
import { first, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit, OnDestroy {

  isLoading = false;
  isImageLoaded = false;
  attachment: AttachmentModel | File;

  imageBase64String: string;
  croppedImage: File = null;

  ratio: 1;

  changed = false;

  private initSize = { w: null, h: null };
  private initPosition = {
    x1: null,
    y1: null,
    x2: null,
    y2: null,
  };

  private subs = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { attachment: AttachmentModel },
    private dialogRef: MatDialogRef<ImageCropperComponent>,
    private http: HttpClient,
  ) {
    this.attachment = this.data.attachment;
  }

  ngOnInit(): void {
    if (this.attachment) {
      this.isLoading = true;
      if (this.attachment instanceof File) {
        this.toBase64(this.attachment);
        this.isLoading = false;
      } else {
        this.subs.add(this.imageUrlToBase64(this.attachment)
          .pipe(first())
          .subscribe(base64String => {
            if (base64String) {
              this.imageBase64String = base64String;
              this.isImageLoaded = true;
            }
            this.isLoading = false;
          }));
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.notifyChanges(event);
    const { blob } = event;
    let fileName = 'unknown';
    if (this.attachment instanceof File) {
      fileName = this.attachment.name;
    } else {
      fileName = this.attachment.file_name as string;
    }
    if (blob != null) {
      this.croppedImage = new File([blob], fileName);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close({
      file: this.changed ? this.croppedImage : null,
    });
  }

  private notifyChanges(event: ImageCroppedEvent): void {
    // for new uploaded file we have to update file (for ratio)
    if (this.attachment instanceof File) {
      this.changed = true;
    }
    if (!this.initSize.w && !this.initSize.h) {
      this.initSize = { w: event.width, h: event.height };
    } else {
      if (this.initSize.w !== event.width || this.initSize.h !== event.height) {
        this.changed = true;
      }
    }
    if (!this.initPosition.x1 || !this.initPosition.x2 || !this.initPosition.y1 || !this.initPosition.y2) {
      this.initPosition = {
        x1: event.imagePosition.x1,
        y1: event.imagePosition.x2,
        x2: event.imagePosition.y1,
        y2: event.imagePosition.y2,
      };
    } else {
      if (this.initPosition.x1 !== event.imagePosition.x1 || this.initPosition.x2 !== event.imagePosition.x2 || this.initPosition.y1 !== event.imagePosition.y1 || this.initPosition.y2 !== event.imagePosition.y2) {
        this.changed = true;
      }
    }
  }

  // private getImageFileFromUrl(attachment: AttachmentModel): Observable<File> {
  //   return this.http.get(attachment.file_url as string, { responseType: 'blob' })
  //     .pipe(first())
  //     .pipe(
  //       map(response => {
  //         const file = new File([response], attachment.file_name as string)
  //         return file;
  //       }),
  //       catchError(error => {
  //         return throwError(error);
  //       }),
  //     );
  // }

  private imageUrlToBase64(attachment: AttachmentModel): Observable<string> {
    return this.http.get(attachment.file_url as string, {
      observe: 'body',
      responseType: 'arraybuffer',
    }).pipe(
      take(1),
      map(arrayBuffer =>
        btoa(Array.from(new Uint8Array(arrayBuffer))
        .map(b => String.fromCharCode(b))
        .join(''))
      )
    )
  }

  private toBase64(file: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imageBase64String = reader.result as string;
      this.isImageLoaded = true;
    };
  }
}
import { Injectable } from '@angular/core';

import { Observable, concat } from 'rxjs';
import { reduce, map, switchMap } from 'rxjs/operators';

import { FullApiService } from '@shared/providers/full-api.service';
import { SurveyQuizQuestionService } from '@components/survey-quiz/providers/survey-quiz-question.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { SurveyQuizModel, SurveyQuizPostModel, SurveyQuizPatchModel } from '@components/survey-quiz/models/survey-quiz.model';
import { SurveyQuizQuestionModel, SurveyQuizQuestionPostModel } from '@components/survey-quiz/models/survey-quiz-question.model';
import { SurveyQuizAnswerModel, SurveyQuizAnswerPostModel } from '@components/survey-quiz/models/survey-quiz-answer.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyQuizProviderService {
  constructor(private apiService: FullApiService, private surveyQuizQuestionService: SurveyQuizQuestionService) {}

  loadSurveyQuizzes(event: EventModel, component: ComponentModel): Observable<SurveyQuizModel[]> {
    return this.apiService.getSurveys(event.id, component.id);
  }

  createSurveyQuiz(event: EventModel, component: ComponentModel, surveyQuiz: SurveyQuizModel): Observable<SurveyQuizModel> {
    const surveyQuizData = mapSurveyQuizPost(surveyQuiz);
    return this.apiService.addSurvey(event.id, component.id, surveyQuizData);
  }

  updateSurveyQuiz(event: EventModel, component: ComponentModel, surveyQuiz: SurveyQuizModel): Observable<SurveyQuizModel> {
    const surveyQuizData = mapSurveyQuizPatch(surveyQuiz);
    return this.apiService.updateSurvey(event.id, component.id, surveyQuiz.id, surveyQuizData).pipe(
      map(updatedSurveyQuiz => ({ ...updatedSurveyQuiz, survey_quiz_questions: surveyQuiz.survey_quiz_questions })),
      switchMap(updatedSurveyQuiz => this.updateSurveyQuizQuestionsFor(event, component, updatedSurveyQuiz))
    );
  }

  deleteSurveyQuiz(event: EventModel, component: ComponentModel, surveyQuiz: SurveyQuizModel): Observable<boolean> {
    return this.apiService.removeSurvey(event.id, component.id, surveyQuiz.id).pipe(
      map(({ success }) => success)
    );
  }

  reorderSurveyQuizzes(event: EventModel, component: ComponentModel, surveyQuizzes: SurveyQuizModel[]): Observable<SurveyQuizModel[]> {
    const updateObservables = surveyQuizzes.map((survey, order) => {
      const surveyQuizData = { ...mapSurveyQuizPatch(survey), order };
      return this.apiService.updateSurvey(event.id, component.id, survey.id, surveyQuizData);
    });

    return concat(...updateObservables).pipe(
      reduce((acc, surveyQuiz) => ([...acc, surveyQuiz]), [])
    );
  }

  exportSurveyQuiz(event: EventModel, component: ComponentModel, surveyQuiz: SurveyQuizModel): Observable<string> {
    return this.apiService.exportSurvey(event.id, component.id, surveyQuiz.id);
  }

  private updateSurveyQuizQuestionsFor(event: EventModel, component: ComponentModel,
    surveyQuiz: SurveyQuizModel): Observable<SurveyQuizModel> {

    const swapSurveyQuizQuestionsBy = (questions: SurveyQuizQuestionModel[]) => {
      return ({ ...surveyQuiz, survey_quiz_questions: questions, questions_count: questions.length });
    };

    return this.surveyQuizQuestionService.loadSurveyQuiz(event, component, surveyQuiz).pipe(
      switchMap(() => this.surveyQuizQuestionService.diffSurveyQuizQuestions(surveyQuiz.survey_quiz_questions)),
      map(surveyQuizQuestions => swapSurveyQuizQuestionsBy(surveyQuizQuestions))
    );
  }
}

function mapSurveyQuizPost(surveyQuiz: SurveyQuizModel): SurveyQuizPostModel {
  const mappedQuestions = surveyQuiz.survey_quiz_questions.map(
    surveyQuizQuestion => mapSurveyQuizQuestionPost(surveyQuizQuestion)
  );

  return {
    name: surveyQuiz.name, description: surveyQuiz.description, mode: surveyQuiz.mode,
    order: surveyQuiz.order, visible_results: surveyQuiz.visible_results, draft: surveyQuiz.draft,
    scheduled: surveyQuiz.scheduled, scheduled_datetime: surveyQuiz.scheduled_datetime,
    questions: mappedQuestions, is_login_required: surveyQuiz.is_login_required,
  };
}

function mapSurveyQuizPatch(surveyQuiz: SurveyQuizModel): SurveyQuizPatchModel {
  return {
    name: surveyQuiz.name, description: surveyQuiz.description, mode: surveyQuiz.mode,
    order: surveyQuiz.order, visible_results: surveyQuiz.visible_results, draft: surveyQuiz.draft,
    scheduled: surveyQuiz.scheduled, scheduled_datetime: surveyQuiz.scheduled_datetime,
    is_login_required: surveyQuiz.is_login_required,
  };
}

function mapSurveyQuizQuestionPost(surveyQuizQuestion: SurveyQuizQuestionModel): SurveyQuizQuestionPostModel {
  const mappedAnswers = surveyQuizQuestion.survey_quiz_answers.map(
    surveyQuizAnswer => mapSurveyQuizAnswerPost(surveyQuizAnswer)
  );

  return {
    question: surveyQuizQuestion.question, mode: surveyQuizQuestion.mode, order: surveyQuizQuestion.order,
    answers: mappedAnswers
  };
}

function mapSurveyQuizAnswerPost(surveyQuizAnswer: SurveyQuizAnswerModel): SurveyQuizAnswerPostModel {
  return {
    answer: surveyQuizAnswer.answer, mode: surveyQuizAnswer.mode,
    order: surveyQuizAnswer.order, correct: surveyQuizAnswer.correct
  };
}

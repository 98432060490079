<div class="row">
  <div class="col-md-12 col-lg-8 py-4 pl-4">
    <router-outlet></router-outlet>

    <!-- <div class="text-right">
      <button type="button" class="btn btn-primary pl-4 pr-4" [routerLink]="['..', 5]">{{ 'buttons.go_next' | translate }}</button>
    </div> -->
  </div>

  <div class="col-md-12 col-lg-4">
    <app-tip-card class="d-block mb-4">
      <h2 class="text-left">{{ 'content_step.tip_title' | translate }}</h2>
      <p class="text-left">
        {{ 'content_step.tip_desc' | translate }}
      </p>
      <li><a [href]="Intercom.components[langKey]" target="_blank">{{ 'content_step.tip_article' | translate }}</a></li>
    </app-tip-card>

    <div *ngIf="error$ | async" class="alert alert-danger" role="alert">
      {{ error$ | async }}
    </div>

    <div class="mb-3">
      <app-builder-component *ngFor="let component of builtInComponents$ | async; trackBy: trackByComponentId" [active]="component.id === (activeComponentId$ | async)"
        [routerLink]="['component', component.id]" class="drop-box" [componentModel]="component" [initComponent]="component.id < 0"
        (deleteComponentRequest)="onDeleteComponent($event)" (editComponentRequest)="onEditComponent($event)"
        (toggleShowComponentRequest)="onToggleShowComponent($event)" [eventId]="eventId" [addedMode]="true">
      </app-builder-component>
    </div>

    <div class="drop-list">
      <div cdkDropList class="cdk-list" [cdkDropListDisabled]="isNotAllowed$ | async" (cdkDropListDropped)="onComponentReorder($event)">
        <app-builder-component *ngFor="let component of components$ | async; trackBy: trackByComponentId" [active]="component.id === (activeComponentId$ | async)"
          [routerLink]="getComponentRouterArray(component)" class="drop-box" [componentModel]="component" [initComponent]="component.id < 0"
          (deleteComponentRequest)="onComponentDelete($event, component)" (editComponentRequest)="onComponentEdit($event)"
          (toggleShowComponentRequest)="onComponentToggleVisibility($event, component)" [eventId]="eventId" [addedMode]="true" [deleteMode]="!(isPaid$ | async)" cdkDrag>
        </app-builder-component>
      </div>
    </div>

    <app-live-preview class="d-block mt-4" [event]="event$ | async"></app-live-preview>
  </div>
</div>

import { Injectable } from '@angular/core';

import { Observable, concat, of, throwError, EMPTY } from 'rxjs';
import { map, filter, concatMap, switchMap, reduce, defaultIfEmpty } from 'rxjs/operators';

import { FullApiService } from '@shared/providers/full-api.service';
import { SurveyQuizAnswerService } from '@components/survey-quiz/providers/survey-quiz-answer.service';

import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { SurveyQuizModel } from '@components/survey-quiz/models/survey-quiz.model';
import { SurveyQuizAnswerModel } from '@components/survey-quiz/models/survey-quiz-answer.model';

import {
  SurveyQuizQuestionModel,
  SurveyQuizQuestionPostModel,
  SurveyQuizQuestionPatchModel
} from '@components/survey-quiz/models/survey-quiz-question.model';

import {
  DiffResult,
  DiffResultAction,
  diff
} from '@utils/diff.util';

@Injectable({
  providedIn: 'root'
})
export class SurveyQuizQuestionProviderService {
  constructor(private apiService: FullApiService, private surveyQuizAnswerService: SurveyQuizAnswerService) {}

  loadSurveyQuizQuestions(event: EventModel, component: ComponentModel,
    surveyQuiz: SurveyQuizModel): Observable<SurveyQuizQuestionModel[]> {

    return this.apiService.getSurveyQuestions(event.id, component.id, surveyQuiz.id);
  }

  createSurveyQuizQuestion(event: EventModel, component: ComponentModel,
    surveyQuiz: SurveyQuizModel, surveyQuizQuestion: SurveyQuizQuestionModel): Observable<SurveyQuizQuestionModel> {

    const surveyQuizQuestionData = mapSurveyQuizQuestionPost(surveyQuizQuestion);
    return this.apiService.addSurveyQuestion(event.id, component.id, surveyQuiz.id, surveyQuizQuestionData).pipe(
      map(createdSurveyQuestion => ({ ...createdSurveyQuestion, survey_quiz_answers: surveyQuizQuestion.survey_quiz_answers })),
      switchMap(createdSurveyQuestion => this.updateSurveyQuizAnswersFor(event, component, surveyQuiz, createdSurveyQuestion))
    );
  }

  updateSurveyQuizQuestion(event: EventModel, component: ComponentModel,
    surveyQuiz: SurveyQuizModel, surveyQuizQuestion: SurveyQuizQuestionModel): Observable<SurveyQuizQuestionModel> {

    const surveyQuizQuestionData = mapSurveyQuizQuestionPatch(surveyQuizQuestion);
    return this.apiService.updateSurveyQuestion(event.id, component.id, surveyQuiz.id, surveyQuizQuestion.id, surveyQuizQuestionData).pipe(
      map(updatedSurveyQuestion => ({ ...updatedSurveyQuestion, survey_quiz_answers: surveyQuizQuestion.survey_quiz_answers })),
      switchMap(updatedSurveyQuestion => this.updateSurveyQuizAnswersFor(event, component, surveyQuiz, updatedSurveyQuestion))
    );
  }

  deleteSurveyQuizQuestion(event: EventModel, component: ComponentModel,
    surveyQuiz: SurveyQuizModel, surveyQuizQuestion: SurveyQuizQuestionModel): Observable<boolean> {

    return this.apiService.removeSurveyQuestion(event.id, component.id, surveyQuiz.id, surveyQuizQuestion.id).pipe(
      map(({ success }) => success)
    );
  }

  diffSurveyQuizQuestions(event: EventModel, component: ComponentModel, surveyQuiz: SurveyQuizModel,
    from: SurveyQuizQuestionModel[], to: SurveyQuizQuestionModel[]): Observable<SurveyQuizQuestionModel[]> {

    const diffResults = diff(from, to, (a, b) => (a.id === b.id));

    const addObservables = this.makeRootDiffObservableFrom(event, component, surveyQuiz, diffResults, DiffResultAction.Create);
    const updateObservables = this.makeRootDiffObservableFrom(event, component, surveyQuiz, diffResults, DiffResultAction.Update);
    const removeObservables = this.makeRootDiffObservableFrom(event, component, surveyQuiz, diffResults, DiffResultAction.Delete);

    return concat(addObservables, updateObservables, removeObservables).pipe(
      reduce((acc, surveyQuizQuestion) => ([...acc, surveyQuizQuestion]), []),
      defaultIfEmpty([])
    );
  }

  private makeRootDiffObservableFrom(event: EventModel, component: ComponentModel, surveyQuiz: SurveyQuizModel,
    diffResults: DiffResult<SurveyQuizQuestionModel>[], action: DiffResultAction): Observable<SurveyQuizQuestionModel> {

    return of(...diffResults).pipe(
      filter(result => (result.action === action)),
      concatMap(result => this.makeDiffObservableFrom(event, component, surveyQuiz, result)),
    );
  }

  private makeDiffObservableFrom(event: EventModel, component: ComponentModel, surveyQuiz: SurveyQuizModel,
    diffResult: DiffResult<SurveyQuizQuestionModel>): Observable<SurveyQuizQuestionModel> {

    if (diffResult.action === DiffResultAction.Create) {
      return this.createSurveyQuizQuestion(event, component, surveyQuiz, diffResult.to);
    } else if (diffResult.action === DiffResultAction.Update) {
      return this.updateSurveyQuizQuestion(event, component, surveyQuiz, diffResult.to);
    } else if (diffResult.action === DiffResultAction.Delete) {
      return this.deleteSurveyQuizQuestion(event, component, surveyQuiz, diffResult.from).pipe(
        switchMap(() => EMPTY)
      );
    }

    return throwError(new Error(`Unknown diff action '${diffResult.action}'`));
  }

  private updateSurveyQuizAnswersFor(event: EventModel, component: ComponentModel, surveyQuiz: SurveyQuizModel,
    surveyQuizQuestion: SurveyQuizQuestionModel): Observable<SurveyQuizQuestionModel> {

    const swapSurveyQuizAnswersBy = (answers: SurveyQuizAnswerModel[]) => {
      return ({ ...surveyQuizQuestion, survey_quiz_answers: answers });
    };

    return this.surveyQuizAnswerService.loadSurveyQuizQuestion(event, component, surveyQuiz, surveyQuizQuestion).pipe(
      switchMap(() => this.surveyQuizAnswerService.diffSurveyQuizAnswers(surveyQuizQuestion.survey_quiz_answers)),
      map(surveyQuizAnswers => swapSurveyQuizAnswersBy(surveyQuizAnswers))
    );
  }
}

function mapSurveyQuizQuestionPost(surveyQuizQuestion: SurveyQuizQuestionModel): SurveyQuizQuestionPostModel {
  return {
    mode: surveyQuizQuestion.mode, order: surveyQuizQuestion.order,
    question: surveyQuizQuestion.question
  };
}

function mapSurveyQuizQuestionPatch(surveyQuizQuestion: SurveyQuizQuestionModel): SurveyQuizQuestionPatchModel {
  return {
    mode: surveyQuizQuestion.mode, order: surveyQuizQuestion.order,
    question: surveyQuizQuestion.question
  };
}

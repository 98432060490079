import { createReducer, on, Action } from '@ngrx/store';

import * as ModalActions from '../actions/modal.actions';

export interface State {
  saving: boolean;
  saved: boolean;
  error: Error;
}

export const initialState: State = {
  saving: false,
  saved: false,
  error: null,
};

export const modalReducer = createReducer(
  initialState,
  on(
    ModalActions.modalCreateVenue,
    ModalActions.modalUpdateVenue,
    state => ({ ...initialState, saving: true })
  ),
  on(
    ModalActions.modalCreateVenueSuccess,
    ModalActions.modalUpdateVenueSuccess,
    state => ({ ...state, saving: false, saved: true })
  ),
  on(
    ModalActions.modalCreateVenueFailure,
    ModalActions.modalUpdateVenueFailure,
    (state, { error }) => ({ ...initialState, error })
  ),
);

export function reducer(state: State, action: Action): State {
  return modalReducer(state, action);
}

export const selectSaving = (state: State) => state.saving;

export const selectSaved = (state: State) => state.saved;

export const selectError = (state: State) => state.error;

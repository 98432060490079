import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { ConstructorService } from '@shared/providers/constructor.service';

@Injectable({
  providedIn: 'root'
})
export class EventTimezoneService {
  private timezone$: Observable<string>;

  constructor(private constructorService: ConstructorService) {
    this.timezone$ = this.constructorService.getEvent().pipe(
      map(event => event ? event.timezone : null), shareReplay()
    );
  }

  getTimezone(): Observable<string> {
    return this.timezone$;
  }
}

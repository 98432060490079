import { SocialMediumService } from '@components/social-medium/providers/social-medium.service';
import { TranslateService } from '@ngx-translate/core';
import { FeedWallThreadService } from '@components/feed-wall/feed-wall-thread.service';
import { BusinessMatchingMeetingStatus } from '@components/business-matching/models/business-matching-meeting.model'
import { BusinessMatchingMeetingService } from '@components/business-matching/providers/business-matching-meeting.service';
import { BookingReservationStatus } from '@components/booking/store/models/booking-reservation.model';
import { BookingReservationFacadeService } from '@components/booking/store/booking-reservation-facade.service';
import { InteractiveMapFacadeService } from '@store/features/interactive-map/providers/interactive-map-facade.service';
import { SurveyQuizService } from '@components/survey-quiz/providers/survey-quiz.service';
import { WwwResourceFacadeService } from '@store/features/www-resource/www-resource-facade.service';
import { PartnerFacadeService } from '@store/features/partner/partner-facade.service';
import { SpeakerFacadeService } from '@store/features/speaker/speaker-facade.service';
import { VenueFacadeService } from '@store/features/venue/venue-facade.service';
import {map, mergeMap, take, first, tap, switchMap, filter} from 'rxjs/operators';
import { combineLatest, EMPTY } from 'rxjs';
import { of } from 'rxjs';

import { ComponentFacadeService } from '@store/features/component/component-facade.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AgendaQuestionStoreService } from '@components/agenda/providers/agenda-question-store.service';
import { AgendaSessionFacadeService } from '@store/features/agenda/agenda-session-facade.service';
import { DropdownOption } from '../dropdown-with-search/dropdown-with-search.component';
import { Injectable } from '@angular/core';
import { EventModel } from '@store/features/event/models/event.model';
import { ComponentModel } from '@shared/models/component.model';
import { ExhibitorFacadeService } from '@store/features/exhibitor/exhibitor-facade.service';
import { AgendaDeepLink, AudioVisualDeepLink, BookingDeepLink, BusinessMatchingDeepLink, ComponentDeepLink, DeepLink, ExhibitorsDeepLink, FeedWallDeepLink, FormsDeepLink, InteractiveMapDeepLink, PartnersDeepLink, QuizzesDeepLink, ResourcesDeepLink, SocialMediaDeepLink, SpeakersDeepLink, UserDeepLink, VenuesDeepLink } from '@utils/deep-link/DeepLink';
import { AgendaQuestionService } from '@components/agenda/providers/agenda-question.service';
import { EventFacadeService } from '@store/features/event/event-facade.service';
import { getComponentChildIds, getComponentIdFromDeepLink, getFirstLevelChildId, getSecondLevelChildId, hasComponentTypeSegment } from '@utils/deep-link/deep-link.util';
import { Store, select } from '@ngrx/store';

import { FeedWallChannelService } from '@components/feed-wall/feed-wall-channel.service';
import { ComponentNames } from '@utils/component-names.util';
import { AudioVisualProviderService } from '@components/audio-visual/store/audio-visual-provider.service';
import { UserPickerSelector } from '@store/features/user';
import { UserPickerAction } from '@store/features/user/actions/user-picker.actions';
import { FormApiService } from '@components/form/store/api/form-api.service';

@Injectable({
  providedIn: 'root',
})
export class DeeplinkBuilderService {

  options: DropdownOption[] = [
    { id: 1, value: ComponentNames.UNDEFINED, name: 'dl.my_components' },
    { id: 2, value: ComponentNames.AGENDA, name: 'dl.agenda' },
    { id: 3, value: ComponentNames.EXHIBITOR, name: 'dl.exhibitors' },
    { id: 4, value: ComponentNames.FEED_WALL, name: 'dl.feed_wall' },
    { id: 5, value: ComponentNames.VENUE, name: 'dl.venues' },
    { id: 6, value: ComponentNames.SPEAKER, name: 'dl.speakers' },
    // { value: 'AttendeesComponent', id: 6, name: 'Attendees', componentName: 'AttendeesComponent' },
    { id: 7, value: ComponentNames.PARTNER, name: 'dl.partners' },
    { id: 8, value: ComponentNames.WWW_RESOURCES, name: 'dl.resources' },
    { id: 9, value: ComponentNames.BUSINESS_MATCHING, name: 'dl.business_matching' },
    // { value: 'BusinessMatchingVideoCallComponent', id: 10, name: 'Business Matching Video Call', componentName: 'BusinessMatchingVideoCallComponent' },
    { id: 11, value: ComponentNames.SURVEY_QUIZ, name: 'dl.quizzes' },
    { id: 12, value: ComponentNames.INTERACTIVE_MAP, name: 'dl.interactive_map' },
    // { value: 'ContentUnlockerComponent', id: 13, name: 'Content Unlocker', componentName: 'ContentUnlockerComponent' },
    { id: 14, value: ComponentNames.BOOKING, name: 'dl.booking' },
    // { value: 'TaxiComponent', id: 15, name: 'Taxi', componentName: 'TaxiComponent' },
    { id: 15, value: ComponentNames.USER, name: 'dl.users' },
    { id: 16, value: ComponentNames.NEWS_SOCIAL_MEDIA, name: 'dl.news' },
    { id: 17, value: ComponentNames.AUDIO_VISUAL, name: 'dl.audio_visual' },
    { id: 18, value: ComponentNames.PHOTO_BOOTH, name: 'dl.photo_booth' },
    { id: 19, value: ComponentNames.FORM, name: 'dl.forms' }
  ];

  public dlTypes$: BehaviorSubject<DropdownOption[]> = new BehaviorSubject<DropdownOption[]>(this.options);
  public selectedDlType$: BehaviorSubject<DropdownOption> = new BehaviorSubject<DropdownOption>(null);
  public customTemplateSecDropdown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public components$: Observable<DropdownOption[]> = this.selectedDlType$.pipe(
    mergeMap(val =>
      this.componentService.getAllComponents().pipe(
        map(components => val?.value === ComponentNames.UNDEFINED ? this.filterComponentsForUndefinedDL(components) : components?.filter(component => component.componentName === val?.value)),
        map((components: ComponentModel[]) =>
          components?.map(component => ({ id: component.id, name: component.label, value: component }))
        )
      )
    ),
  );

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public selectedComponent$: BehaviorSubject<ComponentModel> = new BehaviorSubject<ComponentModel>(null);
  public selectedComponentOption$: BehaviorSubject<DropdownOption> = new BehaviorSubject<DropdownOption>(null);

  public isComponentDropdownVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public dynamicDropdowns$: Observable<DeepLinkDropdown[]> = combineLatest([
    this.selectedDlType$,
    this.selectedComponent$,
  ]).pipe(mergeMap(([dlType, component]) => this.getDynamicDeepLinkDropdowns(dlType, component)));

  public selectedFirstLevel$: BehaviorSubject<DropdownOption> = new BehaviorSubject<DropdownOption>(null);
  public selectedSecondLevel$: BehaviorSubject<DropdownOption> = new BehaviorSubject<DropdownOption>(null);

  private loadEntitiesObservable: Observable<any> = combineLatest([
    this.selectedDlType$,
    this.selectedComponent$,
    this.selectedFirstLevel$,
  ]).pipe(map(([dlType, component, firstLevel]) => this.loadEntities(dlType, component, firstLevel)))

  public deepLink$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private isDeepLinkInitialized = false;

  constructor(
    private eventFacadeService: EventFacadeService,
    private agendaSession: AgendaSessionFacadeService,
    private agendaQuestion: AgendaQuestionStoreService,
    private agendaQuestionService: AgendaQuestionService,
    private componentService: ComponentFacadeService,
    private exhibitorsService: ExhibitorFacadeService,
    private venueFacadeService: VenueFacadeService,
    private speakerService: SpeakerFacadeService,
    private partnerService: PartnerFacadeService,
    private resourceService: WwwResourceFacadeService,
    private surveyQuizService: SurveyQuizService,
    private interactiveMapService: InteractiveMapFacadeService,
    private bookingReservationService: BookingReservationFacadeService,
    private businessMatchingMeetingService: BusinessMatchingMeetingService,
    private store$: Store<any>,
    private feedWallChannelService: FeedWallChannelService,
    private feedWallThreadService: FeedWallThreadService,
    private i18n: TranslateService,
    private socialMediumService: SocialMediumService,
    private audioVisualService: AudioVisualProviderService,
    private formApiService: FormApiService,
  ) {
    this.loadEntitiesObservable.subscribe();
  }

  filterComponentsForUndefinedDL(components: ComponentModel[]): ComponentModel[] {
    return components
      .filter(c => c.componentName !== ComponentNames.PUSH && c.componentName !== ComponentNames.MODERATOR_COMPONENT && c.componentName !== ComponentNames.BANNER);
  }

  setDeepLink(deepLink: string): void {
    this.deepLink$.next(deepLink);
    this.setInitialSelection(deepLink);
  }

  loadEntities(dlType: DropdownOption, component: ComponentModel, firstLevelValue?: DropdownOption): void {
    if (!dlType || !component) return;

    this.eventFacadeService.getActiveEvent()
      .pipe(
        take(1),
      )
      .subscribe(event => {
        switch(dlType.value) {
          case ComponentNames.AGENDA: {
            this.loadAgendaEntities(event, component, firstLevelValue?.value);
            this.agendaSession.getAgendaSessionLoading().subscribe(loading => this.isLoading$.next(loading));
            break;
          }
          case ComponentNames.EXHIBITOR: {
            this.loadExhibitorsEntities(event, component);
            this.exhibitorsService.getExhibitorLoading(component).subscribe(loading => this.isLoading$.next(loading));
            break;
          }
          case ComponentNames.VENUE: {
            this.loadVenuesEntities(event, component);
            this.venueFacadeService.getVenueLoading().subscribe(loading => this.isLoading$.next(loading));
            break;
          }
          case ComponentNames.SPEAKER: {
            this.loadSpeakersEntities(event, component);
            this.speakerService.getSpeakerLoading(component).subscribe(loading => this.isLoading$.next(loading));
            break;
          }
          case ComponentNames.PARTNER: {
            this.loadPartnersEntities(event, component);
            this.partnerService.getPartnerLoading(component).subscribe(loading => this.isLoading$.next(loading));
            break;
          }
          case ComponentNames.WWW_RESOURCES: {
            this.loadResourcesEntities(event, component);
            this.resourceService.getWwwResourceLoading().subscribe(loading => this.isLoading$.next(loading));
            break;
          }
          case ComponentNames.SURVEY_QUIZ: {
            this.loadQuizzesEntities(event, component);
            break;
          }
          case ComponentNames.INTERACTIVE_MAP: {
            this.loadInteractiveMapEntities(event, component);
            this.interactiveMapService.getInteractiveMapLoading().subscribe(loading => this.isLoading$.next(loading));
            break;
          }
          case ComponentNames.BOOKING: {
            this.loadBookingEntities(event, component);
            this.bookingReservationService.isLoading().subscribe(loading => this.isLoading$.next(loading));
            break;
          }
          case ComponentNames.BUSINESS_MATCHING: {
            this.loadBusinessMatchingEntities(event, component);
            break;
          }
          case ComponentNames.USER: {
            this.loadUsersEntities(event, component);
            this.store$.pipe(select(UserPickerSelector.selectPickerUsersLoading)).subscribe(loading => this.isLoading$.next(loading));
            break;
          }
          case ComponentNames.FEED_WALL: {
            this.loadFeedWallEntities(event, component);
            break;
          }
          case ComponentNames.NEWS_SOCIAL_MEDIA: {
            this.loadSocialMediaEntities(event, component);
            break;
          }
          case ComponentNames.AUDIO_VISUAL: {
            this.loadAudioVisualEntities(event, component);
            this.audioVisualService.isLoading.subscribe(loading => this.isLoading$.next(loading));
            break;
          }
        }
      });
  }

  private loadAgendaEntities(event: EventModel, component: ComponentModel, firstLevelValue: any) {
    if(firstLevelValue) {
      this.agendaQuestionService.loadAgendaSession(event, component, firstLevelValue)
        .subscribe();
    } else {
      this.agendaSession.loadAgendaSessions(event, component , false);
    }
  }

  private loadAudioVisualEntities(event: EventModel, component: ComponentModel) {
    this.audioVisualService.loadAudioVisualsForDeepLinks(event, component);
  }

  private loadExhibitorsEntities(event: EventModel, component: ComponentModel) {
    this.exhibitorsService.loadExhibitors(component);
  }

  private loadVenuesEntities(event: EventModel, component: ComponentModel) {
    this.venueFacadeService.loadVenues();
  }
  
  private loadSpeakersEntities(event: EventModel, component: ComponentModel) {
    this.speakerService.loadSpeakers(event, component);
  }

  private loadPartnersEntities(event: EventModel, component: ComponentModel) {
    this.partnerService.loadPartners(component);
  }

  private loadResourcesEntities(event: EventModel, component: ComponentModel) {
    this.resourceService.loadWwwResourcesByComponent(component, false);
  }

  private loadQuizzesEntities(event: EventModel, component: ComponentModel) {
    this.surveyQuizService.loadComponent(event, component).subscribe();
    // we need to subscribe this observable because on every component change we need to load new data
    // there is no endpoint to load all available quizzes from selected component
  }

  private loadInteractiveMapEntities(event: EventModel, component: ComponentModel) {
    this.interactiveMapService.loadInteractiveMaps(event, component);
  }

  private loadBookingEntities(event: EventModel, component: ComponentModel) {
    this.bookingReservationService.loadBookingReservationsForDlBuilder(event, component);
  }

  private loadBusinessMatchingEntities(event: EventModel, component: ComponentModel) {
    this.businessMatchingMeetingService.loadComponent(event, component).subscribe(); // same as quizzes
  }

  private loadUsersEntities(event: EventModel, component: ComponentModel) {
    this.store$.dispatch(
      UserPickerAction.loadEventUsers({ event })
    );
  }

  private loadFeedWallEntities(event: EventModel, component: ComponentModel) {
    this.feedWallChannelService.loadComponent(event, component).subscribe();
  }

  private loadSocialMediaEntities(event: EventModel, component: ComponentModel) {
    this.socialMediumService.loadComponent(event, component).subscribe();
  }

  selectDlType(option: DropdownOption): void {
    this.selectedFirstLevel$.next(null);
    this.selectedSecondLevel$.next(null);
    this.selectedComponent$.next(null);
    this.selectedComponentOption$.next(null);
    this.selectedDlType$.next(option);

    if(option.value === ComponentNames.USER) {
      this.isComponentDropdownVisible$.next(false);
      this.selectedComponent$.next({ id: -1, eventId: -1, iconId: -1, uuid: '', label: 'Component Mock', order: -1, componentName: 'Users', defaultComponent: false, visibleInApp: false, additionalInfo: {}, firstToOpenMode: null  });
    } else {
      this.isComponentDropdownVisible$.next(true);
    }
  }

  reset(): void {
    this.selectedSecondLevel$.next(null);
    this.selectedFirstLevel$.next(null);
    this.selectedComponent$.next(null);
    this.selectedDlType$.next(null);
    this.isDeepLinkInitialized = false;
  }

  onComponentSelected(option: DropdownOption): void {
    if(!option) return;
    this.selectedFirstLevel$.next(null);
    this.selectedSecondLevel$.next(null);
    this.selectedComponentOption$.next(option);
    this.selectedComponent$.next(option.value);
  }

  onDynamicDropdownSelected(option: DropdownOption, index: number): void {
    switch (index) {
      case 0: {
        this.selectedSecondLevel$.next(null);
        this.selectedFirstLevel$.next(option);
        break;
      }
      case 1: {
        this.selectedSecondLevel$.next(option);
      }
    }
  }

  isDynamicDropdownDisabled(dynamicDropdownIndex: number): boolean {
    switch (dynamicDropdownIndex) {
      case 1: {
        return !this.selectedFirstLevel$.value;
      }
      default: {
        return false;
      }
    }
  }

  tryToSave(): boolean {
    if (this.selectedDlType$.value) {
      this.eventFacadeService.getActiveEvent()
        .pipe(take(1))
        .subscribe(event => {
          let deepLink: DeepLink;
          switch(this.selectedDlType$.value.value) {
            case ComponentNames.UNDEFINED: {
              deepLink = new ComponentDeepLink(
                event.appId,
                event.id,
                this.selectedComponent$.value.id,
              );  
              break;
            }
            case ComponentNames.AGENDA: {
              deepLink = new AgendaDeepLink(
                event.appId,
                event.id,
                this.selectedComponent$.value.id,
                this.selectedFirstLevel$?.value?.id,
                this.selectedSecondLevel$?.value?.id
              );
              break;
            }
            case ComponentNames.EXHIBITOR: {
              deepLink = new ExhibitorsDeepLink(
                event.appId,
                event.id,
                this.selectedComponent$?.value?.id,
                this.selectedFirstLevel$?.value?.id,
              );
              break;
            }
            case ComponentNames.VENUE: {
              deepLink = new VenuesDeepLink(
                event.appId,
                event.id,
                this.selectedComponent$.value.id,
                this.selectedFirstLevel$?.value?.id,
                this.selectedSecondLevel$?.value?.id
              );
              break;
            }
            case ComponentNames.SPEAKER: {
              deepLink = new SpeakersDeepLink(
                event.appId,
                event.id,
                this.selectedComponent$?.value?.id,
                this.selectedFirstLevel$?.value?.id,
              );
              break;
            }
            case ComponentNames.PARTNER: {
              deepLink = new PartnersDeepLink(
                event.appId,
                event.id,
                this.selectedComponent$?.value?.id,
                this.selectedFirstLevel$?.value?.id,
              );
              break;
            }
            case ComponentNames.WWW_RESOURCES: {
              deepLink = new ResourcesDeepLink(
                event.appId,
                event.id,
                this.selectedComponent$.value.id,
                this.selectedFirstLevel$?.value?.id,
                this.selectedSecondLevel$?.value?.id
              );
              break;
            }
            case ComponentNames.SURVEY_QUIZ: {
              deepLink = new QuizzesDeepLink(
                event.appId,
                event.id,
                this.selectedComponent$?.value?.id,
                this.selectedFirstLevel$?.value?.id,
              );
              break;
            }
            case ComponentNames.INTERACTIVE_MAP: {
              deepLink = new InteractiveMapDeepLink(
                event.appId,
                event.id,
                this.selectedComponent$.value.id,
                this.selectedFirstLevel$?.value?.id,
                this.selectedSecondLevel$?.value?.id
              );
              break;
            }
            case ComponentNames.BOOKING: {
              deepLink = new BookingDeepLink(
                event.appId,
                event.id,
                this.selectedComponent$.value.id,
                this.selectedFirstLevel$?.value?.value.type,
                this.selectedSecondLevel$?.value?.id
              );
              break;
            }
            case ComponentNames.BUSINESS_MATCHING: {
              deepLink = new BusinessMatchingDeepLink(
                event.appId,
                event.id,
                this.selectedComponent$.value.id,
                this.selectedFirstLevel$?.value?.value.type,
                this.selectedSecondLevel$?.value?.id
              );
              break;
            }
            case ComponentNames.USER: {
              deepLink = new UserDeepLink(
                event.appId,
                this.selectedFirstLevel$?.value?.value.uuid
              );
              break;
            }
            case ComponentNames.FEED_WALL: {
              deepLink = new FeedWallDeepLink(
                event.appId,
                event.id,
                this.selectedComponent$.value.id,
                this.selectedFirstLevel$?.value?.id,
                this.selectedSecondLevel$?.value?.id
              );
              break;
            }
            case ComponentNames.NEWS_SOCIAL_MEDIA: {
              deepLink = new SocialMediaDeepLink(
                event.appId,
                event.id,
                this.selectedComponent$.value.id,
                this.selectedFirstLevel$?.value?.id,
              );
              break;
            }
            case ComponentNames.AUDIO_VISUAL: {
              deepLink = new AudioVisualDeepLink(
                event.appId,
                event.id,
                this.selectedComponent$.value.id,
                this.selectedFirstLevel$?.value?.id,
                this.selectedSecondLevel$?.value?.id,
              );
              break;
            }
            case ComponentNames.FORM: {
              deepLink = new FormsDeepLink(
                event.appId,
                event.id,
                this.selectedComponent$.value.id,
                this.selectedFirstLevel$?.value?.id,
              );
              break;
            }
          }
          this.deepLink$.next(deepLink.getDeepLink())
        })
      return true;
    } else {
      this.deepLink$.next(null);
      return true;
    }
  }

  // ---------------- //
  // DYNAMIC DROPDOWNS
  // ---------------- //

  getDynamicDeepLinkDropdowns(dlType: DropdownOption, component: ComponentModel): Observable<DeepLinkDropdown[]> {
    if (dlType && component) {
      switch (dlType.value) {
        case ComponentNames.UNDEFINED: {
          return of([]);
        }
        case ComponentNames.AGENDA: {
          return of([
            {
              label: this.i18n.instant('dl.select_agenda_session'),
              options: this.getFirstLevelValuesObservable(component),
              required: false,
            },
            {
              label: this.i18n.instant('dl.select_question'),
              options: this.getSecondLevelValuesObservable(),
              required: false,
            },
          ]);
        }
        case ComponentNames.EXHIBITOR: {
          return of([
            {
              label: this.i18n.instant('dl.select_exhibitor'),
              options: this.getFirstLevelValuesObservable(component),
              required: false,
            },
          ]);
        }
        case ComponentNames.VENUE: {
          return of([
            {
              label: this.i18n.instant('dl.select_category'),
              options: this.getFirstLevelValuesObservable(component),
              required: false,
            },
            {
              label: this.i18n.instant('dl.select_location'),
              options: this.getSecondLevelValuesObservable(),
              required: false,
            }
          ]);
        }
        case ComponentNames.SPEAKER: {
          return of([
            {
              label: this.i18n.instant('dl.select_speaker'),
              options: this.getFirstLevelValuesObservable(component),
              required: false,
            }
          ]);
        }
        case ComponentNames.PARTNER: {
          return of([
            {
              label: this.i18n.instant('dl.select_partner'),
              options: this.getFirstLevelValuesObservable(component),
              required: false,
            }
          ]);
        }
        case ComponentNames.WWW_RESOURCES: {
          return of([
            {
              label: this.i18n.instant('dl.select_category'),
              options: this.getFirstLevelValuesObservable(component),
              required: false,
            },
            {
              label: this.i18n.instant('dl.select_resource'),
              options: this.getSecondLevelValuesObservable(),
              required: false,
            }
          ]);
        }
        case ComponentNames.SURVEY_QUIZ: {
          return of([
            {
              label: this.i18n.instant('dl.select_quiz'),
              options: this.getFirstLevelValuesObservable(component),
              required: false,
            }
          ]);
        }
        case ComponentNames.INTERACTIVE_MAP: {
          return of([
            {
              label: this.i18n.instant('dl.select_map'),
              options: this.getFirstLevelValuesObservable(component),
              required: false,
            },
            {
              label: this.i18n.instant('dl.select_location'),
              options: this.getSecondLevelValuesObservable(),
              required: false,
            }
          ]);
        }
        case ComponentNames.BOOKING: {
          return of([
            {
              label: this.i18n.instant('dl.select_reservation_status'),
              options: this.getFirstLevelValuesObservable(component),
              required: false,
            },
            {
              label: this.i18n.instant('dl.select_user'),
              options: this.getSecondLevelValuesObservable(),
              required: false,
            }
          ]);
        }
        case ComponentNames.BUSINESS_MATCHING: {
          return of([
            {
              label: this.i18n.instant('dl.select_matching_status'),
              options: this.getFirstLevelValuesObservable(component),
              required: false,
            },
            {
              label: this.i18n.instant('dl.select_meeting'),
              options: this.getSecondLevelValuesObservable(),
              required: false,
            }
          ]);
        }
        case ComponentNames.AUDIO_VISUAL: {
          return of([
            {
              label: this.i18n.instant('dl.select_category'),
              options: this.getFirstLevelValuesObservable(component),
              required: false,
            },
            {
              label: this.i18n.instant('dl.select_file'),
              options: this.getSecondLevelValuesObservable(),
              required: false,
            }
          ]);
        }
        case ComponentNames.USER: {
          return of([
            {
              label: this.i18n.instant('dl.select_user'),
              options: this.getFirstLevelValuesObservable(component),
              required: false,
            }
          ]);
        }
        case ComponentNames.FEED_WALL: {
          return of([
            {
              label: this.i18n.instant('dl.select_channel'),
              options: this.getFirstLevelValuesObservable(component),
              required: false,
            },
            {
              label: this.i18n.instant('dl.select_thread'),
              options: this.getSecondLevelValuesObservable(),
              required: false,
            }
          ]);
        }
        case ComponentNames.NEWS_SOCIAL_MEDIA: {
          return of([
            {
              label: this.i18n.instant('dl.select_news'),
              options: this.getFirstLevelValuesObservable(component),
              required: false,
            }
          ]);
        }
        case ComponentNames.FORM: {
          return of([
            {
              label: this.i18n.instant('dl.select_form'),
              options: this.getFirstLevelValuesObservable(component),
              required: false,
            }
          ]);
        }
      }
    } else {
      return of(null);
    }
  }

  getFirstLevelValuesObservable(component: ComponentModel): Observable<DropdownOption[]> {
    if (component) {
      let observable: Observable<any>;
      switch (component.componentName) {
        case ComponentNames.AGENDA: {
          observable = this.agendaSession.getAgendaSessions(component);
          break;
        }
        case ComponentNames.EXHIBITOR: {
          observable = this.exhibitorsService.getExhibitors(component);
          break;
        }
        case ComponentNames.VENUE: {
          observable = this.venueFacadeService.getVenueCategoriesByComponent(component);
          break;
        }
        case ComponentNames.SPEAKER: {
          observable = this.speakerService.getSpeakers(component);
          break;
        }
        case ComponentNames.PARTNER: {
          observable = this.partnerService.getPartners(component);
          break;
        }
        case ComponentNames.WWW_RESOURCES: {
          observable = this.resourceService.getWwwResourcesCategoriesByComponent(component);
          break;
        }
        case ComponentNames.SURVEY_QUIZ: {
          observable = this.surveyQuizService.getSurveyQuizzes();
          break;
        }
        case ComponentNames.INTERACTIVE_MAP: {
          observable = this.interactiveMapService.getAllInteractiveMaps();
          break;
        }
        case ComponentNames.BOOKING: {
          observable = of([{ id: 1, type: BookingReservationStatus.Accepted, name: this.i18n.instant('dl.accepted') }]);
          break;
        }
        case ComponentNames.BUSINESS_MATCHING: {
          observable = of([{ id: 1, type: BusinessMatchingMeetingStatus.Accepted, name: this.i18n.instant('dl.accepted') }]);
          break;
        }
        case ComponentNames.USER: {
          observable = this.store$.pipe(
            select(UserPickerSelector.selectPickerUsers),
          );
          break;
        }
        case ComponentNames.FEED_WALL: {
          observable = this.feedWallChannelService.getFeedWallChannels();
          break;
        }
        case ComponentNames.NEWS_SOCIAL_MEDIA: {
          observable = this.socialMediumService.getSocialMedia();
          break;
        }
        case ComponentNames.AUDIO_VISUAL: {
          observable = this.audioVisualService.audioVisuals;
        }
        case ComponentNames.FORM: {
          observable = this.eventFacadeService.getActiveEvent().pipe(
            filter(event => !!event),
            take(1),
            switchMap(event => this.formApiService.getAll(event.id, component.id))
          );
        }
      }
      return observable.pipe(
        map(values => this.mapObjectsToDlOptions(values)),
        tap(values => this.selectFirstLevelDropdown(values))
      );
    } else {
      return EMPTY;
    }
  }

  getSecondLevelValuesObservable(): Observable<DropdownOption[]> {
    return combineLatest([this.selectedDlType$, this.selectedComponent$, this.eventFacadeService.getActiveEvent(), this.selectedFirstLevel$]).pipe(
      mergeMap(([dlType, component, event, firstLevelDropdownOption]) => {
        if(firstLevelDropdownOption) {
          let observable: Observable<any>;
          switch (dlType.value) {
            case ComponentNames.AGENDA: {
              observable = this.agendaQuestion.getOrderedAgendaQuestionsFromSession(firstLevelDropdownOption?.id);
              break;
            }
            case ComponentNames.EXHIBITOR: {
              observable = EMPTY;
              break;
            }
            case ComponentNames.VENUE: {
              observable = this.venueFacadeService.getVenuesByComponent(component)
                .pipe(
                  map(venues => venues.filter(venue => venue.categoryId === firstLevelDropdownOption.id))
                );
              break;
            }
            case ComponentNames.SPEAKER: {
              observable = EMPTY;
              break;
            }
            case ComponentNames.PARTNER: {
              observable = EMPTY;
              break;
            }
            case ComponentNames.WWW_RESOURCES: {
              observable = this.resourceService.getWwwResourcesByComponent(component)
                .pipe(
                  map(resources => resources.filter(resource => resource.categoryId === firstLevelDropdownOption.id))
                );
              break;
            }
            case ComponentNames.INTERACTIVE_MAP: {
              observable = this.interactiveMapService.getInteractiveMapLocations(firstLevelDropdownOption.value);
              break;
            }
            case ComponentNames.BOOKING: {
              observable = this.bookingReservationService.getAll();
              break;
            }
            case ComponentNames.BUSINESS_MATCHING: {
              observable = this.businessMatchingMeetingService.getBusinessMatchingMeetings();
              break;
            }
            case ComponentNames.AUDIO_VISUAL: {
              observable = this.audioVisualService.getAudioVisualFilesForDeepLink(firstLevelDropdownOption.value);
              break;
            }
            case ComponentNames.USER: {
              observable = EMPTY;
              break;
            }
            case ComponentNames.FEED_WALL: {
              observable = this.feedWallThreadService.loadFeedWallChannel(event, component, firstLevelDropdownOption.value);
              break;
            }
            case ComponentNames.NEWS_SOCIAL_MEDIA: {
              observable = EMPTY;
              break;
            }
          }
          return observable.pipe(
            map(values => this.mapObjectsToDlOptions(values)),
            tap(values => this.selectSecondLevelDropdown(values))
          );
        } else {
          return of(null);
        }
      })
    );
  }

  mapObjectsToDlOptions(objects: any[]): DropdownOption[] {
    if (objects === undefined || objects === null) return;
    if(objects.length < 0 || objects.includes(undefined)) return [];
    return objects.map(object => {
      return {
        id: object.id as number,
        name: (
          object.name
          || object.title
          || object.label
          || object.question
          || object.firstName && `${object.firstName} ${object.lastName}`
          || object.from_user?.first_name && `${object.from_user?.first_name} ${object.from_user?.last_name} -> ${object.to_user?.first_name} ${object.to_user?.last_name}`
          || object.user && `${object.user.first_name} ${object.user.last_name}`
          || this.i18n.instant('dl.not_defined')
        ) as string,
        value: object,
      } as DropdownOption;
    });
  }

  getComponentById(id: number): Observable<ComponentModel> {
    return this.componentService.getAllComponents().pipe(map(components => components.filter(c => c.id === id).shift()))
  }

  mapComponentToOption(component: ComponentModel): DropdownOption {
    if(!component) return;
    return {
      id: component.id,
      name: component.label,
      value: component,
    } as DropdownOption;
  }

  setInitialSelection(deepLink: string): void {
    if(!deepLink) return;
    const componentId = getComponentIdFromDeepLink(deepLink);

    this.getComponentById(componentId).pipe(first()).subscribe(component => {
      let opt = this.options.find(o => o.value === component?.componentName);
      if (!hasComponentTypeSegment(deepLink)) {
        opt = this.options.find(o => o.value === ComponentNames.UNDEFINED);
      }
      this.selectedDlType$.next(opt);
      this.onComponentSelected(this.mapComponentToOption(component));
      
      const childIds = getComponentChildIds(deepLink);
      if(!childIds.firstLevelChildId && !childIds.secondLevelChildId) {
        this.isDeepLinkInitialized = true;
      }
    })
  }

  selectFirstLevelDropdown(values: DropdownOption[]) {
    if(!this.isDeepLinkInitialized && values && values.length) {
      let currentDeepLink = this.deepLink$.value;
      const firstLevelChildId = getFirstLevelChildId(currentDeepLink);
      if(currentDeepLink && firstLevelChildId) {
        const item = values.find(s => s.id === firstLevelChildId);
        this.selectedFirstLevel$.next(this.mapObjectsToDlOptions([item])[0]);
        if(!getSecondLevelChildId(currentDeepLink)) {
          this.isDeepLinkInitialized = true;
        }
      }
    }
  }

  selectSecondLevelDropdown(values: DropdownOption[]) {
    if(!this.isDeepLinkInitialized && values && values.length) {
      let currentDeepLink = this.deepLink$.value;
      const secondLevelChildId = getSecondLevelChildId(currentDeepLink);
      if(currentDeepLink && secondLevelChildId) {
        const item = values.find(s => s.id === secondLevelChildId);
        this.selectedSecondLevel$.next(this.mapObjectsToDlOptions([item])[0]);
      }
      this.isDeepLinkInitialized = true;
    }
  }

  isNotValid(): Observable<string> {
    return combineLatest([this.selectedDlType$, this.selectedComponent$, this.selectedFirstLevel$, this.selectedSecondLevel$, this.dynamicDropdowns$, this.isComponentDropdownVisible$]).pipe(
      map(([dlType, component, firstLevel, secondLevel, dynamicDropdowns, compDropdownVisible]) => {
        if(!!dlType && !component && compDropdownVisible) return this.i18n.instant('dl.select_component');
        if(!!dlType && !firstLevel && (dynamicDropdowns && dynamicDropdowns[0] && dynamicDropdowns[0].required)) return this.i18n.instant('dl.select_all_req_fields');
        if(!!dlType && !secondLevel && (dynamicDropdowns && dynamicDropdowns[1] && dynamicDropdowns[1].required)) return this.i18n.instant('dl.select_all_req_fields');
        return;
      })
    );
  }
}

export interface DeepLinkDropdown {
  label: string;
  options: Observable<DropdownOption[]>;
  required: boolean;
  disabled?: boolean;
}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { switchMap, catchError, filter, map, first, mergeMap, tap } from 'rxjs/operators';

import { TokenService } from '@app/core/services/token.service';
import { TokenModel } from '@app/core/models/token.model';

import { API_BASE_URL } from '@env/environment';
import { RouterMainView, RouterProviderService } from '@shared/providers/router-provider.service';

const TOKEN_HEADER = "Authorization";

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService, private routerProvider: RouterProviderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!isApiRequest(req) || isWhitelisted(req)) {
      return next.handle(req);
    }
    return this.handleRequest(req, next);
  }

  private handleRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokenReady$ = this.tokenService.getIsRefreshing().pipe(
      //block current request until refresh token request is completed
      filter(isRefreshing => !isRefreshing),
      switchMap(() => this.tokenService.getToken()),
      first()
    );

    return tokenReady$.pipe(
      map(token => decorateRequest(req, token)),
      mergeMap(tokenizedReq =>
        next.handle(tokenizedReq)
          .pipe(
            catchError(error => this.handleError(error, tokenizedReq, next))
          )
      ),
    );
  }

  private handleError(error: HttpEvent<any>, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (error instanceof HttpErrorResponse && error.status === 401) {

      // if the failed request is for the token, throw error -> that means the refresh token is wrong.
      if (isRefreshTokenRequest(req)) {
        return throwError(error);
      }
      return this.tokenService.getIsRefreshing()
        .pipe(
          first(),
          mergeMap(isRefreshing =>
            this.tokenService.getToken().pipe(
              first(),
              tap(tokensObject => {
                if (isRefreshing) {
                  return of(true)
                }
                //refresh token only if it has not changed and is not being currently refreshed
                const tokenFromHeader = req.headers.get(TOKEN_HEADER)?.replace("Bearer ", "");
                if (!isRefreshing && tokenFromHeader !== null && tokenFromHeader === tokensObject.token) {
                  this.tokenService.refreshToken(tokensObject);
                } else {
                  if (!tokensObject || !tokensObject.token || !tokensObject.refreshToken) {
                    this.routerProvider.toMainView(RouterMainView.ACCOUNT);
                    return of(false);
                  }
                }
              })
            )
          ),
          mergeMap((refreshRequestSended) => {
            if (refreshRequestSended) {
              return this.handleRequest(req, next);
            } else {
              // if refresh token is not exist
              return of(true);
            }
          }),
        )
    }
    return throwError(error);
  }
}

function isApiRequest(req: HttpRequest<any>): boolean {
  return req.url.startsWith(API_BASE_URL);
}

function isRefreshTokenRequest(req: HttpRequest<any>): boolean {
  return req.url.indexOf('/refresh_token') !== -1;
}

function isWhitelisted(req: HttpRequest<any>): boolean {
  const whitelist = ['/login', '/register', '/refresh_token', '/recovery_account', '/send_magic_link'];
  return whitelist.some(fragment => req.url.includes(fragment));
}

function decorateRequest(req: HttpRequest<any>, token: TokenModel): HttpRequest<any> {
  if (token) {
    const headers = req.headers.set(TOKEN_HEADER, `Bearer ${token?.token}`);
    return req.clone({ headers });
  } else {
    return req;
  }
}
